<template>
  <button class="redoButton" @click="reverseStatus">
    <span>{{ $t(`monthlyPl.${month.status.toLowerCase()}`) }}</span>
    <redo-icon class="redoIcon" />
    <span class="tooltip">{{ $t('monthlyPl.reverse') }}</span>
  </button>
</template>

<script>
import RedoIcon from '@/assets/svg/RedoIcon.svg'
export default {
  name: 'MonthlyPlColumnRedoButton',
  components: { RedoIcon },
  props: {
    monthlyIndex: {
      type: Number,
      required: true,
    },
    month: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async reverseStatus() {
      try {
        await this.$store.dispatch('monthlyPl/reverseStatus', {
          month: this.month.month,
        })
        await this.$store.dispatch('monthlyPl/changeEditingFlag', {
          monthlyIndex: this.monthlyIndex,
          value: false,
        })
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.redoButton {
  background-color: $color-theme;
  border-radius: 4px;
  color: $color-white;
  margin: 2px;
  height: 24px;
  transform: translateY(-3px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  z-index: 2;
  width: 100%;

  .redoIcon {
    height: 24px;
    position: absolute;
    right: 0;
  }

  &:hover {
    span.tooltip {
      opacity: 1;
    }
  }

  span.tooltip {
    position: absolute;
    left: calc(100% + 8px);
    top: 50%;
    background-color: $color-orange;
    color: $color-white;
    font-size: 14px;
    transform: translate(0, -50%);
    white-space: nowrap;
    padding: 5px 15px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s;
    opacity: 0;

    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: $color-orange;
      left: 0%;
      bottom: 50%;
      transform: translate(-50%, 50%) rotate(45deg);
    }
  }
}
</style>
