import chartUtil from './chartUtil'

export default {
  yearCostOptions(term) {
    return {
      chart: {
        stacked: true,
        offsetX: -10,
        animations: {
          enabled: false,
          easing: 'easeout',
          speed: 300,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
        },
      },
      dataLabels: {
        enabled: false,
        background: {
          enabled: false,
        },
      },
      fill: {
        colors: ['#FF3C50', '#FF5A64', '#FF7878', '#FF968C'],
        opacity: 1,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      xaxis: {
        categories: term,
        labels: {
          style: {
            colors: '#666666',
          },
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: [
        {
          seriesName: 'Sales&Marketing',
          tickAmount: 10,
          showAlways: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: '#666666',
            },
            formatter: (val) => {
              return chartUtil.formatAmount(val)
            },
          },
        },
      ],
      legend: {
        show: true,
        showForSingleSeries: true,
        fontSize: '10px',
        position: 'bottom',
        horizontalAlign: 'right',
        markers: {
          fillColors: ['#FF3C50', '#FF5A64', '#FF7878', '#FF968C'],
        },
        labels: {
          colors: ['#666666'],
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    }
  },

  costCogsOptions(term) {
    return {
      chart: {
        stacked: true,
        offsetX: -10,
        animations: {
          enabled: false,
          easing: 'easeout',
          speed: 300,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
        },
      },
      dataLabels: {
        enabled: false,
        background: {
          enabled: false,
        },
      },
      fill: {
        colors: ['#FF3C50', '#FF6373', '#FF8A96'],
        opacity: 1,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      xaxis: {
        categories: term,
        labels: {
          style: {
            colors: '#666666',
          },
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: [
        {
          seriesName: 'COGS',
          showAlways: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: '#666666',
            },
            formatter: (val) => {
              return chartUtil.formatAmount(val)
            },
          },
        },
      ],
      legend: {
        show: true,
        showForSingleSeries: true,
        fontSize: '10px',
        position: 'bottom',
        horizontalAlign: 'right',
        markers: {
          fillColors: ['#FF3C50', '#FF6373', '#FF8A96'],
        },
        labels: {
          colors: ['#666666'],
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    }
  },

  costSmOptions(term) {
    return {
      chart: {
        stacked: true,
        offsetX: -10,
        animations: {
          enabled: false,
          easing: 'easeout',
          speed: 300,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
        },
      },
      dataLabels: {
        enabled: false,
        background: {
          enabled: false,
        },
      },
      fill: {
        colors: ['#FF5A64', '#FF7B83', '#FF9CA2'],
        opacity: 1,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      xaxis: {
        categories: term,
        labels: {
          style: {
            colors: '#666666',
          },
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: [
        {
          seriesName: 'COGS',
          showAlways: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: '#666666',
            },
            formatter: (val) => {
              return chartUtil.formatAmount(val)
            },
          },
        },
      ],
      legend: {
        show: true,
        showForSingleSeries: true,
        fontSize: '10px',
        position: 'bottom',
        horizontalAlign: 'right',
        markers: {
          fillColors: ['#FF5A64', '#FF7B83', '#FF9CA2'],
        },
        labels: {
          colors: ['#666666'],
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    }
  },

  costRdOptions(term) {
    return {
      chart: {
        stacked: true,
        offsetX: -10,
        animations: {
          enabled: false,
          easing: 'easeout',
          speed: 300,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
        },
      },
      dataLabels: {
        enabled: false,
        background: {
          enabled: false,
        },
      },
      fill: {
        colors: ['#FF7878', '#FF9CA2'],
        opacity: 1,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      xaxis: {
        categories: term,
        labels: {
          style: {
            colors: '#666666',
          },
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: [
        {
          seriesName: 'COGS',
          showAlways: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: '#666666',
            },
            formatter: (val) => {
              return chartUtil.formatAmount(val)
            },
          },
        },
      ],
      legend: {
        show: true,
        showForSingleSeries: true,
        fontSize: '10px',
        position: 'bottom',
        horizontalAlign: 'right',
        markers: {
          fillColors: ['#FF7878', '#FF9CA2'],
        },
        labels: {
          colors: ['#666666'],
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    }
  },

  costGaOptions(term) {
    return {
      chart: {
        stacked: true,
        offsetX: -10,
        animations: {
          enabled: false,
          easing: 'easeout',
          speed: 300,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
        },
      },
      dataLabels: {
        enabled: false,
        background: {
          enabled: false,
        },
      },
      fill: {
        colors: ['#FF968C', '#FFABA3', '#FFC0BA'],
        opacity: 1,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      xaxis: {
        categories: term,
        labels: {
          style: {
            colors: '#666666',
          },
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: [
        {
          seriesName: 'COGS',
          showAlways: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: '#666666',
            },
            formatter: (val) => {
              return chartUtil.formatAmount(val)
            },
          },
        },
      ],
      legend: {
        show: true,
        showForSingleSeries: true,
        fontSize: '10px',
        position: 'bottom',
        horizontalAlign: 'right',
        markers: {
          fillColors: ['#FF968C', '#FFABA3', '#FFC0BA'],
        },
        labels: {
          colors: ['#666666'],
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    }
  },
}
