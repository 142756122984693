<template>
  <section class="kpi__panel">
    <accordion-panel :title="$t('kpi.monthly.title')">
      <template #contents>
        <div v-if="template === 'saas'">
          <apexchart
            :options="uuOptions"
            :series="uuSeries"
            type="line"
            width="100%"
            height="350"
          />
          <apexchart
            :options="salesOptions"
            :series="salesSeries"
            type="line"
            width="100%"
            height="350"
          />
        </div>
        <div v-else>
          <apexchart
            :options="selfServeOptions"
            :series="selfServeSeries"
            type="line"
            width="100%"
            height="350"
          />
        </div>
      </template>
    </accordion-panel>
    <border />
    <accordion-panel :title="$t('kpi.monthlyTable.title')">
      <template #option>
        <input
          type="button"
          class="downloadButton"
          :value="$t('downloadCsv')"
          @click="clickDownloadCsv"
        />
      </template>
      <template #contents>
        <table class="kpi__body-table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th v-for="(month, key) in term" :key="key">{{ month }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="highlight">
              <td>{{ $t('kpi.monthly.session') }}</td>
              <td>{{ $t('kpi.monthlyTable.case') }}</td>
              <template v-for="(month, key) in monthly">
                <td :key="key">{{ month.session | numberFormat }}</td>
              </template>
            </tr>
            <template v-if="template === 'saas'">
              <tr>
                <td>{{ $t('kpi.monthly.leadRate') }}</td>
                <td>%</td>
                <template v-for="(month, key) in monthly">
                  <td :key="key">{{ month.lead_rate | numberFormat('%') }}</td>
                </template>
              </tr>
              <tr class="highlight">
                <td>{{ $t('kpi.monthly.lead') }}</td>
                <td>{{ $t('kpi.monthlyTable.case') }}</td>
                <template v-for="(month, key) in monthly">
                  <td :key="key">{{ month.lead | numberFormat }}</td>
                </template>
              </tr>
              <tr>
                <td>{{ $t('kpi.monthly.meetingRate') }}</td>
                <td>%</td>
                <template v-for="(month, key) in monthly">
                  <td :key="key">
                    {{ month.meeting_rate | numberFormat('%') }}
                  </td>
                </template>
              </tr>
              <tr class="highlight">
                <td>{{ $t('kpi.monthly.meeting') }}</td>
                <td>{{ $t('kpi.monthlyTable.case') }}</td>
                <template v-for="(month, key) in monthly">
                  <td :key="key">{{ month.meeting | numberFormat }}</td>
                </template>
              </tr>
              <tr>
                <td>{{ $t('kpi.monthly.orderRate') }}</td>
                <td>%</td>
                <template v-for="(month, key) in monthly">
                  <td :key="key">{{ month.order_rate | numberFormat('%') }}</td>
                </template>
              </tr>
              <tr class="highlight">
                <td>{{ $t('kpi.monthly.order') }}</td>
                <td>{{ $t('kpi.monthlyTable.case') }}</td>
                <template v-for="(month, key) in monthly">
                  <td :key="key">{{ month.order | numberFormat }}</td>
                </template>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td>{{ $t('kpi.monthly.registrationRate') }}</td>
                <td>%</td>
                <template v-for="(month, key) in monthly">
                  <td :key="key">
                    {{ month.registration_rate | numberFormat('%') }}
                  </td>
                </template>
              </tr>
              <tr class="highlight">
                <td>{{ $t('kpi.monthly.registration') }}</td>
                <td>{{ $t('kpi.monthlyTable.case') }}</td>
                <template v-for="(month, key) in monthly">
                  <td :key="key">{{ month.registration | numberFormat }}</td>
                </template>
              </tr>
              <tr>
                <td>{{ $t('kpi.monthly.paidRate') }}</td>
                <td>%</td>
                <template v-for="(month, key) in monthly">
                  <td :key="key">
                    {{ month.billing_rate | numberFormat('%') }}
                  </td>
                </template>
              </tr>
              <tr class="highlight">
                <td>{{ $t('kpi.monthly.paid') }}</td>
                <td>{{ $t('kpi.monthlyTable.case') }}</td>
                <template v-for="(month, key) in monthly">
                  <td :key="key">{{ month.billing | numberFormat }}</td>
                </template>
              </tr>
            </template>
            <tr>
              <td>{{ $t('kpi.monthly.churnRate') }}</td>
              <td>%</td>
              <template v-for="(month, key) in monthly">
                <td :key="key">{{ month.churn_rate | numberFormat('%') }}</td>
              </template>
            </tr>
            <tr class="highlight">
              <td>{{ $t('kpi.monthly.churn') }}</td>
              <td>{{ $t('kpi.monthlyTable.case') }}</td>
              <template v-for="(month, key) in monthly">
                <td :key="key">{{ month.churn | numberFormat }}</td>
              </template>
            </tr>
            <tr>
              <td>{{ $t('kpi.monthly.totalPaying') }}</td>
              <td>{{ $t('kpi.monthlyTable.case') }}</td>
              <template v-for="(month, key) in monthly">
                <td :key="key">{{ month.paid | numberFormat }}</td>
              </template>
            </tr>
          </tbody>
        </table>
      </template>
    </accordion-panel>
  </section>
</template>

<script>
import AccordionPanel from '../AccordionPanel'
import Border from '../border'
import KpiChart from '../../charts/kpiChart'
export default {
  name: 'KpiMonthly',
  components: { Border, AccordionPanel },
  props: {
    monthly: Array,
    template: String,
    term: Array,
  },
  computed: {
    uuOptions() {
      return KpiChart.uuOptions(this.term)
    },
    salesOptions() {
      return KpiChart.salesOptions(this.term)
    },
    selfServeOptions() {
      return KpiChart.selfServeOptions(this.term)
    },
    uuSeries() {
      if (typeof this.monthly === 'undefined') return []
      return [
        {
          name: this.$t('kpi.monthly.session'),
          data: this.monthly.map((month) => month.session),
        },
        {
          name: this.$t('kpi.monthly.lead'),
          data: this.monthly.map((month) => month.lead),
        },
        {
          name: this.$t('kpi.monthly.meeting'),
          data: this.monthly.map((month) => month.meeting),
        },
      ]
    },
    salesSeries() {
      if (typeof this.monthly === 'undefined') return []
      return [
        {
          name: this.$t('kpi.monthly.order'),
          data: this.monthly.map((month) => month.order),
        },
        {
          name: this.$t('kpi.monthly.churn'),
          data: this.monthly.map((month) => month.churn),
        },
      ]
    },
    selfServeSeries() {
      if (typeof this.monthly === 'undefined') return []
      return [
        {
          name: this.$t('kpi.monthly.session'),
          data: this.monthly.map((month) => month.session),
        },
        {
          name: this.$t('kpi.monthly.registration'),
          data: this.monthly.map((month) => month.registration),
        },
        {
          name: this.$t('kpi.monthly.paid'),
          data: this.monthly.map((month) => month.billing),
        },
        {
          name: this.$t('kpi.monthly.churn'),
          data: this.monthly.map((month) => month.churn),
        },
      ]
    },
  },
  methods: {
    clickDownloadCsv() {
      this.$emit('click:downloadCsv')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .panel__title-tool {
  flex-grow: 1;
  .downloadButton {
    margin-left: auto;
    margin-right: 4px;
    font-size: 11px;
    font-weight: bold;
    padding: 8px;
    color: $color-white;
    background-color: $color-theme-light;
    border-radius: 8px;
    cursor: pointer;
  }
}
.kpi__body-table {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
  width: 100%;
  white-space: nowrap;
  margin-bottom: 5px;
  th {
    height: 44px;
    border-top: 1px solid rgb(242, 242, 242);
  }
  tr {
    border-top: 1px solid rgb(242, 242, 242);
  }
  td {
    width: 72px;
    color: rgb(51, 51, 51);
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    padding: 8px 10px;
  }
  td:nth-child(1) {
    width: 140px;
    padding-left: 24px;
    font-weight: bold;
    text-align: left;
  }
  td:nth-child(2) {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
  }
  .highlight {
    background-color: rgb(252, 251, 252);
    td:not(:nth-child(2)) {
      color: rgb(80, 52, 121);
      font-weight: bold;
    }
  }
}
</style>
