export default {
  canCreateBusinessPlan(plan, businessPlans) {
    const planName = plan.plan
    const ownerBusinessPlanCount = businessPlans.filter(
      (businessPlan) => businessPlan.is_owner
    ).length
    if (planName === 'Free' || plan.is_trial) {
      return ownerBusinessPlanCount < 1
    }
    if (planName === 'Basic') {
      return ownerBusinessPlanCount < 2
    }
    if (planName === 'Team') {
      return ownerBusinessPlanCount < 3
    }
  },
}
