import monthlyPl from '../../client/monthlyPl'
import monthly from '../../util/monthly'

const monthlyItems = {
  arpa: 0,
  arr: 0,
  beginning_cash_balance: 0,
  burn_multiple: 0,
  cac: 0,
  churn: 0,
  churn_cvr: 0,
  cogs: 0,
  cogs_rate: 0,
  comment: '',
  cumulative: 0,
  debt_payment: 0,
  debt_raised: 0,
  employees: 0,
  ending_cash_balance: 0,
  equity_raised: 0,
  ga: 0,
  ga_rate: 0,
  gross_profit: 0,
  gross_profit_rate: 0,
  monthType: '',
  isEditing: false,
  lead: 0,
  lead_cvr: 0,
  ltv: 0,
  ltv_cac: 0,
  meeting: 0,
  meeting_cvr: 0,
  month: 0,
  monthName: '',
  mrr: 0,
  net_cash_movement: 0,
  operating_expense: 0,
  operating_expense_rate: 0,
  operating_income: 0,
  operating_income_rate: 0,
  order: 0,
  order_cvr: 0,
  other: 0,
  professional_service: 0,
  rd: 0,
  rd_rate: 0,
  revenue: 0,
  revenue_rate: 0,
  session: 0,
  sm: 0,
  sm_rate: 0,
  status: '',
}

const state = {
  currentYearData: {
    graph: {
      arpa: {
        actual: [],
        estimate: [],
      },
      arr: {
        actual: [],
        estimate: [],
      },
      churn: {
        actual: [],
        estimate: [],
      },
      cumulative_customers: {
        actual: [],
        estimate: [],
      },
      cumulative_revenue: {
        actual: [],
        estimate: [],
      },
      ending_cash_balance: {
        actual: [],
        estimate: [],
      },
      meeting: {
        actual: [],
        estimate: [],
      },
      mrr: {
        actual: [],
        estimate: [],
      },
      order: {
        actual: [],
        estimate: [],
      },
      revenue: {
        actual: [],
        estimate: [],
      },
      session: {
        actual: [],
        estimate: [],
      },
      lead: {
        actual: [],
        estimate: [],
      },
      forecast_monthes: 1,
    },
  },
  actual: {
    monthly: [{ ...monthlyItems }],
  },
  estimate: {
    monthly: [{ ...monthlyItems }],
  },
  difference: {
    rate: {
      monthly: [{ ...monthlyItems }],
    },
    value: {
      monthly: [{ ...monthlyItems }],
    },
  },
  monthlyProgress: {
    monthly: [{ ...monthlyItems }],
  },
  isEditingFlags: [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ],
  isRate: true,
  spreadSheet: {
    google_account: null,
    spreadsheet: null,
  },
  spreadSheetAuthUrl: '',
}

const getters = {
  pattern: (state) => state.currentYearData.pattern,
  graph: (state) => state.currentYearData.graph,
  actual: (state) => state.actual,
  estimate: (state) => state.estimate,
  difference: (state) => state.difference,
  monthlyProgress: (state) => state.monthlyProgress,
  isEditingFlags: (state) => state.isEditingFlags,
  isRate: (state) => state.isRate,
  spreadSheet: (state) => state.spreadSheet,
  spreadSheetAuthUrl: (state) => state.spreadSheetAuthUrl,
}

const mutations = {
  getMonthlyPl(state, { currentCaseData, yearIndex }) {
    state.currentYearData = { ...currentCaseData.monthly_pl[yearIndex] }
  },

  setCurrentMonthlyPl(
    state,
    { term, detailName, detailData, detailTypeName = '' }
  ) {
    const monthly = []
    for (let i = 0; i < 12; i++) {
      const month = detailData.monthly[i]
      month.monthType = 'month'
      month.isEditing = false
      month.monthName = term[i]
      monthly.push(month)
      if ((i + 1) % 3 === 0) {
        const quarter = detailData.quarterly[(i + 1) / 3 - 1]
        quarter.monthType = 'quarter'
        quarter.isQuarter = true
        quarter.isEditing = false
        quarter.monthName = quarter.month
        monthly.push(quarter)
      }
    }
    const yearly = detailData.yearly
    yearly.monthType = 'cy'
    yearly.isEditing = false
    yearly.monthName = 'CY'
    monthly.push(yearly)
    if (detailTypeName) {
      state[detailName][detailTypeName] = monthly
    } else {
      state[detailName] = monthly
    }
  },

  changeEditingFlag(state, { monthlyIndex, value }) {
    const isEditingFlags = [...state.isEditingFlags]
    isEditingFlags[monthlyIndex] = value
    state.isEditingFlags = isEditingFlags
  },

  setIsRate(state, { isRate }) {
    state.isRate = isRate
  },

  getSpreadSheet(state, { spreadSheet }) {
    state.spreadSheet = spreadSheet
  },

  getSpreadSheetOauthUrl(state, { authUrl }) {
    state.spreadSheetAuthUrl = authUrl
  },
}

const actions = {
  async getMonthlyPl({ rootState, commit }) {
    const result = await monthlyPl.get({
      business_plan_id: rootState.currentView.businessPlanId,
      case_id: rootState.currentView.caseId,
    })
    const currentCaseData = result.data
    const yearIndex = rootState.currentView.year - 1
    await commit('getMonthlyPl', { currentCaseData, yearIndex })

    const elapsedYears = rootState.currentView.year - 1
    const term = monthly.term(
      rootState.me.businessPlan.start_year + elapsedYears,
      rootState.me.businessPlan.start_month,
      false
    )
    commit('setCurrentMonthlyPl', {
      term,
      detailName: 'actual',
      detailData: rootState.monthlyPl.currentYearData.actual,
    })
    commit('setCurrentMonthlyPl', {
      term,
      detailName: 'estimate',
      detailData: rootState.monthlyPl.currentYearData.estimate,
    })
    commit('setCurrentMonthlyPl', {
      term,
      detailName: 'difference',
      detailData: rootState.monthlyPl.currentYearData.difference.rate,
      detailTypeName: 'rate',
    })
    commit('setCurrentMonthlyPl', {
      term,
      detailName: 'difference',
      detailData: rootState.monthlyPl.currentYearData.difference.value,
      detailTypeName: 'value',
    })
    commit('setCurrentMonthlyPl', {
      term,
      detailName: 'monthlyProgress',
      detailData: rootState.monthlyPl.currentYearData.progress,
    })
  },

  changeEditingFlag({ commit }, { monthlyIndex, value }) {
    commit('changeEditingFlag', { monthlyIndex, value })
  },

  setIsRate({ commit }, { isRate }) {
    commit('setIsRate', { isRate })
  },

  async saveEstimate({ rootState, getters, dispatch }, { monthlyIndex }) {
    const businessPlanId = rootState.currentView.businessPlanId
    const caseId = rootState.currentView.caseId
    const year = rootState.currentView.year

    const params = {
      business_plan_id: businessPlanId,
      case_id: caseId,
      year: year,
      month: getters.estimate[monthlyIndex].month,
      beginning_cash_balance:
        getters.estimate[monthlyIndex].beginning_cash_balance,
      debt_payment: getters.estimate[monthlyIndex].debt_payment,
      debt_raised: getters.estimate[monthlyIndex].debt_raised,
      equity_raised: getters.estimate[monthlyIndex].equity_raised,
      other: getters.estimate[monthlyIndex].other,
    }
    await monthlyPl.editCashFlow(params)
    await dispatch('getMonthlyPl')
  },

  async saveActual({ rootState, getters, dispatch }, { monthlyIndex }) {
    const businessPlanId = rootState.currentView.businessPlanId
    const template = rootState.me.businessPlan.template
    const year = rootState.currentView.year

    const params = {
      business_plan_id: businessPlanId,
      year: year,
      month: getters.actual[monthlyIndex].month,
      arpa: getters.actual[monthlyIndex].arpa,
      session: getters.actual[monthlyIndex].session,
      order: getters.actual[monthlyIndex].order,
      churn: getters.actual[monthlyIndex].churn,
      professional_service: getters.actual[monthlyIndex].professional_service,
      cogs: getters.actual[monthlyIndex].cogs,
      sm: getters.actual[monthlyIndex].sm,
      rd: getters.actual[monthlyIndex].rd,
      ga: getters.actual[monthlyIndex].ga,
      beginning_cash_balance:
        getters.actual[monthlyIndex].beginning_cash_balance,
      debt_payment: getters.actual[monthlyIndex].debt_payment,
      debt_raised: getters.actual[monthlyIndex].debt_raised,
      equity_raised: getters.actual[monthlyIndex].equity_raised,
      other: getters.actual[monthlyIndex].other,
      employees: getters.actual[monthlyIndex].employees,
    }
    if (template === 'saas') {
      params.lead = getters.actual[monthlyIndex].lead
      params.meeting = getters.actual[monthlyIndex].meeting
    } else {
      params.registration = getters.actual[monthlyIndex].registration
    }
    await monthlyPl.edit(params)
    await dispatch('getMonthlyPl')
  },

  async editComment(
    { rootState, getters, dispatch },
    { monthlyIndex, currentDetails, content }
  ) {
    const businessPlanId = rootState.currentView.businessPlanId
    const caseId = rootState.currentView.caseId
    const year = rootState.currentView.year

    const section =
      currentDetails === 'monthlyProgress' ? 'difference' : currentDetails
    let columnName = ''
    if (currentDetails === 'difference') {
      columnName = getters[currentDetails].rate[monthlyIndex].month
    } else {
      columnName = getters[currentDetails][monthlyIndex].month
    }

    const params = {
      business_plan_id: businessPlanId,
      case_id: caseId,
      year: year,
      column_name: columnName,
      section: section,
      content: content,
    }
    await monthlyPl.editComment(params)
    await dispatch('getMonthlyPl')
  },

  async revisedBudget({ rootState, dispatch }, { pattern }) {
    const businessPlanId = rootState.currentView.businessPlanId
    const caseId = rootState.currentView.caseId
    const year = rootState.currentView.year

    const params = {
      business_plan_id: businessPlanId,
      case_id: caseId,
      year: year,
      pattern: pattern,
    }
    await monthlyPl.editAdjust(params)
    await dispatch('getMonthlyPl')
  },

  async reverseStatus({ rootState, dispatch }, { month }) {
    const businessPlanId = rootState.currentView.businessPlanId
    const year = rootState.currentView.year

    await monthlyPl.reverse({
      business_plan_id: businessPlanId,
      year: year,
      month: month,
    })
    await dispatch('getMonthlyPl')
  },

  async getSpreadSheet({ commit, rootState }) {
    const businessPlanId = rootState.currentView.businessPlanId
    const result = await monthlyPl.getSpreadSheet({
      business_plan_id: businessPlanId,
    })
    commit('getSpreadSheet', { spreadSheet: result.data })
  },

  async getSpreadSheetOauthUrl({ commit }) {
    const authUrl = await monthlyPl.getSpreadSheetOauthUrl()
    commit('getSpreadSheetOauthUrl', { authUrl: authUrl.data.auth_url })
  },

  async createSpreadSheet({ rootState, dispatch }) {
    const businessPlanId = rootState.currentView.businessPlanId
    await monthlyPl.createSpreadSheet({ business_plan_id: businessPlanId })
    await dispatch('getSpreadSheet')
  },

  async exportSpreadSheet({ rootState, dispatch }) {
    const businessPlanId = rootState.currentView.businessPlanId
    await monthlyPl.exportSpreadSheet({ business_plan_id: businessPlanId })
    await dispatch('getSpreadSheet')
  },

  async importSpreadSheet({ rootState, dispatch }) {
    const businessPlanId = rootState.currentView.businessPlanId
    await monthlyPl.importSpreadSheet({ business_plan_id: businessPlanId })
    await dispatch('getSpreadSheet')
  },

  async disconnectSpreadSheet({ rootState, getters, dispatch }) {
    const businessPlanId = rootState.currentView.businessPlanId
    const spreadSheetId = getters.spreadSheet.spreadsheet.id
    await monthlyPl.disconnectSpreadSheet({
      business_plan_id: businessPlanId,
      spreadsheet_id: spreadSheetId,
    })
    await dispatch('getSpreadSheet')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
