<template>
  <section class="cost__body-panel">
    <accordion-panel :title="$t('cost.annualCostPlan')">
      <template #contents>
        <div
          :content="'（' + unit(currency, 'k') + '）'"
          class="cost__body-expensesSummary"
        >
          <p>
            COGS：<span>{{ cogs.total | numberFormat }}</span
            >（{{ unit(currency, 'k') }}）
          </p>
          <p>
            S&amp;M：<span>{{ sm.total | numberFormat }}</span
            >（{{ unit(currency, 'k') }}）
          </p>
          <p>
            R&amp;D：<span>{{ rd.total | numberFormat }}</span
            >（{{ unit(currency, 'k') }}）
          </p>
          <p>
            G&amp;A：<span>{{ ga.total | numberFormat }}</span
            >（{{ unit(currency, 'k') }}）
          </p>
        </div>
        <apexchart
          :options="options"
          :series="series"
          type="bar"
          width="100%"
          height="400"
          class="cost__body-expensesChart"
        />
      </template>
    </accordion-panel>
    <div :content="unit(currency, 'k')" class="cost__body-expensesTotal">
      <div>
        <p>
          {{ $t('cost.budget') }}：<span>{{
            budgets.all.budget | numberFormat
          }}</span
          >（{{ unit(currency, 'k') }}）
        </p>
        <p>
          {{ $t('cost.cumulativeCost') }}：<span>{{
            cost.total_cost | numberFormat
          }}</span
          >（{{ unit(currency, 'k') }}）
        </p>
        <p>
          {{ $t('cost.remainingBudget') }}：<span>{{
            budgets.all.remaining_budget | numberFormat
          }}</span
          >（{{ unit(currency, 'k') }}）
        </p>
      </div>
      <cost-progress
        :max="budgets.all.budget"
        :value="cost.total_cost"
        :averages="budgets.all.budgetary_averages"
      />
    </div>
    <warning-message-box v-if="isOverBudget" class="message">
      {{ $t('cost.warningMessage') }}
    </warning-message-box>
  </section>
</template>

<script>
import CostProgress from './CostProgress'
import AccordionPanel from '../AccordionPanel'
import costChart from '../../charts/costChart'
import WarningMessageBox from '../WarningMessageBox'
import currencyUtil from '../../util/currency'
export default {
  name: 'CostYearPlan',
  components: { WarningMessageBox, AccordionPanel, CostProgress },
  props: {
    cost: Object,
    term: Array,
    currency: String,
  },
  computed: {
    budgets() {
      return this.cost.budget
    },
    cogs() {
      if (typeof this.cost === 'undefined') return {}
      return this.cost.cogs
    },
    sm() {
      if (typeof this.cost === 'undefined') return {}
      return this.cost.sm
    },
    rd() {
      if (typeof this.cost === 'undefined') return {}
      return this.cost.rd
    },
    ga() {
      if (typeof this.cost === 'undefined') return {}
      return this.cost.ga
    },
    options() {
      return costChart.yearCostOptions(this.term)
    },
    series() {
      if (typeof this.cost === 'undefined') return []
      return [
        {
          name: 'COGS',
          data: this.cost.cogs.monthly,
        },
        {
          name: 'S&M',
          data: this.cost.sm.monthly,
        },
        {
          name: 'R&D',
          data: this.cost.rd.monthly,
        },
        {
          name: 'G&A',
          data: this.cost.ga.monthly,
        },
      ]
    },
    isOverBudget() {
      return this.budgets.all.budgetary_averages > 100
    },
  },
  methods: {
    unit(currency, unit) {
      return currencyUtil.unit(currency, unit)
    },
  },
}
</script>

<style lang="scss" scoped>
.cost__body-panel {
  margin-bottom: 40px;
  padding: 24px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 10%) 0 0 4px;
}
.cost__body-expensesSummary {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  p {
    padding: 0 4px;
    color: rgb(14, 14, 14);
    font-size: 1.2rem;
  }
  span {
    color: $color-red;
    font-size: 1.4rem;
  }
}
.cost__body-expensesTotal {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  color: rgb(14, 14, 14);
  font-size: 1.4rem;
  div:nth-child(1) {
    display: flex;
    p {
      margin-right: 24px;
      white-space: nowrap;
    }
    span {
      color: $color-theme;
      font-size: 1.8rem;
    }
  }
}
.cost__body-expensesChart {
  position: relative;
  margin: 40px 0;
}
.message {
  margin-top: 24px;
}
</style>
