<template>
  <section class="panel">
    <h2>{{ $t('setting.businessPlan') }}</h2>
    <div>
      <div class="panel__block">
        <h3>{{ $t('setting.businessPlanName') }}</h3>
        <input v-model="businessPlan.business_plan_name" />
      </div>
      <div class="panel__block">
        <h3>{{ $t('setting.businessStartYm') }}</h3>
        <business-start-ym :business-plan="businessPlan" />
      </div>
      <div class="panel__block">
        <h3>{{ $t('setting.businessPlanIcon') }}</h3>
        <icon-upload :icon="businessPlan.business_plan_icon" v-model="file" />
      </div>
    </div>
    <div class="panel__buttons">
      <button class="panel__buttons-rename" @click="saveName">
        <edit-icon />
        {{ $t('setting.save') }}
      </button>
      <button class="panel__buttons-delete" @click="confirmDelete">
        <delete-icon />
        {{ $t('setting.delete') }}
      </button>
    </div>
    <delete-business-plan ref="deleteBusinessPlan" @click:delete="deletePlan" />
  </section>
</template>

<script>
import DeleteIcon from '@/assets/svg/DeleteIcon.svg'
import EditIcon from '@/assets/svg/EditIcon.svg'
import DeleteBusinessPlan from '../Modal/DeleteBusinessPlan'
import IconUpload from '../IconUpload'
import BusinessStartYm from './BusinessStartYm'
export default {
  name: 'BusinessPlanName',
  components: {
    BusinessStartYm,
    IconUpload,
    DeleteBusinessPlan,
    DeleteIcon,
    EditIcon,
  },
  props: {
    businessPlan: Object,
  },
  data() {
    return {
      editing: false,
      isImageEnter: false,
      uploadImage: [],
      file: null,
    }
  },
  methods: {
    editName() {
      this.editing = true
    },
    saveName() {
      this.editing = false
      this.$emit('click:editBusinessPlan', {
        business_plan_name: this.businessPlan.business_plan_name,
        business_plan_icon: this.file,
        start_year: this.businessPlan.start_year,
        start_month: this.businessPlan.start_month,
      })
    },
    deletePlan() {
      this.$emit('click:deletePlan')
    },
    confirmDelete() {
      this.$refs.deleteBusinessPlan.showModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.panel {
  h2 {
    font-size: 2rem;
    line-height: 30px;
    margin-bottom: 20px;
  }
  h3 {
    margin-bottom: 8px;
  }
  &__block {
    margin-bottom: 24px;
    &-contents {
      position: relative;
      box-sizing: border-box;
      max-width: 598px;
      flex-grow: 1;
      height: 60px;
      padding: 20px 60px 20px 24px;
      font-weight: bold;
      font-size: 2rem;
      line-height: 20px;
      background: rgb(242, 242, 242);
      border: none;
      border-radius: 8px;
    }
    input {
      width: 598px;
      height: 60px;
      padding: 20px 60px 20px 24px;
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
      border: 2px solid $color-light-gray;
      border-radius: 8px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    gap: 24px;
    button {
      display: flex;
      gap: 4px;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 16px 54px;
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 14px;
      border-radius: 4px;
      cursor: pointer;
      svg {
        margin-right: 5px;
      }
    }
    &-rename {
      color: rgb(153, 153, 153);
      background: rgb(255, 255, 255);
      border: 1px solid rgb(153, 153, 153);
    }
    &-delete {
      color: rgb(235, 87, 87);
      background: rgb(253, 238, 238);
      border: 1px solid rgb(235, 87, 87);
    }
  }
}
</style>
