<template>
  <modal name="settingsModal" :clickToClose="false" width="90%" height="90%">
    <settings />
  </modal>
</template>

<script>
import Settings from './Settings'
export default {
  name: 'SettingsModal',
  components: { Settings },
  watch: {
    showModal() {
      this.toggleModal()
    },
  },
  computed: {
    showModal() {
      return this.$store.getters['currentView/showSettings']
    },
  },
  mounted() {
    this.toggleModal()
  },
  methods: {
    toggleModal() {
      const body = document.getElementsByTagName('body')[0]
      if (this.showModal) {
        body.style.overflow = 'hidden'
        this.$modal.show('settingsModal')
        return
      }
      body.style.overflow = 'auto'
      this.$modal.hide('settingsModal')
    },
  },
}
</script>
