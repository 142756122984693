<template>
  <button
    @click="clickButton"
    class="columnEditButton"
    :class="{ '--isEditing': isEditing }"
  >
    <slot />
    <span class="columnEditButton__icon">
      <save-icon v-if="isEditing" />
      <edit-icon v-else />
    </span>
  </button>
</template>

<script>
import EditIcon from '@/assets/svg/EditIconPure.svg'
import SaveIcon from '@/assets/svg/SaveIconPure.svg'

export default {
  name: 'MonthlyPlColumnEditButton',
  components: {
    EditIcon,
    SaveIcon,
  },
  inject: ['currentDetails'],
  props: {
    monthlyIndex: {
      type: Number,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    async clickButton() {
      await this.$store.dispatch('monthlyPl/changeEditingFlag', {
        monthlyIndex: this.monthlyIndex,
        value: !this.isEditing,
      })
      if (!this.isEditing) {
        try {
          const targetMethod =
            this.currentDetails().charAt(0).toUpperCase() +
            this.currentDetails().slice(1)
          await this.$store.dispatch(`monthlyPl/save${targetMethod}`, {
            monthlyIndex: this.monthlyIndex,
          })
        } catch (e) {
          this.flashMessage.error({
            title: e.response.title,
            message: e.response.message,
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.columnEditButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 0 8px;
  border-radius: 4px;
  font-weight: bold;
  width: 100%;
  color: $color-theme;
  background-color: $color-white;
  border: 1px solid $color-theme;
  height: 26px;

  &:hover {
    background-color: $color-theme;
    color: $color-white;

    svg path {
      fill: $color-white;
    }
  }

  &.--isEditing {
    background-color: $color-theme;
    color: $color-white;
    svg path {
      fill: $color-white;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

.columnEditButton__icon {
  svg {
    path {
      fill: $color-theme;
    }
  }
}
</style>
