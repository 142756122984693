<template>
  <div>
    <template v-if="image">
      <img :src="image" alt="" />
      <button type="button" class="deleteButton" @click="deleteImage">
        {{ $t('setting.deleteImage') }}
      </button>
    </template>
    <input v-else type="file" @change="upload" />
  </div>
</template>

<script>
export default {
  name: 'IconUpload',
  data() {
    return {
      image: '',
      file: null,
    }
  },
  props: {
    icon: String,
  },
  created() {
    this.image = this.icon || ''
  },
  methods: {
    async upload(event) {
      const files = event.target.files || event.dataTransfer.files
      this.file = files[0]

      if (!this.checkFile) return

      this.image = await this.getBase64()
      this.$emit('input', this.file)
    },
    deleteImage() {
      this.image = ''
      this.file = null
    },
    getBase64() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(this.file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    checkFile() {
      if (!this.file) {
        return false
      }
      if (this.file.type !== 'image/jpeg' && this.file.type !== 'image/png') {
        return false
      }
      if (this.file.size > 2000000) {
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  max-width: 120px;
}
.deleteButton {
  margin-top: 16px;
  padding: 8px 24px;
  border-radius: 4px;
  border: 1px solid $color-light-gray;
}
</style>
