<template>
  <button class="roleEdit">
    <span>{{ $t('setting.roles.editRole') }}</span>
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="2" fill="#503479"></rect>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7729 15.0157C11.0593 15.3021 11.5366 15.3127 11.8336 15.0157L18.1976 8.65177C18.4945 8.35479 18.4839 7.87749 18.1976 7.59111L17.1369 6.53045C16.8399 6.23347 16.3732 6.23347 16.0762 6.53045L9.71228 12.8944C9.41529 13.1914 9.41529 13.6581 9.71228 13.9551L10.7729 15.0157ZM9.8927 16.257C10.0094 16.3737 10.0624 16.554 10.02 16.7237C9.97755 16.8934 9.86088 17.0313 9.69118 17.0949L7.56986 17.795C7.38954 17.848 7.19862 17.8056 7.06074 17.6677C6.92285 17.5298 6.88043 17.3389 6.93346 17.1586L7.65471 15.0372C7.69714 14.8675 7.83502 14.7509 8.00473 14.7084C8.17443 14.666 8.35474 14.7191 8.47142 14.8357L9.8927 16.257Z"
        fill="white"
      ></path>
    </svg>
  </button>
</template>

<script>
export default {
  name: 'RoleEditButton',
}
</script>

<style lang="scss" scoped>
.roleEdit {
  background-color: rgb(255, 255, 255);
  border: 0;
  outline: none;
  cursor: pointer;
  svg {
    color: $color-theme;
    vertical-align: middle;
  }
  span {
    margin-left: -6px;
    margin-right: 6px;
    color: $color-theme;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 12px;
    vertical-align: middle;
  }
}
</style>
