<template>
  <div>
    <pa-header />
    <div class="law">
      <h2 class="law__header">{{ $t('law.title') }}</h2>
      <div class="law__header-contents">
        <div class="law__header-box">
          <h3>{{ $t('law.section1.title') }}</h3>
          <p>{{ $t('law.section1.description') }}</p>
        </div>
        <div class="law__header-box">
          <h3>{{ $t('law.section2.title') }}</h3>
          <p>{{ $t('law.section2.description') }}</p>
        </div>
        <div class="law__header-box">
          <h3>{{ $t('law.section3.title') }}</h3>
          <p>{{ $t('law.section3.description') }}</p>
        </div>
        <div class="law__header-box">
          <h3>{{ $t('law.section4.title') }}</h3>
          <p>{{ $t('law.section4.description') }}</p>
        </div>
        <div class="law__header-box">
          <h3>{{ $t('law.section5.title') }}</h3>
          <p>{{ $t('law.section5.description') }}</p>
        </div>
        <div class="law__header-box">
          <h3>{{ $t('law.section6.title') }}</h3>
          <p>{{ $t('law.section6.description') }}</p>
        </div>
        <div class="law__header-box">
          <h3>{{ $t('law.section7.title') }}</h3>
          <p>{{ $t('law.section7.description') }}</p>
        </div>
        <div class="law__header-box">
          <h3>{{ $t('law.section8.title') }}</h3>
          <p>
            {{ $t('law.section8.description') }}
            <br />
            <a :href="`mailto:${$t('law.section8.mail')}`">
              {{ $t('law.section8.mail') }}
            </a>
          </p>
        </div>
        <div class="law__header-box">
          <p>
            {{ $t('law.description') }}
          </p>
        </div>
      </div>
    </div>
    <pa-footer />
  </div>
</template>

<script>
import PaHeader from '@/components/PaHeader'
import PaFooter from '@/components/PaFooter'

export default {
  name: 'Welcome',
  components: {
    PaHeader,
    PaFooter,
  },
}
</script>

<style lang="scss" scoped>
.law {
  box-sizing: border-box;
  max-width: 1000px;
  margin: 0px auto;
  color: $color-black;
  @include isPC() {
    padding: 80px;
  }
  @include isSP() {
    padding: 20px;
  }
}
.law__header {
  font-weight: normal;
  font-size: 2.4rem;
  font-family: Questrial, sans-serif;
  text-align: center;
}
.law__header-contents {
  box-sizing: border-box;
  margin-top: 40px;
  padding: 20px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(242, 242, 242);
  border-radius: 8px;
  text-align: left;
}
.law__header-box {
  margin-bottom: 32px;
  a {
    color: $color-theme;
  }
}
</style>
