<template>
  <div>
    <pa-header />
    <div class="privacyPolicy">
      <h2 class="privacyPolicy__header">{{ $t('privacyPolicy.title') }}</h2>
      <div class="privacyPolicy__contents">
        <p class="privacyPolicy__contents-box">
          {{ $t('privacyPolicy.description') }}
        </p>
        <ol>
          <li class="privacyPolicy__contents-box">
            <h3>{{ $t('privacyPolicy.section1.title') }}</h3>
            <p>{{ $t('privacyPolicy.section1.description') }}</p>
          </li>
          <li class="privacyPolicy__contents-box">
            <h3>{{ $t('privacyPolicy.section2.title') }}</h3>
            <p>{{ $t('privacyPolicy.section2.description') }}</p>
          </li>
          <li class="privacyPolicy__contents-box">
            <h3>{{ $t('privacyPolicy.section3.title') }}</h3>
            <p>{{ $t('privacyPolicy.section3.description') }}</p>
          </li>
          <li class="privacyPolicy__contents-box">
            <h3>{{ $t('privacyPolicy.section4.title') }}</h3>
            <p>{{ $t('privacyPolicy.section4.description') }}</p>
            <table>
              <tr>
                <th>{{ $t('privacyPolicy.section4.h1') }}</th>
                <th>{{ $t('privacyPolicy.section4.h2') }}</th>
              </tr>
              <tr>
                <td>{{ $t('privacyPolicy.section4.r1.title') }}</td>
                <td>
                  <ul>
                    <li>{{ $t('privacyPolicy.section4.r1.li1') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r1.li2') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r1.li3') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r1.li4') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r1.li5') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r1.li6') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r1.li7') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r1.li8') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r1.li9') }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>{{ $t('privacyPolicy.section4.r2.title') }}</td>
                <td>
                  <ul>
                    <li>{{ $t('privacyPolicy.section4.r2.li1') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r2.li2') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r2.li3') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r2.li4') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r2.li5') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r2.li6') }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>{{ $t('privacyPolicy.section4.r3.title') }}</td>
                <td>
                  <ul>
                    <li>{{ $t('privacyPolicy.section4.r3.li1') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r3.li2') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r3.li3') }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>{{ $t('privacyPolicy.section4.r4.title') }}</td>
                <td>
                  <ul>
                    <li>{{ $t('privacyPolicy.section4.r4.li1') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r4.li2') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r4.li3') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r4.li4') }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>{{ $t('privacyPolicy.section4.r5.title') }}</td>
                <td>
                  <ul>
                    <li>{{ $t('privacyPolicy.section4.r5.li1') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r5.li2') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r5.li3') }}</li>
                    <li>{{ $t('privacyPolicy.section4.r5.li4') }}</li>
                  </ul>
                </td>
              </tr>
            </table>
          </li>
          <li class="privacyPolicy__contents-box">
            <h3>{{ $t('privacyPolicy.section5.title') }}</h3>
            <p>{{ $t('privacyPolicy.section5.description') }}</p>
          </li>
          <li class="privacyPolicy__contents-box">
            <h3>{{ $t('privacyPolicy.section6.title') }}</h3>
            <p>{{ $t('privacyPolicy.section6.description') }}</p>
          </li>
          <li class="privacyPolicy__contents-box">
            <h3>{{ $t('privacyPolicy.section7.title') }}</h3>
            <p>{{ $t('privacyPolicy.section7.description') }}</p>
          </li>
          <li class="privacyPolicy__contents-box">
            <h3>{{ $t('privacyPolicy.section8.title') }}</h3>
            <p>{{ $t('privacyPolicy.section8.description') }}</p>
          </li>
          <li class="privacyPolicy__contents-box">
            <h3>{{ $t('privacyPolicy.section9.title') }}</h3>
            <p v-html="$t('privacyPolicy.section9.description')" />
          </li>
          <li class="privacyPolicy__contents-box">
            <h3>{{ $t('privacyPolicy.section10.title') }}</h3>
            <p>{{ $t('privacyPolicy.section10.description') }}</p>
            <p>
              <a :href="$t('privacyPolicy.section10.link1')">
                {{ $t('privacyPolicy.section10.link1') }}
              </a>
            </p>
            <p>
              <a :href="$t('privacyPolicy.section10.link2')">
                {{ $t('privacyPolicy.section10.link2') }}
              </a>
            </p>
            <p>
              <a :href="$t('privacyPolicy.section10.link3')">
                {{ $t('privacyPolicy.section10.link3') }}
              </a>
            </p>
          </li>
          <li class="privacyPolicy__contents-box">
            <h3>{{ $t('privacyPolicy.section11.title') }}</h3>
            <p>{{ $t('privacyPolicy.section11.description') }}</p>
          </li>
        </ol>
      </div>
    </div>
    <pa-footer />
  </div>
</template>

<script>
import PaHeader from '@/components/PaHeader'
import PaFooter from '@/components/PaFooter'

export default {
  name: 'Welcome',
  components: {
    PaHeader,
    PaFooter,
  },
}
</script>

<style lang="scss" scoped>
.privacyPolicy {
  box-sizing: border-box;
  max-width: 1000px;
  margin: 0 auto;
  color: $color-black;
  word-break: break-all;
  @include isPC() {
    padding: 80px;
  }
  @include isSP() {
    padding: 20px;
  }
}
.privacyPolicy__header {
  font-weight: normal;
  font-size: 2.4rem;
  font-family: Questrial, sans-serif;
  text-align: center;
}
.privacyPolicy__contents {
  margin-top: 40px;
  padding: 20px;
  border: 1px solid $color-light-gray;
  border-radius: 8px;
  text-align: left;
  ol li {
    list-style-type: decimal;
  }
  ol li::marker {
    font-weight: bold;
  }
  @include isSP() {
    ol {
      padding-inline-start: 20px;
    }
  }
}
.privacyPolicy__contents-box {
  margin-bottom: 32px;
  table {
    margin-top: 8px;
    border-collapse: collapse;
    border-spacing: 0;
    th,
    td {
      border: 1px solid $color-black;
      padding-left: 4px;
    }
    ul li {
      list-style: initial;
      margin-left: 24px;
    }
  }
}
</style>
