import chartUtil from './chartUtil'
import i18n from '../locales/i18n'

export default {
  transitionColors(caseIndex) {
    const colors = [
      ['#325AB4', '#5B7BC3'],
      ['#3278B4', '#5B93C3'],
      ['#3296B4', '#5BABC3'],
    ]
    return colors[caseIndex]
  },

  salesColors(caseIndex) {
    const colors = [['#325AB4'], ['#3278B4'], ['#3296B4']]
    return colors[caseIndex]
  },

  transitionOptions(caseIndex, term) {
    const colors = this.transitionColors(caseIndex)
    return {
      chart: {
        stacked: true,
        animations: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: colors,
      fill: {
        colors: colors,
        opacity: 1,
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        offsetY: -20,
        style: {
          colors: ['#000000'],
          fontSize: '12px',
        },
        background: {
          enabled: false,
        },
        formatter: (val) => {
          return chartUtil.formatAmount(val)
        },
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      xaxis: {
        categories: term,
        labels: {
          style: {
            colors: '#666666',
            fontSize: '14px',
          },
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: [
        {
          tickAmount: 5,
          axisBorder: {
            show: false,
          },
          labels: {
            minWidth: 48,
            maxWidth: 48,
            style: {
              colors: '#666666',
            },
            formatter: (val) => {
              return chartUtil.formatAmount(val)
            },
          },
        },
      ],
      tooltip: {
        x: {
          show: false,
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        fontSize: '10px',
        position: 'bottom',
        horizontalAlign: 'right',
        markers: {
          fillColors: colors,
        },
        labels: {
          colors: ['#666666'],
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    }
  },

  salesOptions(caseIndex, term) {
    const colors = this.salesColors(caseIndex)
    return {
      chart: {
        animations: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: colors,
      fill: {
        colors: colors,
        opacity: 1,
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        offsetY: -20,
        style: {
          colors: ['#666666'],
          fontSize: '12px',
        },
        background: {
          enabled: false,
        },
        formatter: (val) => {
          return chartUtil.formatAmount(val)
        },
      },
      grid: {
        strokeDashArray: 3,
      },
      xaxis: {
        categories: term,
        labels: {
          style: {
            colors: '#666666',
            fontSize: '14px',
          },
        },
        axisBorder: {
          show: false,
        },
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      yaxis: [
        {
          seriesName: i18n.t('revenue.sales'),
          labels: {
            minWidth: 48,
            maxWidth: 48,
            style: {
              colors: '#666666',
            },
            formatter: (val) => {
              return chartUtil.formatAmount(val)
            },
          },
        },
      ],
      tooltip: {
        enabled: false,
      },
      legend: {
        showForSingleSeries: true,
        fontSize: '10px',
        position: 'bottom',
        horizontalAlign: 'right',
        markers: {
          fillColors: colors,
        },
        labels: {
          colors: ['#666666'],
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    }
  },
}
