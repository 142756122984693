<template>
  <div class="revise">
    <h2>{{ $t('monthlyPl.reviseBudgetTitle') }}</h2>
    <div class="revise__contents">
      <article>
        <input
          id="upward"
          name="reviseBudget"
          type="radio"
          value="upward"
          v-model="selectedValue"
        />
        <label for="upward" class="radio">
          {{ $t('monthlyPl.upward') }}
        </label>
        <div class="explanation">
          <Tooltip />
          <p>
            {{ $t('monthlyPl.upwardTooltip') }}
            <a
              :href="$t('monthlyPl.upwardExternalLink')"
              target="_blank"
              rel="noreferrer"
              >{{ $t('monthlyPl.upwardExternalText') }}
              <OffLinkIcon />
            </a>
          </p>
        </div>
      </article>
      <article>
        <input
          id="downward"
          name="reviseBudget"
          type="radio"
          value="downward"
          v-model="selectedValue"
        />
        <label for="downward" class="radio">
          {{ $t('monthlyPl.downward') }}
        </label>
        <div class="explanation">
          <Tooltip />
          <p>
            {{ $t('monthlyPl.downwardTooltip') }}
            <a
              :href="$t('monthlyPl.downwardExternalLink')"
              target="_blank"
              rel="noreferrer"
              >{{ $t('monthlyPl.downwardExternalText') }}
              <OffLinkIcon />
            </a>
          </p>
        </div>
      </article>
    </div>
    <div class="revise__buttons">
      <button class="register" @click="register" :disabled="!isRoleCanEdit">
        {{ $t('submit') }}
      </button>
    </div>
  </div>
</template>

<script>
import Tooltip from '@/assets/svg/Tooltip.svg'
import OffLinkIcon from '@/assets/svg/OffLinkIcon.svg'

export default {
  name: 'Revise',
  components: {
    Tooltip,
    OffLinkIcon,
  },
  data() {
    return {
      selectedValue: 'upward',
    }
  },
  computed: {
    isRoleCanEdit() {
      const role = this.$store.getters['me/role']
      return role.monthly_pl === 'can_edit'
    },
  },
  methods: {
    async register() {
      if (!this.isRoleCanEdit) return
      try {
        await this.$store.dispatch('monthlyPl/revisedBudget', {
          pattern: this.selectedValue,
        })
        this.flashMessage.success({
          title: 'Success',
          message: this.$t('monthlyPl.reviseBudgetSuccess'),
        })
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.revise {
  text-align: left;
  padding: 30px;
  background-color: $color-white;

  h2 {
    font-size: 1.6rem;
  }

  &__contents {
    margin: 40px auto;
    padding: 0 30px;
    text-align: left;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    gap: 16px;

    input[type='radio'] {
      display: none;
    }

    .radio {
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 30px;
      position: relative;
      width: auto;
    }

    .radio::before {
      background: #fff;
      border: 1px solid $color-theme;
      border-radius: 50%;
      content: '';
      display: block;
      height: 16px;
      left: 5px;
      margin-top: -8px;
      position: absolute;
      top: 50%;
      width: 16px;
    }

    .radio::after {
      background: $color-theme;
      border-radius: 50%;
      content: '';
      display: block;
      height: 10px;
      left: 9px;
      margin-top: -4px;
      opacity: 0;
      position: absolute;
      top: 50%;
      width: 10px;
    }

    input[type='radio']:checked + .radio::after {
      opacity: 1;
    }

    input[type='radio'] {
      position: relative;
      top: 2px;
    }

    input[type='radio']:disabled + .radio::before {
      border: 1px solid $color-gray;
      cursor: not-allowed;
    }

    input[type='radio']:disabled + .radio::after {
      cursor: not-allowed;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 16px;

    button {
      background-color: $color-white;
      min-width: 120px;
      padding: 10px 24px;
      font-size: 1.2rem;
      font-weight: bold;
      border-radius: 16px;
      cursor: pointer;
    }

    .cancel {
      color: $color-theme;
      border: 1px solid $color-theme;
    }

    .register {
      color: $color-white;
      background-color: $color-theme;
      border: none;
    }

    .register:disabled {
      background-color: rgb(202, 202, 202);
      cursor: default;
      pointer-events: none;
    }
  }
}

.explanation {
  font-size: 14px;
  color: #575757;
  margin-left: 30px;
  display: flex;
  align-items: flex-start;
  font-weight: normal;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 8px;
  gap: 4px;
  letter-spacing: -0.03em;

  svg {
    flex-shrink: 0;
    margin-left: 2px;
  }

  a {
    display: block;
    color: rgba($color-theme, 0.7);
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;

    svg path {
      fill: rgba($color-theme, 0.7);
    }

    &:hover {
      color: $color-theme;

      svg path {
        fill: $color-theme;
      }
    }
  }
}
</style>
