export default {
  browserLanguage() {
    const browserLanguage =
      (window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language

    let lang = 'ja'
    switch (browserLanguage) {
      case 'en':
      case 'en-US':
      case 'en-us':
        lang = 'en'
        break
      default:
        break
    }
    return lang
  },
}
