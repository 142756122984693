import Vue from 'vue'

const state = Vue.observable({
  isAuthenticated: false,
  user: null,
  businessPlans: null,
})

export default {
  state() {
    return state
  },

  setUser(value) {
    state.user = value
  },

  setBusinessPlans(value) {
    state.businessPlans = value
  },

  setIsAuthenticated(value) {
    state.isAuthenticated = value
  },
}
