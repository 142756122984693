<template>
  <section class="themeColorPanel">
    <h2>{{ $t('setting.colorTheme') }}</h2>
    <div class="themeColorPanel__cards">
      <theme-color-card
        theme-color-name="purple"
        :is-active="themeColor === 'purple'"
      />
      <theme-color-card
        theme-color-name="black"
        :is-active="themeColor === 'black'"
      />
      <theme-color-card
        theme-color-name="gray"
        :is-active="themeColor === 'gray'"
      />
    </div>
  </section>
</template>

<script>
import ThemeColorCard from './ThemeColorCard'
export default {
  name: 'ThemeColorPanel',
  components: { ThemeColorCard },
  computed: {
    themeColor() {
      return this.$store.getters['me/businessPlan'].theme_color
    },
  },
}
</script>

<style lang="scss" scoped>
.themeColorPanel__cards {
  display: flex;
  width: 100%;
  margin: 24px 0;
  gap: 24px;
}
</style>
