import Vue from 'vue'
import router from '@/router'
import plColor from '@/charts/plChartColor.js'
import businessPlans from '@/client/businessPlans'

export default {
  namespaced: true,
  state: {
    defaultColors: [
      ['#325AB4', '#FF3C50', '#5B7BC3'],
      ['#3278B4', '#FF5151', '#5B93C3'],
      ['#3296B4', '#FF6F51', '#5BABC3'],
    ],
    colors: [
      [null, null, null],
      [null, null, null],
      [null, null, null],
    ],
    editingColors: [
      [null, null, null],
      [null, null, null],
      [null, null, null],
    ],
  },
  getters: {
    colors: (state) => state.colors,
    editingColors: (state) => state.editingColors,
    postColors: (state) => [
      {
        arr: state.editingColors[0][0],
        pl: state.editingColors[0][1],
        gross_profit: state.editingColors[0][2],
      },
      {
        arr: state.editingColors[1][0],
        pl: state.editingColors[1][1],
        gross_profit: state.editingColors[1][2],
      },
      {
        arr: state.editingColors[2][0],
        pl: state.editingColors[2][1],
        gross_profit: state.editingColors[2][2],
      },
    ],
  },
  mutations: {
    setColors(state, payload) {
      if (payload.length > 0) {
        const colors = payload
        state.colors = [
          [colors[0].arr, colors[0].pl, colors[0].gross_profit],
          [colors[1].arr, colors[1].pl, colors[1].gross_profit],
          [colors[2].arr, colors[2].pl, colors[2].gross_profit],
        ]
      } else {
        state.colors = state.defaultColors
      }
    },
    changeColor(state, payload) {
      const first = payload.newV
      const second = plColor.calculateNextColor(first)
      const third = plColor.calculateNextColor(second)
      Vue.set(state.editingColors[0], payload.index, first)
      Vue.set(state.editingColors[1], payload.index, second)
      Vue.set(state.editingColors[2], payload.index, third)
    },
  },
  actions: {
    async getColors({ commit }) {
      const currentBusinessPlanId = router.currentRoute.params.id
      const result = await businessPlans.getPl({
        business_plan_id: currentBusinessPlanId,
      })
      await commit('setColors', result.data.colors)
    },
    setEditColors({ state, commit }) {
      commit('changeColor', { newV: state.colors[0][0], index: 0 })
      commit('changeColor', { newV: state.colors[0][1], index: 1 })
      commit('changeColor', { newV: state.colors[0][2], index: 2 })
    },
  },
}
