import Vue from 'vue'

Vue.filter('numberFormat', (value, unit = '') => {
  if (typeof value === 'undefined' || value === null) return ''
  if (isNaN(value)) return value
  if (Math.sign(value) < 0) {
    return `${value.toLocaleString().replace('-', '△')}${unit}`
  }
  return value.toLocaleString() + unit
})
