<template>
  <modal name="dbSuggestModal" :clickToClose="false" width="800" height="auto">
    <div class="modalContainer">
      <div class="modalContainer__title">
        <h3>{{ $t('pl.suggestModalTitle') }}</h3>
      </div>
      <p class="modalContainer__description">
        {{ $t('pl.suggestModalDescription1') }}
        <br />
        <a href="https://db.projection-ai.com/" target="_blank"
          >projection-ai:db</a
        >
        <off-link-icon />
        {{ $t('pl.suggestModalDescription2') }}
      </p>
      <div class="modalContainer__contents">
        <h4>{{ $t('pl.suggestModalArrPerEmployee') }}</h4>
        <p class="modalContainer__contents--subtitle">
          {{ $t('pl.suggestModalCompanyAvg') }}
        </p>
        <p>
          {{ perEmployeeData.arr_per_employee.average | numberFormat }}
          {{
            `${unitString}（${perEmployeeData.arr_per_employee.companies} ${$t(
              'pl.suggestModalAvgOfCompany'
            )}）`
          }}
        </p>
        <p class="modalContainer__contents--subtitle">
          {{ $t('pl.suggestModalCurrentPLPlannedValue') }}
        </p>
        <table class="modalContainer__contents--table">
          <thead>
            <tr>
              <td v-for="(year, index) in annualDate" :key="index">
                {{ year }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                v-for="(pl, index) in pls"
                :key="index"
                :class="
                  setClassDifferenceColor(
                    pl.arr_per_headcount,
                    perEmployeeData.arr_per_employee.average
                  )
                "
              >
                {{
                  pl.arr_per_headcount
                    ? pl.arr_per_headcount -
                      perEmployeeData.arr_per_employee.average
                    : '-' | numberFormat
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <h4>{{ $t('pl.suggestModalRevenuePerEmployee') }}</h4>
        <p class="modalContainer__contents--subtitle">
          {{ $t('pl.suggestModalCompanyAvg') }}
        </p>
        <p>
          {{ perEmployeeData.revenue_per_employee.average | numberFormat }}
          {{
            `${unitString}（${
              perEmployeeData.revenue_per_employee.companies
            } ${$t('pl.suggestModalAvgOfCompany')}）`
          }}
        </p>
        <p class="modalContainer__contents--subtitle">
          {{ $t('pl.suggestModalCurrentPLPlannedValue') }}
        </p>
        <table class="modalContainer__contents--table">
          <thead>
            <tr>
              <td v-for="(year, index) in annualDate" :key="index">
                {{ year }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                v-for="(pl, index) in pls"
                :key="index"
                :class="
                  setClassDifferenceColor(
                    pl.revenue_per_headcount,
                    perEmployeeData.revenue_per_employee.average
                  )
                "
              >
                {{
                  pl.revenue_per_headcount
                    ? pl.revenue_per_headcount -
                      perEmployeeData.revenue_per_employee.average
                    : '-' | numberFormat
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="isExistsAlert" class="modalContainer__hint">
          <contact-icon />
          {{ $t('pl.suggestModalHint') }}
        </div>
      </div>
      <div class="modalContainer__footer">
        <button class="modalContainer__footer--button" @click="hideModal">
          {{ $t('close') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import OffLinkIcon from '@/assets/svg/OffLinkIcon.svg'
import ContactIcon from '@/assets/svg/ContactIcon.svg'
import dbSuggest from '@/client/dbSuggest'
import currencyUtil from '../../util/currency'

export default {
  name: 'DbSuggestModal',
  components: { OffLinkIcon, ContactIcon },
  props: {
    annualDate: {
      type: Array,
      require: true,
    },
    pls: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      perEmployeeData: {
        arr_per_employee: {
          average: 0,
          companies: 0,
        },
        revenue_per_employee: {
          average: 0,
          companies: 0,
        },
      },
      isExistsAlert: false,
    }
  },
  computed: {
    currency() {
      const businessPlan = this.$store.getters['me/businessPlan']
      return businessPlan.currency
    },
    unitString() {
      return currencyUtil.unit(this.currency, 'yen')
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    showModal() {
      this.$modal.show('dbSuggestModal')
      this.isExistsAlert = false
    },
    hideModal() {
      this.$modal.hide('dbSuggestModal')
      this.isExistsAlert = false
    },
    async getData() {
      try {
        const result = await dbSuggest.getPerEmployee()
        this.perEmployeeData = result.data
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
    setClassDifferenceColor(plannedValue, avgValue) {
      if (!plannedValue) return ''
      if (plannedValue >= avgValue) {
        return '--safety'
      }
      this.isExistsAlert = true
      return '--alert'
    },
  },
}
</script>

<style lang="scss" scoped>
.modalContainer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.modalContainer__title {
  padding: 16px;
  border-radius: 4px;
  background-color: $color-theme;
  color: $color-theme-text;
  text-align: center;
}

.modalContainer__description {
  font-size: 1.4rem;
  text-align: left;
  color: $color-base-text;
  font-weight: bold;

  a {
    cursor: pointer;
    text-decoration: underline;
    margin-right: 4px;
  }

  svg path {
    fill: $color-base-text;
  }
}

.modalContainer__contents {
  text-align: left;

  h4 {
    display: block;
    padding: 2px 8px;
    font-size: 1.6rem;
    font-weight: bold;
    color: $color-theme-text;
    background-color: $color-theme;
    margin-bottom: 4px;
  }
}

.modalContainer__contents--subtitle {
  font-weight: bold;
  margin: 8px 0 4px;
}

.modalContainer__contents--table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  font-size: 1.2rem;
  margin-bottom: 24px;

  thead tr {
    text-align: center;
    font-weight: bold;

    td {
      border: 1px solid #000;
      padding: 4px;
      color: $color-theme-background-text;
      background-color: $color-theme-background;
    }
  }

  tbody tr {
    text-align: right;

    td {
      border: 1px solid #000;
      padding: 4px 8px;

      &.--safety {
        color: $color-arr;
      }
      &.--alert {
        color: $color-red;
      }
    }
  }
}

.modalContainer__hint {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  color: $color-warning-background-text;
  background-color: $color-warning-background;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: $color-warning-background-text;
    }
  }
}

.modalContainer__footer {
  margin-top: auto;

  &--button {
    cursor: pointer;
    padding: 8px 24px;
    color: $color-theme;
    background-color: $color-white;
    border: 1px solid $color-theme;
    border-radius: 8px;
  }
}
</style>
