<template>
  <div class="caseTab">
    <div class="caseTab__tabArea">
      <div class="caseTab__tabArea--list">
        <div
          v-for="(c, key) in cases"
          :key="key"
          class="caseTab__list--case"
          :class="{ 'active-txt-white': activeTextWhite(c.case_id, key) }"
          :style="`background-color: ${activeBg(c.case_id, key)}`"
          @click="selectCase(c.case_id)"
        >
          <div class="caseTab__list--item">
            <span class="listItem__title">{{ caseName(c.case_name) }}</span>
            <button
              class="listItem__button"
              @click="$set(showMenu, key, !showMenu[key])"
            >
              <arrow-bottom-icon />
            </button>
            <div
              v-show="showMenu[key] && currentCaseId === c.case_id"
              class="caseTab__menu"
            >
              <button
                v-if="cases.length > 1"
                class="caseTab__menu--delete"
                @click="$refs.deleteModal.showModal()"
              >
                {{ $t('pl.deleteCase') }}
              </button>
              <button
                class="caseTab__menu--rename"
                @click="$refs.editModal.showModal(c.case_name)"
              >
                {{ $t('pl.editCaseName') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        v-if="canAdded && cases.length < 3"
        class="caseTab__list--addButton"
        @click="$refs.modal.showModal()"
      >
        <plus-icon />
        {{ $t('pl.addCase') }}
      </button>
      <div v-if="canAdded && cases.length > 1">
        <button
          class="case__comparison"
          @click="$parent.$parent.isComparison = !isComparison"
        >
          <switch-off-icon v-if="!isComparison" />
          <switch-on-icon v-else class="--switch-on" />
          <span>{{ $t('pl.compareCase') }}</span>
        </button>
      </div>
    </div>
    <slot name="tabAreaRight" />
    <PaTabColorPicker
      v-if="hasColorPicker"
      :isEditingColor="isEditingColor"
      :isPostingColor="isPostingColor"
      @editColor="$emit('editColor')"
      @postColor="$emit('postColor')"
    />

    <add-case-modal ref="modal" @fetchData="fetchData" />
    <delete-case-modal ref="deleteModal" @fetchData="fetchData" />
    <edit-case-modal ref="editModal" @fetchData="fetchData" />
  </div>
</template>

<script>
import AddCaseModal from './Modal/AddCase'
import DeleteCaseModal from './Modal/DeleteCase'
import EditCaseModal from './Modal/EditCase'
import PlusIcon from '@/assets/svg/Plus.svg'
import ArrowBottomIcon from '@/assets/svg/ArrowBottom.svg'
import SwitchOnIcon from '@/assets/svg/SwitchOn.svg'
import SwitchOffIcon from '@/assets/svg/SwitchOff.svg'
import PaTabColorPicker from '@/components/PaTabColorPicker'
import localUtil from '../util/local'
import plColor from '@/charts/plChartColor.js'

export default {
  name: 'PaTab',
  components: {
    AddCaseModal,
    DeleteCaseModal,
    EditCaseModal,
    PlusIcon,
    ArrowBottomIcon,
    SwitchOnIcon,
    SwitchOffIcon,
    PaTabColorPicker,
  },
  props: {
    canAdded: {
      defaults: false,
    },
    isComparison: Boolean,
    isEditingColor: {
      type: Boolean,
      default: false,
    },
    isPostingColor: {
      type: Boolean,
      default: false,
    },
    hasColorPicker: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMenu: [false, false, false],
    }
  },
  computed: {
    colors() {
      return this.isEditingColor
        ? this.$store.getters['color/editingColors']
        : this.$store.getters['color/colors']
    },
    currentCaseId() {
      return this.$store.getters['currentView/caseId']
    },
    cases() {
      return this.$store.getters['me/businessPlan'].cases
    },
  },
  methods: {
    async fetchData() {
      await this.$emit('fetch')
      this.showMenu = [false, false, false]
    },
    selectCase(caseId) {
      this.$store.dispatch('currentView/setCaseId', caseId)
    },
    activeClass(caseId) {
      return this.currentCaseId === caseId || this.isComparison
    },
    activeBg(caseId, key) {
      return this.activeClass(caseId) ? this.colors?.[key]?.[0] : '#ffffff'
    },
    activeTextWhite(caseId, key) {
      const bg = this.activeBg(caseId, key)
      return plColor.checkBrightness(bg)
    },
    caseName(name) {
      return localUtil.caseName(name)
    },
  },
}
</script>

<style lang="scss" scoped>
.caseTab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid $color-theme;
}

.caseTab__tabArea {
  display: flex;
  align-items: center;
}

.caseTab__tabArea--list {
  display: flex;

  div:nth-child(n + 2) {
    margin-left: -1px;
  }
}

.caseTab__list--case {
  position: relative;
  width: 160px;
  height: 50px;
  padding: 16px 8px;
  background: $color-white;
  color: $color-theme;
  border: 2px solid $color-theme;
  border-bottom: none;
  cursor: pointer;
  filter: grayscale(1);
  z-index: 100;
}

.caseTab__list--item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;

  .listItem__title {
    font-weight: bold;
    line-height: 1.4rem;
  }

  .listItem__button {
    position: absolute;
    right: 0;
    background: transparent;
  }
}

.caseTab__list--addButton {
  display: flex;
  margin-left: 16px;
  color: rgb(153, 153, 153);
  font-weight: bold;
  background-color: transparent;

  svg {
    margin-right: 8px;
  }
}

.active-txt-white {
  color: $color-white;

  path {
    stroke: $color-white;
  }
}

.caseTab__menu {
  position: absolute;
  top: 34px;

  button {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 160px;
    height: 48px;
    padding: 8px 0;
    color: #999;
    font-weight: bold;
    box-shadow: 0 0 4px rgb(0 0 0 / 10%);
  }

  &--delete {
    background: #f2f2f2;
  }

  &--rename {
    background: #ffffff;
  }

  &--delete:hover,
  &--rename:hover {
    color: $color-theme;
  }
}

.case__comparison {
  display: flex;
  align-items: center;
  margin-left: 24px;
  background: transparent;

  span {
    margin: 0 6px;
    font-weight: bold;
    font-size: 1.4rem;
    font-family: $fontFamily-en;
  }

  .--switch-on {
    path:first-child {
      fill: $color-theme;
    }
  }
}
</style>
