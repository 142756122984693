<template>
  <div class="settingsContainer">
    <div v-if="isLoading" class="loading">
      <loading />
    </div>
    <div class="settingsContainer__titles">
      <h2 class="settings__title">{{ $t('setting.accountSettings') }}</h2>
      <button
        class="settings__close"
        @click="$store.dispatch('currentView/hideSettingsModal')"
      />
    </div>
    <div v-show="!isLoading" class="settings">
      <div class="settings__header">
        <ul class="setting__header-menu">
          <li
            @click="currentComponent = 'businessPlans'"
            :class="{ active: isCurrentComponent('businessPlans') }"
          >
            {{ $t('setting.businessPlanList') }}
          </li>
          <li
            @click="currentComponent = 'plan'"
            :class="{ active: isCurrentComponent('plan') }"
          >
            {{ $t('setting.plan') }}
          </li>
          <li
            @click="currentComponent = 'profile'"
            :class="{ active: isCurrentComponent('profile') }"
          >
            {{ $t('setting.profile') }}
          </li>
        </ul>
        <button @click="signOut">{{ $t('setting.logout') }}</button>
      </div>
      <billing
        v-if="currentComponent === 'plan'"
        :plan="user.plan"
        :billing="billing"
      />
      <business-plan-list
        v-if="currentComponent === 'businessPlans'"
        :business-plans="businessPlans"
        :plan="user.plan"
      />
      <user-info v-if="currentComponent === 'profile'" :user="user" />
      <profile
        v-if="currentComponent === 'profile'"
        :user="user"
        @save:profile="saveProfile"
      />
    </div>
    <FlashMessage />
  </div>
</template>

<script>
import auth from '@/client/auth'
import userStore from '@/store/user'
import UserInfo from './UserInfo'
import Billing from './Billing'
import billing from '../../client/billing'
import BusinessPlanList from './BusinessPlanList'
import Profile from './Profile'
import user from '../../client/user'
import Loading from '../Loading'

export default {
  name: 'Settings',
  components: {
    Loading,
    Profile,
    BusinessPlanList,
    Billing,
    UserInfo,
  },
  props: {
    currentComponentParam: String,
  },
  data() {
    return {
      currentComponent: 'businessPlans',
      isOwner: false,
      billing: {},
      currentYear: 1,
      currentBusinessPlanId: '',
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters['currentView/isLoading']
    },
    user() {
      return userStore.state().user
    },
    businessPlans() {
      return userStore.state().businessPlans
    },
  },
  created() {
    if (!this.currentComponentParam) return
    this.currentComponent = this.currentComponentParam
  },
  async mounted() {
    this.currentBusinessPlanId = this.businessPlans[0].business_plan_id
    await this.getBilling()
    await this.$store.dispatch('currentView/disableLoading')
  },
  methods: {
    async getBilling() {
      const result = await billing.retrieve()
      this.billing = result.data
    },
    async signOut() {
      await auth.signOut()
      await this.$router.replace({ name: 'Home' })
    },
    async saveProfile() {
      try {
        await user.edit({
          name: this.user.name,
          company_name: this.user.company.company_name,
          position: this.user.company.position,
          employees: this.user.company.employees,
          stage: this.user.company.stage,
        })
        this.flashMessage.success({
          title: 'Success',
          message: this.$t('setting.profileComputed'),
        })
        await auth.getMe()
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
    isCurrentComponent(componentName) {
      return this.currentComponent === componentName
    },
    async changeBusinessPlan(businessPlanId) {
      this.$router.push({ name: 'PL', params: { id: businessPlanId } })
    },
  },
}
</script>

<style lang="scss" scoped>
.loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settingsContainer {
  height: 100%;
  overflow-y: scroll;
  background-color: rgb(242, 242, 242);
  padding: 32px;
}

.settingsContainer__titles {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  .settings__close {
    position: relative;
    width: 24px;
    height: 24px;
    background: $color-theme;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 3px;
      height: 16px;
      background: $color-white;
    }
    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.settings {
  width: 100%;
  color: $color-black;
  text-align: left;

  section {
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 10%) 0 0 4px;
    margin-bottom: 24px;
    padding: 40px;
  }
}

.settings__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  .setting__header-menu {
    display: flex;
    gap: 16px;
    background-color: $color-white;
    border-radius: 8px;
    padding: 10px;
    li {
      cursor: pointer;
      color: $color-gray;
      font-size: 1.8rem;
      font-weight: bold;
      padding: 4px 24px;
      border-radius: 4px;
      transition: 0.3s;
      &.active {
        color: $color-white;
        background-color: $color-theme;
      }
      &:not(.active):hover {
        background-color: $color-light-gray;
        color: $color-dark-gray;
      }
    }
  }
  button {
    box-sizing: border-box;
    width: 124px;
    height: 32px;
    padding: 8px 0;
    color: $color-theme;
    font-weight: bold;
    font-size: 1.4rem;
    text-align: center;
    background: $color-white;
    border: 1px solid $color-theme;
    border-radius: 16px;
    cursor: pointer;
  }
}
</style>
