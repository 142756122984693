<template>
  <thead class="monthlyPlTableHeader">
    <tr>
      <th rowspan="4" class="monthlyPlTableHeader__title">
        {{ title }}
        <p v-if="patternText" class="monthlyPlTableHeader__title--pattern">
          {{ patternText }}
        </p>
        <monthly-pl-switch v-if="currentDetails() === 'difference'" />
      </th>
      <template v-for="(month, key) in monthlyData">
        <th v-if="isShow(month)" :key="key">
          <MonthlyPlColumnCancelButton
            :monthly-index="key"
            :is-editing="isEditingFlags[key]"
          />
        </th>
      </template>
    </tr>
    <tr>
      <template v-for="(month, key) in monthlyData">
        <th v-if="isShow(month)" :key="key">
          <template v-if="isEditableMonth(month) && isRoleCanEdit">
            <MonthlyPlColumnEditButton
              :monthly-index="key"
              :is-editing="isEditingFlags[key]"
              >{{ month.monthName }}
            </MonthlyPlColumnEditButton>
          </template>
          <template v-else>
            <span class="monthlyPlTableHeader__term">
              {{ month.monthName }}
            </span>
          </template>
        </th>
      </template>
    </tr>
    <tr>
      <template v-for="(month, key) in monthlyData">
        <th v-if="isShow(month)" :key="key">
          <input
            v-if="isRoleCanEdit"
            :value="month.comment"
            @change="updateComment($event, key)"
            :placeholder="$t('monthlyPl.memo')"
            class="monthlyPlTableHeader__memo"
          />
          <p v-else class="monthlyPlTableHeader__memo">{{ month.comment }}</p>
        </th>
      </template>
    </tr>
    <tr>
      <template v-for="(month, key) in monthlyData">
        <th
          v-if="isShow(month)"
          :key="key"
          class="monthlyPlTableHeader__status"
          :class="{
            '--edit': month.status === 'ACT' && isEditingFlags[key],
            '--act': month.status === 'ACT',
            '--est': month.status === 'EST',
          }"
        >
          <template v-if="month.status === 'ACT' && isEditingFlags[key]">
            <MonthlyPlColumnRedoButton :month="month" :monthly-index="key" />
          </template>
          <template v-else>
            {{ $t(`monthlyPl.${month.status.toLowerCase()}`) }}
          </template>
        </th>
      </template>
    </tr>
  </thead>
</template>

<script>
import MonthlyPlColumnEditButton from './MonthlyPlColumnEditButton'
import MonthlyPlColumnCancelButton from './MonthlyPlColumnCancelButton'
import MonthlyPlColumnRedoButton from './MonthlyPlColumnRedoButton'
import MonthlyPlSwitch from './MonthlyPlSwitch'

export default {
  name: 'MonthlyPlTableHeader',
  components: {
    MonthlyPlSwitch,
    MonthlyPlColumnRedoButton,
    MonthlyPlColumnCancelButton,
    MonthlyPlColumnEditButton,
  },
  inject: ['displayFilter', 'currentDetails'],
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    isRoleCanEdit() {
      const role = this.$store.getters['me/role']
      return role.monthly_pl === 'can_edit'
    },
    isEditingFlags() {
      return this.$store.getters['monthlyPl/isEditingFlags']
    },
    monthlyData() {
      const detailsData =
        this.$store.getters[`monthlyPl/${this.currentDetails()}`]
      if (this.currentDetails() === 'difference') {
        const differenceMode = this.$store.getters['monthlyPl/isRate']
          ? 'rate'
          : 'value'
        return detailsData[differenceMode]
      }
      return detailsData
    },
    patternText() {
      if (this.currentDetails() !== 'actual') return ''
      const pattern = this.$store.getters['monthlyPl/pattern']
      if (!pattern) return ''
      const patternText = {
        not_yet: '',
        upward: this.$t('monthlyPl.upwardTitle'),
        downward: this.$t('monthlyPl.downwardTitle'),
      }
      return patternText[pattern]
    },
  },
  methods: {
    isEditableMonth(month) {
      if (
        this.currentDetails() === 'difference' ||
        this.currentDetails() === 'monthlyProgress'
      ) {
        return false
      }
      return !(month.isQuarter || month.monthName === 'CY')
    },
    isShow(month) {
      if (month.monthType === 'month') {
        return this.displayFilter().months
      }
      if (month.monthType === 'quarter') {
        return this.displayFilter().quarter
      }
      if (month.monthType === 'cy') {
        return this.displayFilter().cy
      }
    },
    async updateComment(e, monthlyIndex) {
      try {
        await this.$store.dispatch('monthlyPl/editComment', {
          monthlyIndex,
          currentDetails: this.currentDetails(),
          content: e.target.value,
        })
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.monthlyPlTableHeader__title {
  font-size: 1.8rem;
  position: sticky;
  z-index: 1;
  left: 0;
  background-color: $color-white;
  border-bottom: 1px solid $color-light-gray;

  &:after {
    content: '';
    position: absolute;
    top: -2px;
    right: -50px;
    width: 50px;
    height: calc(100% + 2px);
    border-left: 1px solid #daeeff;
    background: linear-gradient(
      90deg,
      rgba(20, 32, 38, 0.07) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.monthlyPlTableHeader__title--pattern {
  font-size: 1.3rem;
  color: $color-gray;
}

.monthlyPlTableHeader__term {
  display: block;
  height: 26px;
}

.monthlyPlTableHeader__memo {
  text-align: right;
  width: 100%;
  border: 1px solid #cacaca;
  border-radius: 4px;
  outline: none;
  height: 26px;
}

.monthlyPlTableHeader__status {
  padding: 4px 2px;
  height: 37px;
  text-align: right;
  position: relative;
  border-bottom: 1px solid $color-light-gray;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 7px;
  }

  &.--act {
    &:before {
      background-color: $color-theme;
    }
  }

  &.--act.--edit {
    &:before {
      background: linear-gradient(
        -45deg,
        #f5f4f7 25%,
        $color-theme 25%,
        $color-theme 50%,
        #f5f4f7 50%,
        #f5f4f7 75%,
        $color-theme 75%,
        $color-theme
      );
      background-size: 10px 10px;
    }
  }

  &.--est {
    &:before {
      background: linear-gradient(
        -45deg,
        #fff 25%,
        #cccccc 25%,
        #cccccc 50%,
        #fff 50%,
        #fff 75%,
        #cccccc 75%,
        #cccccc
      );
      background-size: 8px 8px;
    }
  }
}
</style>
