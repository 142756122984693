<template>
  <table class="plComparisonTable">
    <thead>
      <tr>
        <th></th>
        <th></th>
        <th v-for="(c, key) in businessPlan" :key="key">
          <p
            :style="`background-color: ${caseColors[key]}; color: ${textColor(
              caseColors[key]
            )}`"
          >
            {{ caseName(c.case_name) }}
          </p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ `Y1 ${$t('pl.arr')}` }}</td>
        <td>（{{ unit(currency) }}）</td>
        <td v-for="(c, key) in businessPlan" :key="key">
          {{ c.pl[0].arr | numberFormat }}
        </td>
      </tr>
      <tr>
        <td>{{ `Y1 ${$t('pl.operatingIncome')}` }}</td>
        <td>（{{ unit(currency) }}）</td>
        <td v-for="(c, key) in businessPlan" :key="key">
          {{ c.pl[0].operating_pl | numberFormat }}
        </td>
      </tr>
      <tr>
        <td style="display: flex; align-items: center">
          {{ $t('pl.requiredCash') }}
          <pa-tooltip>{{ $t('pl.requiredCashTooltip') }}</pa-tooltip>
        </td>
        <td>（{{ unit(currency) }}）</td>
        <td v-for="(c, key) in businessPlan" :key="key">
          {{ (c.pl[0].operating_pl * -1) | numberFormat }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import PaTooltip from '../PaTooltip'
import currencyUtil from '../../util/currency'
import localUtil from '../../util/local'
import plColor from '@/charts/plChartColor.js'

export default {
  name: 'PlComparisonDetail',
  components: { PaTooltip },
  props: {
    businessPlan: Array,
    currency: String,
    colors: Array,
  },
  computed: {
    caseColors() {
      return [this.colors[0][0], this.colors[1][0], this.colors[2][0]]
    },
  },
  methods: {
    unit(currency) {
      return currencyUtil.unit(currency)
    },
    caseName(caseName) {
      return localUtil.caseName(caseName)
    },
    textColor(bgColor) {
      return plColor.checkBrightness(bgColor) ? '#ffffff' : '#000000'
    },
  },
}
</script>

<style lang="scss" scoped>
.plComparisonTable {
  thead {
    th {
      padding: 20px 0 0 0;
      text-align: center;
      p {
        padding: 10px 0;
        border-radius: 8px 8px 0 0;
      }
    }
  }
  tbody {
    td {
      padding: 8px 0;
    }
    td:first-child {
      text-align: left;
      color: rgb(51, 51, 51);
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
    }
    td:nth-child(2) {
      color: rgb(51, 51, 51);
      font-size: 12px;
      text-align: center;
    }
    td:nth-child(n + 3) {
      font-weight: bold;
      text-align: center;
      padding-left: 16px;
      padding-right: 16px;
    }
    tr:nth-child(1) td:nth-child(n + 3) {
      color: rgb(50, 90, 180);
    }
    tr:nth-child(n + 2) td:nth-child(n + 3) {
      color: rgb(255, 70, 90);
    }
  }
}
</style>
