<template>
  <button>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="2" fill="#999999"></rect>
      <path
        d="M5 5L11 11"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M11 5L5 11"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  </button>
</template>

<script>
export default {
  name: 'CostDeleteButton',
}
</script>

<style lang="scss" scoped>
button {
  color: rgb(153, 153, 153);
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  background-color: rgb(255, 255, 255);
  border: 0;
  outline: none;
  cursor: pointer;
  padding: 2px 8px 0;
}
</style>
