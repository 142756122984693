import http from '../axios/http'

export default {
  async retrieve(params) {
    return await http.get('/v1/authority/retrieve', params)
  },

  async add(params) {
    return await http.post('/v1/authority/add', params)
  },

  async edit(params) {
    return await http.post('/v1/authority/edit', params)
  },

  async delete(params) {
    return await http.post('/v1/authority/delete', params)
  },
}
