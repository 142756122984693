<template>
  <div class="monthlyPlSwitch">
    <button
      :class="['switchLeft', { '--active': isRate }]"
      @click="isRate = true"
    >
      %
    </button>
    <button
      :class="['switchRight', { '--active': !isRate }]"
      @click="isRate = false"
    >
      {{ $t('monthlyPl.value') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'MonthlyPlSwitch',
  data() {
    return {
      isRate: Boolean,
    }
  },
  watch: {
    isRate() {
      this.$store.dispatch('monthlyPl/setIsRate', { isRate: this.isRate })
    },
  },
}
</script>

<style lang="scss" scoped>
.monthlyPlSwitch {
  padding: 8px 16px 0;
  button {
    border: none;
    cursor: pointer;
    outline: none;
    appearance: none;
    font-size: 14px;
    font-weight: bold;
    width: 50%;
    padding: 6px 0;
    background-color: $color-light-gray;
    color: $color-gray;
  }

  .--active {
    background-color: $color-theme;
    color: $color-white;
  }
}

.switchLeft {
  border-radius: 4px 0 0 4px;
}

.switchRight {
  border-radius: 0 4px 4px 0;
}
</style>
