<template>
  <div class="yearController">
    <div class="yearController__select">
      <button
        v-if="currentYear !== 1"
        class="yearController__button"
        @click="decrement"
      >
        <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
        </svg>
      </button>
    </div>
    <div class="yearController__year">
      <p class="year__current">{{ term.year }}</p>
      <p v-if="isMenuOpened" class="year__term">{{ term.duration }}</p>
    </div>
    <div class="yearController__select">
      <button
        v-if="currentYear !== 6"
        class="yearController__button"
        @click="increment"
      >
        <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { addMonths, addYears, format } from 'date-fns'

export default {
  name: 'YearController',
  computed: {
    isMenuOpened() {
      return this.$store.getters['currentView/isMenuOpened']
    },
    currentYear() {
      return this.$store.getters['currentView/year']
    },
    currentBusinessPlan() {
      return this.$store.getters['me/businessPlan']
    },
    currentYm() {
      const startYm = new Date(
        this.currentBusinessPlan.start_year,
        this.currentBusinessPlan.start_month - 1
      )
      return addYears(startYm, this.currentYear - 1)
    },
    term() {
      const endYm = addMonths(this.currentYm, 11)
      const year = `Year${this.currentYear}`
      const duration = `${format(this.currentYm, 'yyyy/MM')} - ${format(
        endYm,
        'yyyy/MM'
      )}`
      return { year: year, duration: duration }
    },
  },
  methods: {
    increment() {
      if (this.currentYear >= 6) return
      this.$store.dispatch('currentView/incrementYear')
    },
    decrement() {
      if (this.currentYear <= 1) return
      this.$store.dispatch('currentView/decrementYear')
    },
  },
}
</script>

<style lang="scss" scoped>
.yearController {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $color-white;
  background-color: $color-theme;
  border-radius: 8px;
  padding: 2px 4px;
}

.yearController__select {
  display: flex;
  align-items: center;
  width: 25px;
}

.yearController__button {
  width: 24px;
  height: 24px;
  padding: 0;
  background-color: transparent;

  svg path {
    fill: $color-white;
  }

  &:hover {
    border-radius: 8px;
    background-color: $color-theme-light;
  }
}

.yearController__year {
  text-align: center;

  .year__current {
    font-size: 1.4rem;
    font-weight: bold;
  }

  .year__term {
    font-size: 1.2rem;
  }
}
</style>
