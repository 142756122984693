<template>
  <business-plan-accessible-layout>
    <div class="pl__body">
      <pa-tab
        :canAdded="!isViewOnly"
        :is-comparison="isComparison"
        :is-editing-color="isEditingColor"
        :is-posting-color="isPostingColor"
        :has-color-picker="true"
        @fetch="getPl"
        @editColor="editColor"
        @postColor="postColor"
      />
      <template v-if="isComparison">
        <section class="pl__body-panel">
          <pl-comparison-charts
            :businessPlan="businessPlan"
            :currency="currency"
            :annualDate="annualDate"
            :colors="currentColors"
          />
          <border />
          <pl-comparison-detail
            :businessPlan="businessPlan"
            :currency="currency"
            :colors="currentColors"
          />
        </section>
      </template>
      <template v-else>
        <pl-plan
          :planCase="currentCase"
          :case-index="currentCaseIndex"
          :currency="currency"
          :annualDate="annualDate"
          :colors="currentColors"
        />
        <section class="pl__body-panel">
          <pl-plan-detail
            :planCase="currentCase"
            :is-view-only="isViewOnly"
            :currency="currency"
            :annualDate="annualDate"
            @change:pl="editCase"
            @change:model="editModel"
            @click:downloadCsv="downloadCsv"
          />
        </section>
      </template>
    </div>
  </business-plan-accessible-layout>
</template>

<script>
import { format, addMonths, addYears } from 'date-fns'

import BusinessPlanAccessibleLayout from '../../components/Common/BusinessPlanAccessibleLayout'
import businessPlans from '@/client/businessPlans'
import cases from '@/client/cases'
import model from '@/client/model'
import colors from '@/client/colors'
import PaTab from '../../components/PaTab'
import Border from '../../components/border'
import PlPlan from '../../components/PL/PlPlan'
import PlPlanDetail from '../../components/PL/PlPlanDetail'
import auth from '../../client/auth'
import PlComparisonCharts from '../../components/PL/PlComparisonCharts'
import PlComparisonDetail from '../../components/PL/PlComparisonDetail'
import file from '../../util/file'

export default {
  name: 'Pl',
  components: {
    BusinessPlanAccessibleLayout,
    PlComparisonDetail,
    PlComparisonCharts,
    PlPlanDetail,
    PlPlan,
    Border,
    PaTab,
  },
  props: {
    businessPlans: Array,
    roles: Object,
    currentBusinessPlanId: String,
    currency: String,
  },
  data() {
    return {
      isComparison: false,
      businessPlan: [
        {
          case_id: '',
          case_name: '',
          model: {
            model_name: '',
            model_rate: [],
          },
          pl: [
            {
              arr: 0,
              cogs: 0,
              cogs_rate: 0,
              ga: 0,
              ga_rate: 0,
              gross_profit: 0,
              gross_profit_rate: 0,
              operating_cost: 0,
              operating_cost_rate: 0,
              operating_pl: -0,
              operating_pl_rate: 0,
              rd: 0,
              rd_rate: 0,
              sales: 0,
              sm: 0,
              sm_rate: 0,
              year: 0,
            },
          ],
        },
      ],
      isEditingColor: false,
      isPostingColor: false,
    }
  },
  async mounted() {
    await this.getPl()
    await this.$store.dispatch('currentView/disableLoading')
  },
  computed: {
    isViewOnly() {
      return this.roles.pl === 'can_view'
    },
    currentCase() {
      return this.businessPlan.find((x) => x.case_id === this.currentCaseId)
    },
    currentCaseIndex() {
      return this.businessPlan.findIndex(
        (x) => x.case_id === this.currentCaseId
      )
    },
    currentBusinessPlan() {
      return this.businessPlans.find(
        (x) => x.business_plan_id === this.currentBusinessPlanId
      )
    },
    currentCaseId() {
      return this.$store.getters['currentView/caseId']
    },
    annualDate() {
      const dateArray = []
      const businessStartDate = new Date(
        this.currentBusinessPlan.start_year,
        this.currentBusinessPlan.start_month - 1
      )
      const fiscalYearEndingMonthDate = addMonths(businessStartDate, 11)
      const years = 6
      for (let i = 0; i < years; i++) {
        dateArray.push(
          format(addYears(fiscalYearEndingMonthDate, i), 'yyyy/MM')
        )
      }
      return dateArray
    },
    currentColors() {
      return this.isEditingColor
        ? this.$store.getters['color/editingColors']
        : this.$store.getters['color/colors']
    },
  },
  methods: {
    async getPl() {
      const result = await businessPlans.getPl({
        business_plan_id: this.currentBusinessPlanId,
      })
      this.businessPlan = result.data.values
    },
    async editCase(args) {
      try {
        await cases.edit({
          business_plan_id: this.currentBusinessPlanId,
          case_id: this.currentCaseId,
          year: args.year,
          key: args.key,
          value: args.value,
        })
        await this.$store.dispatch('me/getMe')
        await this.$store.dispatch(
          'me/setBusinessPlan',
          this.currentBusinessPlanId
        )
        await this.getPl()
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
    async editModel(args) {
      const params = {
        business_plan_id: this.currentBusinessPlanId,
        case_id: this.currentCaseId,
        model: args.model,
      }
      try {
        await model.edit(params)
        await auth.getMe()
        await this.getPl()
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
    async downloadCsv() {
      try {
        const result = await businessPlans.downloadPlCsv({
          business_plan_id: this.currentBusinessPlanId,
          case_id: this.currentCaseId,
        })
        await file.download(
          result,
          this.currentBusinessPlan.business_plan_name,
          this.currentCase.case_name,
          'PL'
        )
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
    editColor() {
      if (!this.isEditingColor) {
        this.$store.dispatch('color/setEditColors')
        this.isEditingColor = true
      } else {
        this.isEditingColor = false
      }
    },
    async postColor() {
      this.isPostingColor = true
      const params = {
        business_plan_id: this.currentBusinessPlanId,
        colors: this.$store.getters['color/postColors'],
      }
      try {
        const result = await colors.create(params)
        if (result.status === 201) {
          await this.$store.dispatch('color/getColors')
          this.isEditingColor = false
          this.isPostingColor = false
          this.flashMessage.show({
            message: this.$t('pl.saveColorSuccess'),
            blockClass: 'flashMessage__success',
            time: 5000,
          })
        }
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pl__body {
  @include mainContentWidthPadding;
}
.pl__body-panel {
  margin-bottom: 40px;
  padding: 24px;
  background: $color-white;
}
</style>
