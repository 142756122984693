import Vue from 'vue'
import http from '../axios/http'
import GAuth from 'vue-google-oauth2'
import userStore from '../store/user'
import store from './../store'

const gAuthOptions = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'profile email',
  prompt: 'consent',
  fetch_basic_profile: false,
  plugin_name: 'projection-ai',
}
const app = Vue.use(GAuth, gAuthOptions)

export default {
  async init() {
    await this.getMe()
  },

  async getMe() {
    try {
      await store.dispatch('me/getMe')
      // vuex移行後消す
      userStore.setUser(store.getters['me/user'])
      userStore.setBusinessPlans(store.getters['me/businessPlans'])
      userStore.setIsAuthenticated(store.getters['me/isAuthenticated'])
    } catch (e) {
      localStorage.removeItem('app_token')
    }
  },

  async googleSignIn() {
    return await app.prototype.$gAuth.signIn()
  },

  async auth() {
    const googleAuthResult = await this.googleSignIn()
    const accessToken = googleAuthResult.getAuthResponse().access_token
    const result = await http.post('/v1/user/google-auth', {
      access_token: accessToken,
    })
    const token = result.data.app_token
    if (token) {
      localStorage.setItem('app_token', token)
    }
    await this.getMe()
    return result
  },

  async authForEmail(email, password) {
    const result = await http.post('/v1/users/email-signin', {
      email: email,
      password: password,
    })
    const token = result.data.app_token
    if (token) {
      localStorage.setItem('app_token', token)
    }
    await this.getMe()
    return result
  },

  async signOut() {
    await app.prototype.$gAuth.signOut()
    store.state.me.user = null
    store.state.me.isAuthenticated = false
    // Vuex移行後消す
    userStore.setUser(null)
    userStore.setIsAuthenticated(false)
    // ----end
    localStorage.removeItem('app_token')
  },

  async checkAuthenticationType(email) {
    return await http.post('/v1/users/email-signup-auth', {
      email: email,
    })
  },

  async applyResetPassword(email) {
    return await http.post('/v1/users/password/apply', {
      email: email,
    })
  },

  async checkToken(token) {
    return await http.post('/v1/users/password/verify', {
      token: token,
    })
  },

  async resetPassword(token, password) {
    return await http.post('/v1/users/password/set', {
      token: token,
      password: password,
    })
  },
}
