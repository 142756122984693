<template>
  <button @click="isLoading = true" :disabled="isLoading">
    <slot />
    <div class="loader" v-if="isLoading"></div>
  </button>
</template>

<script>
export default {
  name: 'ConnectGoogleSpreadSheetButton',
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    hideLoading() {
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.loader,
.loader:after {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.loader {
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.5em solid rgba(255, 255, 255, 0.2);
  border-right: 1.5em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.5em solid rgba(255, 255, 255, 0.2);
  border-left: 1.5em solid $color-gray;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
