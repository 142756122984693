<template>
  <div class="plan">
    <div class="plan__header">
      <button class="plan__header-switch" @click="isMonthly = !isMonthly">
        <span>{{ $t('lp.price.monthlyPrice') }}</span>
        <switch-off v-if="isMonthly" />
        <switch-on v-else />
        <span>{{ $t('lp.price.annualPrice') }}（15% OFF）</span>
      </button>
    </div>
    <div class="plan__plans">
      <template v-if="!isTablet">
        <div
          v-for="(plan, key) in plans"
          class="plan__card"
          :class="{ active: activePlan === plan.name }"
          :key="key"
        >
          <p class="plan__card-name">{{ plan.name }}</p>
          <check-circle
            v-if="activePlan === plan.name"
            class="plan__card-check"
          />
          <p class="plan__card-description">{{ plan.description }}</p>
          <p class="plan__card-price">
            {{ $t('price.currency') }}
            <span>{{ isMonthly ? plan.price.monthly : plan.price.yearly }}</span
            >/{{ $t('lp.price.month') }}（{{ $t('lp.price.includingTax') }}）
          </p>
          <div></div>
          <ul>
            <template v-for="(content, idx) in plan.contents">
              <li :key="idx">{{ content }}</li>
            </template>
          </ul>
        </div>
      </template>
      <template v-if="isTablet">
        <swiper class="customer__swiper" :options="swiperOptions">
          <template v-for="(plan, key) in plans">
            <swiper-slide
              :key="key"
              :class="{ active: activePlan === plan.name }"
            >
              <div class="plan__card">
                <p class="plan__card-name">{{ plan.name }}</p>
                <check-circle
                  v-if="activePlan === plan.name"
                  class="plan__card-check"
                />
                <p class="plan__card-description">{{ plan.description }}</p>
                <p class="plan__card-price">
                  {{ $t('price.currency')
                  }}<span>{{
                    isMonthly ? plan.price.monthly : plan.price.yearly
                  }}</span
                  >/{{ $t('lp.price.month') }}（{{
                    $t('lp.price.includingTax')
                  }}）
                </p>
                <div></div>
                <ul>
                  <template v-for="(content, idx) in plan.contents">
                    <li :key="idx">{{ content }}</li>
                  </template>
                </ul>
              </div>
            </swiper-slide>
          </template>
        </swiper>
        <button id="swiper-prev" class="customer__swiper-prev">
          <ArrowLeft />
        </button>
        <button id="swiper-next" class="customer__swiper-next">
          <ArrowRight />
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import SwitchOn from '@/assets/svg/SwitchOn.svg'
import SwitchOff from '@/assets/svg/SwitchOff.svg'
import ArrowLeft from '@/assets/svg/ArrowLeft.svg'
import ArrowRight from '@/assets/svg/ArrowRight.svg'
import CheckCircle from '@/assets/svg/CheckCircle.svg'
import device from '../store/device'
export default {
  name: 'Plan',
  components: {
    SwitchOn,
    SwitchOff,
    ArrowLeft,
    ArrowRight,
    CheckCircle,
  },
  props: {
    activePlan: String,
    billingPlan: Object,
  },
  data() {
    return {
      isMonthly: true,
      plans: [
        {
          name: 'Free',
          description: this.$t('lp.price.free.description'),
          price: {
            monthly: '0',
            yearly: '0',
          },
          contents: [this.$t('lp.price.free.list1')],
        },
        {
          name: 'Basic',
          description: this.$t('lp.price.basic.description'),
          price: {
            monthly: this.$t('price.monthly.basic'),
            yearly: this.$t('price.yearly.basic'),
          },
          contents: [
            this.$t('lp.price.basic.list1'),
            this.$t('lp.price.basic.list2'),
            this.$t('lp.price.basic.list3'),
            this.$t('lp.price.basic.list4'),
            this.$t('lp.price.basic.list5'),
            this.$t('lp.price.basic.list6'),
            this.$t('lp.price.basic.list7'),
            this.$t('lp.price.basic.list8'),
            this.$t('lp.price.basic.list9'),
            this.$t('lp.price.basic.list10'),
            this.$t('lp.price.basic.list11'),
          ],
        },
        {
          name: 'Team',
          description: this.$t('lp.price.team.description'),
          price: {
            monthly: this.$t('price.monthly.team'),
            yearly: this.$t('price.yearly.team'),
          },
          contents: [
            this.$t('lp.price.team.list1'),
            this.$t('lp.price.team.list2'),
            this.$t('lp.price.team.list3'),
            this.$t('lp.price.team.list4'),
            this.$t('lp.price.team.list5'),
            this.$t('lp.price.team.list6'),
            this.$t('lp.price.team.list7'),
            this.$t('lp.price.team.list8'),
            this.$t('lp.price.team.list9'),
            this.$t('lp.price.team.list10'),
            this.$t('lp.price.team.list11'),
            this.$t('lp.price.team.list12'),
          ],
        },
      ],
      swiperOptions: {
        slidesPerView: 1,
        centeredSlides: true,
        navigation: {
          nextEl: '#swiper-next',
          prevEl: '#swiper-prev',
        },
      },
    }
  },
  computed: {
    isSP() {
      return device.isSP()
    },
    isTablet() {
      return device.isTablet()
    },
  },
}
</script>

<style lang="scss" scoped>
.plan {
  margin-bottom: 80px;
}
.plan__header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 40px 40px 36px;
}
.plan__header-switch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  user-select: none;
  background-color: $color-white;
  border: 0;
  outline: none;
  cursor: pointer;
  span {
    margin: 0 12px;
    font-weight: bold;
    font-size: 1.6rem;
    font-family: $fontFamily-en;
  }
}
.plan__plans {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 20px;
  margin: 0 24px 24px;
  position: relative;
  @include isLarge {
    margin: 0 0 24px;
  }
}
::v-deep .swiper-wrapper {
  align-items: stretch;
  .plan__card {
    height: 100%;
  }
}
.swiper-slide.active {
  order: -1;
  .plan__card {
    border: 2px solid rgb(80, 52, 121);
  }
}

.plan__card {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 32px;
  border: 2px solid rgb(229, 229, 229);
  border-radius: 8px;
  &-name {
    margin-bottom: 10px;
    color: $color-theme;
    font-weight: 700;
    font-size: 30px;
    font-family: Helvetica, sans-serif;
    line-height: 30px;
  }
  &-check {
    position: absolute;
    right: 24px;
    top: 32px;
  }
  &-description {
    margin-bottom: 24px;
    color: $color-dark-gray;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 24px;
  }
  &-price {
    color: $color-black;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    span {
      font-size: 2.8rem;
      font-weight: 700;
    }
  }
  &.active {
    position: relative;
    margin: -20px 0;
    border: 2px solid rgb(80, 52, 121);
    box-shadow: rgb(0 0 0 / 10%) 0 4px 8px;
  }
  div {
    height: 1px;
    margin: 16px auto;
    background: rgb(229, 229, 229);
  }
  ul {
    margin-bottom: 32px;
  }
  li {
    color: rgb(102, 102, 102);
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 24px;
    text-align: left;
  }
}
.customer__swiper-prev,
.customer__swiper-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  background: $color-white;
  border: 0;
  outline: none;
  width: 50px;
  height: 50px;
  ::v-deep svg {
    width: 100%;
    height: auto;
  }
  &.swiper-button-disabled {
    svg path {
      stroke: #cacaca;
    }
  }
}
.customer__swiper-prev {
  left: -25px;
}
.customer__swiper-next {
  right: -25px;
}
</style>
