<template>
  <div>
    <pa-header />
    <main class="error__contents">
      <h1 class="error__contents-title">404</h1>
      <p class="error__contents-message">Not Found</p>
      <not-found-icon />
    </main>
  </div>
</template>

<script>
import PaHeader from '@/components/PaHeader'
import NotFoundIcon from '@/assets/svg/404Icon.svg'

export default {
  name: '404',
  components: {
    PaHeader,
    NotFoundIcon,
  },
}
</script>

<style lang="scss" scoped>
.error__contents {
  padding-top: 280px;
  text-align: center;
  color: $color-gray;
  font-weight: 700;
  line-height: 64px;
  &-title {
    margin-bottom: 32px;
    font-size: 100px;
  }
  &-message {
    margin-bottom: 32px;
    font-size: 40px;
  }
}
</style>
