<template>
  <div class="bars">
    <span class="bars__bar bars__bar--0"></span>
    <span class="bars__bar bars__bar--1"></span>
    <span class="bars__bar bars__bar--2"></span>
    <span class="bars__bar bars__bar--3"></span>
    <span class="bars__bar bars__bar--4"></span>
    <span class="bars__bar bars__bar--5"></span>
    <span class="bars__bar bars__bar--alt bars__bar--0--alt"></span>
    <span class="bars__bar bars__bar--alt bars__bar--1--alt"></span>
    <span class="bars__bar bars__bar--alt bars__bar--2--alt"></span>
    <span class="bars__bar bars__bar--alt bars__bar--3--alt"></span>
    <span class="bars__bar bars__bar--alt bars__bar--4--alt"></span>
    <span class="bars__bar bars__bar--alt bars__bar--5--alt"></span>
  </div>
</template>

<script>
export default {
  name: 'Loading',
}
</script>

<style lang="scss" scoped>
$animation-length: 1.5s;
$loader-height: 150px;
$color: $color-theme;

.bars {
  position: relative;
  $loader-height: 100px;
  height: $loader-height;
  width: $loader-height * 1.7;

  &__bar {
    position: absolute;
    top: 40%;
    bottom: 40%;
    width: 7%;
    background-color: $color;
    animation: bars $animation-length infinite ease-out;

    &--alt {
      background-color: rgba($color, 0.4);
    }

    @for $i from 0 through 5 {
      &--#{$i} {
        left: $i * 18.6%;
        animation-delay: $i * 0.1s;

        &--alt {
          left: $i * 18.6%;
          animation-delay: $i * 0.1s + ($animation-length * 0.33);
        }
      }
    }
  }
}

@keyframes bars {
  0%,
  60% {
    top: 40%;
    bottom: 40%;
  }
  20% {
    top: 0;
    bottom: 40%;
  }
  40% {
    top: 40%;
    bottom: 0;
  }
}

@keyframes monitor {
  0% {
    left: -5%;
  }
  100% {
    left: 100%;
  }
}
</style>
