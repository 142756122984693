<template>
  <section class="panel">
    <h2>{{ $t('setting.members') }}</h2>
    <table class="panel__table">
      <colgroup>
        <col width="7%" />
        <col width="8%" />
        <col width="8%" />
        <col width="6.5%" />
      </colgroup>
      <thead>
        <tr>
          <th>{{ $t('setting.name') }}</th>
          <th>{{ $t('setting.email') }}</th>
          <th>{{ $t('setting.role') }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(role, key) in received" :key="key">
          <td>
            <circle-icon v-if="role.is_owner" />
            {{ role.name }}
          </td>
          <td>{{ role.email }}</td>
          <td>
            <div v-if="role.is_owner">{{ $t('setting.admin') }}</div>
            <div v-else>
              <role-edit-button @click.native="clickRole(true, role.email)" />
            </div>
          </td>
          <td>
            <div v-if="!role.is_owner" class="deleteButton">
              <cost-delete-button
                @click.native="clickDelete(role.name, role.email)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <h2>{{ $t('setting.invitations') }}</h2>
    <table class="panel__table">
      <colgroup>
        <col width="7%" />
        <col width="8%" />
        <col width="8%" />
        <col width="6.5%" />
      </colgroup>
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(role, key) in yet" :key="key">
          <td></td>
          <td>{{ role.email }}</td>
          <td></td>
          <td>
            <div class="deleteButton">
              <cost-delete-button
                @click.native="clickDelete(role.name, role.email)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <cost-add-button
      v-if="planName !== 'Free'"
      @click.native="clickRole(false)"
    />
    <role
      ref="roleModal"
      :plan-name="planName"
      :is-can-edit-addable="isCanEditAddable"
      @click:register="executeRole"
    />
    <delete-member ref="deleteMember" @click:delete="executeDelete" />
  </section>
</template>

<script>
import CircleIcon from '@/assets/svg/CircleIcon.svg'
import CostDeleteButton from '../Cost/CostDeleteButton'
import CostAddButton from '../Cost/CostAddButton'
import RoleEditButton from './RoleEditButton'
import DeleteMember from '../Modal/DeleteMember'
import Role from '../Modal/Role'

export default {
  name: 'Member',
  components: {
    Role,
    DeleteMember,
    RoleEditButton,
    CostAddButton,
    CostDeleteButton,
    CircleIcon,
  },
  props: {
    roles: Array,
    planName: String,
    isCanEditAddable: Boolean,
  },
  data() {
    return {
      role: {
        actuals: 'no_access',
        cogs: 'no_access',
        dashboard: 'no_access',
        email: '',
        ga: 'no_access',
        kpi: 'no_access',
        pl: 'no_access',
        rd: 'no_access',
        sales_detail: 'no_access',
        sm: 'no_access',
        monthly_pl: 'no_access',
      },
    }
  },
  computed: {
    received() {
      if (typeof this.roles === 'undefined') return []
      return this.roles.filter((x) => x.status === 'received')
    },
    yet() {
      if (typeof this.roles === 'undefined') return []
      return this.roles.filter((x) => x.status === 'yet')
    },
  },
  methods: {
    clickDelete(name, email) {
      this.$refs.deleteMember.showModal(name, email)
    },
    executeDelete(email) {
      this.$emit('click:delete', email)
    },
    clickRole(isEdit, email = '') {
      let role
      if (isEdit) {
        role = this.roles.find((x) => x.email === email)
      } else {
        role = this.role
      }
      this.$refs.roleModal.showModal(isEdit, role)
    },
    executeRole(args) {
      if (args.isEdit) {
        this.$emit('save:edit', args.role)
      } else {
        this.$emit('save:add', args.role)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.panel {
  h2 {
    font-size: 2rem;
    line-height: 30px;
    margin-bottom: 20px;
  }
}
.panel__table {
  width: 100%;
  margin-bottom: 40px;
  font-size: 12px;
  border-collapse: collapse;
  thead {
    tr {
      height: 40px;
      margin: 0 12px;
      border-style: solid;
      border-color: rgb(242, 242, 242);
      border-image: initial;
      border-width: 0 0 1px;
    }
    th:first-child {
      padding-left: 16px;
    }
  }
  tbody {
    tr {
      height: 40px;
      margin: 0 12px;
      border-style: solid;
      border-color: rgb(242, 242, 242);
      border-image: initial;
      border-width: 0 0 1px;
    }
    td {
      svg {
        vertical-align: bottom;
      }
    }
    td:first-child {
      padding-left: 16px;
    }
    td:last-child {
      text-align: end;
    }
  }
}
.deleteButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
