<template>
  <tbody>
    <tr class="toggleTitleRow">
      <td class="toggleTitleRow__title">
        <button @click="show = !show">
          <arrow-bottom-icon :class="{ scale: show }" />
        </button>
        {{ title }}
      </td>
    </tr>
    <template v-if="show">
      <slot />
    </template>
  </tbody>
</template>

<script>
import ArrowBottomIcon from '@/assets/svg/ArrowBottom.svg'
export default {
  name: 'ToggleTableBody',
  props: {
    title: String,
  },
  components: {
    ArrowBottomIcon,
  },
  data() {
    return {
      show: true,
    }
  },
}
</script>

<style lang="scss" scoped>
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

.scale {
  transform: scale(1, -1);
}

.toggleTitleRow {
  text-align: left;
  font-weight: bold;
  border-bottom: 1px solid #f2f2f2;
  td {
    padding: 6px 8px;
    button {
      margin-right: 4px;
    }
  }
}

.toggleTitleRow__title {
  position: sticky;
  z-index: 1;
  left: 0;
  background-color: $color-white;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -50px;
    width: 50px;
    height: 100%;
    border-left: 1px solid #daeeff;
    background: linear-gradient(
      90deg,
      rgba(20, 32, 38, 0.07) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}
</style>
