<template>
  <modal
    name="role"
    :clickToClose="false"
    height="auto"
    width="50%"
    :scrollable="true"
  >
    <div class="panel">
      <h2>
        {{
          isEdit
            ? this.$t('setting.roles.title')
            : this.$t('setting.roles.addMember')
        }}
      </h2>
      <div class="panel__contents">
        <div class="email">
          <label>{{ $t('setting.email') }}</label>
          <span v-if="isEdit">{{ role.email }}</span>
          <input
            v-else
            key="email"
            name="email"
            type="email"
            v-model="role.email"
            v-validate="'required|email'"
            data-vv-as="email"
            required
          />
          <p class="errorMessage">{{ errors.first('email') }}</p>
        </div>
      </div>
      <border />
      <div class="panel__contents">
        <table class="roles__table">
          <colgroup>
            <col width="40%" />
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th>[ {{ $t('setting.roles.noAccess') }} ]</th>
              <th>[ {{ $t('setting.roles.canView') }} ]</th>
              <th>[ {{ $t('setting.roles.canEdit') }} ]</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <label>{{ $t('setting.roles.pageTitles.pl') }}</label>
              </td>
              <td>
                <input
                  id="pl_no_access"
                  name="pl"
                  type="radio"
                  value="no_access"
                  v-model="role.pl"
                />
                <label for="pl_no_access" class="radio" />
              </td>
              <td>
                <input
                  id="pl_can_view"
                  name="pl"
                  type="radio"
                  value="can_view"
                  v-model="role.pl"
                />
                <label for="pl_can_view" class="radio" />
              </td>
              <td>
                <input
                  id="pl_can_edit"
                  name="pl"
                  type="radio"
                  value="can_edit"
                  v-model="role.pl"
                  :disabled="disabledCanEdit"
                />
                <label for="pl_can_edit" class="radio" />
              </td>
            </tr>
            <tr>
              <td>
                <label>{{ $t('setting.roles.pageTitles.monthlyPl') }}</label>
              </td>
              <td>
                <input
                  id="monthlyPl_no_access"
                  name="monthlyPl"
                  type="radio"
                  value="no_access"
                  v-model="role.monthly_pl"
                />
                <label for="monthlyPl_no_access" class="radio" />
              </td>
              <td>
                <input
                  id="monthlyPl_can_view"
                  name="monthlyPl"
                  type="radio"
                  value="can_view"
                  v-model="role.monthly_pl"
                />
                <label for="monthlyPl_can_view" class="radio" />
              </td>
              <td>
                <input
                  id="monthlyPl_can_edit"
                  name="monthlyPl"
                  type="radio"
                  value="can_edit"
                  v-model="role.monthly_pl"
                  :disabled="disabledCanEdit"
                />
                <label for="monthlyPl_can_edit" class="radio" />
              </td>
            </tr>
            <tr>
              <td>
                <label>{{ $t('setting.roles.pageTitles.kpi') }}</label>
              </td>
              <td>
                <input
                  id="kpi_no_access"
                  name="kpi"
                  type="radio"
                  value="no_access"
                  v-model="role.kpi"
                />
                <label for="kpi_no_access" class="radio" />
              </td>
              <td>
                <input
                  id="kpi_can_view"
                  name="kpi"
                  type="radio"
                  value="can_view"
                  v-model="role.kpi"
                />
                <label for="kpi_can_view" class="radio" />
              </td>
              <td>
                <input
                  id="kpi_can_edit"
                  name="kpi"
                  type="radio"
                  value="can_edit"
                  v-model="role.kpi"
                  :disabled="disabledCanEdit"
                />
                <label for="kpi_can_edit" class="radio" />
              </td>
            </tr>
            <tr>
              <td>
                <label>{{ $t('setting.roles.pageTitles.revenue') }}</label>
              </td>
              <td>
                <input
                  id="sales_no_access"
                  name="sales"
                  type="radio"
                  value="no_access"
                  v-model="role.sales_detail"
                />
                <label for="sales_no_access" class="radio" />
              </td>
              <td>
                <input
                  id="sales_can_view"
                  name="sales"
                  type="radio"
                  value="can_view"
                  v-model="role.sales_detail"
                />
                <label for="sales_can_view" class="radio" />
              </td>
              <td>
                <input
                  id="sales_can_edit"
                  name="sales"
                  type="radio"
                  value="can_edit"
                  v-model="role.sales_detail"
                  :disabled="disabledCanEdit"
                />
                <label for="sales_can_edit" class="radio" />
              </td>
            </tr>
            <tr>
              <td>
                <label>{{ $t('setting.roles.pageTitles.cost') }}</label>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td class="subRole">COGS</td>
              <td>
                <input
                  id="cogs_no_access"
                  name="cogs"
                  type="radio"
                  value="no_access"
                  v-model="role.cogs"
                />
                <label for="cogs_no_access" class="radio" />
              </td>
              <td>
                <input
                  id="cogs_can_view"
                  name="cogs"
                  type="radio"
                  value="can_view"
                  v-model="role.cogs"
                />
                <label for="cogs_can_view" class="radio" />
              </td>
              <td>
                <input
                  id="cogs_can_edit"
                  name="cogs"
                  type="radio"
                  value="can_edit"
                  v-model="role.cogs"
                  :disabled="disabledCanEdit"
                />
                <label for="cogs_can_edit" class="radio" />
              </td>
            </tr>
            <tr>
              <td class="subRole">S＆M</td>
              <td>
                <input
                  id="sm_no_access"
                  name="sm"
                  type="radio"
                  value="no_access"
                  v-model="role.sm"
                />
                <label for="sm_no_access" class="radio" />
              </td>
              <td>
                <input
                  id="sm_can_view"
                  name="sm"
                  type="radio"
                  value="can_view"
                  v-model="role.sm"
                />
                <label for="sm_can_view" class="radio" />
              </td>
              <td>
                <input
                  id="sm_can_edit"
                  name="sm"
                  type="radio"
                  value="can_edit"
                  v-model="role.sm"
                  :disabled="disabledCanEdit"
                />
                <label for="sm_can_edit" class="radio" />
              </td>
            </tr>
            <tr>
              <td class="subRole">R＆D</td>
              <td>
                <input
                  id="rd_no_access"
                  name="rd"
                  type="radio"
                  value="no_access"
                  v-model="role.rd"
                />
                <label for="rd_no_access" class="radio" />
              </td>
              <td>
                <input
                  id="rd_can_view"
                  name="rd"
                  type="radio"
                  value="can_view"
                  v-model="role.rd"
                />
                <label for="rd_can_view" class="radio" />
              </td>
              <td>
                <input
                  id="rd_can_edit"
                  name="rd"
                  type="radio"
                  value="can_edit"
                  v-model="role.rd"
                  :disabled="disabledCanEdit"
                />
                <label for="rd_can_edit" class="radio" />
              </td>
            </tr>
            <tr>
              <td class="subRole">G＆A</td>
              <td>
                <input
                  id="ga_no_access"
                  name="ga"
                  type="radio"
                  value="no_access"
                  v-model="role.ga"
                />
                <label for="ga_no_access" class="radio" />
              </td>
              <td>
                <input
                  id="ga_can_view"
                  name="ga"
                  type="radio"
                  value="can_view"
                  v-model="role.ga"
                />
                <label for="ga_can_view" class="radio" />
              </td>
              <td>
                <input
                  id="ga_can_edit"
                  name="ga"
                  type="radio"
                  value="can_edit"
                  v-model="role.ga"
                  :disabled="disabledCanEdit"
                />
                <label for="ga_can_edit" class="radio" />
              </td>
            </tr>
            <tr>
              <td class="tooltip">
                <label>{{ $t('setting.roles.pageTitles.dashboard') }}</label>
                <pa-tooltip>
                  {{ $t('setting.roles.pageTitles.dashboardTooltip') }}
                </pa-tooltip>
              </td>
              <td>
                <input
                  id="dashboard_no_access"
                  name="dashboard"
                  type="radio"
                  value="no_access"
                  v-model="role.dashboard"
                />
                <label for="dashboard_no_access" class="radio" />
              </td>
              <td>
                <input
                  id="dashboard_can_view"
                  name="dashboard"
                  type="radio"
                  value="can_view"
                  v-model="role.dashboard"
                />
                <label for="dashboard_can_view" class="radio" />
              </td>
              <td>
                <input
                  id="dashboard_can_edit"
                  name="dashboard"
                  type="radio"
                  value="can_edit"
                  v-model="role.dashboard"
                  :disabled="disabledCanEdit"
                />
                <label for="dashboard_can_edit" class="radio" />
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="planName === 'Basic'" class="messageBox">
          {{ $t('setting.roles.basicPlanMessage') }}
        </div>
        <div v-else class="messageBox">
          {{ $t('setting.roles.teamPlanMessage') }}
        </div>
      </div>
      <div class="panel__buttons">
        <button class="cancel" @click="hideModal">
          {{ $t('setting.card.cancel') }}
        </button>
        <button class="register" @click="register" :disabled="disableRegister">
          {{ isEdit ? this.$t('setting.save') : this.$t('setting.card.add') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import Border from '../border'
import VeeValidate from 'vee-validate'
import i18n from '../../locales/i18n'
import PaTooltip from '../PaTooltip'

const dictionaries = {
  custom: {
    email: {
      required: i18n.t('auth.validateRequired', {
        field: i18n.t('auth.email'),
      }),
      email: i18n.t('auth.validateEmail'),
    },
  },
}
VeeValidate.Validator.localize('ja', dictionaries)
VeeValidate.Validator.localize('en', dictionaries)

export default {
  name: 'Role',
  components: { PaTooltip, Border },
  props: {
    planName: String,
    isCanEditAddable: Boolean,
  },
  data() {
    return {
      isEdit: false,
      role: {},
    }
  },
  computed: {
    disabledCanEdit() {
      return this.planName !== 'Team'
    },
    disableRegister() {
      return (
        !this.isCanEditAddable && Object.values(this.role).includes('can_edit')
      )
    },
  },
  methods: {
    showModal(isEdit, role) {
      this.isEdit = isEdit
      this.role = Object.assign({}, role)
      this.$modal.show('role')
    },
    hideModal() {
      this.role = {}
      this.$modal.hide('role')
    },
    async register() {
      const valid = await this.$validator.validateAll({
        email: this.role.email,
      })
      if (!valid) return
      this.$emit('click:register', { isEdit: this.isEdit, role: this.role })
      this.hideModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.panel {
  padding: 30px;
  h2 {
    text-align: left;
    font-size: 1.6rem;
  }
  &__contents {
    margin: 20px auto 25px;
    padding: 0 30px;
    h3,
    label {
      text-align: left;
      font-size: 1.4rem;
      font-weight: bold;
      margin-right: 16px;
    }
    .email {
      input {
        box-sizing: border-box;
        width: 60%;
        padding: 8px 16px;
        background: #f2f2f2;
        border: none;
        border-radius: 8px;
      }
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
    button {
      background-color: $color-white;
      width: 100px;
      padding: 10px 0;
      font-size: 1rem;
      font-weight: bold;
      border-radius: 16px;
      cursor: pointer;
    }
    .cancel {
      color: $color-theme;
      border: 1px solid $color-theme;
    }
    .register {
      color: $color-white;
      background-color: $color-theme;
      border: none;
    }
    .register:disabled {
      background-color: rgb(202, 202, 202);
      cursor: default;
      pointer-events: none;
    }
  }
}
.roles__table {
  text-align: center;
  width: 80%;
  td:first-child {
    text-align: left;
  }
  .tooltip {
    display: flex;
    label {
      margin-right: 0;
    }
  }
  .subRole {
    text-indent: 1em;
  }
  tr {
    height: 32px;
  }
  input[type='radio'] {
    display: none;
  }
  .radio {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 5px 6px;
    position: relative;
    width: auto;
  }
  .radio::before {
    background: #fff;
    border: 1px solid $color-theme;
    border-radius: 50%;
    content: '';
    display: block;
    height: 16px;
    left: 5px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    width: 16px;
  }
  .radio::after {
    background: $color-theme;
    border-radius: 50%;
    content: '';
    display: block;
    height: 10px;
    left: 9px;
    margin-top: -4px;
    opacity: 0;
    position: absolute;
    top: 50%;
    width: 10px;
  }
  input[type='radio']:checked + .radio::after {
    opacity: 1;
  }
  input[type='radio'] {
    position: relative;
    top: 2px;
  }
  input[type='radio']:disabled + .radio::before {
    border: 1px solid $color-gray;
    cursor: not-allowed;
  }
  input[type='radio']:disabled + .radio::after {
    cursor: not-allowed;
  }
}
.messageBox {
  margin-top: 24px;
  padding: 16px 24px;
  background-color: $color-light-gray;
  color: $color-dark-gray;
}
.errorMessage {
  color: $color-red;
  margin-top: 4px;
}
</style>
