<template>
  <div class="dummyPanel">
    <div class="dummyPanel__description">
      <h1>
        {{ page.title }}
      </h1>
      <p>
        {{ page.text }}
      </p>
      <div>
        <button class="dummyPanel__upgrade" @click="upgrade">
          {{ $t('upgrade') }}
        </button>
        <button class="dummyPanel__demo" @click="demo">
          {{ $t('demoRequest') }}
        </button>
      </div>
    </div>
    <img
      class="dummyPanel__image"
      :src="`/static/img/${page.image}.png`"
      alt="image"
    />
  </div>
</template>

<script>
export default {
  name: 'DummyPanel',
  props: {
    pageName: String,
  },
  data() {
    return {
      pages: [
        {
          page: 'monthlypl',
          title: this.$t('dummy.monthlyPl.title'),
          text: this.$t('dummy.monthlyPl.text'),
          image: 'dummy-monthly_pl',
        },
        {
          page: 'kpi',
          title: this.$t('dummy.kpi.title'),
          text: this.$t('dummy.kpi.text'),
          image: 'dummy-kpi',
        },
        {
          page: 'revenue',
          title: this.$t('dummy.revenue.title'),
          text: this.$t('dummy.revenue.text'),
          image: 'dummy-revenue',
        },
        {
          page: 'expenses',
          title: this.$t('dummy.cost.title'),
          text: this.$t('dummy.cost.text'),
          image: 'dummy-expenses',
        },
        {
          page: 'dashboard',
          title: this.$t('dummy.dashboard.title'),
          text: this.$t('dummy.dashboard.text'),
          image: 'dummy-dashboard',
        },
      ],
    }
  },
  computed: {
    page() {
      return this.pages.filter((page) => page.page === this.pageName)[0]
    },
  },
  methods: {
    upgrade() {
      this.$router.push({ name: 'Setting' })
    },
    demo() {
      window.open(this.$t('meetingUrl'))
    },
  },
}
</script>

<style lang="scss" scoped>
.dummyPanel {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 95px);
  margin: auto 120px;
}
.dummyPanel__description {
  width: 50%;
  text-align: left;
  h1 {
    margin-bottom: 24px;
    color: $color-theme;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 40px;
  }
  p {
    margin-bottom: 40px;
    color: $color-dark-gray;
    font-size: 1.6rem;
    line-height: 32px;
  }
  button {
    align-items: center;
    width: 140px;
    height: 40px;
    padding: 10px 16px;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 14px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 2px solid rgb(80, 52, 121);
  }
}
.dummyPanel__upgrade {
  margin-right: 24px;
  background-color: $color-theme;
  color: $color-white;
}
.dummyPanel__demo {
  background-color: $color-white;
}
.dummyPanel__image {
  width: 50%;
}
</style>
