import http from '../axios/http'

export default {
  async create(params) {
    return await http.post('/v1/business-plan/create', params, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  },

  async edit(params) {
    return await http.post('/v1/business-plan/edit', params, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  },

  async delete(params) {
    return await http.post('/v1/business-plan/delete', params)
  },

  async editThemeColor(params) {
    return await http.post('/v1/business-plan/theme-color', params)
  },

  async getPl(params) {
    return await http.get('/v1/pl/retrieve', params)
  },

  async downloadPlCsv(params) {
    return await http.get('/v1/pl/csv', params, {
      responseType: 'blob',
    })
  },

  async getKpi(params) {
    return await http.get('/v1/kpi/retrieve', params)
  },

  async downloadKpiCsv(params) {
    return await http.get('/v1/kpi/csv', params, {
      responseType: 'blob',
    })
  },

  async getRevenue(params) {
    return await http.get('/v1/revenue/retrieve', params)
  },

  async downloadRevenueCsv(params) {
    return await http.get('/v1/revenue/csv', params, {
      responseType: 'blob',
    })
  },

  async getCost(params) {
    return await http.get('/v1/cost/retrieve', params)
  },

  async getActuals(params) {
    return await http.get('/v1/actuals/retrieve', params)
  },

  async getDashboard(params) {
    return await http.get('/v1/dashboard/retrieve', params)
  },
}
