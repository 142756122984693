<template>
  <div class="progress">
    <p>{{ $t('cost.budgetaryAverages') }}</p>
    <progress :max="max" :value="value"></progress>
    <p>
      <span>{{ averages | numberFormat }}</span
      >%
    </p>
  </div>
</template>

<script>
export default {
  name: 'CostProgress',
  props: {
    max: Number,
    value: Number,
    averages: Number,
  },
}
</script>

<style lang="scss" scoped>
.progress {
  display: flex;
  align-items: center;
  margin-left: auto;
  p {
    margin-right: 24px;
  }
  progress {
    width: 227px;
    height: 4px;
    background-color: $color-theme;
    border-radius: 2px;
    appearance: none;
    margin-right: 13px;
    &::-webkit-progress-value {
      background-color: $color-theme;
    }
  }
  span {
    color: $color-theme;
    font-size: 1.8rem;
  }
}
</style>
