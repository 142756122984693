<template>
  <div class="dateContainer">
    <input
      type="text"
      maxlength="4"
      class="dateContainer-year"
      v-model="inputYear"
      @focus="$event.target.select()"
    />
    <p>/</p>
    <input
      type="text"
      maxlength="2"
      class="dateContainer-month"
      v-model="month"
      @focus="$event.target.select()"
    />
  </div>
</template>

<script>
export default {
  name: 'CostYm',
  props: {
    year: Number,
    month: Number,
  },
  computed: {
    inputYear: {
      get() {
        return this.year
      },
      set(value) {
        this.$emit('update:year', value)
      },
    },
  },
  watch: {
    month(newValue, oldValue) {
      if (!newValue) {
        this.month = oldValue
        this.$forceUpdate()
        return
      }
      if (isNaN(newValue)) {
        this.month = oldValue
        this.$forceUpdate()
        return
      }
      if (!(newValue >= 1 && newValue <= 12)) {
        this.month = oldValue
        this.$forceUpdate()
        return
      }
      this.$emit('update:month', newValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.dateContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 84px;
  height: 24px;
  padding: 0 4px 0 0;
  color: rgb(51, 51, 51);
  font-size: 1.4rem;
  @include inputElement;
  &-year {
    width: 40px;
  }
  &-month {
    width: 26px;
  }
}
input {
  text-align: right;
  border: none;
  border-radius: 4px;
  outline: none;
  background-color: inherit;
  &.dateContainer-year {
    width: 40px;
  }
}
p {
  margin: 0 1px;
}
</style>
