<template>
  <div>
    <pa-header />
    <div class="teams">
      <h2 class="teams__header">{{ $t('terms.title') }}</h2>
      <div class="teams__contents">
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article1.title') }}</h3>
          <ol>
            <li>{{ $t('terms.article1.li1') }}</li>
            <li>{{ $t('terms.article1.li2') }}</li>
            <li>{{ $t('terms.article1.li3') }}</li>
            <li>{{ $t('terms.article1.li4') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article2.title') }}</h3>
          <p>{{ $t('terms.article2.description') }}</p>
          <ol>
            <li>{{ $t('terms.article2.li1') }}</li>
            <li>{{ $t('terms.article2.li2') }}</li>
            <li>{{ $t('terms.article2.li3') }}</li>
            <li v-html="$t('terms.article2.li4')" />
            <li>{{ $t('terms.article2.li5') }}</li>
            <li>{{ $t('terms.article2.li6') }}</li>
            <li>{{ $t('terms.article2.li7') }}</li>
            <li>{{ $t('terms.article2.li8') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article3.title') }}</h3>
          <ol>
            <li>{{ $t('terms.article3.li1') }}</li>
            <li>{{ $t('terms.article3.li2') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article4.title') }}</h3>
          <ol>
            <li>
              {{ $t('terms.article4.li1') }}
              <ol>
                <li>{{ $t('terms.article4.li2') }}</li>
                <li>{{ $t('terms.article4.li3') }}</li>
              </ol>
            </li>
            <li>{{ $t('terms.article4.li4') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article5.title') }}</h3>
          <ol>
            <li>{{ $t('terms.article5.li1') }}</li>
            <li>{{ $t('terms.article5.li2') }}</li>
            <li>{{ $t('terms.article5.li3') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article6.title') }}</h3>
          <ol>
            <li>{{ $t('terms.article6.li1') }}</li>
            <li>{{ $t('terms.article6.li2') }}</li>
            <li>{{ $t('terms.article6.li3') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article7.title') }}</h3>
          <ol>
            <li>{{ $t('terms.article7.li1') }}</li>
            <li>{{ $t('terms.article7.li2') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article8.title') }}</h3>
          <p>{{ $t('terms.article8.description') }}</p>
          <ol>
            <li>{{ $t('terms.article8.li1') }}</li>
            <li>{{ $t('terms.article8.li2') }}</li>
            <li>{{ $t('terms.article8.li3') }}</li>
            <li>{{ $t('terms.article8.li4') }}</li>
            <li>{{ $t('terms.article8.li5') }}</li>
            <li>{{ $t('terms.article8.li6') }}</li>
            <li>{{ $t('terms.article8.li7') }}</li>
            <li>{{ $t('terms.article8.li8') }}</li>
            <li>{{ $t('terms.article8.li9') }}</li>
            <li>{{ $t('terms.article8.li10') }}</li>
            <li>{{ $t('terms.article8.li11') }}</li>
            <li>{{ $t('terms.article8.li12') }}</li>
            <li>{{ $t('terms.article8.li13') }}</li>
            <li>{{ $t('terms.article8.li14') }}</li>
            <li>{{ $t('terms.article8.li15') }}</li>
            <li>{{ $t('terms.article8.li16') }}</li>
            <li>{{ $t('terms.article8.li17') }}</li>
            <li>{{ $t('terms.article8.li18') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article9.title') }}</h3>
          <ol>
            <li>{{ $t('terms.article9.li1') }}</li>
            <li>
              {{ $t('terms.article9.li2') }}
              <ol>
                <li>{{ $t('terms.article9.li3') }}</li>
                <li>{{ $t('terms.article9.li4') }}</li>
                <li>{{ $t('terms.article9.li5') }}</li>
                <li>{{ $t('terms.article9.li6') }}</li>
                <li>{{ $t('terms.article9.li7') }}</li>
                <li>{{ $t('terms.article9.li8') }}</li>
              </ol>
            </li>
            <li>{{ $t('terms.article9.li9') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article10.title') }}</h3>
          <p>{{ $t('terms.article10.description') }}</p>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article11.title') }}</h3>
          <p>{{ $t('terms.article11.description') }}</p>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article12.title') }}</h3>
          <ol>
            <li>{{ $t('terms.article12.li1') }}</li>
            <li>{{ $t('terms.article12.li2') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article13.title') }}</h3>
          <ol>
            <li>{{ $t('terms.article13.li1') }}</li>
            <li>{{ $t('terms.article13.li2') }}</li>
            <li>{{ $t('terms.article13.li3') }}</li>
            <li>{{ $t('terms.article13.li4') }}</li>
            <li>{{ $t('terms.article13.li5') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article14.title') }}</h3>
          <ol>
            <li>{{ $t('terms.article14.li1') }}</li>
            <li>{{ $t('terms.article14.li2') }}</li>
            <li>{{ $t('terms.article14.li3') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article15.title') }}</h3>
          <ol>
            <li>{{ $t('terms.article15.li1') }}</li>
            <li>
              {{ $t('terms.article15.li2') }}
              <ol>
                <li>{{ $t('terms.article15.li3') }}</li>
                <li>{{ $t('terms.article15.li4') }}</li>
                <li>{{ $t('terms.article15.li5') }}</li>
                <li>{{ $t('terms.article15.li6') }}</li>
                <li>{{ $t('terms.article15.li7') }}</li>
              </ol>
            </li>
            <li v-if="$t('terms.article15.li8')">
              {{ $t('terms.article15.li8') }}
            </li>
            <li>{{ $t('terms.article15.li9') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article16.title') }}</h3>
          <p>{{ $t('terms.article16.description') }}</p>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article17.title') }}</h3>
          <ol>
            <li>{{ $t('terms.article17.li1') }}</li>
            <li>{{ $t('terms.article17.li2') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article18.title') }}</h3>
          <p>{{ $t('terms.article18.description') }}</p>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article19.title') }}</h3>
          <ol>
            <li>
              {{ $t('terms.article19.li1') }}
              <ol>
                <li>{{ $t('terms.article19.li2') }}</li>
                <li>{{ $t('terms.article19.li3') }}</li>
                <li>{{ $t('terms.article19.li4') }}</li>
                <li>{{ $t('terms.article19.li5') }}</li>
                <li>{{ $t('terms.article19.li6') }}</li>
                <li>{{ $t('terms.article19.li7') }}</li>
                <li>{{ $t('terms.article19.li8') }}</li>
              </ol>
            </li>
            <li>{{ $t('terms.article19.li9') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article20.title') }}</h3>
          <p>{{ $t('terms.article20.description') }}</p>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article21.title') }}</h3>
          <p>{{ $t('terms.article21.description') }}</p>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article22.title') }}</h3>
          <ol>
            <li>
              {{ $t('terms.article22.li1') }}
              <ol>
                <li>{{ $t('terms.article22.li2') }}</li>
                <li>{{ $t('terms.article22.li3') }}</li>
                <li>
                  {{ $t('terms.article22.li4') }}
                  <ol>
                    <li>{{ $t('terms.article22.li5') }}</li>
                    <li>{{ $t('terms.article22.li6') }}</li>
                    <li>{{ $t('terms.article22.li7') }}</li>
                    <li>{{ $t('terms.article22.li8') }}</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>{{ $t('terms.article22.li9') }}</li>
            <li>{{ $t('terms.article22.li10') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article23.title') }}</h3>
          <p>{{ $t('terms.article23.description') }}</p>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article24.title') }}</h3>
          <ol>
            <li>{{ $t('terms.article24.li1') }}</li>
            <li>{{ $t('terms.article24.li2') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article25.title') }}</h3>
          <ol>
            <li>{{ $t('terms.article25.li1') }}</li>
            <li>{{ $t('terms.article25.li2') }}</li>
          </ol>
        </div>
        <div class="teams__contents-block">
          <h3>{{ $t('terms.article26.title') }}</h3>
          <p>{{ $t('terms.article26.description') }}</p>
        </div>
        <div class="teams__contents-end">
          <p>{{ $t('terms.period') }}</p>
          <p>{{ $t('terms.lastUpdated') }}</p>
        </div>
      </div>
    </div>
    <pa-footer />
  </div>
</template>

<script>
import PaHeader from '@/components/PaHeader'
import PaFooter from '@/components/PaFooter'

export default {
  name: 'Welcome',
  components: {
    PaHeader,
    PaFooter,
  },
}
</script>

<style lang="scss" scoped>
.teams {
  box-sizing: border-box;
  max-width: 1000px;
  margin: 0 auto;
  padding: 80px;
  color: $color-black;
  @include isPC() {
    padding: 80px;
  }
  @include isSP() {
    padding: 20px;
  }
}
.teams__header {
  font-size: 2.4rem;
  font-family: Questrial, sans-serif;
  text-align: center;
}
.teams__contents {
  margin-top: 40px;
  padding: 20px;
  border: 1px solid $color-light-gray;
  border-radius: 8px;
  text-align: left;
  &-block {
    margin-bottom: 32px;
    li {
      list-style-type: decimal;
      ol {
        li {
          line-height: 2.3rem;
          list-style-type: none;
          counter-increment: cnt;
          ol {
            li {
              list-style-type: lower-alpha;
            }
            li:before {
              content: none;
            }
          }
        }
        li:before {
          content: '(' counter(cnt) ')';
          display: inline-block;
        }
      }
    }
    @include isPC() {
      ol li {
        ol li:before {
          margin-left: -2em;
          width: 2em;
          ol li {
            margin-left: -1em;
          }
        }
      }
    }
    @include isSP() {
      ol {
        padding-inline-start: 20px;
        li {
          ol li:before {
            margin-left: -1.5em;
            width: 1.5em;
          }
        }
      }
    }
  }
  &-end {
    text-align: right;
  }
}
</style>
