<template>
  <div class="panel">
    <article class="panel__title">
      <div class="panel__title-tool">
        <div class="panel__title-left">
          <h2>{{ title }}</h2>
          <pa-tooltip v-if="tooltipText">{{ tooltipText }}</pa-tooltip>
        </div>
        <div class="panel__title-right">
          <slot name="switch" />
        </div>
      </div>
    </article>
    <article>
      <slot name="contents" />
    </article>
  </div>
</template>

<script>
import PaTooltip from '../PaTooltip'
export default {
  name: 'DashboardPanel',
  components: { PaTooltip },
  props: {
    title: String,
    tooltipText: String,
    showTerm: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.panel {
  background: $color-white;
  box-shadow: rgb(0 0 0 / 10%) 0 0 4px;
  padding: 16px;
  margin-bottom: 24px;
}
.panel__title {
  margin-bottom: 24px;
  h2 {
    font-size: 1.8rem;
  }
  &-tool {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.panel__title-left {
  display: flex;
  align-items: center;
}
.panel__title-right {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-left: auto;
  .term {
    font-size: 14px;
  }
}
</style>
