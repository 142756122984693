import chartUtil from './chartUtil'
import i18n from '../locales/i18n'

export default {
  colors(colors9, caseIndex) {
    const colors = colors9
    return colors?.[caseIndex]
  },

  options(caseIndex, annualDate, colors9) {
    const colors = this.colors(colors9, caseIndex)
    return {
      chart: {
        animations: {
          enabled: false,
          easing: 'easeout',
          speed: 300,
        },
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: colors,
      fill: {
        colors: colors,
        opacity: 1,
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -10,
        style: {
          colors: ['#f5f5f5'],
          fontSize: '12px',
        },
        background: {
          enabled: true,
          foreColor: '#666666',
          borderRadius: 2,
          borderWidth: 0,
          opacity: 0.6,
        },
        formatter: (val) => {
          return chartUtil.formatAmount(val)
        },
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      xaxis: {
        categories: annualDate,
        labels: {
          style: {
            colors: '#666666',
            fontSize: '14px',
          },
        },
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: [
        {
          seriesName: i18n.t('pl.arr'),
          showAlways: true,
          labels: {
            minWidth: 48,
            maxWidth: 48,
            style: {
              colors: '#666666',
            },
            formatter: (val) => {
              return chartUtil.formatAmount(val)
            },
          },
        },
        {
          seriesName: i18n.t('pl.arr'),
          show: false,
          labels: {
            minWidth: 48,
            maxWidth: 48,
            style: {
              colors: '#666666',
            },
            formatter: (val) => {
              return chartUtil.formatAmount(val)
            },
          },
        },
        {
          opposite: true,
          labels: {
            minWidth: 48,
            maxWidth: 48,
            style: {
              colors: '#666666',
            },
            formatter: (val) => {
              return chartUtil.formatAmount(val)
            },
          },
        },
      ],
      tooltip: {
        enabled: true,
        marker: {
          fillColors: colors,
        },
        x: {
          show: false,
        },
        y: {
          formatter: function (value, { seriesIndex }) {
            const val = Math.round(value)
            return seriesIndex === 2 ? `${val}%` : val
          },
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        fontSize: '10px',
        position: 'bottom',
        horizontalAlign: 'right',
        markers: {
          fillColors: colors,
        },
        labels: {
          colors: ['#666666'],
        },
      },
      markers: {
        size: 2,
        strokeColors: colors,
      },
      stroke: {
        width: 2,
      },
    }
  },

  comparisonPlOptions(annualDate, plColors) {
    if (!plColors) return
    return {
      chart: {
        id: 'arpu',
        group: 'pl',
        offsetY: 10,
        animations: {
          enabled: false,
          easing: 'easeout',
          speed: 300,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          colors: ['#666666'],
          fontSize: '12px',
        },
        background: {
          enabled: false,
        },
        formatter: (val) => {
          return chartUtil.formatAmount(val)
        },
      },
      fill: {
        colors: plColors || ['#FF3C50', '#FF5151', '#FF6F51'],
        opacity: 1,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff'],
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      xaxis: {
        categories: annualDate,
        labels: {
          style: {
            colors: '#666666',
          },
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        seriesName: '理想ケース',
        tickAmount: 10,
        showAlways: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: '#666666',
          },
          minWidth: 60,
          formatter: (val) => {
            return chartUtil.formatAmount(val)
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        fontSize: '10px',
        position: 'bottom',
        horizontalAlign: 'right',
        markers: {
          fillColors: plColors || ['#FF3C50', '#FF5151', '#FF6F51'],
        },
        labels: {
          colors: ['#666666'],
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    }
  },

  comparisonArrOptions(annualDate, arrColors) {
    if (!arrColors) return
    return {
      chart: {
        id: 'pl',
        group: 'pl',
        offsetY: 10,
        animations: {
          enabled: false,
          easing: 'easeout',
          speed: 300,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          colors: ['#666666'],
          fontSize: '12px',
        },
        background: {
          enabled: false,
        },
        formatter: (val) => {
          return chartUtil.formatAmount(val)
        },
      },
      fill: {
        colors: arrColors || ['#325AB4', '#3278B4', '#3296B4'],
        opacity: 1,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#FFFFFF'],
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      xaxis: {
        categories: annualDate,
        labels: {
          style: {
            colors: '#666666',
          },
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        seriesName: '理想ケース',
        showAlways: true,
        tickAmount: 10,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: '#666666',
          },
          minWidth: 60,
          formatter: (val) => {
            return chartUtil.formatAmount(val)
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        fontSize: '10px',
        position: 'bottom',
        horizontalAlign: 'right',
        markers: {
          fillColors: arrColors || ['#325AB4', '#3278B4', '#3296B4'],
        },
        labels: {
          colors: ['#666666'],
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    }
  },
}
