import http from '../axios/http'

export default {
  async get(params) {
    return await http.get('/v1/pl/monthly/retrieve', params)
  },

  async edit(params) {
    return await http.post('/v1/pl/monthly/edit', params)
  },

  async editCashFlow(params) {
    return await http.post('/v1/pl/monthly/cashflow/edit', params)
  },

  async editComment(params) {
    return await http.post('/v1/pl/monthly/comment/edit', params)
  },

  async editAdjust(params) {
    return await http.post('/v1/pl/monthly/adjust/edit', params)
  },

  async reverse(params) {
    return await http.post('/v1/pl/monthly/reverse', params)
  },

  async getSpreadSheet(params) {
    return await http.get('/v1/spreadsheet/retrieve', params)
  },

  async getSpreadSheetOauthUrl() {
    return await http.get('/v1/spreadsheet/auth_url')
  },

  async connectSpreadSheet(params) {
    return await http.post('/v1/spreadsheet/connect', params)
  },

  async createSpreadSheet(params) {
    return await http.post('/v1/spreadsheet/create', params)
  },

  async exportSpreadSheet(params) {
    return await http.post('/v1/spreadsheet/update', params)
  },

  async importSpreadSheet(params) {
    return await http.post('/v1/spreadsheet/import', params)
  },

  async disconnectSpreadSheet(params) {
    return await http.post('/v1/spreadsheet/disconnect', params)
  },
}
