import axios from 'axios'
import VueRouter from 'vue-router'
import Vue from 'vue'
import router from '../router'

Vue.use(VueRouter)

const baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.baseURL = `${baseURL}/api`

axios.interceptors.request.use((config) => {
  config.headers['X-Requested-With'] = 'XMLHttpRequest'
  config.headers['Authorization'] = `Bearer ${localStorage.getItem(
    'app_token'
  )}`
  return config
})
axios.interceptors.response.use(
  (response) => {
    const token =
      response.headers['Authorization'] || response.data['app_token']
    if (token) {
      localStorage.setItem('app_token', token)
    }
    return response
  },
  (error) => {
    if (error.response?.status === 401 || error.response?.state === 403) {
      // 認証NGとアクセス不可は一旦ログインし直してもらう
      localStorage.removeItem('app_token')
      router.replace({ name: 'Home' })
    } else if (error.response?.status === 400) {
      // リクエスト不正はフラッシュメッセージで通知
      error.response.title = 'Bad Request'
      error.response.message =
        '不正なリクエストです。入力内容をチェックしてください。'
      throw error
    } else if (error.response?.status === 500) {
      // サーバーエラー
      if (error.response?.config.method === 'get') {
        error.response.title = 'Internal Error'
        error.response.message =
          'データの取得に失敗しました。再度画面を読み込むか、改善しない場合はサービス運営にお問い合わせください。'
        throw error
      } else {
        // Postの場合はフラッシュメッセージで通知
        error.response.title = 'Internal Error'
        error.response.message =
          '予期しないエラーが発生しました。サービス運営にお問い合わせください。'
        throw error
      }
    } else {
      error.response.title = 'Internal Error'
      error.response.message =
        '予期しないエラーが発生しました。サービス運営にお問い合わせください。'
      throw error
    }
  }
)

export default {
  async get(url, params) {
    return await axios.get(url, { params: params })
  },

  async post(url, data, config = {}) {
    return await axios.post(url, data, config)
  },
}
