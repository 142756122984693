<template>
  <div class="businessPlans">
    <opened-menu
      v-if="isMenuOpened"
      @change:businessPlan="changeBusinessPlan"
    />
    <closed-menu
      v-if="!isMenuOpened"
      @change:businessPlan="changeBusinessPlan"
    />
    <div
      class="businessPlans__container"
      :class="{ '--isMenuOpen': isMenuOpened }"
    >
      <div v-if="isLoading" class="loading">
        <loading />
      </div>
      <main v-show="!isLoading" class="main_container">
        <trial-message
          v-if="user.plan.is_trial"
          :trial-end="user.plan.trial_end"
        />
        <dummy-panel v-if="isLock" :pageName="$route.name.toLowerCase()" />
        <router-view
          v-else
          :key="$route.params.id"
          :businessPlans="businessPlans"
          :roles="role"
          :user="user"
          :template="template"
          :term="term"
          :currency="currency"
          :current-business-plan-id="currentBusinessPlanId"
          :current-case-id="currentCaseId"
          :current-year="currentYear"
          @change:businessPlan="changeBusinessPlan"
        />
      </main>
      <pa-footer class="businessPlans__container-footer" />
    </div>
    <FlashMessage />
  </div>
</template>

<script>
import PaFooter from '../components/PaFooter'
import userStore from '@/store/user'
import DummyPanel from '../components/DummyPanel'
import TrialMessage from '../components/TrialMessage'
import monthly from '../util/monthly'
import Loading from '../components/Loading'
import OpenedMenu from '../components/Menu/OpenedMenu'
import ClosedMenu from '../components/Menu/ClosedMenu'

export default {
  name: 'BusinessPlans',
  components: {
    ClosedMenu,
    OpenedMenu,
    Loading,
    TrialMessage,
    DummyPanel,
    PaFooter,
  },
  async created() {
    await this.initializeBusinessPlan(this.$route.params.id)
  },
  async mounted() {
    let dataLayer = (window.dataLayer = window.dataLayer || [])
    dataLayer.push({
      email: this.user.email,
      plan: this.user.plan.plan,
      is_trial: this.user.plan.is_trial,
      position: this.user.company.position,
      business_plan_id: this.currentBusinessPlanId,
    })
    await this.$store.dispatch('currentView/setYear', this.getCurrentYear())
    await this.$store.dispatch('color/getColors')
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        if (!this.isLock) {
          this.$store.dispatch('currentView/enableLoading')
        }
      }
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters['currentView/isLoading']
    },
    isMenuOpened() {
      return this.$store.getters['currentView/isMenuOpened']
    },
    businessPlans() {
      return userStore.state().businessPlans
    },
    user() {
      return userStore.state().user
    },
    role() {
      return this.businessPlans.find(
        (x) => x.business_plan_id === this.currentBusinessPlanId
      ).role
    },
    currentBusinessPlan() {
      return this.businessPlans.find(
        (x) => x.business_plan_id === this.currentBusinessPlanId
      )
    },
    currentBusinessPlanId() {
      return this.$store.getters['currentView/businessPlanId']
    },
    currentYear() {
      return this.$store.getters['currentView/year']
    },
    currentCaseId() {
      return this.$store.getters['currentView/caseId']
    },
    term() {
      return monthly.term(
        this.currentBusinessPlan.start_year,
        this.currentBusinessPlan.start_month
      )
    },
    template() {
      return this.currentBusinessPlan.template
    },
    currency() {
      return this.currentBusinessPlan.currency
    },
    isLock() {
      // Planで課金している場合は利用可能
      if (this.user.plan.plan !== 'Free') {
        return false
      }
      // FreeプランだけどPLと設定ページはロックかけない
      if (this.$route.name === 'PL' || this.$route.name === 'Setting') {
        return false
      }
      // そのほかのページでもオーナーではない場合はロックかけない（権限で制御する）
      if (!this.currentBusinessPlan.is_owner) {
        return false
      }
      return true
    },
  },
  methods: {
    async initializeBusinessPlan(businessPlanId) {
      await this.$store.dispatch(
        'currentView/setBusinessPlanId',
        businessPlanId
      )
      await this.$store.dispatch('me/setBusinessPlan', businessPlanId)
      const cases = this.$store.getters['me/businessPlan'].cases
      if (!cases.find((c) => c.case_id === this.currentCaseId)) {
        await this.$store.dispatch(
          'currentView/setCaseId',
          this.$store.getters['me/businessPlan'].cases[0].case_id
        )
      }
    },
    async changeBusinessPlan(businessPlanId) {
      await this.$store.dispatch('currentView/enableLoading')
      await this.initializeBusinessPlan(businessPlanId)
      await this.$router.replace({
        name: this.$route.name,
        params: { id: businessPlanId },
      })
      // FixMe：子コンポーネントの再構築が走ってちらつくのでもう一度セットしてる
      // Vuex化終わればコンポーネントの再構築がいらなくなるはず
      await this.$store.dispatch('currentView/enableLoading')
      // ----
      await this.$store.dispatch('color/getColors')
      await this.$store.dispatch('currentView/setYear', this.getCurrentYear())
    },
    getCurrentYear() {
      const businessStartYear = this.currentBusinessPlan.start_year
      const now = new Date()
      for (let i = 0; i < 6; i++) {
        if (businessStartYear + i === now.getFullYear()) {
          return i + 1
        }
      }
      return 1
    },
  },
}
</script>

<style lang="scss" scoped>
.loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.businessPlans {
  display: flex;
  min-height: 100vh;
  background-color: $color-theme-overall-background;
}
.businessPlans__container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 130px);
  transition: width 0.3s;

  &.--isMenuOpen {
    width: calc(100% - 300px);
  }
}
.main_container {
  width: 100%;
  box-sizing: border-box;
}
</style>
