<template>
  <business-plan-accessible-layout>
    <div class="cost__body">
      <pa-tab @fetch="getCost" />
      <cost-year-plan :cost="cost" :term="term" :currency="currency" />
      <div class="expenses__switch-buttons">
        <button
          v-for="(expense, key) in expenses"
          :key="key"
          :class="expense.class"
          @click="clickExpense(expense.key)"
        >
          {{ expense.label }}
        </button>
      </div>
      <cost-plans
        :selected-expense="selectedExpense"
        :cost="cost"
        :roles="roles"
        :term="term"
        :currency="currency"
        :expenses="expenses"
        @click:add="addCost"
        @click:delete="deleteCost"
        @change:cost="editCost"
      />
    </div>
  </business-plan-accessible-layout>
</template>

<script>
import BusinessPlanAccessibleLayout from '../../components/Common/BusinessPlanAccessibleLayout'
import businessPlans from '@/client/businessPlans'
import costs from '@/client/costs'
import PaTab from '../../components/PaTab'
import CostYearPlan from '../../components/Cost/CostYearPlan'
import CostPlans from '../../components/Cost/CostPlans'

export default {
  name: 'Cost',
  components: {
    BusinessPlanAccessibleLayout,
    CostPlans,
    CostYearPlan,
    PaTab,
  },
  props: {
    businessPlans: Array,
    roles: Object,
    term: Array,
    currency: String,
    currentBusinessPlanId: String,
    currentYear: Number,
  },
  data() {
    return {
      businessPlan: [],
      selectedExpense: 'cogs',
      expenses: [
        { key: 'cogs', label: 'COGS', class: 'active' },
        { key: 'sm', label: 'S＆M', class: '' },
        { key: 'rd', label: 'R＆D', class: '' },
        { key: 'ga', label: 'G＆A', class: '' },
      ],
      defaultCost: {
        budget: {
          all: {
            budget: 0,
            budgetary_averages: 0,
            remaining_budget: 0,
          },
          cogs: {
            budget: 0,
            budgetary_averages: 0,
            remaining_budget: 0,
          },
          ga: {
            budget: 0,
            budgetary_averages: 0,
            remaining_budget: 0,
          },
          rd: {
            budget: 0,
            budgetary_averages: 0,
            remaining_budget: 0,
          },
          sm: {
            budget: 0,
            budgetary_averages: 0,
            remaining_budget: 0,
          },
        },
        cogs: {
          detail: [],
          item: {},
          monthly: [],
          total: 0,
        },
        ga: {
          detail: [],
          item: {},
          monthly: [],
          total: 0,
        },
        rd: {
          detail: [],
          item: {},
          monthly: [],
          total: 0,
        },
        sm: {
          detail: [],
          item: {},
          monthly: [],
          total: 0,
        },
        total_cost: 0,
        year: 1,
      },
    }
  },
  async mounted() {
    await this.getCost()
    await this.$store.dispatch('currentView/disableLoading')
  },
  computed: {
    currentCaseId() {
      return this.$store.getters['currentView/caseId']
    },
    currentCase() {
      return this.businessPlan.find((x) => x.case_id === this.currentCaseId)
    },
    cost() {
      if (typeof this.currentCase === 'undefined') return this.defaultCost
      return this.currentCase.cost[this.currentYear - 1]
    },
  },
  methods: {
    async getCost() {
      const result = await businessPlans.getCost({
        business_plan_id: this.currentBusinessPlanId,
      })
      this.businessPlan = result.data
    },
    async addCost() {
      try {
        await costs.create({
          business_plan_id: this.currentBusinessPlanId,
          case_id: this.currentCaseId,
          year: this.currentYear,
          item: this.selectedExpense,
        })
        await this.getCost()
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
    async editCost(args) {
      try {
        await costs.edit({
          business_plan_id: this.currentBusinessPlanId,
          case_id: this.currentCaseId,
          cost_id: args.costId,
          item: this.selectedExpense,
          item_title: args.itemTitle,
          item_name: args.itemName,
          price: args.price,
          start_year: args.startYear,
          end_year: args.endYear,
          start_month: args.startMonth,
          end_month: args.endMonth,
        })
        await this.getCost()
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
    async deleteCost(costId) {
      try {
        await costs.delete({
          business_plan_id: this.currentBusinessPlanId,
          case_id: this.currentCaseId,
          cost_id: costId,
        })
        await this.getCost()
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
    clickExpense(expense) {
      this.selectedExpense = expense
      this.expenses.forEach((item) => {
        item.class = item.key === expense ? 'active' : ''
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.cost__body {
  @include mainContentWidthPadding;
}
.expenses__switch-buttons {
  display: flex;
  gap: 24px;
  justify-content: center;
  margin-bottom: 24px;
  button {
    width: 200px;
    padding: 8px;
    font-weight: bold;
    color: $color-white;
    background: $color-gray;
    border: 1px solid $color-gray;
    border-radius: 4px;
  }
  .active {
    background: $color-theme;
    border: 1px solid $color-theme;
  }
}
</style>
