<template>
  <business-plan-accessible-layout>
    <div class="monthlyPl__body">
      <pa-tab @fetch="getMonthlyPl">
        <template #tabAreaRight>
          <button
            v-if="isOwner"
            class="monthlyPl__body-googleConnect"
            @click="$refs.modal.showModal()"
          >
            <spread-sheet-icon />
            {{ $t('monthlyPl.googleSpreadSheet.title') }}
          </button>
        </template>
      </pa-tab>
      <section class="monthlyPl__charts">
        <monthly-pl-chart class="monthlyPl__charts--inner" graph-type="kpi" />
        <monthly-pl-chart class="monthlyPl__charts--inner" graph-type="pl" />
      </section>
      <section class="monthlyPl__submenus">
        <div class="monthlyPl__submenus--inner">
          <sub-menu-title icon="Document">
            {{ $t('monthlyPl.menu.cashFlowBudget') }}
          </sub-menu-title>
          <div class="monthlyPl__submenus--buttons">
            <sub-menu-button
              @click.native="currentDetails = 'estimate'"
              :is-active="currentDetails === 'estimate'"
            >
              <template #label>{{ details.estimate.label }}</template>
              <template #tooltip>{{ details.estimate.tooltip }}</template>
            </sub-menu-button>
            <sub-menu-button
              @click.native="currentDetails = 'revise'"
              :is-active="currentDetails === 'revise'"
            >
              <template #label>{{ details.revise.label }}</template>
              <template #tooltip>{{ details.revise.tooltip }}</template>
            </sub-menu-button>
          </div>
        </div>
        <div class="monthlyPl__submenus--inner">
          <sub-menu-title icon="Rocket"
            >{{ $t('monthlyPl.menu.actuals') }}
          </sub-menu-title>
          <div class="monthlyPl__submenus--buttons">
            <sub-menu-button
              @click.native="currentDetails = 'actual'"
              :is-active="currentDetails === 'actual'"
            >
              <template #label>{{ details.actual.label }}</template>
              <template #tooltip>{{ details.actual.tooltip }}</template>
            </sub-menu-button>
          </div>
        </div>
        <div class="monthlyPl__submenus--inner">
          <sub-menu-title icon="BarGraph"
            >{{ $t('monthlyPl.menu.BudgetToActualAnalysis') }}
          </sub-menu-title>
          <div class="monthlyPl__submenus--buttons">
            <sub-menu-button
              @click.native="currentDetails = 'monthlyProgress'"
              :is-active="currentDetails === 'monthlyProgress'"
            >
              <template #label>{{ details.monthlyProgress.label }}</template>
              <template #tooltip
                >{{ details.monthlyProgress.tooltip }}
              </template>
            </sub-menu-button>
            <sub-menu-button
              @click.native="currentDetails = 'difference'"
              :is-active="currentDetails === 'difference'"
            >
              <template #label>{{ details.difference.label }}</template>
              <template #tooltip>{{ details.difference.tooltip }}</template>
            </sub-menu-button>
          </div>
        </div>
        <div>
          <sub-menu-title icon="Optional"
            >{{ $t('monthlyPl.menu.displayOption') }}
          </sub-menu-title>
          <div class="monthlyPl__submenus--filters">
            <div class="monthlyPl__filter">
              <label>{{ $t('monthlyPl.menu.month') }}</label>
              <div @click="displayFilter.months = !displayFilter.months">
                <switch-on
                  v-if="displayFilter.months"
                  class="monthlyPl__filter--switchOn"
                />
                <switch-off v-else />
              </div>
            </div>
            <div class="monthlyPl__filter">
              <label>{{ $t('monthlyPl.menu.quarter') }}</label>
              <div @click="displayFilter.quarter = !displayFilter.quarter">
                <switch-on
                  v-if="displayFilter.quarter"
                  class="monthlyPl__filter--switchOn"
                />
                <switch-off v-else />
              </div>
            </div>
            <div class="monthlyPl__filter">
              <label>{{ $t('monthlyPl.menu.cy') }}</label>
              <div @click="displayFilter.cy = !displayFilter.cy">
                <switch-on
                  v-if="displayFilter.cy"
                  class="monthlyPl__filter--switchOn"
                />
                <switch-off v-else />
              </div>
            </div>
          </div>
        </div>
      </section>
      <component :is="currentDetails" :title="details[currentDetails].label" />
    </div>
    <connect-google-spread-sheet-modal ref="modal" />
  </business-plan-accessible-layout>
</template>

<script>
import BusinessPlanAccessibleLayout from '../../components/Common/BusinessPlanAccessibleLayout'
import PaTab from '../../components/PaTab'
import SpreadSheetIcon from '@/assets/svg/SpreadSheetIcon.svg'
import MonthlyPlChart from '../../components/MonthlyPl/MonthlyPLChart'

import SwitchOn from '@/assets/svg/SwitchOn.svg'
import SwitchOff from '@/assets/svg/SwitchOff.svg'
import SubMenuTitle from '../../components/MonthlyPl/SubMenuTitle'
import SubMenuButton from '../../components/MonthlyPl/SubMenuButton'

import Estimate from '../../components/MonthlyPl/Estimate'
import Revise from '../../components/MonthlyPl/Revise'
import Actual from '../../components/MonthlyPl/Actual'
import MonthlyProgress from '../../components/MonthlyPl/MonthlyProgress'
import Difference from '../../components/MonthlyPl/Difference'
import ConnectGoogleSpreadSheetModal from '../../components/MonthlyPl/ConnectGoogleSpreadSheetModal'

export default {
  name: 'MonthlyPl',
  components: {
    BusinessPlanAccessibleLayout,
    ConnectGoogleSpreadSheetModal,
    MonthlyPlChart,
    Estimate,
    Difference,
    Actual,
    PaTab,
    SwitchOn,
    SwitchOff,
    SubMenuTitle,
    SubMenuButton,
    MonthlyProgress,
    Revise,
    SpreadSheetIcon,
  },
  data() {
    return {
      displayFilter: {
        months: true,
        quarter: false,
        cy: true,
      },
      currentDetails: 'actual',
      details: {
        estimate: {
          label: this.$t('monthlyPl.menu.editCashFlow'),
          tooltip: this.$t('monthlyPl.menu.editCashFlowTooltip'),
        },
        revise: {
          label: this.$t('monthlyPl.menu.reviseBudget'),
          tooltip: this.$t('monthlyPl.menu.reviseBudgetTooltip'),
        },
        actual: {
          label: this.$t('monthlyPl.menu.enterActuals'),
          tooltip: this.$t('monthlyPl.menu.enterActualsTooltip'),
        },
        monthlyProgress: {
          label: this.$t('monthlyPl.menu.progress'),
          tooltip: this.$t('monthlyPl.menu.progressTooltip'),
        },
        difference: {
          label: this.$t('monthlyPl.menu.variance'),
          tooltip: this.$t('monthlyPl.menu.varianceTooltip'),
        },
      },
    }
  },
  provide() {
    return {
      currentDetails: () => this.currentDetails,
      displayFilter: () => this.displayFilter,
    }
  },
  watch: {
    computedDisplayFilter: {
      handler(newValue, oldValue) {
        if (!newValue.months && !newValue.quarter && !newValue.cy) {
          this.displayFilter = { ...oldValue }
        }
      },
      deep: true,
    },
    currentCaseId() {
      this.getMonthlyPl()
    },
    currentYear() {
      this.getMonthlyPl()
    },
  },
  async mounted() {
    if (!this.currentCaseId) {
      const businessPlan = this.$store.getters['me/businessPlan']
      await this.$store.dispatch(
        'currentView/setCaseId',
        businessPlan.cases[0].case_id
      )
      this.$store.subscribe(async (mutation) => {
        if (mutation.type === 'currentView/setCaseId') {
          await this.getMonthlyPl()
        }
      })
    } else {
      await this.getMonthlyPl()
    }
    await this.$store.dispatch('currentView/disableLoading')
  },
  computed: {
    isOwner() {
      return this.$store.getters['me/businessPlan']?.is_owner
    },
    currentCaseId() {
      return this.$store.getters['currentView/caseId']
    },
    currentYear() {
      return this.$store.getters['currentView/year']
    },
    computedDisplayFilter() {
      return { ...this.displayFilter }
    },
  },
  methods: {
    async getMonthlyPl() {
      try {
        await this.$store.dispatch('monthlyPl/getMonthlyPl')
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
        throw e
      }
    },
    async confirmEditing() {
      if (this.$store.getters['monthlyPl/isEditingFlags'].includes(true)) {
        return window.confirm(this.$t('editingConfirmMessage'))
      }
      return true
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (await this.confirmEditing()) {
      const isEditingFlags = this.$store.getters['monthlyPl/isEditingFlags']
      isEditingFlags.forEach((isEditing, index) => {
        this.$store.dispatch('monthlyPl/changeEditingFlag', {
          monthlyIndex: index,
          value: false,
        })
      })
      next()
    } else {
      next(false)
    }
  },
}
</script>

<style lang="scss" scoped>
.monthlyPl__body {
  @include mainContentWidthPadding;
}

.monthlyPl__body-googleConnect {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: bold;
  margin-right: 20px;
  padding: 0 16px;
  background-color: $color-white;
  height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;

  svg {
    width: 24px;
  }
}

.monthlyPl__charts {
  margin: 20px 20px 0;
  display: flex;
  gap: 16px;

  .monthlyPl__charts--inner {
    position: relative;
  }
}

.monthlyPl__submenus {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 20px;
}

.monthlyPl__submenus--buttons {
  display: flex;
  gap: 8px;
  padding: 8px 4px;
}

.monthlyPl__submenus--filters {
  display: flex;
  gap: 16px;
  padding: 8px 4px;
}

.monthlyPl__filter {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 0;
  font-weight: bold;
  cursor: pointer;

  .monthlyPl__filter--switchOn {
    path:first-child {
      fill: $color-theme;
    }
  }
}

@include isLarge {
  .monthlyPl__charts {
    .monthlyPl__charts--inner {
      flex-basis: 50%;
    }
  }
}

@include isTablet {
  .monthlyPl__charts {
    flex-wrap: wrap;

    .monthlyPl__charts--inner {
      flex-basis: 100%;
    }
  }
}

@include isSP {
  .monthlyPl__charts {
    flex-wrap: wrap;

    .monthlyPl__charts--inner {
      flex-basis: 100%;
    }
  }
}
</style>
