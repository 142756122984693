<template>
  <accordion-panel :title="$t('pl.plan')" v-if="planCase">
    <template #option>
      <div class="panel__title-option">
        <label>{{ $t('pl.growthRate') }}</label>
        <pa-select
          :height="24"
          :fontSize="1.2"
          :options="growthMagnificationOptions"
          :read-only="isViewOnly"
          v-model="planCase.model.model_name"
          @change.native="changeModel"
        />
        <pa-tooltip>
          {{ $t('pl.growthRateTooltip') }}
        </pa-tooltip>
        <input
          type="button"
          class="downloadButton"
          :value="$t('downloadCsv')"
          @click="clickDownloadCsv"
        />
      </div>
    </template>
    <template #contents>
      <div>
        <div v-if="!hideShowSuggestQuestion" class="suggestQuestion">
          <p class="suggestQuestion__text">
            {{ $t('pl.suggestQuestion1') }}
            <a
              href="https://www.notion.so/Knowledge-Page-0b4f7303a2444d47a453b46c816741ae"
              target="_blank"
            >
              {{ $t('pl.suggestQuestion2') }}
            </a>
            <off-link-icon />
            {{ $t('pl.suggestQuestion3') }}
          </p>
          <div class="suggestQuestion__button">
            <button
              class="suggestQuestion__button--yes"
              @click="$refs.suggestModal.showModal()"
            >
              {{ $t('pl.suggestQuestionYes') }}
            </button>
            <button
              class="suggestQuestion__button--no"
              @click="hideSuggestQuestionMessage"
            >
              {{ $t('pl.suggestQuestionNo') }}
            </button>
          </div>
        </div>
        <db-suggest-modal
          ref="suggestModal"
          :pls="pls"
          :annual-date="annualDate"
        />
        <table class="table__contents">
          <thead>
            <tr class="table__contents-years">
              <th></th>
              <th v-for="(date, index) in annualDate" :key="index">
                {{ date }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="table__contents-growthMagnification">
              <td>
                <span>{{ $t('pl.growthRate') }}</span>
              </td>
              <td></td>
              <template v-for="(rate, key) in planCase.model.model_rate">
                <td :key="key">
                  <template v-if="planCase.model.model_name === 'custom'">
                    <div class="rateInputField">
                      x
                      <pa-input
                        v-model="planCase.model.model_rate[key]"
                        :read-only="isViewOnly"
                        :is-decimal="true"
                        max-length="4"
                        class="rateInput"
                        @change.native="changePl(key, 'model')"
                      />
                    </div>
                  </template>
                  <template v-else>
                    {{ `x${rate}` }}
                  </template>
                </td>
              </template>
            </tr>
            <tr class="table__contents-arr">
              <td>
                <div class="td__flex">
                  <div class="name">
                    {{ $t('pl.arr') }}
                    <pa-tooltip :isAlignLeft="true">
                      {{ $t('pl.arrTooltip') }}
                    </pa-tooltip>
                  </div>
                  <span>({{ unit(currency, 'yen') }}）</span>
                </div>
              </td>
              <template v-for="(pl, key) in pls">
                <td :key="key">
                  <pa-input
                    v-model="pl.arr"
                    :read-only="isViewOnly"
                    class="priceInput"
                    @change.native="changePl(key, 'arr')"
                  />
                </td>
              </template>
            </tr>
            <tr class="table__contents-row">
              <td>
                <div class="td__flex">
                  <div class="name">
                    {{ $t('pl.revenue') }}
                  </div>
                  <span>({{ unit(currency, 'yen') }}）</span>
                </div>
              </td>
              <template v-for="(pl, key) in pls">
                <td :key="key">
                  <p :class="color(pl.revenue)">
                    {{ pl.revenue | numberFormat }}
                  </p>
                  <p>100%</p>
                </td>
              </template>
            </tr>
            <tr class="table__contents-row">
              <td>
                <div class="td__flex">
                  <div class="name">
                    {{ $t('pl.cogs') }}
                    <pa-tooltip :isAlignLeft="true">
                      {{ $t('pl.cogsTooltip') }}
                    </pa-tooltip>
                  </div>
                  <span>({{ unit(currency, 'yen') }}）</span>
                </div>
              </td>
              <template v-for="(pl, key) in pls">
                <td :key="key">
                  <p :class="color(pl.cogs)">
                    {{ pl.cogs | numberFormat }}
                  </p>
                  <div class="rateInputField">
                    <pa-input
                      v-model="pl.cogs_rate"
                      :read-only="isViewOnly"
                      class="rateInput"
                      @change.native="changePl(key, 'cogs_rate')"
                    />
                    %
                  </div>
                </td>
              </template>
            </tr>
            <tr class="table__contents-row">
              <td>
                <div class="td__flex">
                  <div class="name">
                    {{ $t('pl.grossProfit') }}
                  </div>
                  <span>({{ unit(currency, 'yen') }}）</span>
                </div>
              </td>
              <template v-for="(pl, key) in pls">
                <td :key="key">
                  <p :class="color(pl.gross_profit)">
                    {{ pl.gross_profit | numberFormat }}
                  </p>
                  <p>{{ pl.gross_profit_rate | numberFormat('%') }}</p>
                </td>
              </template>
            </tr>
            <tr class="table__contents-row">
              <td>
                <div class="td__flex">
                  <div class="name">
                    {{ $t('pl.operatingExpense') }}
                  </div>
                  <span>({{ unit(currency, 'yen') }}）</span>
                </div>
              </td>
              <template v-for="(pl, key) in pls">
                <td :key="key">
                  <p :class="color(pl.operating_cost)">
                    {{ pl.operating_cost | numberFormat }}
                  </p>
                  <p>{{ pl.operating_cost_rate | numberFormat('%') }}</p>
                </td>
              </template>
            </tr>
            <tr class="table__contents-row">
              <td>
                <div class="td__flex">
                  <div class="name">
                    {{ $t('pl.sm') }}
                    <pa-tooltip :isAlignLeft="true">
                      {{ $t('pl.smTooltip') }}
                    </pa-tooltip>
                  </div>
                  <span>({{ unit(currency, 'yen') }}）</span>
                </div>
              </td>
              <template v-for="(pl, key) in pls">
                <td :key="key">
                  <p :class="color(pl.sm)">{{ pl.sm | numberFormat }}</p>
                  <div class="rateInputField">
                    <pa-input
                      v-model="pl.sm_rate"
                      :read-only="isViewOnly"
                      class="rateInput"
                      @change.native="changePl(key, 'sm_rate')"
                    />
                    %
                  </div>
                </td>
              </template>
            </tr>
            <tr class="table__contents-row">
              <td>
                <div class="td__flex">
                  <div class="name">
                    {{ $t('pl.rd') }}
                    <pa-tooltip :isAlignLeft="true">
                      {{ $t('pl.rdTooltip') }}
                    </pa-tooltip>
                  </div>
                  <span>({{ unit(currency, 'yen') }}）</span>
                </div>
              </td>
              <template v-for="(pl, key) in pls">
                <td :key="key">
                  <p :class="color(pl.rd)">{{ pl.rd | numberFormat }}</p>
                  <div class="rateInputField">
                    <pa-input
                      v-model="pl.rd_rate"
                      :read-only="isViewOnly"
                      class="rateInput"
                      @change.native="changePl(key, 'rd_rate')"
                    />
                    %
                  </div>
                </td>
              </template>
            </tr>
            <tr class="table__contents-row">
              <td>
                <div class="td__flex">
                  <div class="name">
                    {{ $t('pl.ga') }}
                    <pa-tooltip :isAlignLeft="true">
                      {{ $t('pl.gaTooltip') }}
                    </pa-tooltip>
                  </div>
                  <span>({{ unit(currency, 'yen') }}）</span>
                </div>
              </td>
              <template v-for="(pl, key) in pls">
                <td :key="key">
                  <p :class="color(pl.ga)">{{ pl.ga | numberFormat }}</p>
                  <div class="rateInputField">
                    <pa-input
                      v-model="pl.ga_rate"
                      :read-only="isViewOnly"
                      class="rateInput"
                      @change.native="changePl(key, 'ga_rate')"
                    />
                    %
                  </div>
                </td>
              </template>
            </tr>
            <tr class="table__contents-row">
              <td>
                <div class="td__flex">
                  <div class="name">
                    {{ $t('pl.operatingIncome') }}
                  </div>
                  <span>({{ unit(currency, 'yen') }}）</span>
                </div>
              </td>
              <template v-for="(pl, key) in pls">
                <td :key="key">
                  <p :class="color(pl.operating_pl)">
                    {{ pl.operating_pl | numberFormat }}
                  </p>
                  <p :class="color(pl.operating_pl_rate)">
                    {{ pl.operating_pl_rate | numberFormat('%') }}
                  </p>
                </td>
              </template>
            </tr>
            <tr class="table__contents-row">
              <td>
                <div class="td__flex">
                  <div class="name">
                    {{ $t('pl.employees') }}
                  </div>
                  <span>({{ $t('pl.employeesUnit') }}）</span>
                </div>
              </td>
              <template v-for="(pl, key) in pls">
                <td :key="key">
                  <div class="rateInputField">
                    <pa-input
                      v-model="pl.employees"
                      :read-only="isViewOnly"
                      class="employeesInput"
                      @change.native="changePl(key, 'employees')"
                    />
                  </div>
                </td>
              </template>
            </tr>
            <tr class="table__contents-row">
              <td>
                <div class="td__flex">
                  <div class="name">
                    {{ $t('pl.arrPerHeadcount') }}
                  </div>
                  <span>({{ unit(currency, 'yen') }}）</span>
                </div>
              </td>
              <template v-for="(pl, key) in pls">
                <td :key="key">
                  <p :class="color(pl.arr_per_headcount)">
                    {{
                      pl.arr_per_headcount
                        ? pl.arr_per_headcount
                        : '-' | numberFormat
                    }}
                  </p>
                </td>
              </template>
            </tr>
            <tr class="table__contents-row">
              <td>
                <div class="td__flex">
                  <div class="name">
                    {{ $t('pl.revenuePerHeadcount') }}
                  </div>
                  <span>({{ unit(currency, 'yen') }}）</span>
                </div>
              </td>
              <template v-for="(pl, key) in pls">
                <td :key="key">
                  <p :class="color(pl.revenue_per_headcount)">
                    {{
                      pl.revenue_per_headcount
                        ? pl.revenue_per_headcount
                        : '-' | numberFormat
                    }}
                  </p>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </accordion-panel>
</template>

<script>
import AccordionPanel from '../AccordionPanel'
import PaSelect from '../Welcome/PaSelect'
import PaTooltip from '../PaTooltip'
import PaInput from '../PaInput'
import lang from '@/locales/lang'
import currencyUtil from '../../util/currency'
import OffLinkIcon from '@/assets/svg/OffLinkIcon.svg'
import DbSuggestModal from './DbSuggestModal'

export default {
  name: 'PlPlanDetail',
  components: {
    DbSuggestModal,
    PaInput,
    PaTooltip,
    PaSelect,
    AccordionPanel,
    OffLinkIcon,
  },
  props: {
    planCase: Object,
    isViewOnly: Boolean,
    annualDate: Array,
    currency: String,
  },
  data() {
    return {
      growthMagnification: {
        ja: [
          { key: 't2d3', value: this.$t('template.t2d3') },
          { key: 'sansan', value: this.$t('template.sansan') },
          { key: 'freee', value: this.$t('template.freee') },
          { key: 'salesforce', value: this.$t('template.salesforce') },
          { key: 'workday', value: this.$t('template.workday') },
          { key: 'custom', value: this.$t('template.custom') },
        ],
        en: [
          { key: 't2d3', value: this.$t('template.t2d3') },
          { key: 'salesforce', value: this.$t('template.salesforce') },
          { key: 'wix', value: this.$t('template.wix') },
          { key: 'okta', value: this.$t('template.okta') },
          { key: 'workday', value: this.$t('template.workday') },
          { key: 'custom', value: this.$t('template.custom') },
        ],
      },
      hideShowSuggestQuestion: localStorage.getItem(
        `hideSuggestMessage_${this.$route.params.id}`
      ),
    }
  },
  computed: {
    pls() {
      if (typeof this.planCase === 'undefined') return []
      return this.planCase.pl
    },
    growthMagnificationOptions() {
      return this.growthMagnification[lang.browserLanguage()]
    },
  },
  methods: {
    color(val) {
      return Number(val) < 0 ? 'color-red' : ''
    },
    changePl(index, targetKey) {
      const year =
        targetKey === 'model' ? this.pls[index + 1].year : this.pls[index].year
      const value =
        targetKey === 'model'
          ? this.planCase.model.model_rate[index]
          : this.pls[index][targetKey]
      this.$emit('change:pl', { year: year, key: targetKey, value: value })
    },
    changeModel() {
      this.$emit('change:model', {
        model: this.planCase.model.model_name,
        rates: this.planCase.model.model_rate,
      })
    },
    unit(currency, unit) {
      return currencyUtil.unit(currency, unit)
    },
    clickDownloadCsv() {
      this.$emit('click:downloadCsv')
    },
    hideSuggestQuestionMessage() {
      this.hideShowSuggestQuestion = true
      localStorage.setItem(`hideSuggestMessage_${this.$route.params.id}`, true)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .panel__title-tool {
  flex-wrap: wrap;
  flex-grow: 1;

  @include isTablet {
    label {
      margin: 2px 10px 2px 24px;
    }

    .select__container {
      width: 280px;
    }
  }
}

.panel__title-option {
  display: flex;
  flex-grow: 1;
  .downloadButton {
    margin-left: auto;
    margin-right: 4px;
    font-size: 11px;
    font-weight: bold;
    padding: 4px 8px;
    color: $color-white;
    background-color: $color-theme-light;
    border-radius: 8px;
    cursor: pointer;
  }
}

.suggestQuestion {
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-theme-background;
  border-radius: 8px;
}

.suggestQuestion__text {
  font-weight: bold;

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  svg path {
    fill: $color-base-text;
  }
}

.suggestQuestion__button {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.2rem;

  &--yes {
    color: $color-theme-text;
    background-color: $color-theme;
    padding: 8px 16px;
    border-radius: 8px;
    font-weight: bold;
  }

  &--no {
    color: $color-theme;
    background-color: $color-white;
    border: 2px solid $color-theme;
    padding: 8px 16px;
    border-radius: 8px;
    font-weight: bold;
  }
}

.table__contents {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 1.4rem;
  line-height: 14px;
  font-weight: bold;
  text-align: right;
  padding: 0 24px;

  th:nth-child(1),
  td:nth-child(1) {
    text-align: left;
    display: flex;
    align-items: center;
  }

  tbody {
    th:nth-child(1),
    td:nth-child(1) {
      border-top: 0.5px solid $color-light-gray;
    }
  }
}

.table__contents-years {
  color: $color-dark-gray;

  th {
    padding: 14px 0;
  }
}

.table__contents-growthMagnification,
.table__contents-arr,
.table__contents-row {
  td:nth-child(n + 2) {
    border-top: 0.5px solid $color-light-gray;
  }
}

.table__contents-arr,
.table__contents-row {
  td {
    padding: 8px 0;
  }
}

.table__contents-row {
  td:nth-child(n + 2) {
    :first-child {
      margin-bottom: 6px;
    }
  }
}

.table__contents-growthMagnification,
.table__contents-arr {
  background-color: rgb(251, 252, 254);
}

.table__contents-growthMagnification {
  td {
    padding: 8px 0;
  }

  input {
    width: 56px;
  }
}

.table__contents-arr {
  .priceInput {
    width: 112px;
    margin-left: auto;
  }

  td,
  ::v-deep input {
    color: rgb(50, 90, 180);
  }
}

.table__contents-growthMagnification {
  .rateInputField {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .rateInput {
    width: 56px;
  }
}

.table__contents-row {
  .rateInputField {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .rateInput {
    width: 56px;
    margin-bottom: -8px !important;
  }

  .employeesInput {
    width: 68px;
  }
}

.td__flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  .name {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: $color-dark-gray;
    margin-left: auto;
    text-indent: 0;
  }
}
</style>
