import http from '../axios/http'

export default {
  async retrieve() {
    return await http.get('/v1/billing/retrieve')
  },

  async paymentAttach(params) {
    return await http.post('/v1/billing/payment/attach', params)
  },

  async create(params) {
    return await http.post('/v1/billing/create', params)
  },

  async cancel() {
    return await http.post('/v1/billing/cancel')
  },

  async restore() {
    return await http.post('/v1/billing/restore')
  },
}
