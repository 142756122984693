<template>
  <modal name="editCase" :clickToClose="false">
    <div class="addCaseContainer">
      <h2>{{ $t('pl.editCaseNameTitle') }}</h2>
      <div class="addCaseContainer__contents">
        <div class="addCaseContainer__contents-name">
          <h3>{{ $t('pl.caseName') }}</h3>
          <input type="text" v-model="name" />
        </div>
      </div>
      <div class="addCaseContainer__buttons">
        <button class="cancel" @click="hideModal">{{ $t('cancel') }}</button>
        <button class="register" @click="register" :disabled="isDisabled">
          {{ $t('submit') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import cases from '@/client/cases'

export default {
  name: 'EditCase',
  data() {
    return {
      name: '',
    }
  },
  computed: {
    isDisabled() {
      return !this.name
    },
    currentBusinessPlanId() {
      return this.$store.getters['currentView/businessPlanId']
    },
    currentCaseId() {
      return this.$store.getters['currentView/caseId']
    },
  },
  methods: {
    showModal(caseName) {
      this.name = caseName
      this.$modal.show('editCase')
    },
    hideModal() {
      this.$modal.hide('editCase')
    },
    async register() {
      try {
        await cases.editName({
          business_plan_id: this.currentBusinessPlanId,
          case_id: this.currentCaseId,
          case_name: this.name,
        })
        await this.$store.dispatch('me/getMe')
        await this.$store.dispatch(
          'me/setBusinessPlan',
          this.currentBusinessPlanId
        )
        await this.$emit('fetchData')
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      } finally {
        this.hideModal()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.addCaseContainer {
  padding: 30px;
  h2 {
    text-align: left;
    font-size: 1.6rem;
  }
  &__contents {
    margin: 20px auto 25px;
    padding: 0 30px;
    h3 {
      text-align: left;
      margin-bottom: 5px;
      font-size: 1.4rem;
      font-weight: bold;
    }
    &-name {
      margin-bottom: 16px;
      input {
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        color: rgb(51, 51, 51);
        font-size: 12px;
        border: 1px solid rgb(202, 202, 202);
        border-radius: 4px;
        outline: none;
      }
    }
    &-operator {
      div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      input {
        width: 42px;
        margin: 0 8px;
      }
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
    button {
      background-color: $color-white;
      width: 100px;
      padding: 10px 0;
      font-size: 1rem;
      font-weight: bold;
      border-radius: 16px;
      cursor: pointer;
    }
    .cancel {
      color: $color-theme;
      border: 1px solid $color-theme;
    }
    .register {
      color: $color-white;
      background-color: $color-theme;
      border: none;
    }
    .register:disabled {
      background-color: rgb(202, 202, 202);
      cursor: default;
      pointer-events: none;
    }
  }
}
</style>
