<template>
  <header class="openedMenu">
    <section class="openedMenu__parent">
      <ul class="openedMenu__parent--businessPlans">
        <li v-for="(plan, index) in businessPlans" :key="index">
          <button
            class="businessPlans__button"
            :class="{ '--active': isActiveBusinessPlan(plan.business_plan_id) }"
            @click="$emit('change:businessPlan', plan.business_plan_id)"
            @mouseover="hoverBusinessPlan(index)"
            @mouseleave="isHover = false"
          >
            <img
              :src="
                plan.business_plan_icon ||
                require('@/assets/images/default_plan_icon.png')
              "
              alt="Business Plan Icon"
            />
            <template v-if="isHover && isHoverIndex === index">
              <span class="businessPlans__button--polygon" />
              <span class="businessPlans__button--name">
                {{ plan.business_plan_name }}
              </span>
            </template>
          </button>
        </li>
        <li v-if="canCreate">
          <router-link
            :to="{ name: 'Create' }"
            class="businessPlans__createButton"
          >
            <PlusIcon />
          </router-link>
        </li>
      </ul>
      <div class="openedMenu__parent--settings">
        <CogIcon @click="$store.dispatch('currentView/showSettingsModal')" />
      </div>
    </section>
    <section class="openedMenu__child">
      <div class="openedMenu__child--block">
        <div class="block__businessPlanInfo">
          <h2 class="businessPlanInfo__title">
            {{ businessPlan.business_plan_name }}
          </h2>
          <button class="businessPlanInfo__toggle">
            <TripleArrowIcon
              @click="$store.dispatch('currentView/closeMenu')"
            />
          </button>
        </div>
      </div>
      <div class="openedMenu__year">
        <year-controller :class="{ '--hide': $route.name === 'PL' }" />
      </div>
      <div class="openedMenu__child--block">
        <nav class="block__menuInfo">
          <h3 class="block__menuInfo--title">{{ $t('menu.management') }}</h3>
          <ul>
            <li
              v-for="(menu, index) in managementMenus"
              :key="index"
              class="block__menuInfo--menu"
            >
              <router-link
                :to="{ name: menu.name, params: { id: currentBusinessPlanId } }"
                class="block__menuInfo--link"
                :class="{ '--active': isActiveMenu(menu.name) }"
              >
                <component :is="`icon-${menu.icon}`" />
                <p>{{ menu.label }}</p>
              </router-link>
            </li>
          </ul>
          <h3 class="block__menuInfo--title">{{ $t('menu.budgeting') }}</h3>
          <ul>
            <li
              v-for="(menu, index) in budgetingMenus"
              :key="index"
              class="block__menuInfo--menu"
            >
              <router-link
                :to="{ name: menu.name, params: { id: currentBusinessPlanId } }"
                class="block__menuInfo--link"
                :class="{ '--active': isActiveMenu(menu.name) }"
              >
                <component :is="`icon-${menu.icon}`" />
                <p>{{ menu.label }}</p>
              </router-link>
            </li>
          </ul>
          <template v-if="businessPlan.is_owner">
            <h3 class="block__menuInfo--title">{{ $t('menu.setting') }}</h3>
            <ul>
              <li class="block__menuInfo--menu">
                <router-link
                  :to="{
                    name: 'Setting',
                    params: { id: currentBusinessPlanId },
                  }"
                  class="block__menuInfo--link"
                  :class="{ '--active': isActiveMenu('Setting') }"
                >
                  <component :is="`icon-setting`" />
                  <p>{{ $t('menu.businessPlanSetting') }}</p>
                </router-link>
              </li>
            </ul>
          </template>
        </nav>
      </div>
      <div class="openedMenu__child--block">
        <nav class="block__menuInfo">
          <ul>
            <template v-for="(menu, index) in referenceMenus">
              <li
                v-if="!(menu.hideFreePlan && plan === 'Free')"
                :key="index"
                class="block__menuInfo--menu"
              >
                <a
                  :href="menu.link"
                  target="_blank"
                  rel="noreferrer"
                  class="block__menuInfo--link --referenceMenu"
                >
                  <component :is="`Link${menu.icon}`" />
                  {{ menu.label }}
                </a>
              </li>
            </template>
          </ul>
        </nav>
      </div>
    </section>

    <settings-modal ref="modal" />
  </header>
</template>

<script>
import TripleArrowIcon from '@/assets/svg/TripleArrow.svg'
import CogIcon from '@/assets/svg/Settings.svg'
import IconPl from '@/assets/svg/NavAnnual.svg'
import IconMonthlyPl from '@/assets/svg/NavMonthly.svg'
import IconKpi from '@/assets/svg/NavAction.svg'
import IconRevenue from '@/assets/svg/NavRevenue.svg'
import IconCost from '@/assets/svg/NavCost.svg'
import IconSetting from '@/assets/svg/NavSetting.svg'
import IconDashboard from '@/assets/svg/NavDashboard.svg'
import LinkProjectionDB from '@/assets/svg/LinkProjectiondb.svg'
import LinkKnowledge from '@/assets/svg/ListKnowledge.svg'
import LinkHelp from '@/assets/svg/LinkHelp.svg'
import LinkFeedback from '@/assets/svg/LinkFeedback.svg'
import PlusIcon from '@/assets/svg/PlusThick.svg'
import YearController from './YearController'
import businessPlan from '@/util/businessPlan'
import SettingsModal from '../Settings/SettingsModal.vue'

export default {
  name: 'OpenedMenu',
  components: {
    SettingsModal,
    YearController,
    TripleArrowIcon,
    CogIcon,
    IconPl,
    IconMonthlyPl,
    IconKpi,
    IconRevenue,
    IconCost,
    IconDashboard,
    IconSetting,
    LinkProjectionDB,
    LinkKnowledge,
    LinkHelp,
    LinkFeedback,
    PlusIcon,
  },
  data() {
    return {
      isHover: false,
      isHoverIndex: null,
      managementMenus: [
        {
          label: this.$t('menu.dashboard'),
          name: 'Dashboard',
          icon: 'dashboard',
        },
        {
          label: this.$t('menu.monthlyPl'),
          name: 'MonthlyPl',
          icon: 'monthly-pl',
        },
      ],
      budgetingMenus: [
        {
          label: this.$t('menu.pl'),
          name: 'PL',
          icon: 'pl',
        },
        {
          label: this.$t('menu.kpi'),
          name: 'Kpi',
          icon: 'kpi',
        },
        {
          label: this.$t('menu.revenue'),
          name: 'Revenue',
          icon: 'revenue',
        },
        {
          label: this.$t('menu.cost'),
          name: 'Expenses',
          icon: 'cost',
        },
      ],
      referenceMenus: [
        {
          label: this.$t('menu.knowledge'),
          link: 'https://www.notion.so/Knowledge-Page-0b4f7303a2444d47a453b46c816741ae',
          icon: 'Knowledge',
          hideFreePlan: true,
        },
        {
          label: this.$t('menu.help'),
          link: 'https://www.notion.so/onecapital/Tutorial-projection-ai-a88dfb124e0a46128673db58436691eb',
          icon: 'Help',
          hideFreePlan: false,
        },
        {
          label: this.$t('menu.feedback'),
          link: this.$t('menu.upvoty'),
          icon: 'Feedback',
          hideFreePlan: false,
        },
        {
          label: this.$t('menu.database'),
          link: 'https://db-lp.projection-ai.com/',
          icon: 'ProjectionDB',
          hideFreePlan: false,
        },
      ],
    }
  },
  computed: {
    businessPlans() {
      return this.$store.getters['me/businessPlans']
    },
    businessPlan() {
      return this.$store.getters['me/businessPlan']
    },
    currentBusinessPlanId() {
      return this.$store.getters['currentView/businessPlanId']
    },
    plan() {
      return this.$store.getters['me/user'].plan.plan
    },
    canCreate() {
      return businessPlan.canCreateBusinessPlan(
        this.$store.getters['me/user'].plan,
        this.businessPlans
      )
    },
  },
  methods: {
    isActiveBusinessPlan(businessPlanId) {
      return businessPlanId === this.currentBusinessPlanId
    },
    isActiveMenu(routeName) {
      return this.$route.name === routeName
    },
    hoverBusinessPlan(index) {
      this.isHover = true
      this.isHoverIndex = index
    },
  },
}
</script>

<style lang="scss" scoped>
.openedMenu {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  max-height: 100vh;
  background-color: $color-theme;
  width: 300px;
  z-index: 999;
}

.openedMenu__parent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
}

.openedMenu__parent--businessPlans {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  .businessPlans__createButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: $color-theme-light;
    width: 44px;
    height: 44px;
  }

  .businessPlans__button {
    position: relative;
    padding: 0;
    width: 44px;
    height: 44px;
    border-radius: 8px;
    border: 2px solid $color-theme-light;
    box-shadow: 0 0 0 2px $color-theme-light;
    background-color: $color-theme-light;

    &.--active {
      border-radius: 8px;
      border: 2px solid $color-theme;
      box-shadow: 0 0 0 2px $color-theme-light, 0 0 0 5px $color-white;
    }

    img {
      width: 100%;
      border-radius: 6px;
      vertical-align: middle;
      height: 40px;
    }
  }

  .businessPlans__button--polygon {
    position: absolute;
    z-index: 1001;
    top: 14px;
    left: calc(100% + 16px);
    height: 10px;
    width: 10px;
    background-color: $color-white;
    clip-path: polygon(0 50%, 100% 0, 100% 100%);
  }

  .businessPlans__button--name {
    display: block;
    position: absolute;
    z-index: 1001;
    top: 8px;
    left: calc(100% + 24px);
    white-space: nowrap;
    background-color: $color-white;
    padding: 4px 8px;
    font-weight: bold;
    border-radius: 4px;
  }
}

.openedMenu__parent--settings {
  .settings__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 8px;

    &:hover {
      background-color: $color-theme-light;
    }
  }
}

.openedMenu__child {
  display: flex;
  flex-direction: column;
  color: $color-theme-light-text;
  background-color: $color-theme-light;
  overflow-y: auto;
}

.openedMenu__year {
  padding: 8px 16px;

  .--hide {
    visibility: hidden;
  }
}

.openedMenu__child--block {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid $color-theme-background;

  &:first-child {
    border-top: none;
  }

  &:last-child {
    margin-top: auto;
  }

  .block__businessPlanInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .businessPlanInfo__title {
      font-size: 1.6rem;
    }

    .businessPlanInfo__toggle {
      background-color: transparent;

      rect {
        fill: $color-theme-background;
      }
      path {
        fill: $color-theme;
      }
    }
  }

  .block__menuInfo {
    .block__menuInfo--title {
      color: $color-theme-light-text;
      opacity: 0.6;
      font-size: 1.4rem;
      margin-bottom: 8px;
      text-align: left;
    }

    .block__menuInfo--menu {
      margin-bottom: 8px;
    }

    .block__menuInfo--link {
      display: flex;
      gap: 16px;
      align-items: center;
      font-size: 1.6rem;
      font-weight: bold;
      padding: 2px 4px;

      svg {
        path {
          fill: $color-theme-light-text;
        }
      }

      &.--referenceMenu {
        font-size: 1.3rem;
      }

      &.--active,
      &:hover {
        border-radius: 8px;
        color: $color-theme-background-hover-text;
        background-color: $color-theme-background-hover;

        svg {
          path {
            fill: $color-theme-background-hover-text;
          }
        }
      }
    }
  }
}
</style>
