const state = {
  businessPlanId: '',
  caseId: '',
  year: 1,
  isLoading: true,
  isMenuOpened: true,
  showSettings: false,
}

const getters = {
  businessPlanId: (state) => state.businessPlanId,
  caseId: (state) => state.caseId,
  year: (state) => state.year,
  isLoading: (state) => state.isLoading,
  isMenuOpened: (state) => state.isMenuOpened,
  showSettings: (state) => state.showSettings,
}

const mutations = {
  setBusinessPlanId(state, businessPlanId) {
    state.businessPlanId = businessPlanId
  },
  setCaseId(state, caseId) {
    state.caseId = caseId
  },
  setYear(state, year) {
    state.year = year
  },
  incrementYear(state) {
    state.year++
  },
  decrementYear(state) {
    state.year--
  },
  enableLoading(state) {
    state.isLoading = true
  },
  disableLoading(state) {
    state.isLoading = false
  },
  openMenu(state) {
    state.isMenuOpened = true
  },
  closeMenu(state) {
    state.isMenuOpened = false
  },
  showSettingsModal(state) {
    state.showSettings = true
  },
  hideSettingsModal(state) {
    state.showSettings = false
  },
}

const actions = {
  setBusinessPlanId({ commit }, businessPlanId) {
    commit('setBusinessPlanId', businessPlanId)
  },
  setCaseId({ commit }, caseId) {
    commit('setCaseId', caseId)
  },
  setYear({ commit }, year) {
    commit('setYear', year)
  },
  incrementYear({ commit }) {
    commit('incrementYear')
  },
  decrementYear({ commit }) {
    commit('decrementYear')
  },
  enableLoading({ commit }) {
    commit('enableLoading')
  },
  disableLoading({ commit }) {
    commit('disableLoading')
  },
  openMenu({ commit }) {
    commit('openMenu')
  },
  closeMenu({ commit }) {
    commit('closeMenu')
  },
  showSettingsModal({ commit }) {
    commit('showSettingsModal')
  },
  hideSettingsModal({ commit }) {
    commit('hideSettingsModal')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
