<template>
  <section class="revenue__body-panel">
    <accordion-panel :title="$t('revenue.revenueChart')">
      <template #contents>
        <apexchart
          :options="options"
          :series="series"
          type="bar"
          width="100%"
          height="500"
        />
      </template>
    </accordion-panel>
    <border />
    <accordion-panel :title="$t('revenue.revenueDetail')">
      <template #option>
        <input
          type="button"
          class="downloadButton"
          :value="$t('downloadCsv')"
          @click="clickDownloadCsv"
        />
      </template>
      <template #contents>
        <table class="revenue__body-table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th v-for="(month, key) in term" :key="key">{{ month }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>MRR</td>
              <td>（{{ unit(currency, 'k') }}）</td>
              <template v-for="(month, key) in monthly">
                <td :key="key">{{ month.mrr | numberFormat }}</td>
              </template>
            </tr>
            <tr>
              <td>Professional<br />Service</td>
              <td>（{{ unit(currency, 'k') }}）</td>
              <template v-for="(month, key) in monthly">
                <td :key="key">
                  {{ month.professional_service | numberFormat }}
                </td>
              </template>
            </tr>
            <tr class="highlight">
              <td>{{ $t('revenue.monthly') }}</td>
              <td>（{{ unit(currency, 'k') }}）</td>
              <template v-for="(month, key) in monthly">
                <td :key="key">{{ month.revenue | numberFormat }}</td>
              </template>
            </tr>
          </tbody>
        </table>
      </template>
    </accordion-panel>
  </section>
</template>

<script>
import AccordionPanel from '../AccordionPanel'
import Border from '../border'
import revenueChart from '../../charts/revenueChart'
import currencyUtil from '../../util/currency'
import file from '../../util/file'

export default {
  name: 'RevenueTransition',
  components: { Border, AccordionPanel },
  computed: {
    currentCaseIndex() {
      return this.$store.getters['revenue/caseIndex']
    },
    monthly() {
      return this.$store.getters['revenue/revenue'].monthly
    },
    term() {
      return this.$store.getters['me/term']
    },
    currency() {
      return this.$store.getters['me/businessPlan'].currency
    },
    options() {
      return revenueChart.transitionOptions(this.currentCaseIndex, this.term)
    },
    series() {
      return [
        {
          name: 'MRR',
          data: this.monthly.map((month) => month.mrr),
        },
        {
          name: 'Professional Service',
          data: this.monthly.map((month) => month.professional_service),
        },
      ]
    },
  },
  methods: {
    unit(currency, unit) {
      return currencyUtil.unit(currency, unit)
    },
    async clickDownloadCsv() {
      try {
        await this.$store.dispatch('revenue/getRevenueCsvData')
        const csvData = await this.$store.getters['revenue/csvData']
        await file.download(
          csvData.contents,
          csvData.businessPlanName,
          csvData.caseName,
          'Revenue'
        )
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .panel__title-tool {
  flex-grow: 1;
  .downloadButton {
    margin-left: auto;
    margin-right: 4px;
    font-size: 11px;
    font-weight: bold;
    padding: 8px;
    color: $color-white;
    background-color: $color-theme-light;
    border-radius: 8px;
    cursor: pointer;
  }
}
.revenue__body-table {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
  min-width: 880px;
  width: 100%;
  margin-bottom: 5px;
  th {
    border-top: 1px solid rgb(242, 242, 242);
    padding-top: 8px;
    padding-bottom: 8px;
  }
  tbody tr {
    border-top: 1px solid rgb(242, 242, 242);
  }
  td {
    width: 72px;
    color: rgb(51, 51, 51);
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    white-space: nowrap;
  }
  td:nth-child(1) {
    width: 140px;
    padding-left: 24px;
    font-weight: bold;
    text-align: left;
  }
  td:nth-child(2) {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
  }
  .highlight {
    background-color: rgb(252, 251, 252);
    td:not(:nth-child(2)) {
      color: rgb(80, 52, 121);
      font-weight: bold;
    }
  }
}
</style>
