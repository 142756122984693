export default {
  // -----------------------------
  // rgb to hex
  // input r, g, b
  // output #000000
  // -----------------------------
  rgbToHex(r, g, b) {
    function componentToHex(c) {
      var hex = c.toString(16)
      return hex.length == 1 ? '0' + hex : hex
    }
    return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
  },
  // -----------------------------
  // hex to rgb
  // input #000000
  // output rgb(0,0,0)
  // -----------------------------
  hexToRgb(hex) {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b
    })

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : { r: 255, g: 255, b: 255 }
  },

  // -----------------------------
  // calculate saturation
  // input {r:r, g:g, b:b}
  // output 0 ~ 1
  // -----------------------------
  saturation(rgb) {
    if (!rgb) return
    if (Object.keys(rgb).length === 0) return
    let min = Math.min(rgb.r, rgb.g, rgb.b)
    let max = Math.max(rgb.r, rgb.g, rgb.b)
    return (max - min) / max
  },

  // -----------------------------
  // calculate brightness
  // input bg color #000000
  // output boolean (if true, need white txt)
  // -----------------------------
  checkBrightness(color) {
    if (!color) return
    const red = parseInt(color?.substr(1, 2), 16)
    const green = parseInt(color?.substr(3, 2), 16)
    const blue = parseInt(color?.substr(5, 2), 16)
    const luminance = (red * 299 + green * 587 + blue * 114) / 2550
    return luminance < 65
  },
  // -----------------------------
  // lighten hex color by +amt
  // or darken hex color by -amt
  // input hex
  // output hex
  // -----------------------------
  LightenDarkenColor(col, amt) {
    let usePound = false
    if (col[0] == '#') {
      col = col.slice(1)
      usePound = true
    }

    let num = parseInt(col, 16)
    let r = (num >> 16) + amt
    if (r > 255) r = 255
    else if (r < 0) r = 0

    let b = ((num >> 8) & 0x00ff) + amt
    if (b > 255) b = 255
    else if (b < 0) b = 0

    let g = (num & 0x0000ff) + amt
    if (g > 255) g = 255
    else if (g < 0) g = 0

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
  },

  // -----------------------------
  // calculate next color
  // input hex
  // output hex
  // -----------------------------
  calculateNextColor(hex) {
    if (!hex) return
    let rgb = this.hexToRgb(hex)
    let resultRGB

    if (this.saturation(rgb) < 0.8) {
      if (rgb.g < 225) {
        resultRGB = { r: rgb.r, g: rgb.g + 30, b: rgb.b }
      } else if (rgb.b < 225) {
        resultRGB = { r: rgb.r, g: rgb.g, b: rgb.b + 30 }
      } else if (rgb.r < 225) {
        resultRGB = { r: rgb.r + 30, g: rgb.g, b: rgb.b }
      } else {
        resultRGB = { r: 255, g: 255, b: 255 }
      }
      let newR = resultRGB.r.toString(16),
        newG = resultRGB.g.toString(16),
        newB = resultRGB.b.toString(16)

      if (newR.length == 1) newR = '0' + newR
      if (newG.length == 1) newG = '0' + newG
      if (newB.length == 1) newB = '0' + newB

      return '#' + newR + newG + newB
    } else {
      const hex = this.rgbToHex(rgb.r, rgb.g, rgb.b)
      const ligthen = this.LightenDarkenColor(hex, 50)
      return ligthen
    }
  },
}
