<template>
  <div class="notAccessiblePanel">
    <div class="notAccessiblePanel__description">
      <h1>{{ $t('notAccess.title') }}</h1>
      <p>{{ $t('notAccess.text') }}</p>
      <not-found-icon />
    </div>
  </div>
</template>

<script>
import NotFoundIcon from '@/assets/svg/404Icon.svg'
export default {
  name: 'notAccessiblePanel',
  components: { NotFoundIcon },
}
</script>

<style lang="scss" scoped>
.notAccessiblePanel {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 95px);
  margin: auto 120px;
}
.notAccessiblePanel__description {
  width: 50%;
  text-align: center;
  h1 {
    margin-bottom: 24px;
    color: $color-theme;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 40px;
  }
  p {
    margin-bottom: 40px;
    color: $color-dark-gray;
    font-size: 1.6rem;
    line-height: 32px;
  }
}
</style>
