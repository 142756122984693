<template>
  <div>
    <article class="panel__title">
      <div class="panel__title-tool">
        <h2>{{ title }}</h2>
        <slot name="option" />
      </div>
      <pa-accordion :isOpen="isShow" @click.native="isShow = !isShow" />
    </article>
    <article v-if="isShow" class="article">
      <slot name="contents" />
    </article>
    <article v-else>
      <slot name="foldingContents" />
    </article>
  </div>
</template>

<script>
import PaAccordion from './PaAccordion'
export default {
  name: 'AccordionPanel',
  components: { PaAccordion },
  props: {
    title: String,
  },
  data() {
    return {
      isShow: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.panel__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  h2 {
    font-size: 1.6rem;
  }
  &-tool {
    display: flex;
    align-items: center;
    label {
      margin-left: 100px;
      margin-right: 16px;
      font-weight: bold;
    }
    select {
      border: 1px solid rgb(202, 202, 202);
      border-radius: 12px;
      box-shadow: none;
    }
  }
}

.article {
  overflow-x: auto;
  overflow-y: hidden;
}
</style>
