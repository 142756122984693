import { format, addMonths } from 'date-fns'
import i18n from '../locales/i18n'

export default {
  term(startYear, startMonth, isMonthOnly = true) {
    const businessStartYm = new Date(startYear, startMonth - 1)
    const term = []
    const dateFormat = isMonthOnly
      ? `M${i18n.t('month')}`
      : `Y${i18n.t('year')} M${i18n.t('month')}`
    for (let i = 0; i < 12; i++) {
      term.push(format(addMonths(businessStartYm, i), dateFormat))
    }
    return term
  },
}
