<template>
  <div class="costTerm">
    <cost-ym :year.sync="inputStartYear" :month.sync="inputStartMonth" />
    <leader />
    <cost-ym :year.sync="inputEndYear" :month.sync="inputEndMonth" />
  </div>
</template>

<script>
import Leader from '@/assets/svg/Leader.svg'
import CostYm from './CostYm'
export default {
  name: 'CostTerm',
  components: { CostYm, Leader },
  props: {
    startYear: Number,
    startMonth: Number,
    endYear: Number,
    endMonth: Number,
  },
  computed: {
    inputStartYear: {
      get() {
        return this.startYear
      },
      set(value) {
        this.$emit('update:startYear', Number(value))
      },
    },
    inputStartMonth: {
      get() {
        return this.startMonth
      },
      set(value) {
        this.$emit('update:startMonth', Number(value))
      },
    },
    inputEndYear: {
      get() {
        return this.endYear
      },
      set(value) {
        this.$emit('update:endYear', Number(value))
      },
    },
    inputEndMonth: {
      get() {
        return this.endMonth
      },
      set(value) {
        this.$emit('update:endMonth', Number(value))
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.costTerm {
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
}
</style>
