<template>
  <div class="trialMessage">
    <i18n path="trialMessage" tag="p" :places="{ date: remainingDays }">
      <router-link :to="{ name: 'Setting' }">
        {{ $t('trialMessageLink') }}
      </router-link>
    </i18n>
  </div>
</template>

<script>
import { differenceInDays } from 'date-fns'
export default {
  name: 'TrialMessage',
  props: {
    trialEnd: String,
  },
  computed: {
    remainingDays() {
      const today = new Date()
      const trialEndDate = new Date(this.trialEnd)

      return differenceInDays(trialEndDate, today)
    },
  },
}
</script>

<style lang="scss" scoped>
.trialMessage {
  display: flex;
  align-items: center;
  height: 80px;
  padding-right: 100px;
  padding-left: 100px;
  color: rgb(235, 87, 87);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  background-color: rgb(253, 238, 238);
  a {
    text-decoration: underline;
    cursor: pointer;
    color: rgb(235, 87, 87);
  }
}
</style>
