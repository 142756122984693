<template>
  <business-plan-accessible-layout>
    <div class="revenue__body">
      <pa-tab @fetch="getRevenue" />
      <section class="revenue__body-panel">
        <div class="revenue__body-panel-summary">
          <article>
            <h2>{{ $t('revenue.recurringRevenue') }}</h2>
            <pa-tooltip>
              {{ $t('revenue.recurringRevenueTooltip') }}
            </pa-tooltip>
            <pa-input
              class="revenue__body-panel-summary-input"
              v-model="revenue.recurring_revenue_rate"
              :read-only="isViewOnly"
              @change.native="editRecurringRevenueRate()"
            />
            <span>%</span>
          </article>
          <article>
            <h2>{{ $t('revenue.professionalService') }}</h2>
            <pa-tooltip>
              {{ $t('revenue.professionalServiceTooltip') }}
            </pa-tooltip>
            <p>{{ revenue.professional_service_rate }}</p>
            <span>%</span>
          </article>
        </div>
      </section>
      <revenue-transition />
      <revenue-sales />
    </div>
  </business-plan-accessible-layout>
</template>

<script>
import BusinessPlanAccessibleLayout from '../../components/Common/BusinessPlanAccessibleLayout'
import PaTab from '../../components/PaTab'
import PaTooltip from '../../components/PaTooltip'
import PaInput from '../../components/PaInput'
import RevenueTransition from '../../components/Revenue/RevenueTransition'
import RevenueSales from '../../components/Revenue/RevenueSales'

export default {
  name: 'Revenue',
  components: {
    BusinessPlanAccessibleLayout,
    RevenueSales,
    RevenueTransition,
    PaInput,
    PaTooltip,
    PaTab,
  },
  props: {
    roles: Object,
  },
  watch: {
    currentCaseId() {
      this.getRevenue()
    },
    currentYear() {
      this.getRevenue()
    },
  },
  async mounted() {
    await this.getRevenue()
    await this.$store.dispatch('currentView/disableLoading')
  },
  computed: {
    isViewOnly() {
      return this.roles.sales_detail === 'can_view'
    },
    currentCaseId() {
      return this.$store.getters['currentView/caseId']
    },
    currentYear() {
      return this.$store.getters['currentView/year']
    },
    currentBusinessPlanId() {
      return this.$store.getters['currentView/businessPlanId']
    },
    revenue() {
      return this.$store.getters['revenue/revenue']
    },
  },
  methods: {
    async getRevenue() {
      try {
        await this.$store.dispatch('revenue/getRevenue')
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
        throw e
      }
    },
    async editRecurringRevenueRate() {
      try {
        await this.$store.dispatch('revenue/editRecurringRevenueRate')
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.revenue__body {
  @include mainContentWidthPadding;
}
.revenue__body-panel {
  margin-bottom: 40px;
  padding: 24px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 10%) 0 0 4px;
}
.revenue__body-panel-summary {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  article {
    display: flex;
    align-items: center;
    margin-right: 24px;
  }
  h2 {
    color: rgb(51, 51, 51);
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
  &-input {
    width: 56px;
    margin-left: 16px;
    margin-right: 5px;
  }
  p {
    margin: 0 4px 0 12px;
    color: rgb(51, 51, 51);
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    text-align: right;
  }
  span {
    color: rgb(51, 51, 51);
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
  }
}
// pa-inputでreadOnlyの場合のスタイル
// scope超えてスタイル当てているので注意
.revenue__body-panel-summary-input::v-deep p {
  font-weight: bold;
  font-size: 2rem;
}
</style>
