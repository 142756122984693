<template>
  <section class="panel">
    <div class="panel__contents">
      <pa-select :options="years" v-model="businessPlan.start_year" />/
      <pa-select :options="months" v-model="businessPlan.start_month" />
    </div>
  </section>
</template>

<script>
import { addYears, eachYearOfInterval } from 'date-fns'
import PaSelect from '../Welcome/PaSelect'

export default {
  name: 'BusinessStartYm',
  components: { PaSelect },
  props: {
    businessPlan: Object,
  },
  computed: {
    years() {
      const startDate = new Date('2018/1/1')
      const endDate = addYears(new Date(), 6)
      return eachYearOfInterval({ start: startDate, end: endDate }).map(
        (date) => {
          return { key: date.getFullYear(), value: date.getFullYear() }
        }
      )
    },
    months() {
      return [...Array(12)].map((_, i) => {
        return { key: i + 1, value: i + 1 }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.panel {
  h2 {
    font-size: 2rem;
    line-height: 30px;
    margin-bottom: 20px;
  }
  &__contents {
    display: flex;
    gap: 16px;
    align-items: center;
  }
}
</style>
