<template>
  <div class="columnCancelButton">
    <button v-show="isEditing" @click="clickButton()">
      <cancel-icon />
    </button>
  </div>
</template>

<script>
import CancelIcon from '@/assets/svg/CloseIconPure.svg'
export default {
  name: 'MonthlyPlColumnCancelButton',
  components: { CancelIcon },
  props: {
    monthlyIndex: {
      type: Number,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    clickButton() {
      this.$store.dispatch('monthlyPl/changeEditingFlag', {
        monthlyIndex: this.monthlyIndex,
        value: false,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.columnCancelButton {
  height: 29px;

  button {
    padding: 4px 0 0;

    svg {
      rect {
        fill: $color-theme;
      }
    }
  }
}
</style>
