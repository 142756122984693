import http from '../axios/http'

export default {
  async create(params) {
    return await http.post('/v1/cost/create', params)
  },

  async delete(params) {
    return await http.post('/v1/cost/delete', params)
  },

  async edit(params) {
    return await http.post('/v1/cost/edit', params)
  },
}
