<template>
  <dashboard-panel :title="chartTitle" :tooltip-text="chartTooltip">
    <template v-if="switchText" #switch>
      <dashboard-switch :switch-text="switchText" @on:switch="onSwitch" />
    </template>
    <template #contents>
      <div class="chartInfo">
        <div>
          <p class="latestValue">
            {{ chartItems.latest_value.toLocaleString() }}
            <span>{{ chartUnit }}</span>
          </p>
          <div
            v-if="
              chartItems.fiscal_year_achieved &&
              !isNaN(chartItems.fiscal_year_achieved)
            "
            class="achieved"
          >
            <label for="fiscal-year-achieved" class="achieved__label">{{
              $t('dashboard.achieved')
            }}</label>
            <div class="achieved__progress">
              <progress
                id="fiscal-year-achieved"
                class="achieved__progress-bar"
                max="100"
                :value="chartItems.fiscal_year_achieved"
              />
              <p class="achieved__progress-value">
                {{ `${chartItems.fiscal_year_achieved}%` }}
              </p>
            </div>
          </div>
        </div>
        <div class="averages">
          <table>
            <tbody>
              <tr>
                <td class="label">{{ $t('dashboard.average3month') }}</td>
                <td class="value">
                  {{ chartItems.ave_three_monthes.toLocaleString() }}
                </td>
                <td class="unit">{{ chartUnit }}</td>
              </tr>
              <tr>
                <td class="label">{{ $t('dashboard.average6month') }}</td>
                <td class="value">
                  {{ chartItems.ave_six_monthes.toLocaleString() }}
                </td>
                <td class="unit">{{ chartUnit }}</td>
              </tr>
              <tr>
                <td class="label">{{ $t('dashboard.average12month') }}</td>
                <td class="value">
                  {{ chartItems.ave_twelve_monthes.toLocaleString() }}
                </td>
                <td class="unit">{{ chartUnit }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <span class="chartUnit">{{ chartUnit ? `（${chartUnit}）` : '' }}</span>
      <apexchart
        :options="options"
        :series="series"
        type="line"
        width="100%"
        height="300"
      />
    </template>
  </dashboard-panel>
</template>

<script>
import DashboardPanel from './DashboardPanel'
import dashboardChart from '../../charts/dashboardChart'
import DashboardSwitch from './DashboardSwitch'
export default {
  name: 'DashboardOtherChart',
  props: {
    title: Array,
    items: Array,
    term: Array,
    forecastMonth: Number,
    unit: Array,
    switchText: Object,
    tooltipText: Array,
    showEstimateChart: Boolean,
  },
  components: { DashboardSwitch, DashboardPanel },
  data() {
    return {
      switchLeft: true,
    }
  },
  computed: {
    chartTitle() {
      return this.switchLeft ? this.title[0] : this.title[1]
    },
    chartTooltip() {
      return this.switchLeft ? this.tooltipText[0] : this.tooltipText[1]
    },
    chartItems() {
      return this.switchLeft ? this.items[0] : this.items[1]
    },
    chartUnit() {
      return this.switchLeft ? this.unit[0] : this.unit[1]
    },
    currentCaseIndex() {
      return this.$store.getters['dashboard/caseIndex']
    },
    options() {
      return dashboardChart.lineChartOptions(
        this.term,
        this.forecastMonth,
        this.currentCaseIndex,
        false
      )
    },
    series() {
      const series = [
        {
          name: this.$t('dashboard.act'),
          data: this.chartItems.actual,
        },
      ]
      if (this.showEstimateChart) {
        series.push({
          name: this.$t('dashboard.est'),
          data: this.chartItems.estimate,
        })
      }
      return series
    },
  },
  methods: {
    onSwitch(switchLeft) {
      this.switchLeft = switchLeft
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .panel__title {
  margin-bottom: 8px;
}
.chartInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .latestValue {
    font-weight: bold;
    font-size: 3.2rem;
    span {
      margin-left: 4px;
      font-size: 2.4rem;
    }
  }
  .achieved {
    .achieved__label {
      display: block;
      font-size: 1rem;
      color: #2c3e50;
    }
    .achieved__progress {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .achieved__progress-bar {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: 4px;
      &::-webkit-progress-bar {
        border-radius: 2px;
        background-color: #dcdcdc;
      }
      &::-webkit-progress-value {
        border-radius: 2px;
        background-color: #ff8a00;
      }
    }
    .achieved__progress-value {
      font-size: 1.2rem;
    }
  }
  .averages {
    table tbody {
      font-weight: bold;
      tr,
      td {
        border-collapse: collapse;
        padding: 0;
        line-height: 2.3rem;
      }
      .label {
        font-size: 1rem;
        color: $color-gray;
      }
      .value {
        text-align: right;
        font-size: 2rem;
        padding-left: 4px;
        padding-right: 2px;
      }
      .unit {
        font-size: 1.4rem;
      }
    }
  }
}
.chartUnit {
  display: block;
  font-size: 1rem;
  margin-bottom: -15px;
  height: 16px;
}
</style>
