<template>
  <pa-input v-if="editable" v-model="inputValue" />
  <p v-else :class="color(signInversion(value))">
    {{ signInversion(value) | numberFormat(unit) }}
  </p>
</template>

<script>
import PaInput from '../PaInput'

export default {
  name: 'MonthlyPlTableCell',
  components: { PaInput },
  props: {
    editable: {
      type: Boolean,
      required: true,
    },
    value: {
      type: [String, Number, Array],
      default: 0,
    },
    unit: {
      type: String,
      default: '',
    },
    isSignInversion: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
  methods: {
    color(val) {
      if (isNaN(val)) return ''
      return Number(val) < 0 ? 'color-red' : ''
    },
    signInversion(val) {
      if (!this.isSignInversion) return val
      if (isNaN(val)) return val
      if (val === 0) return val
      return val * -1
    },
  },
}
</script>

<style lang="scss" scoped></style>
