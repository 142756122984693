import http from '../axios/http'

export default {
  async create(params) {
    return await http.post('/v1/case/create', params)
  },

  async delete(params) {
    return await http.post('/v1/case/delete', params)
  },

  async editName(params) {
    return await http.post('/v1/casename/edit', params)
  },

  async edit(params) {
    return await http.post('/v1/case/edit', params)
  },
}
