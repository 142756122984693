<template>
  <h2 class="subMenuTitle">
    <component :is="`${icon}Icon`" class="subMenuTitle__icon" />
    <slot />
  </h2>
</template>

<script>
import BarGraphIcon from '@/assets/svg/BarGraphIcon.svg'
import DocumentIcon from '@/assets/svg/DocumentIcon.svg'
import OptionalIcon from '@/assets/svg/OptionalIcon.svg'
import RocketIcon from '@/assets/svg/RocketIcon.svg'

export default {
  name: 'SubMenuTitle',
  components: {
    BarGraphIcon,
    DocumentIcon,
    OptionalIcon,
    RocketIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.subMenuTitle {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 1.4rem;
  color: $color-theme;
  padding: 0 8px 2px;
  border-bottom: 2px solid $color-theme;

  .subMenuTitle__icon {
    width: 20px !important;
    height: auto !important;

    path {
      fill: $color-theme;
    }
  }
}
</style>
