<template>
  <div class="appContainer">
    <router-view />
  </div>
</template>

<script>
import device from './store/device'
import userStore from '@/store/user'

export default {
  name: 'App',
  computed: {
    user() {
      return userStore.state().user
    },
    ownerBusinessPlan() {
      return userStore.state().businessPlans?.find((x) => x.is_owner)
    },
    themeColor() {
      return this.$store.getters['me/businessPlan'].theme_color
    },
  },
  watch: {
    themeColor() {
      this.setThemeColor()
    },
  },
  mounted() {
    device.setWidth(window.innerWidth)
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        device.setWidth(window.innerWidth)
      })
    })
    document.title = this.$t('title')
  },
  updated() {
    let script = document.createElement('script')
    script.type = 'application/javascript'
    /* eslint-disable */
    script.innerHTML = `
      (function(opts) {
              opts.traits = {
                  'email': '${this.user?.email || ''}',
                  'plan': '${this.user?.plan.plan || ''}',
                  'is_trial': '${typeof this.user?.plan.is_trial === 'undefined' ? '' : this.user.plan.is_trial}',
                  'position': '${this.user?.company.position || ''}',
                  'business_plan_id': '${this.ownerBusinessPlan?.business_plan_id || ''}'
               }
          })(window._sva = window._sva || {});
    `
    /* eslint-enable */
    document.body.appendChild(script)
  },
  methods: {
    setThemeColor() {
      document.documentElement.setAttribute('theme', this.themeColor)
    },
  },
}
</script>

<style lang="scss">
.appContainer {
  width: 100%;
  text-align: center;
  color: #2c3e50;
}
</style>
