<template>
  <section class="revenue__body-panel">
    <accordion-panel :title="$t('revenue.sales')">
      <template #contents>
        <apexchart
          :options="options"
          :series="series"
          type="bar"
          width="100%"
          height="500"
        />
      </template>
    </accordion-panel>
  </section>
</template>

<script>
import AccordionPanel from '../AccordionPanel'
import revenueChart from '../../charts/revenueChart'

export default {
  name: 'RevenueSales',
  components: { AccordionPanel },
  computed: {
    currentCaseIndex() {
      return this.$store.getters['revenue/caseIndex']
    },
    monthly() {
      return this.$store.getters['revenue/revenue'].monthly
    },
    term() {
      return this.$store.getters['me/term']
    },
    options() {
      return revenueChart.salesOptions(this.currentCaseIndex, this.term)
    },
    series() {
      return [
        {
          name: this.$t('revenue.sales'),
          data: this.monthly.map((month) => month.sales),
        },
      ]
    },
  },
}
</script>
