<template>
  <header class="header">
    <h1 class="header__logo">
      <a href="/">
        <LpLogo />
      </a>
    </h1>
  </header>
</template>

<script>
import LpLogo from '@/assets/svg/LpLogo.svg'
export default {
  name: 'PaHeader',
  components: {
    LpLogo,
  },
}
</script>

<style lang="scss" scoped>
.header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 105px;
  padding: 0 40px;
  background-color: $color-white;
  border-bottom: 1px solid $color-gray;
}
</style>
