<template>
  <header class="closedMenu">
    <div class="closedMenu__block">
      <div class="block__businessPlanInfo">
        <button class="businessPlanInfo__toggle">
          <TripleArrowIcon @click="$store.dispatch('currentView/openMenu')" />
        </button>
        <div class="businessPlanInfo__block">
          <button
            class="businessPlanInfo__block--icon"
            @click="isShowBusinessPlans = !isShowBusinessPlans"
          >
            <img
              :src="
                businessPlan.business_plan_icon ||
                require('@/assets/images/default_plan_icon.png')
              "
              alt="Business Plan Icon"
            />
          </button>
          <p class="businessPlanInfo__block--businessPlanName">
            {{ businessPlan.business_plan_name }}
          </p>
          <article
            v-if="isShowBusinessPlans"
            class="businessPlanInfo__block--list"
          >
            <ul>
              <li v-for="(plan, index) in businessPlans" :key="index">
                <button
                  class="businessPlanInfo__block--button"
                  :class="{
                    '--active': isActiveBusinessPlan(plan.business_plan_id),
                  }"
                  @click="changeBusinessPlan(plan.business_plan_id)"
                >
                  <img
                    :src="
                      plan.business_plan_icon ||
                      require('@/assets/images/default_plan_icon.png')
                    "
                    alt="Business Plan Icon"
                  />
                  {{ plan.business_plan_name }}
                </button>
              </li>
              <li>
                <router-link
                  v-if="canCreate"
                  :to="{ name: 'Create' }"
                  class="businessPlanInfo__block--createButton"
                >
                  <PlusIcon />
                  {{ $t('setting.addBusinessPlan') }}
                </router-link>
              </li>
            </ul>
          </article>
        </div>
        <year-controller :class="{ '--hide': $route.name === 'PL' }" />
      </div>
    </div>
    <div class="closedMenu__block">
      <nav class="block__menuInfo">
        <h3 class="block__menuInfo--title">{{ $t('menu.management') }}</h3>
        <ul>
          <li
            v-for="(menu, index) in managementMenus"
            :key="index"
            class="block__menuInfo--menu"
          >
            <router-link
              :to="{ name: menu.name, params: { id: currentBusinessPlanId } }"
              class="block__menuInfo--link"
              :class="{ '--active': isActiveMenu(menu.name) }"
            >
              <component :is="`icon-${menu.icon}`" />
              {{ menu.label }}
            </router-link>
          </li>
        </ul>
        <h3 class="block__menuInfo--title">{{ $t('menu.budgeting') }}</h3>
        <ul>
          <li
            v-for="(menu, index) in budgetingMenus"
            :key="index"
            class="block__menuInfo--menu"
          >
            <router-link
              :to="{ name: menu.name, params: { id: currentBusinessPlanId } }"
              class="block__menuInfo--link"
              :class="{ '--active': isActiveMenu(menu.name) }"
            >
              <component :is="`icon-${menu.icon}`" />
              {{ menu.label }}
            </router-link>
          </li>
        </ul>
        <template v-if="businessPlan.is_owner">
          <h3 class="block__menuInfo--title">{{ $t('menu.setting') }}</h3>
          <ul>
            <li class="block__menuInfo--menu">
              <router-link
                :to="{
                  name: 'Setting',
                  params: { id: currentBusinessPlanId },
                }"
                class="block__menuInfo--link"
                :class="{ '--active': isActiveMenu('Setting') }"
              >
                <component :is="`icon-setting`" />
                <p>{{ $t('menu.businessPlanSetting') }}</p>
              </router-link>
            </li>
          </ul>
        </template>
      </nav>
    </div>
    <div class="closedMenu__block">
      <nav class="block__menuInfo">
        <ul>
          <template v-for="(menu, index) in referenceMenus">
            <li
              v-if="!(menu.hideFreePlan && plan === 'Free')"
              :key="index"
              class="block__menuInfo--menu"
            >
              <a
                :href="menu.link"
                target="_blank"
                rel="noreferrer"
                class="block__menuInfo--link --referenceMenu"
              >
                <component :is="`Link${menu.icon}`" />
                {{ menu.label }}
              </a>
            </li>
          </template>
          <li>
            <CogIcon
              @click="$store.dispatch('currentView/showSettingsModal')"
            />
          </li>
        </ul>
      </nav>
    </div>

    <settings-modal ref="modal" />
  </header>
</template>

<script>
import YearController from './YearController'
import LinkProjectionDB from '@/assets/svg/LinkProjectiondb.svg'
import LinkKnowledge from '@/assets/svg/ListKnowledge.svg'
import LinkHelp from '@/assets/svg/LinkHelp.svg'
import LinkFeedback from '@/assets/svg/LinkFeedback.svg'
import CogIcon from '@/assets/svg/Settings.svg'
import IconPl from '@/assets/svg/NavAnnual.svg'
import IconMonthlyPl from '@/assets/svg/NavMonthly.svg'
import IconKpi from '@/assets/svg/NavAction.svg'
import IconRevenue from '@/assets/svg/NavRevenue.svg'
import IconCost from '@/assets/svg/NavCost.svg'
import IconDashboard from '@/assets/svg/NavDashboard.svg'
import IconSetting from '@/assets/svg/NavSetting.svg'
import TripleArrowIcon from '@/assets/svg/TripleArrow.svg'
import PlusIcon from '@/assets/svg/PlusThick.svg'
import businessPlan from '@/util/businessPlan'
import SettingsModal from '../Settings/SettingsModal.vue'

export default {
  name: 'ClosedMenu',
  components: {
    LinkProjectionDB,
    LinkKnowledge,
    LinkHelp,
    LinkFeedback,
    CogIcon,
    IconPl,
    IconMonthlyPl,
    IconKpi,
    IconRevenue,
    IconCost,
    IconDashboard,
    IconSetting,
    TripleArrowIcon,
    YearController,
    PlusIcon,
    SettingsModal,
  },
  data() {
    return {
      isShowBusinessPlans: false,
      managementMenus: [
        {
          label: this.$t('menu.dashboard'),
          name: 'Dashboard',
          icon: 'dashboard',
        },
        {
          label: this.$t('menu.monthlyPl'),
          name: 'MonthlyPl',
          icon: 'monthly-pl',
        },
      ],
      budgetingMenus: [
        {
          label: this.$t('menu.pl'),
          name: 'PL',
          icon: 'pl',
        },
        {
          label: this.$t('menu.kpi'),
          name: 'Kpi',
          icon: 'kpi',
        },
        {
          label: this.$t('menu.revenue'),
          name: 'Revenue',
          icon: 'revenue',
        },
        {
          label: this.$t('menu.cost'),
          name: 'Expenses',
          icon: 'cost',
        },
      ],
      referenceMenus: [
        {
          label: this.$t('menu.knowledge'),
          link: 'https://www.notion.so/Knowledge-Page-0b4f7303a2444d47a453b46c816741ae',
          icon: 'Knowledge',
          hideFreePlan: true,
        },
        {
          label: this.$t('menu.help'),
          link: 'https://www.notion.so/onecapital/Tutorial-projection-ai-a88dfb124e0a46128673db58436691eb',
          icon: 'Help',
          hideFreePlan: false,
        },
        {
          label: this.$t('menu.feedback'),
          link: this.$t('menu.upvoty'),
          icon: 'Feedback',
          hideFreePlan: false,
        },
        {
          label: this.$t('menu.database'),
          link: 'https://db-lp.projection-ai.com/',
          icon: 'ProjectionDB',
          hideFreePlan: false,
        },
      ],
    }
  },
  computed: {
    businessPlans() {
      return this.$store.getters['me/businessPlans']
    },
    businessPlan() {
      return this.$store.getters['me/businessPlan']
    },
    currentBusinessPlanId() {
      return this.$store.getters['currentView/businessPlanId']
    },
    plan() {
      return this.$store.getters['me/user'].plan.plan
    },
    canCreate() {
      return businessPlan.canCreateBusinessPlan(
        this.$store.getters['me/user'].plan,
        this.businessPlans
      )
    },
  },
  methods: {
    isActiveBusinessPlan(businessPlanId) {
      return businessPlanId === this.currentBusinessPlanId
    },
    isActiveMenu(routeName) {
      return this.$route.name === routeName
    },
    changeBusinessPlan(business_plan_id) {
      this.$emit('change:businessPlan', business_plan_id)
      this.isShowBusinessPlans = false
    },
  },
}
</script>

<style lang="scss" scoped>
.closedMenu {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 130px;
  max-height: 100vh;
  background-color: $color-theme;
  z-index: 100;
}

.block__businessPlanInfo {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;

  .businessPlanInfo__toggle {
    background-color: transparent;

    svg {
      transform: rotate(180deg);
    }
  }

  .businessPlanInfo__block {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .businessPlanInfo__block--businessPlanName {
      margin-top: 8px;
      font-size: 1rem;
      text-align: center;
      color: $color-white;
      font-weight: bold;
    }

    .businessPlanInfo__block--icon {
      padding: 0;
      width: 44px;
      height: 44px;
      border-radius: 8px;
      border: 2px solid $color-theme;
      box-shadow: 0 0 0 2px $color-theme-light, 0 0 0 5px $color-white;
      background-color: $color-theme-light;

      img {
        width: 100%;
        border-radius: 6px;
        vertical-align: middle;
        height: 40px;
      }
    }

    .businessPlanInfo__block--list {
      position: absolute;
      top: 64px;
      left: 16px;
      z-index: 1000;
      margin-top: 8px;
      white-space: nowrap;
      background-color: $color-theme-background;
      padding: 8px 16px;
      border-radius: 8px;
    }

    .businessPlanInfo__block--button {
      background-color: transparent;
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 8px 16px;
      width: 100%;
      border-radius: 8px;
      font-weight: bold;

      img {
        border-radius: 8px;
        width: 44px;
        height: 44px;
        padding: 2px;
        border: 2px solid $color-theme-background-hover-text;
      }

      &.--active {
        background-color: $color-theme-background-hover;
      }
    }

    .businessPlanInfo__block--createButton {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 16px 16px 8px;
      width: 100%;
      margin-top: 16px;
      border-top: 1px solid $color-theme;

      svg {
        width: 40px;
        path {
          fill: $color-theme-background-hover;
        }
      }
    }
  }

  .--hide {
    visibility: hidden;
  }
}

.closedMenu__block {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  &:first-child {
    border-top: none;
  }

  &:last-child {
    margin-top: auto;
  }

  .block__menuInfo {
    .block__menuInfo--title {
      padding: 0 2px;
      color: $color-theme-text;
      opacity: 0.6;
      font-size: 1.4rem;
      margin-bottom: 8px;
      text-align: center;
      text-decoration: underline;
    }

    .block__menuInfo--menu {
      margin-bottom: 8px;
    }

    .block__menuInfo--link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2px;
      color: $color-theme-text;
      text-align: center;
      font-size: 1rem;

      &.--active,
      &:hover {
        border-radius: 8px;
        font-weight: bold;
        color: $color-theme-light-text;
        background-color: $color-theme-light;

        svg {
          path {
            fill: $color-theme-light-text;
          }
        }
      }
    }
  }
}
</style>
