import businessPlans from '@/client/businessPlans'
import cases from '@/client/cases'

const state = {
  caseIndex: 0,
  revenue: {
    mrr: 0,
    professional_service_rate: 0,
    recurring_revenue_rate: 0,
    year: 1,
    monthly: [
      {
        month: 0,
        mrr: 0,
        professional_service: 0,
        revenue: 0,
        sales: 0,
      },
    ],
  },
  csvData: {
    businessPlanName: '',
    caseName: '',
    contents: null,
  },
}

const getters = {
  caseIndex: (state) => state.caseIndex,
  revenue: (state) => state.revenue,
  csvData: (state) => state.csvData,
}

const mutations = {
  getRevenue(state, { cases, caseId, yearIndex }) {
    const currentCaseIndex = cases.findIndex((x) => x.case_id === caseId)
    const currentCaseData = cases[currentCaseIndex]
    state.caseIndex = currentCaseIndex
    state.revenue = { ...currentCaseData.revenue[yearIndex] }
  },

  getRevenueCsvData(state, { businessPlanName, caseName, csvContents }) {
    state.csvData.businessPlanName = businessPlanName
    state.csvData.caseName = caseName
    state.csvData.contents = csvContents
  },
}

const actions = {
  async getRevenue({ commit, rootState }) {
    const businessPlanId = rootState.currentView.businessPlanId
    const result = await businessPlans.getRevenue({
      business_plan_id: businessPlanId,
    })
    const cases = result.data
    const caseId = rootState.currentView.caseId
    const yearIndex = rootState.currentView.year - 1
    commit('getRevenue', { cases, caseId, yearIndex })
  },

  async getRevenueCsvData({ commit, rootState }) {
    const businessPlanId = rootState.currentView.businessPlanId
    const caseId = rootState.currentView.caseId
    const year = rootState.currentView.year

    const businessPlanName = rootState.me.businessPlan.business_plan_name
    const caseName = rootState.me.businessPlan.cases.find(
      (x) => x.case_id === caseId
    ).case_name

    const result = await businessPlans.downloadRevenueCsv({
      business_plan_id: businessPlanId,
      case_id: caseId,
      year: year,
    })
    commit('getRevenueCsvData', {
      businessPlanName,
      caseName,
      csvContents: result,
    })
  },

  async editRecurringRevenueRate({ rootState, getters, dispatch }) {
    const businessPlanId = rootState.currentView.businessPlanId
    const caseId = rootState.currentView.caseId
    const year = rootState.currentView.year

    await cases.edit({
      business_plan_id: businessPlanId,
      case_id: caseId,
      year: year,
      key: 'recurring_revenue_rate',
      value: getters.revenue.recurring_revenue_rate,
    })
    await dispatch('getRevenue')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
