<template>
  <div class="body-border" />
</template>

<script>
export default {
  name: 'border',
}
</script>

<style lang="scss" scoped>
.body-border {
  height: 2px;
  margin: 24px 0;
  background: $color-light-gray;
  border-radius: 1px;
}
</style>
