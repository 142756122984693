import Vue from 'vue'
import VueI18n from 'vue-i18n'
import lang from './lang'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: lang.browserLanguage(),
  messages: {
    ja: require('./ja.json'),
    en: require('./en.json'),
  },
})
export default i18n
