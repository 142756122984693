<template>
  <div class="paInput">
    <input
      type="text"
      v-model="inputValue"
      :maxlength="maxLength"
      :disabled="disabled"
    />
    <span v-if="unit">{{ unit }}</span>
  </div>
</template>

<script>
export default {
  name: 'PaInput',
  props: {
    value: String,
    unit: String,
    maxLength: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.paInput {
  position: relative;
  width: 100%;
  input {
    box-sizing: border-box;
    width: 100%;
    padding: 20px 60px 20px 20px;
    font-weight: bold;
    font-size: 1.8rem;
    background: $color-white;
    border: none;
    border-radius: 8px;
    outline: none;
    box-shadow: rgb(0 0 0 / 20%) 0 0 8px;
  }
  input:disabled {
    background-color: #f2f2f2;
    color: $color-gray;
  }
  span {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    height: 24px;
    margin: auto;
    font-weight: bold;
    font-size: 24px;
    line-height: 1;
  }
}
</style>
