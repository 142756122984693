<template>
  <monthly-pl-table-layout :title="title">
    <toggle-table-body title="KPI">
      <tr v-for="(kpi, index) in detailsData.kpi" :key="index">
        <template v-if="kpi[template]">
          <td class="tableDetails__row--titleCell" :class="`--lv${kpi.lv}`">
            {{ kpi.title }}
          </td>
          <template v-for="(month, index) in monthlyProgress">
            <td v-if="isShow(month)" :key="index" :class="`--lv${kpi.lv}`">
              <monthly-pl-table-cell
                v-model="month[kpi.name]"
                :editable="false"
                unit="%"
              />
            </td>
          </template>
        </template>
      </tr>
    </toggle-table-body>
    <toggle-table-body title="Profit & Loss">
      <tr v-for="(profit, index) in detailsData.profit" :key="index">
        <td
          class="tableDetails__row--titleCell"
          :class="[`--lv${profit.lv}`, { '--arr': profit.name === 'arr' }]"
        >
          {{ profit.title }}
          <span v-if="profit.showUnit">
            （{{ unitString(currency, 'yen') }}）
          </span>
        </td>
        <template v-for="(month, index) in monthlyProgress">
          <td
            v-if="isShow(month)"
            :key="index"
            :class="[`--lv${profit.lv}`, { '--arr': profit.name === 'arr' }]"
          >
            <monthly-pl-table-cell
              v-model="month[profit.name]"
              :editable="false"
              unit="%"
              :is-sign-inversion="profit.signInversion"
            />
          </td>
        </template>
      </tr>
    </toggle-table-body>
    <toggle-table-body title="Cash Flow">
      <tr v-for="(cash, index) in detailsData.cash" :key="index">
        <td class="tableDetails__row--titleCell" :class="`--lv${cash.lv}`">
          {{ cash.title }}<span>（{{ unitString(currency, 'yen') }}）</span>
        </td>
        <template v-for="(month, index) in monthlyProgress">
          <td v-if="isShow(month)" :key="index" :class="`--lv${cash.lv}`">
            <monthly-pl-table-cell
              v-model="month[cash.name]"
              :editable="false"
              unit="%"
            />
          </td>
        </template>
      </tr>
    </toggle-table-body>
  </monthly-pl-table-layout>
</template>

<script>
import MonthlyPlTableLayout from './MonthlyPlTableLayout.vue'
import ToggleTableBody from './ToggleTableBody'
import currencyUtil from '../../util/currency'
import MonthlyPlTableCell from './MonthlyPlTableCell'

export default {
  name: 'MonthlyProgress',
  components: { MonthlyPlTableCell, ToggleTableBody, MonthlyPlTableLayout },
  inject: ['displayFilter'],
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      /* eslint-disable */
      detailsData: {
        kpi: [
          { lv: 1, name: 'session', title: this.$t('monthlyPl.session'), saas: true, selfserve: true },
          { lv: 1, name: 'lead', title: this.$t('monthlyPl.lead'), saas: true, selfserve: false },
          { lv: 2, name: 'lead_cvr', title: this.$t('monthlyPl.leadRate'), saas: true, selfserve: false },
          { lv: 1, name: 'meeting', title: this.$t('monthlyPl.meeting'), saas: true, selfserve: false },
          { lv: 2, name: 'meeting_cvr', title: this.$t('monthlyPl.meetingRate'), saas: true, selfserve: false },
          { lv: 1, name: 'registration', title: this.$t('monthlyPl.registration'), saas: false, selfserve: true },
          { lv: 2, name: 'registration_cvr', title: this.$t('monthlyPl.registrationRate'), saas: false, selfserve: true },
          { lv: 1, name: 'order', title: this.$t('monthlyPl.newCustomer'), saas: true, selfserve: true },
          { lv: 2, name: 'order_cvr', title: this.$t('monthlyPl.newCustomerRate'), saas: true, selfserve: true },
          { lv: 1, name: 'churn', title: this.$t('monthlyPl.churn'), saas: true, selfserve: true },
          { lv: 2, name: 'churn_cvr', title: this.$t('monthlyPl.churnRate'), saas: true, selfserve: true },
          { lv: 1, name: 'cumulative', title: this.$t('monthlyPl.totalCustomer'), saas: true, selfserve: true },
          { lv: 1, name: 'arpa', title: this.$t('monthlyPl.arpa'), saas: true, selfserve: true },
          { lv: 1, name: 'ltv', title: this.$t('monthlyPl.ltv'), saas: true, selfserve: true },
          { lv: 1, name: 'cac', title: this.$t('monthlyPl.cac'), saas: true, selfserve: true },
          { lv: 1, name: 'ltv_cac', title: this.$t('monthlyPl.ltvCac'), saas: true, selfserve: true },
          { lv: 1, name: 'burn_multiple', title: this.$t('monthlyPl.burnMultiple'), saas: true, selfserve: true },
        ],
        profit: [
          { lv: 1, name: 'arr', title: this.$t('monthlyPl.arr'), showUnit: true, signInversion: false },
          { lv: 1, name: 'revenue', title: this.$t('monthlyPl.sales'), showUnit: true, signInversion: false },
          { lv: 2, name: 'revenue_rate', title: this.$t('monthlyPl.growthRate'), showUnit: false, signInversion: false },
          { lv: 3, name: 'mrr', title: this.$t('monthlyPl.mrr'), showUnit: true, signInversion: false },
          { lv: 3, name: 'professional_service', title: this.$t('monthlyPl.professionalService'), showUnit: true, signInversion: false },
          { lv: 1, name: 'cogs', title: this.$t('monthlyPl.cogs'), showUnit: true, signInversion: true },
          { lv: 2, name: 'cogs_rate', title: this.$t('monthlyPl.cogsRate'), showUnit: false, signInversion: false },
          { lv: 1, name: 'gross_profit', title: this.$t('monthlyPl.grossProfit'), showUnit: true, signInversion: false },
          { lv: 2, name: 'gross_profit_rate', title: this.$t('monthlyPl.grossProfitRate'), showUnit: false, signInversion: false },
          { lv: 1, name: 'operating_expense', title: this.$t('monthlyPl.operatingExpense'), showUnit: true, signInversion: true },
          { lv: 2, name: 'operating_expense_rate', title: this.$t('monthlyPl.operatingExpenseRate'), showUnit: false, signInversion: false },
          { lv: 3, name: 'sm', title: this.$t('monthlyPl.sm'), showUnit: true, signInversion: true },
          { lv: 4, name: 'sm_rate', title: this.$t('monthlyPl.smRate'), showUnit: false, signInversion: false },
          { lv: 3, name: 'rd', title: this.$t('monthlyPl.rd'), showUnit: true, signInversion: true },
          { lv: 4, name: 'rd_rate', title: this.$t('monthlyPl.rdRate'), showUnit: false, signInversion: false },
          { lv: 3, name: 'ga', title: this.$t('monthlyPl.ga'), showUnit: true, signInversion: true },
          { lv: 4, name: 'ga_rate', title: this.$t('monthlyPl.gaRate'), showUnit: false, signInversion: false },
          { lv: 1, name: 'operating_income', title: this.$t('monthlyPl.operatingIncome'), showUnit: true, signInversion: false },
          { lv: 2, name: 'operating_income_rate', title: this.$t('monthlyPl.operatingIncomeRate'), showUnit: false, signInversion: false },
          { lv: 1, name: 'employees', title: `${this.$t('monthlyPl.employees')}（${this.$t('monthlyPl.employeesUnit')}）`, showUnit: false, signInversion: false },
        ],
        cash: [
          { lv: 1, name: 'beginning_cash_balance', title: this.$t('monthlyPl.cashBalance.beginning') },
          { lv: 1, name: 'net_cash_movement', title: this.$t('monthlyPl.cashBalance.diff') },
          { lv: 1, name: 'other', title: this.$t('monthlyPl.cashBalance.other') },
          { lv: 1, name: 'debt_payment', title: this.$t('monthlyPl.cashBalance.debtPayment') },
          { lv: 1, name: 'debt_raised', title: this.$t('monthlyPl.cashBalance.debtRaised') },
          { lv: 1, name: 'equity_raised', title: this.$t('monthlyPl.cashBalance.equityRaised') },
          { lv: 1, name: 'ending_cash_balance', title: this.$t('monthlyPl.cashBalance.end') },
        ]
      },
    }
  },
  computed: {
    template() {
      const businessPlan = this.$store.getters['me/businessPlan']
      return businessPlan.template
    },
    currency() {
      const businessPlan = this.$store.getters['me/businessPlan']
      return businessPlan.currency
    },
    monthlyProgress() {
      return this.$store.getters['monthlyPl/monthlyProgress']
    },
    isEditingFlags() {
      return this.$store.getters['monthlyPl/isEditingFlags']
    },
  },
  methods: {
    unit(lv) {
      return lv === 2 || lv === 4 ? '%' : ''
    },
    unitString(currency, unitString) {
      return currencyUtil.unit(currency, unitString)
    },
    isShow(month) {
      if (month.monthType === 'month') {
        return this.displayFilter().months
      }
      if (month.monthType === 'quarter') {
        return this.displayFilter().quarter
      }
      if (month.monthType === 'cy') {
        return this.displayFilter().cy
      }
    },
    isEditableCell(monthIndex, isEditable, isFirstMonthOnly) {
      if (monthIndex === 0) {
        return isEditable
      }
      return isEditable && !isFirstMonthOnly
    }
  }
}
</script>

<style lang="scss" scoped>
tbody {
  tr td {
    font-weight: bold;
    text-align: right;
    padding: 4px 0;
    border-bottom: 1px solid #f2f2f2;
  }

  tr:hover td {
    color: $color-theme;
    background-color: $color-light-base;
  }
}

.tableDetails__row--titleCell {
  position: sticky;
  z-index: 1;
  left: 0;
  background-color: $color-white;
  text-align: left;
  font-weight: bold;
  text-indent: 2em;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -50px;
    width: 50px;
    height: 100%;
    border-left: 1px solid #daeeff;
    background: linear-gradient(
        90deg,
        rgba(20, 32, 38, 0.07) 0%,
        rgba(0, 0, 0, 0) 100%
    );
  }

  &.--lv3,
  &.--lv4 {
    text-indent: 3em;
    &:before {
      content: '-'
    }
  }
}

.--arr {
  color: $color-arr;
}

.--lv2,
.--lv4 {
  color: $color-gray;
}
</style>
