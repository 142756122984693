<template>
  <modal name="ConnectGoogleSpreadSheetModal" width="660" height="auto">
    <div class="modalContainer">
      <h2 class="modalContainer__title">
        <spread-sheet-icon />
        {{ $t('monthlyPl.googleSpreadSheet.title') }}
      </h2>
      <template v-if="!spreadSheet.google_account">
        <div class="modalContainer__inner">
          <p
            class="modalContainer__inner--text"
            v-html="$t('monthlyPl.googleSpreadSheet.description')"
          />
          <div class="modalContainer__inner--link">
            <HelpIcon />
            <a
              href="https://onecapital.notion.site/51f32b04eed94cc4b972c40d66307f7a"
              target="_blank"
            >
              {{ $t('monthlyPl.googleSpreadSheet.link') }}
            </a>
          </div>
          <img
            src="/static/img/connect-spread-sheet.png"
            alt=""
            class="modalContainer__inner--image"
          />
        </div>
        <p class="modalContainer__connectText">
          {{ $t('monthlyPl.googleSpreadSheet.connect') }}
        </p>
        <button
          class="modalContainer__googleButton"
          @click="getSpreadSheetOauthUrl()"
        >
          <img
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
            alt=""
          />
          <span class="modalContainer__googleButton--text"
            >Sign in with Google</span
          >
        </button>
      </template>
      <template v-else>
        <div class="modalContainer__inner">
          <template v-if="!spreadSheet.spreadsheet.id">
            <connect-google-spread-sheet-button
              ref="create"
              class="modalContainer__inner--add"
              @click.native="createSpreadSheet()"
            >
              <plus-icon />
              {{ $t('monthlyPl.googleSpreadSheet.create') }}
            </connect-google-spread-sheet-button>
          </template>
          <template v-else>
            <div class="modalContainer__inner--sheets">
              <p
                v-if="resultMessage"
                class="resultMessage"
                :class="`resultMessage__${resultMessageType}`"
              >
                {{ resultMessage }}
              </p>
              <h3 class="sheets__title">{{ spreadSheet.spreadsheet.name }}</h3>
              <a
                :href="spreadSheet.spreadsheet.url"
                target="_blank"
                class="sheets__link"
              >
                <target-blank-icon />
                {{ $t('monthlyPl.googleSpreadSheet.open') }}
              </a>
              <connect-google-spread-sheet-button
                ref="export"
                class="sheets__export"
                @click.native="exportSpreadSheet()"
              >
                <export-icon />
                {{ $t('monthlyPl.googleSpreadSheet.export') }}
              </connect-google-spread-sheet-button>
              <connect-google-spread-sheet-button
                ref="import"
                class="sheets__import"
                @click.native="importSpreadSheet()"
              >
                <import-icon />
                {{ $t('monthlyPl.googleSpreadSheet.import') }}
              </connect-google-spread-sheet-button>
              <p class="sheets__distance">
                {{
                  $t('monthlyPl.googleSpreadSheet.modified', {
                    modified: formatDistanceToNow(
                      spreadSheet.spreadsheet.modified
                    ),
                  })
                }}
              </p>
              <button
                class="sheets__disconnect"
                @click="disconnectSpreadSheet()"
              >
                <delete-icon />
                <span>
                  {{ $t('monthlyPl.googleSpreadSheet.disconnect') }}
                  <small>
                    {{ $t('monthlyPl.googleSpreadSheet.disconnectNotice') }}
                  </small>
                </span>
              </button>
            </div>
          </template>
          <div class="modalContainer__inner--account">
            <div class="account__container">
              <img
                :src="spreadSheet.google_account.picture"
                alt=""
                class="account__image"
              />
              <div>
                <p class="account__name">
                  {{ spreadSheet.google_account.name }}
                </p>
                <p class="account__email">
                  {{ spreadSheet.google_account.email }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </modal>
</template>

<script>
import SpreadSheetIcon from '@/assets/svg/SpreadSheetIcon.svg'
import TargetBlankIcon from '@/assets/svg/TargetBlankIcon.svg'
import ExportIcon from '@/assets/svg/ExportIcon.svg'
import ImportIcon from '@/assets/svg/ImportIcon.svg'
import PlusIcon from '@/assets/svg/Plus.svg'
import DeleteIcon from '@/assets/svg/DeleteIcon.svg'
import HelpIcon from '@/assets/svg/HelpIcon.svg'
import { formatDistanceToNow } from 'date-fns'
import { ja, enUS } from 'date-fns/esm/locale'
import ConnectGoogleSpreadSheetButton from './ConnectGoogleSpreadSheetButton'
import lang from '../../locales/lang'

export default {
  name: 'ConnectGoogleSpreadSheetModal',
  components: {
    ConnectGoogleSpreadSheetButton,
    SpreadSheetIcon,
    PlusIcon,
    TargetBlankIcon,
    ExportIcon,
    ImportIcon,
    DeleteIcon,
    HelpIcon,
  },
  data() {
    return {
      resultMessage: '',
      resultMessageType: '',
    }
  },
  computed: {
    spreadSheet() {
      return this.$store.getters['monthlyPl/spreadSheet']
    },
  },
  methods: {
    async showModal() {
      await this.getSpreadSheet()
      this.$modal.show('ConnectGoogleSpreadSheetModal')
    },
    hideModal() {
      this.$modal.hide('ConnectGoogleSpreadSheetModal')
    },
    formatDistanceToNow(date) {
      const locale = lang.browserLanguage() === 'ja' ? ja : enUS
      return formatDistanceToNow(new Date(date), { locale })
    },
    async getSpreadSheet() {
      await this.$store.dispatch('monthlyPl/getSpreadSheet')
    },
    async getSpreadSheetOauthUrl() {
      await this.$store.dispatch('monthlyPl/getSpreadSheetOauthUrl')
      localStorage.setItem(
        'currentBusinessPlanId',
        this.$store.getters['currentView/businessPlanId']
      )
      window.location.href = this.$store.getters['monthlyPl/spreadSheetAuthUrl']
    },
    async createSpreadSheet() {
      try {
        await this.$store.dispatch('monthlyPl/createSpreadSheet')
        this.showResultMessage(
          'success',
          this.$t('monthlyPl.googleSpreadSheet.createComplete')
        )
      } catch (e) {
        this.showResultMessage(
          'error',
          this.$t('monthlyPl.googleSpreadSheet.error')
        )
      }
    },
    async exportSpreadSheet() {
      try {
        await this.$store.dispatch('monthlyPl/exportSpreadSheet')
        this.showResultMessage(
          'success',
          this.$t('monthlyPl.googleSpreadSheet.exportComplete')
        )
      } catch (e) {
        this.showResultMessage(
          'error',
          this.$t('monthlyPl.googleSpreadSheet.error')
        )
      } finally {
        this.$refs.export.hideLoading()
      }
    },
    async importSpreadSheet() {
      try {
        await this.$store.dispatch('monthlyPl/importSpreadSheet')
        this.showResultMessage(
          'success',
          this.$t('monthlyPl.googleSpreadSheet.importComplete')
        )
      } catch (e) {
        this.showResultMessage(
          'error',
          this.$t('monthlyPl.googleSpreadSheet.error')
        )
      } finally {
        this.$refs.import.hideLoading()
      }
    },
    async disconnectSpreadSheet() {
      await this.$store.dispatch('monthlyPl/disconnectSpreadSheet')
    },
    showResultMessage(type, message) {
      this.resultMessageType = type
      this.resultMessage = message
      setTimeout(() => {
        this.resultMessageType = ''
        this.resultMessage = ''
      }, 3000)
    },
  },
}
</script>

<style lang="scss" scoped>
.modalContainer {
  padding: 32px;
}

.modalContainer__title {
  display: flex;
  gap: 8px;
  align-items: center;
}

.modalContainer__inner {
  text-align: left;
  padding: 24px 0 40px;
}

.modalContainer__inner--text {
  margin-bottom: 4px;
}

.modalContainer__inner--link {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 32px;
  font-size: 1.3rem;

  svg {
    width: 16px;
    path {
      fill: $color-theme;
    }
  }
  a {
    border-bottom: 1px solid $color-theme;
  }
}

.modalContainer__inner--image {
  width: 100%;
}

.modalContainer__inner--add {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  background-color: $color-white;
  border: 1px solid $color-light-gray;
  border-radius: 4px;
  padding: 16px;
  font-size: 1.6rem;
  font-weight: bold;

  &:hover {
    background-color: $color-light-gray;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.modalContainer__inner--sheets {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 16px;
  border: 1px solid $color-light-gray;
  border-radius: 4px;

  .sheets__title {
    font-size: 1.8rem;
    margin-bottom: 16px;
  }

  .sheets__link,
  .sheets__export,
  .sheets__import,
  .sheets__disconnect {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: left;
    padding: 16px;
    border-radius: 4px;
  }

  .sheets__export,
  .sheets__import {
    background-color: $color-white;
    border: 1px solid $color-light-gray;

    &:hover {
      background-color: $color-light-gray;
    }
  }

  .sheets__link {
    color: $color-white;
    background-color: #34a853;

    &:hover {
      opacity: 0.8;
    }
  }

  .sheets__distance {
    font-size: 1.3rem;
    text-align: right;
  }

  .sheets__disconnect {
    margin-top: 24px;
    color: $color-oi;
    border: 1px solid $color-oi;
    background-color: #fdeeee;
  }
}

.modalContainer__inner--account {
  display: table;
  margin: 32px auto -32px;
  border: 1px solid $color-light-gray;
  border-radius: 4px;
}

.account__container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;

  .account__image {
    border-radius: 50%;
    width: 40px;
  }

  .account__name {
    font-weight: bold;
  }

  .account__email {
    font-size: 12px;
  }
}

.modalContainer__connectText {
  font-size: 1.2rem;
  font-weight: bold;
  color: $color-gray;
}

.modalContainer__googleButton {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 8px auto 0;
  background-color: $color-white;
  border: 0;
  outline: none;
  cursor: pointer;
  min-height: 40px;
  padding: 8px 16px;
  line-height: normal;
  text-align: left;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 14%) 0 2px 2px 0, rgb(0 0 0 / 20%) 0 3px 1px -2px,
    rgb(0 0 0 / 12%) 0px 1px 5px 0px;

  img {
    width: 18px;
    height: 18px;
  }
}

.modalContainer__googleButton--text {
  padding-left: 16px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
  font-size: 1.4rem;
  font-family: Roboto, sans-serif;
  vertical-align: middle;
}

.resultMessage {
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 4px;

  &__success {
    color: $color-light-blue;
    background-color: #98d9d9;
  }

  &__error {
    color: $color-oi;
    background-color: #fdeeee;
  }
}
</style>
