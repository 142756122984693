import chartUtil from './chartUtil'

export default {
  uuOptions(term) {
    return {
      chart: {
        id: 'uu',
        group: 'sales',
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#735D94', '#9685AF', '#B9AEC9'],
      plotOptions: {
        bar: {
          columnWidth: '50%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -5,
        style: {
          colors: ['#666666'],
        },
        background: {
          enabled: false,
        },
        formatter: (val) => {
          return chartUtil.formatAmount(val)
        },
      },
      stroke: {
        width: 2,
        strokeColors: ['#735D94', '#9685AF', '#B9AEC9'],
      },
      markers: {
        size: 6,
        colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF'],
        strokeColors: ['#735D94', '#9685AF', '#B9AEC9'],
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      xaxis: {
        tickPlacement: 'between',
        categories: term,
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: [
        {
          show: window.innerWidth <= 375 ? false : true,
          tickAmount: 5,
          labels: {
            style: {
              colors: '#666666',
            },
            minWidth: window.innerWidth <= 834 ? 20 : 60,
            formatter: (val) => {
              return chartUtil.formatAmount(val)
            },
          },
        },
      ],
      tooltip: {
        enabled: true,
        marker: {
          fillColors: ['#735D94', '#9685AF', '#B9AEC9'],
        },
        x: {
          show: false,
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        fontSize: '10px',
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          fillColors: ['#735D94', '#9685AF', '#B9AEC9'],
        },
        labels: {
          colors: ['#666666'],
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    }
  },

  salesOptions(term) {
    return {
      chart: {
        id: 'sales',
        group: 'sales',
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#735D94', '#9685AF', '#B9AEC9'],
      plotOptions: {
        bar: {
          columnWidth: '50%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -5,
        style: {
          colors: ['#666666'],
        },
        background: {
          enabled: false,
        },
        formatter: (val) => {
          return chartUtil.formatAmount(val)
        },
      },
      stroke: {
        width: 2,
        colors: ['#735D94', '#9685AF', '#B9AEC9'],
      },
      markers: {
        size: 6,
        colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF'],
        strokeColors: ['#735D94', '#9685AF', '#B9AEC9'],
      },
      grid: {
        show: true,
        strokeDashArray: 6,
      },
      xaxis: {
        tickPlacement: 'between',
        categories: term,
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: [
        {
          show: window.innerWidth <= 375 ? false : true,
          tickAmount: 5,
          labels: {
            style: {
              colors: '#666666',
            },
            minWidth: window.innerWidth <= 768 ? 20 : 60,
            formatter: (val) => {
              return chartUtil.formatAmount(val)
            },
          },
        },
      ],
      tooltip: {
        enabled: true,
        marker: {
          fillColors: ['#735D94', '#9685AF', '#B9AEC9'],
        },
        x: {
          show: false,
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        fontSize: '10px',
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          fillColors: ['#735D94', '#9685AF', '#B9AEC9'],
        },
        labels: {
          colors: ['#666666'],
        },
        onItemClick: {
          toggleDataSeries: true,
        },
      },
    }
  },

  selfServeOptions(term) {
    return {
      chart: {
        id: 'sales',
        group: 'sales',
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#735D94', '#9685AF', '#B9AEC9'],
      plotOptions: {
        bar: {
          columnWidth: '50%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -5,
        style: {
          colors: ['#666666'],
        },
        background: {
          enabled: false,
        },
        formatter: (val) => {
          return chartUtil.formatAmount(val)
        },
      },
      stroke: {
        width: 2,
        colors: ['#735D94', '#9685AF', '#B9AEC9'],
      },
      markers: {
        size: 6,
        colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF'],
        strokeColors: ['#735D94', '#9685AF', '#B9AEC9'],
      },
      grid: {
        show: true,
        strokeDashArray: 6,
      },
      xaxis: {
        tickPlacement: 'between',
        categories: term,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: [
        {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#666666',
            },
            minWidth: 60,
            formatter: (val) => {
              return chartUtil.formatAmount(val)
            },
          },
        },
      ],
      tooltip: {
        enabled: true,
        marker: {
          fillColors: ['#735D94', '#9685AF', '#B9AEC9'],
        },
        x: {
          show: false,
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        fontSize: '10px',
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          fillColors: ['#735D94', '#9685AF', '#B9AEC9'],
        },
        labels: {
          colors: ['#666666'],
        },
        onItemClick: {
          toggleDataSeries: true,
        },
      },
    }
  },
}
