<template>
  <button>
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1V4H1C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6H4V9C4 9.55229 4.44771 10 5 10C5.55228 10 6 9.55229 6 9V6H9C9.55229 6 10 5.55228 10 5C10 4.44772 9.55229 4 9 4H6V1Z"
        fill="white"
      ></path>
    </svg>
    {{ $t('cost.addButtonLabel') }}
  </button>
</template>

<script>
export default {
  name: 'CostAddButton',
}
</script>

<style lang="scss" scoped>
button {
  margin: 0 auto;
  width: 262px;
  height: 40px;
  font-size: 1.4rem;
  border-radius: 20px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  min-width: 96px;
  padding: 0 32px;
  color: $color-white;
  font-weight: bold;
  background: $color-theme;
  border: 1px solid transparent;
  cursor: pointer;
}
</style>
