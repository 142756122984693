<template>
  <div class="color-pickers" :class="{ isEditing: isEditingColor }">
    <div v-if="!isEditingColor" class="click-area" @click="clickBlock"></div>
    <div class="icon-wrapper">
      <IconColorPicker
        v-if="isEditingColor"
        icon="close"
        class="close"
        @click.native="$emit('editColor')"
      />
      <span class="tooltip">{{ $t('cancel') }}</span>
    </div>
    <div class="tooltip-wrapper">
      <div class="color-wrapper">
        <input type="color" :disabled="!isEditingColor" v-model="colorA1" />
      </div>
      <span class="tooltip">{{ $t('pl.arr') }}</span>
    </div>
    <div class="tooltip-wrapper">
      <div class="color-wrapper">
        <input type="color" :disabled="!isEditingColor" v-model="colorB1" />
      </div>
      <span class="tooltip">{{ $t('pl.operatingIncome') }}</span>
    </div>
    <div class="tooltip-wrapper">
      <div class="color-wrapper">
        <input type="color" :disabled="!isEditingColor" v-model="colorC1" />
      </div>
      <span class="tooltip">{{ $t('pl.grossProfit') }}</span>
    </div>
    <div class="icon-wrapper">
      <LoadingIcon v-if="isPostingColor" />
      <IconColorPicker
        v-else-if="isEditingColor"
        icon="save"
        @click.native="$emit('postColor')"
      />
      <IconColorPicker v-else icon="edit" @click.native="$emit('editColor')" />
      <span v-if="isEditingColor" class="tooltip">{{
        $t('setting.save')
      }}</span>
      <span class="tooltip --edit" v-if="!isEditingColor">{{
        $t('pl.editColor')
      }}</span>
    </div>
  </div>
</template>

<script>
import IconColorPicker from '@/components/PL/IconColorPicker'
import LoadingIcon from '@/components/LoadingIcon'

export default {
  name: 'PaTab',
  components: {
    IconColorPicker,
    LoadingIcon,
  },
  props: {
    isEditingColor: {
      type: Boolean,
      default: false,
    },
    isPostingColor: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorA1: {
      get() {
        return this.isEditingColor
          ? this.$store.getters['color/editingColors'][0][0]
          : this.$store.getters['color/colors']?.[0]?.[0]
      },
      set(newV) {
        this.$store.commit('color/changeColor', { newV: newV, index: 0 })
      },
    },
    colorB1: {
      get() {
        return this.isEditingColor
          ? this.$store.getters['color/editingColors'][0][1]
          : this.$store.getters['color/colors']?.[0]?.[1]
      },
      set(newV) {
        this.$store.commit('color/changeColor', { newV: newV, index: 1 })
      },
    },
    colorC1: {
      get() {
        return this.isEditingColor
          ? this.$store.getters['color/editingColors'][0][2]
          : this.$store.getters['color/colors']?.[0]?.[2]
      },
      set(newV) {
        this.$store.commit('color/changeColor', { newV: newV, index: 2 })
      },
    },
  },
  methods: {
    clickBlock() {
      if (this.isEditingColor) {
        return
      } else {
        this.$emit('editColor')
      }
    },
  },
}
</script>

<style scoped lang="scss">
@mixin tooltip {
  span.tooltip {
    display: block;
    position: absolute;
    padding: 2px 10px;
    background-color: $color-theme;
    color: $color-white;
    font-weight: bold;
    font-size: 14px;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    border-radius: 5px;
    white-space: nowrap;
    top: calc(100% + 15px);
    transform: translate(-50%, 0);
    transition: opacity 0.3s;
    visibility: hidden;
    z-index: 10;
    &:before {
      content: '';
      position: absolute;
      width: 7px;
      height: 7px;
      background-color: inherit;
      top: 0;
      right: 20%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
  &:hover {
    span.tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
}
.color-pickers {
  margin-left: auto;
  display: flex;
  justify-items: center;
  align-items: center;
  height: 36px;
  padding: 6px 0 6px 8px;
  border-radius: 4px;
  background-color: transparent;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s, box-shadow 0.3s, width 0.3s;
  position: relative;
  @include tooltip;
  .click-area {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    cursor: pointer;
  }
  .tooltip-wrapper {
    position: relative;
    @include tooltip;
  }
  .color-wrapper {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    border: 2px solid $color-white;
    box-shadow: 0 0 0 3px transparent;
    transition: border-color 0.3s, box-shadow 0.3s;
    input[type='color'] {
      position: absolute;
      width: 200%;
      height: 200%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
  .icon-wrapper {
    position: relative;
    @include tooltip;
    .close {
      margin-right: 10px;
    }
  }
  &:hover {
    background-color: $color-white;
  }
  &:not(.isEditing) {
    .color-pickers {
      pointer-events: none;
    }
    &:hover {
      box-shadow: 0 0 0 2px $color-theme;
      .icon-wrapper {
        span.tooltip.--edit {
          opacity: 1;
          visibility: visible;
        }
      }
      ::v-deep .color-picker-icon {
        background-color: $color-theme;
        border-radius: 0 4px 4px 0;
        svg path {
          fill: $color-white;
        }
      }
    }
  }

  &.isEditing {
    padding: 6px 0;
    background-color: $color-white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    .color-wrapper {
      border: 2px solid $color-dark-gray;
      &:hover {
        box-shadow: 0 0 0 3px rgba($color-theme, 0.3);
      }
    }
    ::v-deep .color-picker-icon {
      &.save {
        border-radius: 0 4px 4px 0;
      }
      &.close {
        border-radius: 4px 0 0 4px;
      }
    }
  }
}
</style>
