<template>
  <section class="cost__body-panel">
    <template v-if="isAccessible">
      <accordion-panel :title="$t('cost.costPlanning')">
        <template #contents>
          <cost-plan-cogs
            v-if="selectedExpense === 'cogs'"
            :cost="cost"
            :term="term"
            :currency="currency"
          />
          <cost-plan-sm
            v-else-if="selectedExpense === 'sm'"
            :cost="cost"
            :term="term"
            :currency="currency"
          />
          <cost-plan-rd
            v-else-if="selectedExpense === 'rd'"
            :cost="cost"
            :term="term"
            :currency="currency"
          />
          <cost-plan-ga
            v-else-if="selectedExpense === 'ga'"
            :cost="cost"
            :term="term"
            :currency="currency"
          />
        </template>
      </accordion-panel>
      <border />
      <accordion-panel :title="$t('cost.costDetails')">
        <template #option>
          <pa-tooltip>
            {{ $t('cost.tooltipLaborCost') }}
          </pa-tooltip>
        </template>
        <template #contents>
          <table class="expensesTable">
            <colgroup>
              <col v-for="(col, key) in cols" :key="key" :width="`${col}%`" />
            </colgroup>
            <thead>
              <tr>
                <td></td>
                <td>
                  {{ $t('cost.monthlyPrice') }}
                  <br />
                  ({{ unit(currency, 'k') }})
                </td>
                <td class="has-tooltip">
                  {{ $t('cost.period') }}
                  <pa-tooltip isAlignLeft isWider>
                    {{ $t('cost.annualCostTooltip') }}
                  </pa-tooltip>
                </td>
                <td>
                  {{ $t('cost.annualCost') }}
                  <br />
                  ({{ unit(currency, 'k') }})
                </td>
                <td v-for="(month, key) in term" :key="key">{{ month }}</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, key) in cost[selectedExpense].detail"
                :key="key"
              >
                <td>
                  <div v-if="isViewOnly">
                    <p>{{ item.item_title }}</p>
                    <p>{{ item.item_name }}</p>
                  </div>
                  <div v-else>
                    <select @change="editCost(item)" v-model="item.item_title">
                      <option
                        v-for="(option, key) in costOptions[selectedExpense]"
                        :key="key"
                        :value="option.key"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <input
                      type="text"
                      :placeholder="$t('cost.formPlaceholderName')"
                      class="name"
                      v-model="item.item_name"
                      @change="editCost(item)"
                    />
                  </div>
                </td>
                <td>
                  <pa-input
                    :max-length="6"
                    :read-only="isViewOnly"
                    v-model="item.price"
                    @change.native="editCost(item)"
                  />
                </td>
                <td>
                  <p v-if="isViewOnly">
                    {{
                      `${item.start_year}/${item.start_month} - ${item.end_year}/${item.end_month}`
                    }}
                  </p>
                  <cost-term
                    v-else
                    :start-year.sync="item.start_year"
                    :start-month.sync="item.start_month"
                    :end-year.sync="item.end_year"
                    :end-month.sync="item.end_month"
                    @change.native="editCost(item)"
                  />
                </td>
                <td>{{ item.total }}</td>
                <td v-for="(month, key) in item.monthly" :key="key">
                  {{ month | numberFormat }}
                </td>
                <td>
                  <cost-delete-button
                    v-if="!isViewOnly"
                    @click.native="clickDeleteButton(item.cost_id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </accordion-panel>
      <div class="expensesTable__detailSummary">
        <div>
          <p>
            {{ $t('cost.budget') }}：<span>{{
              budgets[selectedExpense].budget | numberFormat
            }}</span>
          </p>
          <p>
            {{ $t('cost.cumulativeCost') }}：<span>{{
              cost[selectedExpense].total | numberFormat
            }}</span>
          </p>
          <p>
            {{ $t('cost.remainingBudget') }}：<span>{{
              budgets[selectedExpense].remaining_budget | numberFormat
            }}</span>
          </p>
        </div>
        <cost-progress
          :max="budgets[selectedExpense].budget"
          :value="cost[selectedExpense].total"
          :averages="budgets[selectedExpense].budgetary_averages"
        />
      </div>
      <div v-if="!isViewOnly" class="expensesTable__addButton">
        <cost-add-button @click.native="clickAddButton" />
      </div>
      <warning-message-box v-if="isOverBudget" class="message">
        {{ $t('cost.warningMessageDetail', { expense: expensesLabel }) }}
      </warning-message-box>
      <delete-cost ref="deleteModal" @click:delete="executeDelete" />
    </template>
    <template v-else>
      <not-accessible-panel />
    </template>
  </section>
</template>

<script>
import PaTooltip from '../PaTooltip'
import CostPlanCogs from './CostPlanCogs'
import CostDeleteButton from './CostDeleteButton'
import PaInput from '../PaInput'
import CostAddButton from './CostAddButton'
import CostProgress from './CostProgress'
import CostTerm from './CostTerm'
import CostPlanSm from './CostPlanSm'
import CostPlanRd from './CostPlanRd'
import CostPlanGa from './CostPlanGa'
import AccordionPanel from '../AccordionPanel'
import Border from '../border'
import DeleteCost from '../Modal/DeleteCost'
import NotAccessiblePanel from '../Common/NotAccessiblePanel'
import WarningMessageBox from '../WarningMessageBox'
import currencyUtil from '../../util/currency'
export default {
  name: 'CostPlans',
  components: {
    WarningMessageBox,
    NotAccessiblePanel,
    DeleteCost,
    Border,
    AccordionPanel,
    CostPlanGa,
    CostPlanRd,
    CostPlanSm,
    CostTerm,
    CostProgress,
    CostAddButton,
    PaInput,
    CostDeleteButton,
    CostPlanCogs,
    PaTooltip,
  },
  props: {
    selectedExpense: String,
    cost: Object,
    roles: Object,
    term: Array,
    expenses: Array,
    currency: String,
  },
  data() {
    return {
      cols: [
        '12%',
        '6%',
        '12%',
        '6%',
        '4%',
        '4%',
        '4%',
        '4%',
        '4%',
        '4%',
        '4%',
        '4%',
        '4%',
        '4%',
        '4%',
        '4%',
        '4%',
      ],
      costOptions: {
        cogs: [
          { key: '人件費（正社員）', label: this.$t('cost.laborCost') },
          { key: 'インフラ費用', label: this.$t('cost.infrastructureCost') },
          { key: 'その他費用', label: this.$t('cost.otherExpenses') },
        ],
        sm: [
          { key: '人件費（正社員）', label: this.$t('cost.laborCost') },
          { key: '広告宣伝費', label: this.$t('cost.advertisingCost') },
          { key: 'その他費用', label: this.$t('cost.otherExpenses') },
        ],
        rd: [
          { key: '人件費（正社員）', label: this.$t('cost.laborCost') },
          { key: 'その他費用', label: this.$t('cost.otherExpenses') },
        ],
        ga: [
          { key: '人件費（正社員）', label: this.$t('cost.laborCost') },
          { key: '地代家賃', label: this.$t('cost.landRent') },
          { key: 'その他費用', label: this.$t('cost.otherExpenses') },
        ],
      },
    }
  },
  computed: {
    budgets() {
      return this.cost.budget
    },
    cogs() {
      if (typeof this.cost === 'undefined') return {}
      return this.cost.cogs
    },
    sm() {
      if (typeof this.cost === 'undefined') return {}
      return this.cost.sm
    },
    rd() {
      if (typeof this.cost === 'undefined') return {}
      return this.cost.rd
    },
    ga() {
      if (typeof this.cost === 'undefined') return {}
      return this.cost.ga
    },
    isAccessible() {
      if (this.selectedExpense === 'cogs') {
        return this.roles.cogs !== 'no_access'
      }
      if (this.selectedExpense === 'sm') {
        return this.roles.sm !== 'no_access'
      }
      if (this.selectedExpense === 'rd') {
        return this.roles.rd !== 'no_access'
      }
      if (this.selectedExpense === 'ga') {
        return this.roles.ga !== 'no_access'
      }
      return false
    },
    isViewOnly() {
      if (this.selectedExpense === 'cogs') {
        return this.roles.cogs === 'can_view'
      }
      if (this.selectedExpense === 'sm') {
        return this.roles.sm === 'can_view'
      }
      if (this.selectedExpense === 'rd') {
        return this.roles.rd === 'can_view'
      }
      if (this.selectedExpense === 'ga') {
        return this.roles.ga === 'can_view'
      }
      return true
    },
    expensesLabel() {
      return this.expenses.find((x) => x.key === this.selectedExpense).label
    },
    isOverBudget() {
      if (this.selectedExpense === 'cogs') {
        return this.budgets.cogs.budgetary_averages > 100
      }
      if (this.selectedExpense === 'sm') {
        return this.budgets.sm.budgetary_averages > 100
      }
      if (this.selectedExpense === 'rd') {
        return this.budgets.rd.budgetary_averages > 100
      }
      if (this.selectedExpense === 'ga') {
        return this.budgets.ga.budgetary_averages > 100
      }
      return false
    },
  },
  methods: {
    clickAddButton() {
      this.$emit('click:add')
    },
    clickDeleteButton(costId) {
      this.$refs.deleteModal.showModal(costId)
    },
    executeDelete(costId) {
      this.$emit('click:delete', costId)
    },
    editCost(item) {
      if (!this.validateTerm(item)) {
        this.flashMessage.error({
          title: this.$t('cost.validateTermErrorTitle'),
          message: this.$t('cost.validateTermError'),
        })
        return
      }
      const params = {
        costId: item.cost_id,
        itemTitle: item.item_title,
        itemName: item.item_name || '',
        price: item.price,
        startYear: Number(item.start_year),
        endYear: Number(item.end_year),
        startMonth: Number(item.start_month),
        endMonth: Number(item.end_month),
      }
      this.$emit('change:cost', params)
    },
    unit(currency, unit) {
      return currencyUtil.unit(currency, unit)
    },
    validateTerm(item) {
      const startYm = new Date(`${item.start_year}/${item.start_month}`)
      const endYm = new Date(`${item.end_year}/${item.end_month}`)
      return startYm <= endYm
    },
  },
}
</script>

<style lang="scss" scoped>
.cost__body-panel {
  margin-bottom: 40px;
  padding: 24px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 10%) 0 0 4px;
}
.expensesTable {
  width: 100%;
  min-width: 1000px;
  margin: 24px 0;
  color: rgb(51, 51, 51);
  text-align: center;
  border-collapse: collapse;
  thead {
    tr {
      border-style: solid;
      border-color: rgb(242, 242, 242);
      border-image: initial;
      border-width: 1px 0;
      td {
        font-weight: bold;
        font-size: 1.2rem;
        padding-top: 4px;
        padding-bottom: 4px;
        white-space: nowrap;
      }
    }
  }
  tbody {
    tr {
      border-style: solid;
      border-color: rgb(242, 242, 242);
      border-image: initial;
      border-width: 1px 0;
      td {
        justify-content: center;
        font-size: 14px;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
    select {
      appearance: none;
      box-sizing: border-box;
      min-width: 120px;
      width: calc(100% - 20px);
      margin-right: 20px;
      height: 24px;
      margin-bottom: 4px;
      padding: 2px;
      font-size: 14px;
      line-height: 14px;
      background: rgb(255, 255, 255);
      border: 1px solid rgb(202, 202, 202);
      border-radius: 4px;
      margin-left: 5px;
      @include inputElement;
    }
    .name {
      box-sizing: border-box;
      min-width: 120px;
      width: calc(100% - 20px);
      margin-right: 20px;
      margin-left: 5px;
      height: 24px;
      color: rgb(51, 51, 51);
      font-size: 14px;
      line-height: 14px;
      @include inputElement;
    }
  }
}
.expensesTable__addButton {
  margin: 40px 0;
}
.expensesTable__detailSummary {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  color: $color-black;
  font-size: 1.4rem;
  div:nth-child(1) {
    display: flex;
  }
  p {
    margin-right: 24px;
  }
  span {
    color: $color-theme;
    font-size: 1.8rem;
  }
  &-progress {
    display: flex;
    align-items: center;
    p {
      margin-right: 24px;
    }
  }
}
.message {
  margin-top: -16px;
}
.has-tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
