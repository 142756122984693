<template>
  <div class="chart">
    <div class="chart__summary">
      <div>
        <p>
          {{ $t('cost.budget') }}：<span>{{
            budgets.budget | numberFormat
          }}</span
          >（{{ unit(currency, 'k') }}）
        </p>
        <p>
          {{ $t('cost.cumulativeCost') }}：<span>{{
            sm.total | numberFormat
          }}</span
          >（{{ unit(currency, 'k') }}）
        </p>
        <p>
          {{ $t('cost.remainingBudget') }}：<span>{{
            budgets.remaining_budget | numberFormat
          }}</span
          >（{{ unit(currency, 'k') }}）
        </p>
      </div>
      <div>
        <p>
          {{ $t('cost.laborCost') }}：
          <span>{{ sm.item.sm_labor_cost | numberFormat }}</span
          >（{{ unit(currency, 'k') }}）
        </p>
        <p>
          {{ $t('cost.advertisingCost') }}：<span>{{
            sm.item.sm_ads_cost | numberFormat
          }}</span
          >（{{ unit(currency, 'k') }}）
        </p>
        <p>
          {{ $t('cost.otherExpenses') }}：<span>{{
            sm.item.sm_other_cost | numberFormat
          }}</span
          >（{{ unit(currency, 'k') }}）
        </p>
      </div>
    </div>
    <apexchart
      :options="options"
      :series="series"
      type="bar"
      width="100%"
      height="400"
    />
    <p class="chart-label">{{ $t('cost.thousandsOfYen') }}</p>
  </div>
</template>

<script>
import costChart from '../../charts/costChart'
import currencyUtil from '../../util/currency'
export default {
  name: 'CostPlanSm',
  props: {
    cost: Object,
    term: Array,
    currency: String,
  },
  computed: {
    budgets() {
      if (typeof this.cost.budget === 'undefined') return {}
      return this.cost.budget.sm
    },
    sm() {
      if (typeof this.cost === 'undefined') return {}
      return this.cost.sm
    },
    options() {
      return costChart.costSmOptions(this.term)
    },
    series() {
      return [
        {
          name: this.$t('cost.laborCost'),
          data: this.sm.item.sm_labor_cost_monthly,
        },
        {
          name: this.$t('cost.advertisingCost'),
          data: this.sm.item.sm_ads_cost_monthly,
        },
        {
          name: this.$t('cost.otherExpenses'),
          data: this.sm.item.sm_other_cost_monthly,
        },
      ]
    },
  },
  methods: {
    unit(currency, unit) {
      return currencyUtil.unit(currency, unit)
    },
  },
}
</script>

<style lang="scss" scoped>
.chart {
  position: relative;
  margin-bottom: 40px;
  &-label {
    position: absolute;
    bottom: 28px;
    left: 16px;
    font-size: 1rem;
  }
}
.chart__summary {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  div {
    display: flex;
  }
  p {
    padding: 0 4px;
    color: $color-black;
    font-size: 1.2rem;
    span {
      color: $color-theme;
      font-size: 1.4rem;
    }
  }
}
</style>
