<template>
  <section class="kpi__panel">
    <accordion-panel :title="$t('kpi.quarter.title')">
      <template #contents>
        <table class="kpi__body-table">
          <colgroup>
            <col width="20%" />
            <col width="6%" />
            <col width="14%" />
            <col width="14%" />
            <col width="14%" />
            <col width="14%" />
            <col width="14%" />
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <template v-for="(quarter, key) in quarterly">
                <th :key="key">{{ quarterText(quarter.quarter) }}</th>
              </template>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t('kpi.quarter.progress') }}</td>
              <td>
                <span>%</span>
              </td>
              <template v-for="(quarter, key) in quarterly">
                <td :key="key">{{ quarter.progress_rate | numberFormat }}</td>
              </template>
              <td></td>
            </tr>
            <tr>
              <td>{{ $t('kpi.quarter.session') }}</td>
              <td>
                <span>{{ $t('kpi.monthlyTable.case') }}</span>
              </td>
              <template v-for="(quarter, key) in quarterly">
                <td :key="key">{{ quarter.session | numberFormat }}</td>
              </template>
              <td></td>
            </tr>
            <template v-if="template === 'saas'">
              <tr>
                <td>{{ $t('kpi.quarter.lead') }}</td>
                <td>
                  <span>{{ $t('kpi.monthlyTable.case') }}</span>
                </td>
                <template v-for="(quarter, key) in quarterly">
                  <td :key="key">{{ quarter.lead | numberFormat }}</td>
                </template>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t('kpi.quarter.meeting') }}</td>
                <td>
                  <span>{{ $t('kpi.monthlyTable.case') }}</span>
                </td>
                <template v-for="(quarter, key) in quarterly">
                  <td :key="key">{{ quarter.meeting | numberFormat }}</td>
                </template>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t('kpi.quarter.order') }}</td>
                <td>
                  <span>{{ $t('kpi.monthlyTable.case') }}</span>
                </td>
                <template v-for="(quarter, key) in quarterly">
                  <td :key="key">{{ quarter.order | numberFormat }}</td>
                </template>
                <td></td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td>{{ $t('kpi.quarter.registration') }}</td>
                <td>
                  <span>{{ $t('kpi.monthlyTable.case') }}</span>
                </td>
                <template v-for="(quarter, key) in quarterly">
                  <td :key="key">{{ quarter.registration | numberFormat }}</td>
                </template>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t('kpi.quarter.paid') }}</td>
                <td>
                  <span>{{ $t('kpi.monthlyTable.case') }}</span>
                </td>
                <template v-for="(quarter, key) in quarterly">
                  <td :key="key">{{ quarter.billing | numberFormat }}</td>
                </template>
                <td></td>
              </tr>
            </template>
            <tr>
              <td>{{ $t('kpi.quarter.churn') }}</td>
              <td>
                <span>{{ $t('kpi.monthlyTable.case') }}</span>
              </td>
              <template v-for="(quarter, key) in quarterly">
                <td :key="key">{{ quarter.churn | numberFormat }}</td>
              </template>
              <td></td>
            </tr>
          </tbody>
        </table>
      </template>
    </accordion-panel>
  </section>
</template>

<script>
import AccordionPanel from '../AccordionPanel'
export default {
  name: 'KpiQuarterly',
  components: { AccordionPanel },
  props: {
    quarterly: Array,
    template: String,
  },
  methods: {
    quarterText(text) {
      return text === '年間合計' ? this.$t('kpi.quarter.total') : text
    },
  },
}
</script>

<style lang="scss" scoped>
.kpi__body-table {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
  white-space: nowrap;
  th {
    padding: 15px 5px;
    line-height: 2;
    text-align: right;
  }
  th:nth-child(1) {
    padding-left: 10px;
    text-align: left;
  }
  td {
    padding: 8px 5px 8px 24px;
    text-align: right;
    word-break: break-all;
    @include isTablet {
      padding-left: 0;
    }
    span {
      font-size: 1.2rem;
    }
  }
  td:nth-child(1) {
    text-align: left;
    font-weight: bold;
  }
  td:nth-child(n + 2) {
    text-align: right;
    border-top: 1px solid $color-light-gray;
  }
}
</style>
