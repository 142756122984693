<template>
  <button>
    <svg
      v-if="isOpen"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2929 9.70711C10.6834 10.0976 11.3166 10.0976 11.7071 9.70711C12.0976 9.31658 12.0976 8.68342 11.7071 8.29289L8.87868 5.46447C8.87258 5.45836 8.86642 5.45236 8.8602 5.44645C8.83528 5.42275 8.80943 5.40059 8.78278 5.37997C8.39055 5.07631 7.82442 5.10447 7.46444 5.46444L4.63602 8.29287C4.24549 8.68339 4.24549 9.31656 4.63602 9.70708C5.02654 10.0976 5.65971 10.0976 6.05023 9.70708L8.17155 7.58576L10.2929 9.70711Z"
        fill="#503479"
      ></path>
      <circle
        cx="8"
        cy="8"
        r="7.5"
        transform="rotate(-180 8 8)"
        stroke="#503479"
      ></circle>
    </svg>
    <svg
      v-else
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.70711 6.29289C5.31658 5.90237 4.68342 5.90237 4.29289 6.29289C3.90237 6.68342 3.90237 7.31658 4.29289 7.70711L7.12132 10.5355C7.12742 10.5416 7.13358 10.5476 7.1398 10.5536C7.16472 10.5772 7.19057 10.5994 7.21722 10.62C7.60945 10.9237 8.17558 10.8955 8.53556 10.5356L11.364 7.70713C11.7545 7.31661 11.7545 6.68344 11.364 6.29292C10.9735 5.90239 10.3403 5.90239 9.94977 6.29292L7.82845 8.41424L5.70711 6.29289Z"
        fill="#503479"
      ></path>
      <circle cx="8" cy="8" r="7.5" stroke="#503479"></circle>
    </svg>
  </button>
</template>

<script>
export default {
  name: 'PaAccordion',
  props: {
    isOpen: Boolean,
  },
}
</script>

<style lang="scss" scoped>
button {
  background-color: $color-white;
  border: 0;
  outline: none;
  cursor: pointer;

  svg {
    path {
      fill: $color-theme;
    }
    circle {
      stroke: $color-theme;
    }
  }
}
</style>
