<template>
  <div class="start">
    <div class="start__progress">
      <start-progress-en v-if="isEn" />
      <start-progress v-else />
    </div>
    <div class="start__container">
      <div class="start__form">
        <div class="start__form-box">
          <h2 class="start__form-heading">
            {{
              `${$t('create.arr')}${Number(
                arr
              ).toLocaleString()}${displayCurrency}`
            }}
          </h2>
          <input
            type="range"
            :min="arrMin[currency]"
            :max="arrMax[currency]"
            :step="arrStep[currency]"
            v-model="inputArr"
          />
        </div>
        <div class="start__form-box">
          <h2 class="start__form-heading">
            {{ $t('create.limit', { month: period }) }}
          </h2>
          <input type="range" min="1" max="12" v-model="inputPeriod" />
        </div>
        <div class="start__form-box">
          <h2 class="start__form-heading">
            {{
              `${$t('create.arpa')}${Number(
                arpa
              ).toLocaleString()}${displayCurrency}`
            }}
            <pa-tooltip>
              {{ $t('create.arpaTooltip') }}
            </pa-tooltip>
          </h2>
          <input
            type="range"
            :min="arpaMin[currency]"
            :max="arpaMax[currency]"
            :step="arpaStep[currency]"
            v-model="inputArpa"
          />
        </div>
        <div class="start__form-box">
          <h2 class="start__form-heading">
            {{ $t('create.currency') }}
          </h2>
          <div class="selectInput">
            <pa-select :options="currencies" v-model="selectedCurrency" />
          </div>
        </div>
        <div class="start__form-box">
          <h2 class="start__form-heading">
            {{ $t('create.template') }}
            <pa-tooltip>
              {{ $t('create.templateTooltip') }}
            </pa-tooltip>
          </h2>
          <pa-select :options="templates" v-model="selectedTemplate" />
        </div>
        <div class="start__form-box">
          <h2 class="start__form-heading">
            {{ $t('create.businessPlanName') }}
            <pa-tooltip>
              {{ $t('create.businessPlanNameTooltip') }}
            </pa-tooltip>
          </h2>
          <pa-input v-model="inputBusinessPlanName" :max-length="50" />
        </div>
      </div>
      <div class="start__form">
        <div class="start__from--kpi">
          <template v-if="template === 'saas'">
            <p
              v-html="
                $t('create.kpi.saas.text1', {
                  totalOrder: kpiVariable.totalOrder,
                  orderPerMonth: kpiVariable.orderPerMonth,
                  orderPerDay: kpiVariable.orderPerDay,
                })
              "
            />
            <p
              v-html="
                $t('create.kpi.saas.text2', {
                  totalMeeting: kpiVariable.totalMeeting,
                  meetingPerMonth: kpiVariable.meetingPerMonth,
                  meetingPerDay: kpiVariable.meetingPerDay,
                })
              "
            />
            <p
              v-html="
                $t('create.kpi.saas.text3', {
                  totalLead: kpiVariable.totalLead,
                  leadPerMonth: kpiVariable.leadPerMonth,
                  leadPerDay: kpiVariable.leadPerDay,
                })
              "
            />
            <p
              v-html="
                $t('create.kpi.saas.text4', {
                  totalSession: kpiVariable.totalSession,
                  sessionPerMonth: kpiVariable.sessionPerMonth,
                  sessionPerDay: kpiVariable.sessionPerDay,
                })
              "
            />
            <p
              v-html="
                $t('create.kpi.saas.text5', {
                  arrPerPerson: kpiVariable.arrPerPerson,
                  totalEmployee: kpiVariable.totalEmployee,
                })
              "
            />
          </template>
          <template v-else>
            <p
              v-html="
                $t('create.kpi.selfserve.text1', {
                  totalPaid: kpiVariable.totalPaid,
                  monthlyPaid: kpiVariable.monthlyPaid,
                  dailyPaid: kpiVariable.dailyPaid,
                })
              "
            />
            <p
              v-html="
                $t('create.kpi.selfserve.text2', {
                  totalRegistered: kpiVariable.totalRegistered,
                  monthlyRegistered: kpiVariable.monthlyRegistered,
                  dailyRegistered: kpiVariable.dailyRegistered,
                })
              "
            />
            <p
              v-html="
                $t('create.kpi.selfserve.text3', {
                  totalSession: kpiVariable.totalSession,
                  monthlySession: kpiVariable.monthlySession,
                  dailySession: kpiVariable.dailySession,
                })
              "
            />
            <p
              v-html="
                $t('create.kpi.selfserve.text4', {
                  arrPerPerson: kpiVariable.arrPerPerson,
                  totalEmployee: kpiVariable.totalEmployee,
                })
              "
            />
          </template>
          <a
            href="https://www.notion.so/onecapital/Conversion-Rate-CVR-39c1245e064b4b0c9809efc4d529dc93"
            target="_blank"
          >
            <sup>※</sup>{{ $t('create.averageCvrLink') }}
          </a>
        </div>
      </div>
    </div>
    <div class="start__form-box-button">
      <button class="button-create" :disabled="loading" @click="create">
        {{ $t('create.create') }}
      </button>
      <button
        v-if="ownerBusinessPlans.length"
        class="button-prev"
        @click="backSettings"
      >
        {{ $t('create.backSettings') }}
      </button>
      <button v-else class="button-prev" @click="prev">
        {{ $t('create.back') }}
      </button>
    </div>
  </div>
</template>

<script>
import PaSelect from '@/components/Welcome/PaSelect'
import StartProgress from '@/assets/svg/StartProgress.svg'
import StartProgressEn from '@/assets/svg/StartProgressEn.svg'
import PaTooltip from '@/components/PaTooltip'
import userStore from '@/store/user'
import PaInput from '../../components/Welcome/PaInput'
import lang from '../../locales/lang'

export default {
  name: 'Start',
  components: {
    PaInput,
    PaTooltip,
    StartProgress,
    StartProgressEn,
    PaSelect,
  },
  props: {
    arr: String,
    period: String,
    arpa: String,
    currency: String,
    template: String,
    businessPlanName: String,
    isEn: Boolean,
  },
  data() {
    return {
      templates: [
        { key: 'saas', value: this.$t('create.templates.saas') },
        { key: 'selfserve', value: this.$t('create.templates.selfServe') },
      ],
      currencies: [
        { key: 'JPY', value: 'JPY' },
        { key: 'USD', value: 'USD' },
      ],
      loading: false,
      arrMin: {
        JPY: '5000000',
        USD: '50000',
      },
      arrMax: {
        JPY: '100000000',
        USD: '1000000',
      },
      arrStep: {
        JPY: '500000',
        USD: '5000',
      },
      arpaMin: {
        JPY: '500',
        USD: '5',
      },
      arpaMax: {
        JPY: '500000',
        USD: '5000',
      },
      arpaStep: {
        JPY: '500',
        USD: '5',
      },
    }
  },
  watch: {
    currency() {
      if (this.currency === 'JPY') {
        this.inputArr = this.inputArr * 100
        this.inputArpa = this.inputArpa * 100
      } else {
        this.inputArr = this.inputArr / 100
        this.inputArpa = this.inputArpa / 100
      }
    },
  },
  computed: {
    ownerBusinessPlans() {
      return userStore
        .state()
        .businessPlans.filter((businessPlan) => businessPlan.is_owner)
    },
    inputArr: {
      get() {
        return this.arr
      },
      set(newValue) {
        this.$parent.arr = newValue
      },
    },
    inputPeriod: {
      get() {
        return this.period
      },
      set(newValue) {
        this.$parent.period = newValue
      },
    },
    inputArpa: {
      get() {
        return this.arpa
      },
      set(newValue) {
        this.$parent.arpa = newValue
      },
    },
    selectedCurrency: {
      get() {
        return this.currency
      },
      set(newValue) {
        this.$parent.currency = newValue
      },
    },
    selectedTemplate: {
      get() {
        return this.template
      },
      set(newValue) {
        this.$parent.template = newValue
      },
    },
    inputBusinessPlanName: {
      get() {
        return this.businessPlanName
      },
      set(newValue) {
        this.$parent.businessPlanName = newValue
      },
    },
    displayCurrency() {
      return this.currency === 'JPY' ? this.$t('yen') : this.$t('usd')
    },
    kpiVariable() {
      if (this.template === 'saas') {
        const totalOrder = Math.round((this.arr / this.arpa) / 12)
        const orderPerMonth = Math.round(totalOrder / this.period)
        const orderPerDay = Math.round(orderPerMonth / 30)
        const totalMeeting = Math.round(totalOrder / 0.27)
        const meetingPerMonth = Math.round(totalMeeting / this.period)
        const meetingPerDay = Math.round(meetingPerMonth / 30)
        const totalLead = Math.round(totalMeeting / 0.36)
        const leadPerMonth = Math.round(totalLead / this.period)
        const leadPerDay = Math.round(leadPerMonth / 30)
        const totalSession = Math.round(totalLead / 0.07)
        const sessionPerMonth = Math.round(totalSession / this.period)
        const sessionPerDay = Math.round(sessionPerMonth / 30)
        const totalEmployee = Math.round(
          this.arr / (this.currency === 'JPY' ? 5000000 : 50000)
        )
        return {
          totalOrder: totalOrder.toLocaleString(),
          orderPerMonth: orderPerMonth.toLocaleString(),
          orderPerDay: orderPerDay.toLocaleString(),
          totalMeeting: totalMeeting.toLocaleString(),
          meetingPerMonth: meetingPerMonth.toLocaleString(),
          meetingPerDay: meetingPerDay.toLocaleString(),
          totalLead: totalLead.toLocaleString(),
          leadPerMonth: leadPerMonth.toLocaleString(),
          leadPerDay: leadPerDay.toLocaleString(),
          totalSession: totalSession.toLocaleString(),
          sessionPerMonth: sessionPerMonth.toLocaleString(),
          sessionPerDay: sessionPerDay.toLocaleString(),
          arrPerPerson: this.arrPerPerson(),
          totalEmployee: totalEmployee.toLocaleString(),
        }
      } else {
        const totalPaid = Math.round(this.arr / this.arpa)
        const monthlyPaid = Math.round(totalPaid / this.period)
        const dailyPaid = Math.round(monthlyPaid / 30)
        const totalRegistered = Math.round(totalPaid / 0.25)
        const monthlyRegistered = Math.round(totalRegistered / this.period)
        const dailyRegistered = Math.round(monthlyRegistered / 30)
        const totalSession = Math.round(totalRegistered / 0.1)
        const monthlySession = Math.round(totalSession / this.period)
        const dailySession = Math.round(monthlySession / 30)
        const totalEmployee = Math.round(
          this.arr / (this.currency === 'JPY' ? 5000000 : 50000)
        )
        return {
          totalPaid: totalPaid.toLocaleString(),
          monthlyPaid: monthlyPaid.toLocaleString(),
          dailyPaid: dailyPaid.toLocaleString(),
          totalRegistered: totalRegistered.toLocaleString(),
          monthlyRegistered: monthlyRegistered.toLocaleString(),
          dailyRegistered: dailyRegistered.toLocaleString(),
          totalSession: totalSession.toLocaleString(),
          monthlySession: monthlySession.toLocaleString(),
          dailySession: dailySession.toLocaleString(),
          arrPerPerson: this.arrPerPerson(),
          totalEmployee: totalEmployee.toLocaleString(),
        }
      }
    },
  },
  methods: {
    prev() {
      this.$emit('click:prev')
    },
    async backSettings() {
      await this.$router.go(-1)
      await this.$store.dispatch('currentView/showSettingsModal')
    },
    create() {
      this.loading = true
      this.$emit('click:create')
    },
    arrPerPerson() {
      if (lang.browserLanguage() === 'ja') {
        if (this.currency === 'JPY') {
          return `500${this.$t('millionYen')}`
        } else {
          return `${(50000).toLocaleString()}${this.$t('usd')}`
        }
      } else {
        if (this.currency === 'JPY') {
          return `5 ${this.$t('millionYen')}`
        } else {
          return `${(50000).toLocaleString()}${this.$t('usd')}`
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.start__progress {
  margin: 24px auto 40px;

  @include isSP() {
    svg {
      width: 100%;
    }
  }
}

.start__container {
  display: flex;
  max-width: 800px;
  margin: 0 auto;
}

.start__title {
  margin-bottom: 16px;
}

.start__form {
  box-sizing: border-box;
  width: 50%;

  &:first-child {
    padding-right: 32px;
    border-right: 1px dashed $color-theme-background;
  }

  &:last-child {
    padding-left: 32px;
  }
}

.start__from--kpi {
  text-align: left;

  p {
    margin-bottom: 16px;
  }

  a {
    text-decoration: underline;
  }
}

.start__form-box {
  box-sizing: border-box;
  margin-bottom: 24px;

  ::v-deep input {
    padding: 16px 60px 16px 20px;
  }

  ::v-deep select {
    height: auto;
    padding: 16px 60px 16px 20px;
  }

  input[type='range'] {
    padding: 0;
    width: 100%;
    -webkit-appearance: none;
    cursor: pointer;
    background: #cac2d6;
    border-radius: 10px;
    height: 10px;
  }
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #503479;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}

.start__form-box-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 32px;

  button {
    width: 320px;
    height: 64px;
    font-weight: bold;
    font-size: 2rem;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 40px;
    cursor: pointer;
  }

  button:disabled {
    background: none rgb(202, 202, 202);
    cursor: default;
  }

  .button-create {
    color: $color-white;
    background: $color-theme;
    margin-bottom: 16px;
  }

  .button-prev {
    border: 2px solid $color-theme;
    color: $color-theme;
    background: $color-white;
  }
}

.start__form-heading {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 21px;
  text-align: left;
  margin-bottom: 8px;

  a {
    text-decoration: underline;
    margin-right: 4px;
  }
}

.selectInput {
  display: flex;
  ::v-deep select {
    width: 120px;
  }
}
</style>
