<template>
  <div
    class="themeColorCard"
    :class="{ '--active': isActive }"
    @click="editThemeColor"
  >
    <img
      :src="require(`@/assets/images/theme_${themeColorName}.png`)"
      :alt="`theme ${themeColorName}`"
      class="themeColorCard__image"
      :class="{ '--isLoading': isLoading }"
    />
    <LoadingIcon v-if="isLoading" class="themeColorCard__loading" />
    <span class="themeColorCard__name">
      <CheckCircle v-if="isActive" class="themeColorCard__activeIcon" />
      {{ $t(`setting.${themeColorName}ThemeColorName`) }}
    </span>
  </div>
</template>

<script>
import CheckCircle from '@/assets/svg/CheckCircle.svg'
import LoadingIcon from '@/components/LoadingIcon.vue'

export default {
  name: 'ThemeColorCard',
  components: { CheckCircle, LoadingIcon },
  props: {
    themeColorName: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    async editThemeColor() {
      try {
        this.isLoading = true
        await this.$store.dispatch('me/editThemeColor', {
          themeColor: this.themeColorName,
        })
        this.flashMessage.show({
          message: this.$t('pl.saveColorSuccess'),
          blockClass: 'flashMessage__success',
          time: 3000,
        })
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.themeColorCard {
  position: relative;
  width: 100%;
  border: 2px solid $color-smoke;
  border-radius: 8px;
  padding: 0;
  transition: border-color 0.3s;

  &:hover,
  &.--active {
    border: 2px solid $color-theme;

    .themeColorCard__name {
      border-top: 2px solid $color-theme;
    }
  }
}

.themeColorCard__image {
  width: 100%;
  border-radius: 6px 6px 0 0;

  &.--isLoading {
    opacity: 0.5;
  }
}

.themeColorCard__loading {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
}

.themeColorCard__name {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  gap: 16px;
  font-size: 16px;
  font-weight: bold;
  border-top: 2px solid $color-smoke;
  background-color: $color-white;
  border-radius: 0 0 5px 6px;
  transition: border-color 0.3s;
}

.themeColorCard__activeIcon {
  width: 20px;
  height: 20px;

  circle {
    fill: $color-theme;
  }
}
</style>
