<template>
  <div class="home-ja-container">
    <section id="section-hero" class="home-ja-section-hero section">
      <div class="home-ja-main-contants">
        <div class="home-ja-text-wrapper-1">
          <div class="home-ja-container001">
            <span class="home-ja-text003">一瞬</span>
            <span class="home-ja-text004">で作れる</span>
          </div>
          <span class="home-ja-text005">事業計画</span>
        </div>
        <div class="home-ja-text-wrapper-2">
          <span class="home-ja-text006">全てのSaaSスタートアップに</span>
          <span class="home-ja-text007">ハイグレードな</span>
          <span class="home-ja-text008">事業計画を</span>
        </div>
        <div class="home-ja-iframe-container">
          <iframe
            src="https://app.quden.io/embed/player/6235f6aeaf13cd00272321f2"
            class="home-ja-iframe"
          ></iframe>
        </div>
        <div class="home-ja-image-container"></div>
        <div class="home-ja-paragraph-container">
          <span class="home-ja-text009">
            <span>
              “projection-ai”は、以下3つのインプットを入力するだけで、
            </span>
            <br />
            <span>
              逆算されたオリジナルな事業計画が出来上がるサービスです
            </span>
          </span>
          <div class="home-ja-container002">
            <div class="home-ja-container003">
              <div class="home-ja-container004">
                <div class="home-ja-container005">
                  <span class="home-ja-text013">
                    <span>1</span>
                    <span></span>
                  </span>
                </div>
                <span class="home-ja-text016">
                  <span>ビジネスモデル</span>
                </span>
              </div>
            </div>
            <div class="home-ja-container006">
              <div class="home-ja-container007">
                <div class="home-ja-container008">
                  <span class="home-ja-text018"><span>2</span></span>
                </div>
                <span class="home-ja-text020">
                  <span>月額顧客単価</span>
                </span>
              </div>
            </div>
            <div class="home-ja-container009">
              <div class="home-ja-container010">
                <div class="home-ja-container011">
                  <span class="home-ja-text022"><span>3</span></span>
                </div>
                <span class="home-ja-text024"><span>成長倍率</span></span>
              </div>
            </div>
          </div>
          <span class="home-ja-text026">
            <span>
              目標から逆算してKPI算出を行うため、蓋然性の高い事業計画を簡単に早く制作できます
            </span>
          </span>
        </div>
        <div class="home-ja-button-set">
          <a
            :href="$t('meetingUrl')"
            target="_blank"
            rel="noreferrer noopener"
            class="home-ja-link05"
          >
            <span class="home-ja-text028">無料トライアル</span>
          </a>
        </div>
      </div>
    </section>
    <div class="home-ja-section-logo section">
      <div class="home-ja-container012">
        <img
          alt="image"
          src="/static/lp/images/logo_company01.svg"
          class="home-ja-image06"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company02.svg"
          class="home-ja-image07"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company03.svg"
          class="home-ja-image08"
        />
        <img
          alt="image"
          src="/static/lp/images/cloudbrains.svg"
          class="home-ja-image09"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company06.svg"
          class="home-ja-image10"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company07.svg"
          class="home-ja-image11"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company02.svg"
          class="home-ja-image12"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company04.svg"
          class="home-ja-image13"
        />
      </div>
      <div class="home-ja-container013">
        <img
          alt="image"
          src="/static/lp/images/logo_company05.svg"
          class="home-ja-image14"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company06.svg"
          class="home-ja-image15"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company07.svg"
          class="home-ja-image16"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company02.svg"
          class="home-ja-image17"
        />
      </div>
    </div>
    <section id="section-steps" class="home-ja-section-steps section-ai">
      <div class="home-ja-en-title-wrapper">
        <span class="home-ja-text029"><span>機能</span></span>
        <div class="home-ja-titlebar-buttom"></div>
      </div>
      <div class="home-ja-container014">
        <div class="home-ja-step-title-item">
          <div class="home-ja-container015">
            <span class="home-ja-text031 en_Heading2">1</span>
          </div>
          <span class="home-ja-text032">サインアップ</span>
        </div>
        <div class="home-ja-step-title-item1">
          <div class="home-ja-container016">
            <span class="home-ja-text033 en_Heading2">2</span>
          </div>
          <span class="home-ja-text034">ケースの作成・比較</span>
        </div>
        <div class="home-ja-step-title-item2">
          <div class="home-ja-container017">
            <span class="home-ja-text035 en_Heading2">3</span>
          </div>
          <span class="home-ja-text036">月間KPIの算出</span>
        </div>
        <div class="home-ja-step-title-item3">
          <div class="home-ja-container018">
            <span class="home-ja-text037 en_Heading2">4</span>
          </div>
          <span class="home-ja-text038">予実管理</span>
        </div>
        <div class="home-ja-step-title-item4">
          <div class="home-ja-container019">
            <span class="home-ja-text039 en_Heading2">5</span>
          </div>
          <span class="home-ja-text040">ダッシュボードの活用</span>
        </div>
      </div>
      <div class="home-ja-step-item-setup">
        <div class="home-ja-container020">
          <div class="home-ja-step-title-wrapper">
            <img
              alt="image"
              src="/static/lp/images/5-corner.svg"
              class="home-ja-image18"
            />
            <span class="home-ja-text041">サインイン</span>
          </div>
          <div class="home-ja-step-content">
            <span class="home-ja-text042">
              <span>
                Google
                Accountを選択もしくはメールアドレスをご入力頂き、ログインください。
              </span>
            </span>
          </div>
          <a
            :href="$t('meetingUrl')"
            target="_blank"
            rel="noreferrer noopener"
            class="home-ja-link06"
          >
            <span class="home-ja-text044">無料トライアル</span>
          </a>
        </div>
        <img
          alt="image"
          src="/static/lp/images/ja_01-contant-wrapper-1500w.png"
          class="home-ja-image19"
        />
      </div>
      <div class="home-ja-step-item-setup1">
        <div class="home-ja-container021">
          <div class="home-ja-step-title-wrapper1">
            <img
              alt="image"
              src="/static/lp/images/5-corner.svg"
              class="home-ja-image20"
            />
            <span class="home-ja-text045">セットアップ</span>
          </div>
          <div class="home-ja-step-content1">
            <span class="home-ja-text046">
              <span>
                氏名などの基本情報を入力したら、計画情報を入力（選択）しましょう。テンプレート、ARPA（月額顧客単価）、成長倍率、事業開始年度を入力すれば、あとは事業計画を作成するのみです。
              </span>
            </span>
          </div>
        </div>
        <img
          alt="image"
          src="/static/lp/images/ja_02-contant-wrapper-1500w.png"
          class="home-ja-image21"
        />
      </div>
      <div class="home-ja-step-item-setup2">
        <div class="home-ja-container022">
          <div class="home-ja-step-title-wrapper2">
            <img
              alt="image"
              src="/static/lp/images/5-corner.svg"
              class="home-ja-image22"
            />
            <span class="home-ja-text048">
              <span>ケースの作成・比較</span>
              <br />
              <span></span>
            </span>
          </div>
          <div class="home-ja-step-content2">
            <span class="home-ja-text051">
              <span>
                基本情報を入力すると、以下のようなPLページが自動作成されます。以下、３つの項目を入力してみましょう。
              </span>
            </span>
            <div class="home-ja-container023">
              <div class="home-ja-container024">
                <button class="home-ja-button-like content3">ARR</button>
              </div>
              <div class="home-ja-container025">
                <button class="home-ja-button-like content3">
                  Cost of Service
                </button>
              </div>
              <div class="home-ja-container026">
                <button class="home-ja-button-like content3">
                  Operating Expenses
                </button>
              </div>
            </div>
            <span class="home-ja-text053">
              <span>
                projection-aiでは、複数のケース（通常ケース、理想ケース、慎重ケースなど）を簡単に作成することができます。「ケース作成」を押し、設定値を入力することで別のケースを作成できます。
              </span>
            </span>
          </div>
        </div>
        <img
          alt="image"
          src="/static/lp/images/ja_02_2-contant-wrapper-1500w.png"
          class="home-ja-image23"
        />
      </div>
      <div class="home-ja-step-item-setup3">
        <div class="home-ja-container027">
          <div class="home-ja-step-title-wrapper3">
            <img
              alt="image"
              src="/static/lp/images/5-corner.svg"
              class="home-ja-image24"
            />
            <span class="home-ja-text055">
              <span>月間KPIの算出</span>
              <br />
              <span></span>
            </span>
          </div>
          <div class="home-ja-step-content3">
            <span class="home-ja-text058">
              <span>
                目標売上（ARR）を達成するためには、具体的なアクションプラン（KPI）へ落とし込むことが重要です。Projection-aiでは、1年間に必要なKPIを簡単にシミュレーションし、月時のメトリクスに落とし込むことができます。
              </span>
            </span>
          </div>
        </div>
        <img
          alt="image"
          src="/static/lp/images/ja_03-contant-wrapper-1500w.png"
          class="home-ja-image25"
        />
      </div>
      <div class="home-ja-step-item-setup4">
        <div class="home-ja-container028">
          <div class="home-ja-step-title-wrapper4">
            <img
              alt="image"
              src="/static/lp/images/5-corner.svg"
              class="home-ja-image26"
            />
            <span class="home-ja-text060">予実管理</span>
          </div>
          <div class="home-ja-step-content4">
            <span class="home-ja-text061">
              <span>
                月次PLを使って、目標売上（ARR）や目標KPIなどの予算に対する予実管理を簡単に行うことができます。
              </span>
            </span>
            <div class="home-ja-container029">
              <div
                class="
                  step-item-name-container step-item-name-root-class-name21
                "
              >
                <button class="step-item-name-button content3">
                  <span>Sales</span>
                </button>
              </div>
              <div
                class="
                  step-item-name-container step-item-name-root-class-name21
                "
              >
                <button class="step-item-name-button content3">
                  <span>COGS</span>
                </button>
              </div>
              <div
                class="
                  step-item-name-container step-item-name-root-class-name21
                "
              >
                <button class="step-item-name-button content3">
                  <span>S&M</span>
                </button>
              </div>
              <div
                class="
                  step-item-name-container step-item-name-root-class-name21
                "
              >
                <button class="step-item-name-button content3">
                  <span>R&D</span>
                </button>
              </div>
              <div
                class="
                  step-item-name-container step-item-name-root-class-name21
                "
              >
                <button class="step-item-name-button content3">
                  <span>G&A</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <img
          alt="image"
          src="/static/lp/images/ja_04-contant-wrapper-ver02-1500w.png"
          class="home-ja-image27"
        />
      </div>
      <div class="home-ja-step-item-setup5">
        <div class="home-ja-container030">
          <div class="home-ja-step-title-wrapper5">
            <img
              alt="image"
              src="/static/lp/images/5-corner.svg"
              class="home-ja-image28"
            />
            <span class="home-ja-text063">
              <span>ダッシュボードの活用</span>
              <br />
              <span></span>
            </span>
          </div>
          <div class="home-ja-step-content5">
            <span class="home-ja-text066">
              <span>
                必要なデータを入力すると、ダッシュボードとして活用することができます。ケースや事業年度度に現金推移、ランウェイ、MRR、ARPA、顧客数、LTV/CACなど、SaaSのKPIを管理することができます。
              </span>
            </span>
          </div>
          <div class="home-ja-container031">
            <div
              class="step-item-name-container step-item-name-root-class-name21"
            >
              <button class="step-item-name-button content3">
                <span>Sales</span>
              </button>
            </div>
            <div
              class="step-item-name-container step-item-name-root-class-name21"
            >
              <button class="step-item-name-button content3">
                <span>COGS</span>
              </button>
            </div>
            <div
              class="step-item-name-container step-item-name-root-class-name21"
            >
              <button class="step-item-name-button content3">
                <span>S&M</span>
              </button>
            </div>
            <div
              class="step-item-name-container step-item-name-root-class-name21"
            >
              <button class="step-item-name-button content3">
                <span>R&D</span>
              </button>
            </div>
            <div
              class="step-item-name-container step-item-name-root-class-name21"
            >
              <button class="step-item-name-button content3">
                <span>G&A</span>
              </button>
            </div>
          </div>
        </div>
        <img
          alt="image"
          src="/static/lp/images/ja_05-contant-wrapper-1500w.png"
          class="home-ja-image29"
        />
      </div>
    </section>
    <div class="red section-full home-ja-cv-01">
      <div class="home-ja-container032">
        <div class="home-ja-step-title-wrapper6">
          <span class="home-ja-text068">今すぐ事業計画書を作成する</span>
        </div>
        <div class="home-ja-step-content6">
          <div class="home-ja-container033"></div>
        </div>
        <a
          :href="$t('meetingUrl')"
          target="_blank"
          rel="noreferrer noopener"
          class="home-ja-link07"
        >
          <span class="home-ja-text069">無料トライアル</span>
        </a>
      </div>
    </div>
    <section id="section-feature" class="home-ja-section-feature section-ai">
      <div class="home-ja-step-container">
        <div class="home-ja-en-title-wrapper1">
          <span class="home-ja-text070"> Benefits of using projection-ai </span>
          <div class="home-ja-titlebar-buttom1"></div>
        </div>
        <div class="home-ja-container034">
          <div class="home-ja-container035">
            <div class="home-ja-container036">
              <img
                alt="image"
                src="/static/lp/images/feature01.svg"
                class="home-ja-image30"
              />
              <span class="home-ja-text071 en_Heading2">01</span>
            </div>
            <span class="home-ja-text072">
              逆算型の事業計画をミスなくスピーディーに
            </span>
            <span class="home-ja-text073">
              目標ARRから逆算して、必要なファネルKPIを自動算出することができ、目標達成に必要なアクションが見える化することで、達成できる道筋が分かる事業計画を作ることができます。
            </span>
          </div>
          <div class="home-ja-container037">
            <div class="home-ja-container038">
              <img
                alt="image"
                src="/static/lp/images/feature01.svg"
                class="home-ja-image31"
              />
              <span class="home-ja-text074 en_Heading2">02</span>
            </div>
            <span class="home-ja-text075"> 実績管理までシームレスに対応 </span>
            <span class="home-ja-text076">
              projection-aiでは、事業計画を作成するだけでなく、実績値を入力することができます。月次で予実管理することで、軌道修正しながら、効率的に目標達成に近づくことができます。
            </span>
          </div>
          <div class="home-ja-container039">
            <div class="home-ja-container040">
              <img
                alt="image"
                src="/static/lp/images/feature01.svg"
                class="home-ja-image32"
              />
              <span class="home-ja-text077 en_Heading2">03</span>
            </div>
            <span class="home-ja-text078">
              ビジネスモデルに即した包括的な指標
            </span>
            <span class="home-ja-text079">
              「解約率が1%改善したらどれくらい収益にインパクトがあるか」「有料ユーザーをX人増やすにはLPへの年間セッション数をどれくらい増やす必要があるか」といったファネル全体の目線で議論しアクションにつなげやすいです。
            </span>
          </div>
          <div class="home-ja-container041">
            <div class="home-ja-container042">
              <img
                alt="image"
                src="/static/lp/images/feature01.svg"
                class="home-ja-image33"
              />
              <span class="home-ja-text080 en_Heading2">04</span>
            </div>
            <span class="home-ja-text081">豊富なナレッジで安心</span>
            <span class="home-ja-text082">
              国内外のSaaS企業に関する成長倍率やコスト構造、人員計画などのナレッジを集約しています。また、SaaSに特化したVCであるOne
              Capital代表
              浅田の知見も取り入れているため、VCが重視するポイントの把握も可能です。
            </span>
          </div>
        </div>
      </div>
    </section>
    <section id="section-voices" class="home-ja-section-steps1">
      <div class="en-title-wrapper-en-title-wrapper">
        <span class="en-title-wrapper-text"><span>Testimonials</span></span>
        <div class="en-title-wrapper-titlebar-buttom"></div>
      </div>
      <span class="home-ja-text083">
        <span>お客さまの声</span>
        <br />
        <span></span>
      </span>
      <customer-voices />
    </section>
    <div class="home-ja-cv-02">
      <div class="home-ja-container051">
        <div class="home-ja-container052">
          <div class="home-ja-step-title-wrapper7">
            <span class="home-ja-text090">今すぐ事業計画書を作成する</span>
          </div>
          <div class="home-ja-step-content7">
            <div class="home-ja-container053"></div>
          </div>
          <a
            :href="$t('meetingUrl')"
            target="_blank"
            rel="noreferrer noopener"
            class="home-ja-link08"
          >
            <span class="home-ja-text091">無料トライアル</span>
          </a>
        </div>
        <img
          alt="image"
          src="/static/lp/images/kv02-1500w.png"
          class="home-ja-image37"
        />
      </div>
    </div>
    <section id="section-media" class="home-ja-section-media">
      <div class="home-ja-en-title-wrapper2">
        <span class="home-ja-text092"><span>Media</span></span>
        <div class="home-ja-titlebar-buttom2"></div>
      </div>
      <span class="home-ja-text094">
        <span class="home-ja-text095">メディア掲載事例</span>
      </span>
      <div class="home-ja-step-container2">
        <div class="home-ja-container054">
          <a
            href="https://thebridge.jp/2021/06/projection-ai-beta-launch"
            target="_blank"
            rel="noreferrer noopener"
            class="home-ja-link09"
          >
            <div class="home-ja-container055">
              <div class="home-ja-container056">
                <div class="home-ja-container057">
                  <img
                    alt="image"
                    src="/static/lp/images/logo.svg"
                    class="home-ja-image38"
                  />
                </div>
                <div class="home-ja-container058">
                  <span class="home-ja-text096">
                    SaaSに出資するVCが作る事業計画SaaS、「projection-ai」がβローンチ
                  </span>
                  <span class="home-ja-text097">
                    投資家が日々の投資活動で必要なプラットフォームを自ら作って世に出すことはよくある。アメリカで、スタートアップの資本構成（キャップテーブル）の管理を支援する
                    Carta は先ごろ、CVC の組成を発表
                  </span>
                  <div class="home-ja-container059">
                    <span class="home-ja-text098 en_Heading2">
                      <span>See more</span>
                    </span>
                    <img
                      alt="image"
                      src="/static/lp/images/arrow-right.svg"
                      class="home-ja-image39"
                    />
                  </div>
                </div>
              </div>
            </div>
          </a>
          <a
            href="https://jp.techcrunch.com/2021/06/07/2021%E2%88%9206-07%E2%88%92projection-ai-bata-release/"
            target="_blank"
            rel="noreferrer noopener"
            class="home-ja-link10"
          >
            <div class="home-ja-container060">
              <div class="home-ja-container061">
                <div class="home-ja-container062">
                  <img
                    alt="image"
                    src="/static/lp/images/techcrunch-min-800w.png"
                    class="home-ja-image40"
                  />
                </div>
                <div class="home-ja-container063">
                  <span class="home-ja-text100">
                    未達の事業計画を撲滅、SaaSの事業計画を「ざっくり正しく」作成する
                  </span>
                  <span class="home-ja-text101">
                    SaaSスタートアップを起業したい人の事業計画作りを支援するprojection-ai（プロジェクション・エーアイ）は6月7日、事業計画書作成サービス「projection-ai」のベータ版公開リリースを発表した。
                  </span>
                  <div class="home-ja-container064">
                    <span class="home-ja-text102 en_Heading2">
                      <span>See more</span>
                    </span>
                    <img
                      alt="image"
                      src="/static/lp/images/arrow-right.svg"
                      class="home-ja-image41"
                    />
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
    <section id="section-price" class="home-ja-section-price">
      <div class="home-ja-section-steps2 section-ai">
        <div class="home-ja-en-title-wrapper3">
          <span class="home-ja-text104"><span>料金プラン</span></span>
          <div class="home-ja-titlebar-buttom3"></div>
        </div>
        <span class="home-ja-text106">
          <span>企業規模に応じて</span>
          <br />
          <span>最適なプランをお選びいただけます</span>
        </span>
        <div class="home-ja-container065">
          <div class="home-ja-price-item">
            <div class="home-ja-item-wrapper-top">
              <img
                alt="image"
                src="/static/lp/images/free.svg"
                class="home-ja-image42"
              />
              <span class="home-ja-text109">
                <span>
                  PLをざっくり作成してみたい創業前のスタートアップにおすすめです。
                </span>
              </span>
              <div class="home-ja-container066"></div>
              <div class="home-ja-container067"></div>
              <div class="home-ja-container068">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image43"
                />
                <span class="home-ja-text111 content2">
                  <span>PLの作成 / ケース比較</span>
                </span>
              </div>
            </div>
            <div class="home-ja-container069">
              <div class="home-ja-container070"></div>
              <div class="home-ja-container071">
                <span class="home-ja-text113">¥</span>
                <span class="home-ja-text114"><span>0</span></span>
              </div>
            </div>
          </div>
          <div class="home-ja-price-item1">
            <div class="home-ja-item-wrapper-top1">
              <img
                alt="image"
                src="/static/lp/images/basic.svg"
                class="home-ja-image44"
              />
              <span class="home-ja-text116">
                <span>
                  シード/アーリーステージのスタートアップにおすすめです。
                </span>
              </span>
              <div class="home-ja-container072"></div>
              <div class="home-ja-container073"></div>
              <div class="home-ja-container074">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image45"
                />
                <span class="home-ja-text118 content2">
                  <span>PLの作成 / ケース比較</span>
                </span>
              </div>
              <div class="home-ja-container075">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image46"
                />
                <span class="home-ja-text120 content2">
                  <span>売上計画の作成</span>
                </span>
              </div>
              <div class="home-ja-container076">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image47"
                />
                <span class="home-ja-text122 content2">
                  <span>月次KPIの算出</span>
                </span>
              </div>
              <div class="home-ja-container077">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image48"
                />
                <span class="home-ja-text124 content2">
                  <span>コスト計画の作成</span>
                </span>
              </div>
              <div class="home-ja-container078">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image49"
                />
                <span class="home-ja-text126 content2">
                  <span>実績値入力及び予実管理</span>
                </span>
              </div>
              <div class="home-ja-container079">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image50"
                />
                <span class="home-ja-text128 content2">
                  <span>ダッシュボードの閲覧</span>
                </span>
              </div>
              <div class="home-ja-container080">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image51"
                />
                <span class="home-ja-text130 content2">
                  <span>ナレッジページへのアクセス</span>
                </span>
              </div>
              <div class="home-ja-container081">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image52"
                />
                <span class="home-ja-text132 content2">
                  <span>権限の詳細設定</span>
                </span>
              </div>
              <div class="home-ja-container082">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image53"
                />
                <span class="home-ja-text134 content2">
                  <span>閲覧者の追加 / 無制限</span>
                </span>
              </div>
              <div class="home-ja-container083">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image54"
                />
                <span class="home-ja-text136 content2">
                  <span>事業計画の作成支援 /</span>
                  <br />
                  <span>資金調達前の壁打ち</span>
                </span>
              </div>
              <span class="home-ja-text139">
                <span>※年に1回：60分まで</span>
              </span>
              <div class="home-ja-container084">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image55"
                />
                <span class="home-ja-text141 content2">
                  <span>事業計画書の作成 / 2つまで</span>
                </span>
              </div>
            </div>
            <div class="home-ja-container085">
              <div class="home-ja-container086"></div>
              <div class="home-ja-container087">
                <span class="home-ja-text143">¥</span>
                <span class="home-ja-text144">
                  <span>
                    <span>{{
                      isMonthly
                        ? $t('price.monthly.basic')
                        : $t('price.yearly.basic')
                    }}</span>
                  </span>
                </span>
                <span class="home-ja-text146">/月(税込)</span>
              </div>
            </div>
          </div>
          <div class="home-ja-price-item2">
            <div class="home-ja-item-wrapper-top2">
              <img
                alt="image"
                src="/static/lp/images/team.svg"
                class="home-ja-image56"
              />
              <span class="home-ja-text147">
                <span>
                  ミドルステージ以降のスタートアップにおすすめです。
                </span>
              </span>
              <div class="home-ja-container088"></div>
              <div class="home-ja-container089"></div>
              <div class="home-ja-container090">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image57"
                />
                <span class="home-ja-text149 content2">
                  <span>PLの作成 / ケース比較</span>
                </span>
              </div>
              <div class="home-ja-container091">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image58"
                />
                <span class="home-ja-text151 content2">
                  <span>売上計画の作成</span>
                </span>
              </div>
              <div class="home-ja-container092">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image59"
                />
                <span class="home-ja-text153 content2">
                  <span>月次KPIの算出</span>
                </span>
              </div>
              <div class="home-ja-container093">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image60"
                />
                <span class="home-ja-text155 content2">
                  <span>コスト計画の作成</span>
                </span>
              </div>
              <div class="home-ja-container094">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image61"
                />
                <span class="home-ja-text157 content2">
                  <span>実績値入力及び予実管理</span>
                </span>
              </div>
              <div class="home-ja-container095">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image62"
                />
                <span class="home-ja-text159 content2">
                  <span>ダッシュボードの閲覧</span>
                </span>
              </div>
              <div class="home-ja-container096">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image63"
                />
                <span class="home-ja-text161 content2">
                  <span>ナレッジページへのアクセス</span>
                </span>
              </div>
              <div class="home-ja-container097">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image64"
                />
                <span class="home-ja-text163 content2">
                  <span>権限の詳細設定</span>
                </span>
              </div>
              <div class="home-ja-container098">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image65"
                />
                <span class="home-ja-text165 content2">
                  <span>閲覧者の追加 / 無制限</span>
                </span>
              </div>
              <div class="home-ja-container099">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image66"
                />
                <span class="home-ja-text167 content2">
                  <span>編集者の追加 / 4名まで</span>
                </span>
              </div>
              <div class="home-ja-container100">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image67"
                />
                <span class="home-ja-text169 content2">
                  <span>事業計画の作成支援 /</span>
                  <br />
                  <span>資金調達前の壁打ち</span>
                </span>
              </div>
              <span class="home-ja-text172">
                <span>※年に1回：60分まで</span>
              </span>
              <div class="home-ja-container101">
                <img
                  alt="image"
                  src="/static/lp/images/notification-icon.svg"
                  class="home-ja-image68"
                />
                <span class="home-ja-text174 content2">
                  <span>事業計画書の作成 / 3つまで</span>
                </span>
              </div>
            </div>
            <div class="home-ja-container102">
              <div class="home-ja-container103"></div>
              <div class="home-ja-container104">
                <span class="home-ja-text176">¥</span>
                <span class="home-ja-text177">
                  <span>
                    {{
                      isMonthly
                        ? $t('price.monthly.team')
                        : $t('price.yearly.team')
                    }}
                  </span>
                </span>
                <span class="home-ja-text179">/月(税込)</span>
              </div>
            </div>
          </div>
        </div>
        <div class="plan__header">
          <button class="plan__header-switch" @click="isMonthly = !isMonthly">
            <span>{{ $t('lp.price.monthlyPrice') }}</span>
            <switch-off v-if="isMonthly" />
            <switch-on v-else />
            <span>{{ $t('lp.price.annualPrice') }}（15% OFF）</span>
          </button>
        </div>
      </div>
    </section>
    <div class="red section-full home-ja-cv-03">
      <div class="home-ja-container105">
        <div class="home-ja-step-title-wrapper8">
          <span class="home-ja-text180">今すぐ事業計画書を作成する</span>
        </div>
        <div class="home-ja-step-content8">
          <div class="home-ja-container106"></div>
        </div>
        <a
          :href="$t('meetingUrl')"
          target="_blank"
          rel="noreferrer noopener"
          class="home-ja-link11"
        >
          <span class="home-ja-text181">無料トライアル</span>
        </a>
      </div>
    </div>
    <section id="section-product" class="home-ja-section-product section-ai">
      <div class="home-ja-container107">
        <div class="home-ja-en-title-wrapper4">
          <span class="home-ja-text182"
            ><span>SaaS産業情報データベース</span></span
          >
          <div class="home-ja-titlebar-buttom4"></div>
        </div>
        <span class="home-ja-text184">
          <span>起業家の本質を解決する</span>
          <br />
          <span>プロダクト</span>
        </span>
      </div>
      <div class="home-ja-step-container3">
        <div class="home-ja-container108">
          <div class="home-ja-container109">
            <div class="home-ja-container110">
              <span class="home-ja-text187">projection-ai:db</span>
              <span class="home-ja-text188">
                <span class="home-ja-text189">
                  projection-ai:dbは、SaaS市場に特化したデータベースです。SaaS市場規模や上場企業のマルチプル、SaaSメトリクスなど、SaaSに関わるあらゆる情報が集約されています。VCをはじめとした投資家や上場企業のIR担当者のリサーチコストを大幅に削減します。
                </span>
              </span>
              <div class="home-ja-container111">
                <div class="home-ja-container112">
                  <a
                    href="https://db.projection-ai.com/"
                    target="_blank"
                    rel="noreferrer noopener"
                    class="home-ja-link12 en_Heading2"
                  >
                    <span>See more</span>
                  </a>
                  <img
                    alt="image"
                    src="/static/lp/images/arrow-right.svg"
                    class="home-ja-image69"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="home-ja-container109">
            <div class="home-ja-container110">
              <span class="home-ja-text187">projection-ai:blog</span>
              <span class="home-ja-text188">
                <span class="home-ja-text189">
                  projection-ai:blogは、国内外のSaaSに関する情報を、より分かりやすく、よりオープンにするメディアです。
                </span>
              </span>
              <div class="home-ja-container111">
                <div class="home-ja-container112">
                  <a
                    href="https://blog.projection-ai.com/"
                    target="_blank"
                    rel="noreferrer noopener"
                    class="home-ja-link12 en_Heading2"
                  >
                    <span>See more</span>
                  </a>
                  <img
                    alt="image"
                    src="/static/lp/images/arrow-right.svg"
                    class="home-ja-image69"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import '@/assets/lp/css/reset.css'
import '@/assets/lp/css/style.css'
import '@/assets/lp/css/home-ja.css'
import SwitchOn from '@/assets/svg/SwitchOn.svg'
import SwitchOff from '@/assets/svg/SwitchOff.svg'
import CustomerVoices from './CustomerVoices'

export default {
  name: 'MainContainerJa',
  components: { CustomerVoices, SwitchOff, SwitchOn },
  data() {
    return {
      isMonthly: true,
    }
  },
  methods: {
    openDemoLink() {
      window.open(this.$t('meetingUrl'))
    },
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.google.com/noto/specimen/Noto+Sans+JP?subset=japanese');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.plan__header {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.plan__header-switch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  user-select: none;
  background-color: $color-white;
  border: 0;
  outline: none;
  cursor: pointer;
  span {
    margin: 0 12px;
    font-weight: bold;
    font-size: 1.6rem;
    font-family: $fontFamily-en;
  }
}
</style>
