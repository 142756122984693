<template>
  <div class="tooltip" v-on:mouseover="show" v-on:mouseleave="hide">
    <tooltip />
    <div
      v-show="isShow"
      class="tooltip__message"
      :class="{ '--w300': isWider, '--left': isAlignLeft }"
    >
      <p>
        <slot />
      </p>
    </div>
  </div>
</template>

<script>
import Tooltip from '@/assets/svg/Tooltip.svg'
export default {
  name: 'PaTooltip',
  components: {
    Tooltip,
  },
  data() {
    return {
      isShow: false,
    }
  },
  props: {
    isWider: { type: Boolean, default: false },
    isAlignLeft: { type: Boolean, default: false },
  },
  methods: {
    show() {
      this.isShow = true
    },
    hide() {
      this.isShow = false
    },
  },
}
</script>

<style lang="scss" scoped>
.tooltip {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 5px;
  &:before {
    content: '';
    position: absolute;
    top: -50%;
    left: 0;
    width: 100%;
    height: 150%;
  }
}
.tooltip__message {
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
  width: 200px;
  display: flex;
  justify-content: center;
  &.--w300 {
    width: 300px;
    white-space: normal;
  }
  &.--left {
    left: calc(100% + 10px);
    bottom: 50%;
    transform: translateY(50%);
  }

  p {
    display: inline-block;
    padding: 10px;
    font-weight: normal;
    font-size: 1.2rem;
    background: #ffffff;
    border-radius: 10px;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.1));
  }
}
</style>
