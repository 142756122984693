import chartUtil from './chartUtil'

export default {
  color(caseIndex) {
    const colors = ['#325ab4', '#3278B4', '#3296B4']
    return colors[caseIndex]
  },

  colors(showEstimateChart, caseIndex) {
    return showEstimateChart ? [this.color(caseIndex), '#FF8A00'] : ['#FF8A00']
  },

  cashOutColors(cashOut) {
    if (cashOut <= 12) {
      return '#FF3C50'
    }
    if (cashOut >= 19) {
      return '#FF8A00'
    }
    return '#32B4B4'
  },

  cashBalanceOptions(cashOut) {
    return {
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: [this.cashOutColors(cashOut)],
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '60%',
          },
          dataLabels: {
            show: true,
            name: {
              fontSize: '1.7rem',
              fontWeight: 'bold',
              color: 'black',
              offsetY: 20,
            },
            value: {
              fontSize: '3rem',
              fontWeight: 'bold',
              color: 'black',
              offsetY: -20,
              /* eslint-disable */
              formatter: function (val) {
                return cashOut
              },
            },
          },
        },
      },
      labels: ['Months'],
    }
  },

  mrrOptions() {
    return {
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: ['#FF8A00'],
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '60%',
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              fontSize: '4.8rem',
              fontWeight: 'bold',
              color: '#FF8A00',
            },
          },
        },
      },
    }
  },

  lineChartOptions(term, forecastMonth, caseIndex, dataLabelEnabled) {
    return {
      chart: {
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#FF8A00', this.color(caseIndex)],
      plotOptions: {
        bar: {
          columnWidth: '50%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: dataLabelEnabled,
        offsetY: -5,
        style: {
          fontSize: '1rem',
        },
        background: {
          enabled: false,
        },
        formatter: (val) => {
          return chartUtil.formatAmount(val, 10)
        },
      },
      stroke: {
        width: 2,
        strokeColors: ['#FF8A00', this.color(caseIndex)],
      },
      markers: {
        size: 2,
        strokeColors: ['#FF8A00', this.color(caseIndex)],
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      xaxis: {
        tickPlacement: 'between',
        categories: term,
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: [
        {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#666666',
            },
                    formatter: (val) => {
          return chartUtil.formatAmount(val, 10)
        },
          },
        },
      ],
      tooltip: {
        enabled: true,
        marker: {
          fillColors: ['#FF8A00', this.color(caseIndex)],
        },
        x: {
          show: false,
        },
      },
      forecastDataPoints: {
        count: forecastMonth,
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        fontSize: '10px',
        position: 'bottom',
        horizontalAlign: 'right',
        markers: {
          fillColors: ['#FF8A00', this.color(caseIndex)],
        },
        labels: {
          colors: ['#666666'],
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        inverseOrder: true,
      },
    }
  },

  barChartOptions(term, forecastMonth, caseIndex, showEstimateChart) {
    return {
      chart: {
        animations: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: this.colors(showEstimateChart, caseIndex),
      fill: {
        colors: this.colors(showEstimateChart, caseIndex),
        opacity: 1,
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
          dataLabels: {
            enabled: true,
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: false,
        offsetY: -6,
        style: {
          fontSize: '1rem',
          colors: this.colors(showEstimateChart, caseIndex),
        },
        formatter: (val) => {
          return chartUtil.formatAmount(val)
        },
        background: {
          enabled: false,
        },
      },
      stroke: {
        show: true,
        width: 3,
        colors: ['#fff'],
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      xaxis: {
        categories: term,
        labels: {
          style: {
            colors: '#666666',
            fontSize: '14px',
          },
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: [
        {
          tickAmount: 5,
          axisBorder: {
            show: false,
          },
          labels: {
            minWidth: 48,
            maxWidth: 48,
            style: {
              colors: '#666666',
            },
            formatter: (val) => {
              return chartUtil.formatAmount(val)
            },
          },
        },
      ],
      tooltip: {
        x: {
          show: false,
        },
      },
      forecastDataPoints: {
        count: forecastMonth,
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        fontSize: '10px',
        position: 'bottom',
        horizontalAlign: 'right',
        markers: {
          fillColors: this.colors(showEstimateChart, caseIndex),
        },
        labels: {
          colors: ['#666666'],
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    }
  },
}
