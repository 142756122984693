import saveAs from 'file-saver'
import { format } from 'date-fns'

export default {
  async download(result, businessPlanName, caseName, dataName) {
    const blob = new Blob([result.data], {
      type: result.headers['content-type'],
    })
    const date = format(new Date(), 'yyyyMMdd')
    saveAs(blob, `${businessPlanName}_${caseName}_${dataName}_${date}`)
  },
}
