<template>
  <div class="home-ja-header">
    <div class="home-ja-header-nav">
      <div class="home-ja-header-nav-left">
        <a href="#">
          <img
            alt="image"
            src="/static/lp/images//projection-ai_logo_beta.svg"
            class="home-ja-image"
          />
        </a>
        <div class="home-ja-nav-item-wrapper">
          <a href="#section-steps" class="home-ja-link">
            <div class="home-ja-nav-item-02">
              <img
                alt="image"
                src="/static/lp/images/chevron-down.svg"
                class="home-ja-image01"
              />
            </div>
          </a>
          <a href="#section-steps" class="home-ja-link01">
            <div class="home-ja-nav-item-03">
              <span class="home-ja-text">{{ $t('lp.menu.features') }}</span>
              <img
                alt="image"
                src="/static/lp/images/chevron-down.svg"
                class="home-ja-image02"
              />
            </div>
          </a>
          <a href="#section-price" class="home-ja-link02">
            <div class="home-ja-nav-item-06">
              <span class="home-ja-text001">{{ $t('lp.menu.price') }}</span>
              <img
                alt="image"
                src="/static/lp/images/chevron-down.svg"
                class="home-ja-image03"
              />
            </div>
          </a>
          <a href="#section-product" class="home-ja-link03">
            <div class="home-ja-nav-item-01">
              <span class="home-ja-text002">{{ $t('lp.menu.database') }}</span>
              <img
                alt="image"
                src="/static/lp/images/chevron-down.svg"
                class="home-ja-image04"
              />
            </div>
          </a>
          <a href="#section-product" class="home-ja-link03">
            <div class="home-ja-nav-item-01">
              <span class="home-ja-text002">{{ $t('lp.menu.blog') }}</span>
              <img
                alt="image"
                src="/static/lp/images/chevron-down.svg"
                class="home-ja-image04"
              />
            </div>
          </a>
        </div>
      </div>
      <div class="home-ja-hedar-nav-right">
        <button v-if="isJa" class="home-ja-link04" @click="openDemoLink">
          {{ $t('lp.demo') }}
        </button>
        <button class="home-ja-button" @click="showModal">
          {{ $t('lp.login') }}
        </button>
      </div>
      <div class="home-ja-mobile-hamb">
        <img
          alt="image"
          src="/static/lp/images/hamburger.svg"
          class="home-ja-image05"
          @click="$emit('click:menu')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/lp/css/reset.css'
import '@/assets/lp/css/style.css'
import '@/assets/lp/css/home-ja.css'
import lang from '@/locales/lang'

export default {
  name: 'PaHeader',
  computed: {
    isJa() {
      return lang.browserLanguage() === 'ja'
    },
  },
  methods: {
    openDemoLink() {
      window.open(this.$t('meetingUrl'))
    },
    showModal() {
      this.$emit('show:modal')
    },
  },
}
</script>
