import http from '../../axios/http'
import monthly from '../../util/monthly'
import cases from '../../client/cases'
import businessPlans from '../../client/businessPlans'

const state = {
  businessPlans: [],
  businessPlan: {
    business_plan_id: '',
    business_plan_name: '',
    business_plan_icon: null,
    cases: [
      {
        case_id: '',
        case_name: '',
      },
    ],
    colors: [],
    theme_color: 'purple',
    currency: 'JPY',
    is_owner: null,
    start_month: 1,
    start_year: 2021,
    template: '',
  },
  role: {
    actuals: '',
    cogs: '',
    dashboard: '',
    ga: '',
    kpi: '',
    monthly_pl: '',
    pl: '',
    rd: '',
    sales_detail: '',
    sm: '',
  },
  term: [],
  user: {
    email: '',
    name: '',
    picture: '',
    company: {
      company_name: '',
      employees: '',
      position: '',
      stage: '',
    },
    plan: {
      is_trial: null,
      plan: '',
      trial_end: '',
    },
    isAuthenticated: false,
  },
}

const getters = {
  businessPlans: (state) => state.businessPlans,
  businessPlan: (state) => state.businessPlan,
  role: (state) => state.role,
  term: (state) => state.term,
  user: (state) => state.user,
  isAuthenticated: (state) => state.isAuthenticated,
}

const mutations = {
  getMe(state, { businessPlans, user, isAuthenticated }) {
    state.businessPlans = [...businessPlans]
    state.user = user
    state.isAuthenticated = isAuthenticated
  },

  setBusinessPlan(state, businessPlanId) {
    const { role, ...businessPlan } = state.businessPlans.find(
      (x) => x.business_plan_id === businessPlanId
    )
    state.businessPlan = businessPlan
    state.role = role
    state.term = monthly.term(businessPlan.start_year, businessPlan.start_month)
  },
}

const actions = {
  async getMe({ commit }) {
    const result = await http.get('/v1/user/me')
    const businessPlans = result.data.business_plans
    const user = result.data.user
    commit('getMe', { businessPlans, user, isAuthenticated: true })
  },

  async setBusinessPlan({ commit }, businessPlanId) {
    commit('setBusinessPlan', businessPlanId)
  },

  async editCase({ rootState, dispatch }, { caseId, name }) {
    const businessPlanId = rootState.currentView.businessPlanId
    await cases.editName({
      business_plan_id: businessPlanId,
      case_id: caseId,
      case_name: name,
    })
    await dispatch('getMe')
    await dispatch('setBusinessPlan', businessPlanId)
  },

  async deleteCase({ rootState, commit, getters, dispatch }, { caseId }) {
    const businessPlanId = rootState.currentView.businessPlanId
    await cases.delete({
      business_plan_id: businessPlanId,
      case_id: caseId,
    })
    await dispatch('getMe')
    await commit('setBusinessPlan', businessPlanId)
    await dispatch(
      'currentView/setCaseId',
      getters.businessPlan.cases[0].case_id,
      { root: true }
    )
  },

  async editThemeColor({ rootState, dispatch }, { themeColor }) {
    const businessPlanId = rootState.currentView.businessPlanId
    await businessPlans.editThemeColor({
      business_plan_id: businessPlanId,
      theme_color: themeColor,
    })
    await dispatch('getMe')
    await dispatch('setBusinessPlan', businessPlanId)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
