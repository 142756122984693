<template>
  <div class="monthlyPlTableContainer">
    <div class="monthlyPlTableContainer__inner">
      <table class="monthlyPlTable">
        <colgroup>
          <col class="monthlyPlTable__titleCol" />
          <template v-for="(month, index) in monthlyData">
            <col
              v-if="isShow(month)"
              :key="index"
              class="monthlyPlTable__dataCol"
            />
          </template>
        </colgroup>
        <monthly-pl-table-header :title="title" />
        <slot />
      </table>
    </div>
  </div>
</template>

<script>
import MonthlyPlTableHeader from './MonthlyPlTableHeader.vue'
export default {
  name: 'MonthlyPlTableLayout',
  components: { MonthlyPlTableHeader },
  inject: ['displayFilter', 'currentDetails'],
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    monthlyData() {
      const detailsData =
        this.$store.getters[`monthlyPl/${this.currentDetails()}`]
      if (this.currentDetails() === 'difference') {
        const differenceMode = this.$store.getters['monthlyPl/isRate']
          ? 'rate'
          : 'value'
        return detailsData[differenceMode]
      }
      return detailsData
    },
  },
  methods: {
    isShow(month) {
      if (month.monthType === 'month') {
        return this.displayFilter().months
      }
      if (month.monthType === 'quarter') {
        return this.displayFilter().quarter
      }
      if (month.monthType === 'cy') {
        return this.displayFilter().cy
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.monthlyPlTableContainer {
  padding: 0 16px;
  background-color: $color-white;
}

.monthlyPlTableContainer__inner {
  overflow-x: scroll;
  white-space: nowrap;
}

.monthlyPlTable {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.monthlyPlTable__titleCol {
  width: 300px;
}

.monthlyPlTable__dataCol {
  min-width: 114px;
}
</style>
