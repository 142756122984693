<template>
  <dashboard-panel :title="title" :tooltip-text="tooltip">
    <template #switch>
      <span class="term">{{ currentTerm }}</span>
      <dashboard-switch
        :switch-text="{ left: 'MRR', right: 'ARR' }"
        @on:switch="changeMrr"
      />
    </template>
    <template #contents>
      <span class="chartUnit">{{ `（${unit(currency, 'k')}）` }}</span>
      <apexchart
        :options="barChartOptions"
        :series="mrrSeries"
        type="bar"
        width="100%"
        height="300"
      />
    </template>
  </dashboard-panel>
</template>

<script>
import DashboardPanel from './DashboardPanel'
import DashboardSwitch from './DashboardSwitch'
import currencyUtil from '../../util/currency'
export default {
  name: 'DashboardMrrChart',
  components: { DashboardSwitch, DashboardPanel },
  props: {
    items: Object,
    barChartOptions: Object,
    currentTerm: String,
    currency: String,
    showEstimateChart: Boolean,
  },
  data() {
    return {
      selectedItems: 'mrr',
    }
  },
  computed: {
    title() {
      return this.selectedItems === 'mrr'
        ? this.$t('dashboard.mrrTrend')
        : this.$t('dashboard.arrTrend')
    },
    tooltip() {
      return this.selectedItems === 'mrr'
        ? this.$t('dashboard.mrrTrendTooltip')
        : this.$t('dashboard.arrTrendTooltip')
    },
    mrrSeries() {
      const series = [
        {
          name: this.$t('dashboard.act'),
          data: this.items[this.selectedItems].actual,
        },
      ]
      if (this.showEstimateChart) {
        series.unshift({
          name: this.$t('dashboard.est'),
          data: this.items[this.selectedItems].estimate,
        })
      }
      return series
    },
  },
  methods: {
    changeMrr(onLeft) {
      this.selectedItems = onLeft ? 'mrr' : 'arr'
    },
    unit(currency, digit = '') {
      return currencyUtil.unit(currency, digit)
    },
  },
}
</script>

<style lang="scss" scoped>
.term {
  font-size: 1.8rem;
  font-weight: bold;
}
.chartUnit {
  position: absolute;
  top: -4px;
  left: 0;
  font-size: 1rem;
}
</style>
