<template>
  <div>
    <button :class="['switchLeft', { active: onLeft }]" @click="onLeft = true">
      {{ switchText.left }}
    </button>
    <button
      :class="['switchRight', { active: !onLeft }]"
      @click="onLeft = false"
    >
      {{ switchText.right }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'DashboardSwitch',
  props: {
    switchText: Object,
  },
  data() {
    return {
      onLeft: Boolean,
    }
  },
  watch: {
    onLeft() {
      this.$emit('on:switch', this.onLeft)
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none;
  font-size: 1rem;
  font-weight: bold;
  padding: 6px 8px;
  background-color: $color-light-gray;
  color: $color-gray;
}
.active {
  background-color: $color-theme;
  color: $color-white;
}
.switchLeft {
  border-radius: 4px 0 0 4px;
}
.switchRight {
  border-radius: 0 4px 4px 0;
}
</style>
