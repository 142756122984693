<template>
  <footer class="home-ja-footer">
    <div class="home-ja-container113">
      <div class="home-ja-container114">
        <img
          alt="image"
          src="/static/lp/images/projection-ai_logo.svg"
          class="home-ja-image70"
        />
        <a
          href="https://twitter.com/projection_ai"
          target="_blank"
          rel="noreferrer noopener"
          class="home-ja-link13"
        >
          <img
            alt="image"
            src="/static/lp/images/twitter.svg"
            class="home-ja-image71"
          />
        </a>
      </div>
      <div class="home-ja-container115">
        <a href="#section-steps" class="home-ja-link14">
          {{ $t('lp.menu.features') }}
        </a>
        <a href="#section-price" class="home-ja-link15">
          {{ $t('lp.menu.price') }}
        </a>
        <a href="#section-product" class="home-ja-link16">
          {{ $t('lp.menu.database') }}
        </a>
      </div>
      <div class="home-ja-container116">
        <div class="home-ja-container117">
          <router-link
            to="/terms"
            target="_blank"
            rel="noreferrer noopener"
            class="home-ja-link17"
          >
            {{ $t('lp.links.terms') }}
          </router-link>
          <router-link
            to="/law"
            target="_blank"
            rel="noreferrer noopener"
            class="home-ja-link18"
          >
            {{ $t('lp.links.law') }}
          </router-link>
          <router-link
            to="/privacy-policy"
            target="_blank"
            rel="noreferrer noopener"
            class="home-ja-link19"
          >
            {{ $t('lp.links.privacyPolicy') }}
          </router-link>
          <router-link
            :href="$t('lp.links.contactUrl')"
            target="_blank"
            rel="noreferrer noopener"
            class="home-ja-link20"
          >
            {{ $t('lp.links.contact') }}
          </router-link>
        </div>
        <div class="home-ja-copy">
          <span class="home-ja-text191">©︎projection-ai</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import '@/assets/lp/css/reset.css'
import '@/assets/lp/css/style.css'
import '@/assets/lp/css/home-ja.css'

export default {
  name: 'PaFooter',
}
</script>
