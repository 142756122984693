<template>
  <div>
    <pa-header />
    <main class="container">
      <h1>{{ $t('auth.resetPassword') }}</h1>
      <div class="inputContainer">
        <section>
          <p>{{ $t('auth.newPassword') }}</p>
          <input
            key="newPassword"
            name="newPassword"
            type="password"
            ref="newPassword"
            v-model="newPassword"
            :placeholder="$t('auth.newPassword')"
            v-validate="
              'required|regex:^[a-zA-Z0-9!-/:-@¥[-`{-~]*$|min:8|max:100'
            "
            data-vv-as="newPassword"
            autocomplete="new-password"
            required
          />
          <p class="errorMessage">{{ errors.first('newPassword') }}</p>
        </section>
        <section>
          <p>{{ $t('auth.newPasswordConfirm') }}</p>
          <input
            key="confirmPassword"
            name="confirmPassword"
            type="password"
            v-model="confirmPassword"
            :placeholder="$t('auth.newPasswordConfirm')"
            v-validate="
              'required|regex:^[a-zA-Z0-9!-/:-@¥[-`{-~]*$|min:8|max:100|confirmed:newPassword'
            "
            data-vv-as="confirmPassword"
            autocomplete="new-password"
            required
          />
          <p class="errorMessage">{{ errors.first('confirmPassword') }}</p>
        </section>
        <button class="button submitButton" @click="onSubmit">
          {{ $t('submit') }}
        </button>
      </div>
      <router-link class="link" to="/">{{ $t('auth.topPage') }}</router-link>
    </main>
    <FlashMessage />
  </div>
</template>

<script>
import PaHeader from '@/components/PaHeader'
import auth from '../client/auth'

export default {
  name: 'PasswordReset',
  components: {
    PaHeader,
  },
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
    }
  },
  async mounted() {
    try {
      const token = this.$route.query.key
      await auth.checkToken(token)
    } catch (e) {
      this.$router.replace({ name: 'Home' })
    }
  },
  methods: {
    async onSubmit() {
      const valid = await this.$validator.validateAll({
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
      })
      if (!valid) return
      try {
        const token = this.$route.query.key
        await auth.resetPassword(token, this.newPassword)
        this.flashMessage.success({
          title: 'Success',
          message: this.$t('auth.passwordResetSuccess'),
        })
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  margin: 0 auto;
  max-width: 350px;
  padding-top: 40px;
  text-align: center;
  color: $color-gray;
  h1 {
    margin-bottom: 16px;
  }
  section {
    margin-bottom: 8px;
    p {
      font-weight: bold;
      text-align: left;
      margin-bottom: 4px;
    }
    input {
      width: 100%;
    }
  }
}
.inputContainer {
  background-color: $color-light-base;
  padding: 40px 32px;
  border-radius: 8px;
  margin-bottom: 16px;
}
input {
  width: 100%;
  font-size: 1.6rem;
  box-sizing: border-box;
  height: 40px;
  padding: 8px;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  outline: none;
  margin-bottom: 4px;
}
.button {
  box-sizing: border-box;
  width: 124px;
  height: 32px;
  padding: 8px 0;
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
  border-radius: 16px;
  cursor: pointer;
}
.submitButton {
  margin-top: 24px;
  color: #fff;
  background: #503479;
  border: 1px solid #503479;
}
.errorMessage {
  color: $color-red;
  font-weight: normal !important;
}
.link {
  text-decoration: underline;
}
</style>
