<template>
  <div>
    <template v-if="isAccessible">
      <slot />
    </template>
    <template v-else>
      <not-accessible-panel />
    </template>
  </div>
</template>

<script>
import NotAccessiblePanel from './NotAccessiblePanel'
export default {
  name: 'BusinessPlanAccessibleLayout',
  components: { NotAccessiblePanel },
  data() {
    return {
      mappingRouteName: {
        Dashboard: 'dashboard',
        PL: 'pl',
        MonthlyPl: 'monthly_pl',
        Expenses: '',
        Kpi: 'kpi',
        Revenue: 'sales_detail',
      },
    }
  },
  computed: {
    isAccessible() {
      const roles = this.$store.getters['me/role']
      if (this.$route.name === 'Expenses') {
        return (
          roles.cogs !== 'no_access' ||
          roles.sm !== 'no_access' ||
          roles.rd !== 'no_access' ||
          roles.ga !== 'no_access'
        )
      }
      return roles[this.mappingRouteName[this.$route.name]] !== 'no_access'
    },
  },
}
</script>
