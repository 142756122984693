<template>
  <div class="redirect">
    <h2 class="redirect__title">Redirecting...</h2>
    <FlashMessage />
  </div>
</template>

<script>
import monthlyPl from '../client/monthlyPl'

export default {
  name: 'OAuthGoogleDrive',
  async created() {
    try {
      const businessPlanId = localStorage.getItem('currentBusinessPlanId')
      await monthlyPl.connectSpreadSheet({
        business_plan_id: businessPlanId,
        auth_param: `?${this.$route.fullPath.split('?')[1]}`,
      })
      this.flashMessage.success({
        title: 'Success',
        message: this.$t('monthlyPl.googleSpreadSheet.connectComplete'),
      })
    } catch (e) {
      this.flashMessage.error({
        title: e.response.title,
        message: e.response.message,
      })
    } finally {
      const businessPlanId = localStorage.getItem('currentBusinessPlanId')
      await this.$router.replace({
        name: 'MonthlyPl',
        params: { id: businessPlanId },
      })
      localStorage.removeItem('currentBusinessPlanId')
    }
  },
}
</script>

<style lang="scss" scoped>
.redirect {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.redirect__title {
  font-size: 2.4rem;
  font-weight: bold;
  color: var(--color-base);
}
</style>
