<template>
  <business-plan-accessible-layout>
    <div class="kpi__body">
      <pa-tab @fetch="getKpi" />
      <kpi-summary
        :kpi="kpi"
        :is-view-only="isViewOnly"
        :template="template"
        :currency="currency"
        @change:summary="editCase"
      />
      <kpi-monthly
        :monthly="monthly"
        :template="template"
        :term="term"
        @click:downloadCsv="downloadCsv"
      />
      <kpi-quarterly :quarterly="quarterly" :template="template" />
    </div>
  </business-plan-accessible-layout>
</template>

<script>
import BusinessPlanAccessibleLayout from '../../components/Common/BusinessPlanAccessibleLayout'
import businessPlans from '@/client/businessPlans'
import PaTab from '../../components/PaTab'
import KpiQuarterly from '../../components/Kpi/KpiQuarterly'
import KpiMonthly from '../../components/Kpi/KpiMonthly'
import KpiSummary from '../../components/Kpi/KpiSummary'
import cases from '@/client/cases'
import file from '../../util/file'

export default {
  name: 'Kpi',
  components: {
    BusinessPlanAccessibleLayout,
    KpiSummary,
    KpiMonthly,
    KpiQuarterly,
    PaTab,
  },
  props: {
    businessPlans: Array,
    roles: Object,
    template: String,
    term: Array,
    currency: String,
    currentBusinessPlanId: String,
    currentYear: Number,
  },
  data() {
    return {
      businessPlan: [
        {
          case_id: '',
          case_name: '',
          kpi: [
            {
              arpa: 0,
              arr: 0,
              churn_rate: 0,
              lead_rate: 0,
              meeting_rate: 0,
              monthly: [
                {
                  churn: 0,
                  churn_rate: 0,
                  lead: 0,
                  lead_rate: 0,
                  meeting: 0,
                  meeting_rate: 0,
                  month: 0,
                  order: 0,
                  order_rate: 0,
                  paid: 0,
                  session: 0,
                },
              ],
              mrr: 0,
              order_rate: 0,
              quarterly: [
                {
                  churn: 0,
                  lead: 0,
                  meeting: 0,
                  order: 0,
                  progress_rate: '',
                  quarter: '',
                  session: 0,
                },
              ],
              upselling_month: 0,
              upselling_rate: 0,
              year: 0,
              yearly: [
                {
                  churn: 0,
                  lead: 0,
                  meeting: 0,
                  order: 0,
                  paid: 0,
                  session: 0,
                },
              ],
            },
          ],
        },
      ],
    }
  },
  async mounted() {
    await this.getKpi()
    await this.$store.dispatch('currentView/disableLoading')
  },
  computed: {
    isViewOnly() {
      return this.roles.kpi === 'can_view'
    },
    currentBusinessPlan() {
      return this.businessPlans.find(
        (x) => x.business_plan_id === this.currentBusinessPlanId
      )
    },
    currentCaseId() {
      return this.$store.getters['currentView/caseId']
    },
    currentCase() {
      return this.businessPlan.find((x) => x.case_id === this.currentCaseId)
    },
    kpi() {
      if (typeof this.currentCase === 'undefined') return {}
      return this.currentCase.kpi[this.currentYear - 1]
    },
    quarterly() {
      return this.kpi.quarterly
    },
    monthly() {
      return this.kpi.monthly
    },
  },
  methods: {
    async getKpi() {
      const result = await businessPlans.getKpi({
        business_plan_id: this.currentBusinessPlanId,
      })
      this.businessPlan = result.data
    },
    async editCase(args) {
      try {
        await cases.edit({
          business_plan_id: this.currentBusinessPlanId,
          case_id: this.currentCaseId,
          year: this.currentYear,
          key: args.key,
          value: args.value,
        })
        await this.getKpi()
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
    async downloadCsv() {
      try {
        const result = await businessPlans.downloadKpiCsv({
          business_plan_id: this.currentBusinessPlanId,
          case_id: this.currentCaseId,
          year: this.currentYear,
        })
        await file.download(
          result,
          this.currentBusinessPlan.business_plan_name,
          this.currentCase.case_name,
          'KPI'
        )
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.kpi__header {
  padding: 20px 40px;
  background: $color-light-gray;
  display: flex;
}
.kpi__header-select {
  width: 320px;
  margin-right: 16px;
  .select__container {
    font-size: 1.6rem;
  }
}
.kpi__body {
  @include mainContentWidthPadding;
}
.kpi__summaryPanel,
.kpi__panel {
  margin-bottom: 40px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 10%) 0 0 4px;
}
.kpi__panel {
  padding: 24px;
  ::v-deep article {
    overflow-x: auto;
    overflow-y: hidden;
  }
}
</style>
