<template>
  <section class="panel">
    <h2>{{ $t('setting.plan') }}</h2>
    <p class="panel__planDescription">{{ $t('setting.planDescription') }}</p>
    <plan :activePlan="plan.plan" :billing-plan="invoice.plan" />
    <div class="panel__billingMessage">
      <div v-if="invoice.plan.status === 'scheduled_canceled'">
        <div class="panel__billingMessage-box">
          {{
            $t('setting.cancelScheduledMessage1', { date: currentPeriodEnd })
          }}
        </div>
        <p class="panel__billingMessage-description">
          {{
            $t('setting.cancelScheduledMessage2', { date: currentPeriodEnd })
          }}
        </p>
      </div>
      <div v-else>
        <p v-if="currentPeriodEnd" class="panel__billingMessage-description">
          {{ $t('setting.scheduledMessage', { date: currentPeriodEnd }) }}
        </p>
      </div>
    </div>
    <div class="panel__invoice">
      <div>
        <accordion-panel :title="$t('setting.billingInfo')">
          <template #contents>
            <table class="panel__invoice-table">
              <tbody>
                <tr v-for="(item, key) in invoiceListLimit" :key="key">
                  <td>
                    <a target="_blank" rel="noreferrer" :href="item.url">
                      {{ item.created_at }}
                      <link-icon />
                    </a>
                  </td>
                  <td>¥{{ item.price | numberFormat }}</td>
                </tr>
              </tbody>
            </table>
          </template>
          <template #foldingContents>
            <table class="panel__invoice-table">
              <tbody>
                <tr v-for="(item, key) in invoice.invoice_list" :key="key">
                  <td>
                    <a target="_blank" rel="noreferrer" :href="item.url">
                      {{ item.created_at }}
                      <link-icon />
                    </a>
                  </td>
                  <td>¥{{ item.price | numberFormat }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </accordion-panel>
      </div>
    </div>
  </section>
</template>

<script>
import AccordionPanel from '../AccordionPanel'
import LinkIcon from '@/assets/svg/Link.svg'
import Plan from '../Plan'
import { format } from 'date-fns'

export default {
  name: 'Billing',
  components: { Plan, AccordionPanel, LinkIcon },
  props: {
    plan: Object,
    billing: Object,
  },
  computed: {
    invoice() {
      return this.billing.invoice
    },
    invoiceListLimit() {
      return this.invoice.invoice_list.slice(0, 3)
    },
    existCardInfo() {
      if (typeof this.invoice === 'undefined') return false
      return this.invoice.last4 !== ''
    },
    cardNumber() {
      if (typeof this.invoice === 'undefined') return '0000'
      return this.invoice.last4 === '' ? '0000' : this.invoice.last4
    },
    expYear() {
      if (typeof this.invoice === 'undefined') return '00'
      return this.invoice.exp_year === '' ? '00' : this.invoice.exp_year
    },
    expMonth() {
      if (typeof this.invoice === 'undefined') return '00'
      return this.invoice.exp_month === '' ? '00' : this.invoice.exp_month
    },
    currentPeriodEnd() {
      if (
        typeof this.invoice === 'undefined' ||
        !this.invoice.plan.current_period_end
      )
        return ''
      return format(
        new Date(this.invoice.plan.current_period_end),
        this.$t('dateFormat')
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.panel {
  h2 {
    font-size: 2rem;
    line-height: 30px;
    margin-bottom: 20px;
  }
}
.panel__planDescription {
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
  color: $color-theme;
  @include isLarge {
    text-align: left;
  }
}
.panel__billingMessage {
  margin-top: -40px;
  margin-bottom: 80px;
  &-box {
    text-align: center;
    color: #eb5757;
    background-color: #fdeeee;
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0 -40px;
    padding: 16px 0;
  }
}
.panel__billingMessage-description {
  margin: 16px 0;
  font-weight: bold;
}
.panel__invoice-table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
  tr {
    border-bottom: 1px solid rgb(242, 242, 242);
  }
  tr:first-child {
    border-top: 1px solid rgb(242, 242, 242);
  }
  tbody {
    tr td:first-child {
      width: 200px;
      padding-left: 24px;
    }
    tr td {
      padding: 14px 0;
      color: rgb(51, 51, 51);
      font-size: 14px;
      line-height: 12px;
    }
    td {
      a {
        color: $color-theme;
      }
    }
  }
}
</style>
