<template>
  <div class="welcome">
    <div class="welcome__progress">
      <welcome-progress-en v-if="isEn" />
      <welcome-progress v-else />
    </div>
    <div class="welcome__title">
      <h1>Welcome!</h1>
    </div>
    <div class="welcome__form">
      <div class="welcome__form-box">
        <h2 class="welcome__form-heading">{{ $t('create.name') }}</h2>
        <PaInput v-model="inputName" :maxLength="50" />
      </div>
      <div class="welcome__form-box">
        <h2 class="welcome__form-heading">{{ $t('create.company') }}</h2>
        <PaInput v-model="inputCompanyName" :maxLength="50" />
      </div>
      <div class="welcome__form-box">
        <h2 class="welcome__form-heading">{{ $t('create.position') }}</h2>
        <pa-select :options="positions" v-model="selectedPosition" />
      </div>
      <div class="welcome__form-box">
        <h2 class="welcome__form-heading">{{ $t('create.employee') }}</h2>
        <pa-select :options="employees" v-model="selectedEmployee" />
      </div>
      <div class="welcome__form-box">
        <h2 class="welcome__form-heading">{{ $t('create.stage') }}</h2>
        <pa-select :options="stages" v-model="selectedStage" />
      </div>
      <div class="welcome__form-box">
        <button :disabled="isDisabled" @click="next">
          {{ $t('create.next') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PaInput from '@/components/Welcome/PaInput'
import PaSelect from '@/components/Welcome/PaSelect'
import WelcomeProgress from '@/assets/svg/WelcomeProgress.svg'
import WelcomeProgressEn from '@/assets/svg/WelcomeProgressEn.svg'

export default {
  name: 'Welcome',
  components: {
    WelcomeProgress,
    WelcomeProgressEn,
    PaInput,
    PaSelect,
  },
  props: {
    name: String,
    companyName: String,
    position: String,
    employee: String,
    stage: String,
    isEn: Boolean,
  },
  data() {
    return {
      positions: [
        { key: 'CEO', value: this.$t('create.positions.ceo') },
        { key: 'CFO', value: this.$t('create.positions.cfo') },
        { key: '事業責任者', value: this.$t('create.positions.manager') },
        { key: 'アドバイザー', value: this.$t('create.positions.adviser') },
        { key: 'その他', value: this.$t('create.positions.other') },
      ],
      employees: [
        { key: '1-10名', value: this.$t('create.employees.employees1') },
        { key: '11-30名', value: this.$t('create.employees.employees2') },
        { key: '30-50名', value: this.$t('create.employees.employees3') },
        { key: '50名以上', value: this.$t('create.employees.employees4') },
      ],
      stages: [
        { key: '創業前', value: this.$t('create.stages.before') },
        { key: 'エンジェル/シード', value: this.$t('create.stages.angel') },
        { key: 'シリーズA-B', value: this.$t('create.stages.seriesAb') },
        { key: 'シリーズC以降', value: this.$t('create.stages.seriesC') },
        { key: '上場企業など', value: this.$t('create.stages.listed') },
      ],
    }
  },
  computed: {
    isDisabled() {
      return this.name === '' || this.companyName === ''
    },
    inputName: {
      get() {
        return this.name
      },
      set(newValue) {
        this.$parent.name = newValue
      },
    },
    inputCompanyName: {
      get() {
        return this.companyName
      },
      set(newValue) {
        this.$parent.companyName = newValue
      },
    },
    selectedPosition: {
      get() {
        return this.position
      },
      set(newValue) {
        this.$parent.position = newValue
      },
    },
    selectedEmployee: {
      get() {
        return this.employee
      },
      set(newValue) {
        this.$parent.employee = newValue
      },
    },
    selectedStage: {
      get() {
        return this.stage
      },
      set(newValue) {
        this.$parent.stage = newValue
      },
    },
  },
  methods: {
    next() {
      this.$emit('click:next')
    },
  },
}
</script>

<style lang="scss" scoped>
.welcome__progress {
  margin: 24px auto 16px;
  @include isSP() {
    svg {
      width: 100%;
    }
  }
}
.welcome__title {
  margin-bottom: 16px;
}
.welcome__form {
  box-sizing: border-box;
  max-width: 400px;
  margin: 0 auto;
}
.welcome__form-box {
  box-sizing: border-box;
  margin-bottom: 24px;
  ::v-deep input {
    padding: 16px 60px 16px 20px;
  }
  ::v-deep select {
    height: auto;
    padding: 16px 60px 16px 20px;
  }
  button {
    margin-top: 32px;
    width: 320px;
    height: 64px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 2rem;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    background: $color-theme;
  }
  button:disabled {
    background: none rgb(202, 202, 202);
    cursor: default;
  }
}
.welcome__form-heading {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 21px;
  text-align: left;
  margin-bottom: 8px;
}
</style>
