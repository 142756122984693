<template>
  <div class="welcome">
    <pa-header />
    <welcome
      v-if="currentComponent === 'Welcome'"
      @click:next="next"
      :name="name"
      :companyName="companyName"
      :position="position"
      :employee="employee"
      :stage="stage"
      :is-en="isEn"
    />
    <start
      v-if="currentComponent === 'Start'"
      @click:prev="prev"
      @click:create="create"
      :arr="arr"
      :period="period"
      :template="template"
      :currency="currency"
      :arpa="arpa"
      :businessPlanName="businessPlanName"
      :is-en="isEn"
    />
    <pa-footer />
  </div>
</template>

<script>
import PaHeader from '@/components/PaHeader'
import PaFooter from '@/components/PaFooter'
import Welcome from './Welcome'
import Start from './Start'
import businessPlans from '@/client/businessPlans'
import userStore from '@/store/user'
import lang from '@/locales/lang'
export default {
  name: 'Create',
  components: {
    Start,
    Welcome,
    PaFooter,
    PaHeader,
  },
  data() {
    return {
      currentComponent: 'Welcome',
      name: '',
      companyName: '',
      position: 'CEO',
      employee: '1-10名',
      stage: '創業前',
      template: 'saas',
      currency: 'JPY',
      arr: '5000000',
      period: '12',
      arpa: '15000',
      businessPlanName: '',
    }
  },
  computed: {
    user() {
      return userStore.state().user
    },
    existCompanyInfo() {
      if (typeof this.user.company === 'undefined') return false
      return this.user.name !== null
    },
    isEn() {
      return lang.browserLanguage() === 'en'
    },
  },
  mounted() {
    if (this.existCompanyInfo) {
      this.currentComponent = 'Start'
      this.name = this.user.name
      this.companyName = this.user.company.company_name
      this.position = this.user.company.position
      this.employee = this.user.company.employees
      this.stage = this.user.company.stage
    }
    const now = new Date()
    this.year = now.getFullYear()
    this.month = now.getMonth() + 1
  },
  methods: {
    next() {
      this.currentComponent = 'Start'
    },
    prev() {
      this.currentComponent = 'Welcome'
    },
    async create() {
      try {
        const now = new Date()
        const formData = new FormData()
        // start
        formData.append('name', this.name)
        formData.append('company_name', this.companyName)
        formData.append('position', this.position)
        formData.append('employees', this.employee)
        formData.append('stage', this.stage)
        // welcome
        formData.append('arr', this.arr)
        formData.append('period', this.period)
        formData.append('arpa', this.arpa)
        formData.append('template', this.template)
        formData.append('start_year', now.getFullYear().toString())
        formData.append('start_month', (now.getMonth() + 1).toString())
        formData.append('business_plan_name', this.businessPlanName)
        formData.append('currency', this.currency)

        const result = await businessPlans.create(formData)
        this.$router.replace({
          name: 'PL',
          params: { id: result.data.business_plan_id },
        })
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
