<template>
  <section class="panel">
    <div class="panel__heading">
      <h2>{{ $t('setting.profile') }}</h2>
      <button v-if="isEditing" @click="saveProfile"><save-icon />保存</button>
      <button v-else @click="isEditing = !isEditing"><edit-icon />編集</button>
    </div>
    <table class="panel-table">
      <colgroup>
        <col width="200px" />
      </colgroup>
      <tbody>
        <tr>
          <th>{{ $t('setting.name') }}</th>
          <td>
            <pa-input
              v-model="inputName"
              :maxLength="50"
              :disabled="!isEditing"
              class="itemField"
            />
          </td>
        </tr>
        <tr>
          <th>{{ $t('create.company') }}</th>
          <td>
            <pa-input
              v-model="inputCompanyName"
              :maxLength="50"
              :disabled="!isEditing"
              class="itemField"
            />
          </td>
        </tr>
        <tr>
          <th>{{ $t('create.position') }}</th>
          <td>
            <pa-select
              :options="positions"
              v-model="selectedPosition"
              :disabled="!isEditing"
              class="itemField"
            />
          </td>
        </tr>
        <tr>
          <th>{{ $t('create.employee') }}</th>
          <td>
            <pa-select
              :options="employees"
              v-model="selectedEmployee"
              :disabled="!isEditing"
              class="itemField"
            />
          </td>
        </tr>
        <tr>
          <th>{{ $t('create.stage') }}</th>
          <td>
            <pa-select
              :options="stages"
              v-model="selectedStage"
              :disabled="!isEditing"
              class="itemField"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import PaInput from '../Welcome/PaInput'
import PaSelect from '../Welcome/PaSelect'
import EditIcon from '@/assets/svg/EditIcon.svg'
import SaveIcon from '@/assets/svg/SaveIcon.svg'
export default {
  name: 'Profile',
  components: { PaSelect, PaInput, EditIcon, SaveIcon },
  props: {
    user: Object,
  },
  data() {
    return {
      isEditing: false,
      positions: [
        { key: 'CEO', value: this.$t('create.positions.ceo') },
        { key: 'CFO', value: this.$t('create.positions.cfo') },
        { key: '事業責任者', value: this.$t('create.positions.manager') },
        { key: 'アドバイザー', value: this.$t('create.positions.adviser') },
        { key: 'その他', value: this.$t('create.positions.other') },
      ],
      employees: [
        { key: '1-10名', value: this.$t('create.employees.employees1') },
        { key: '11-30名', value: this.$t('create.employees.employees2') },
        { key: '30-50名', value: this.$t('create.employees.employees3') },
        { key: '50名以上', value: this.$t('create.employees.employees4') },
      ],
      stages: [
        { key: '創業前', value: this.$t('create.stages.before') },
        { key: 'エンジェル/シード', value: this.$t('create.stages.angel') },
        { key: 'シリーズA-B', value: this.$t('create.stages.seriesAb') },
        { key: 'シリーズC以降', value: this.$t('create.stages.seriesC') },
        { key: '上場企業など', value: this.$t('create.stages.listed') },
      ],
    }
  },
  computed: {
    inputName: {
      get() {
        return this.user.name
      },
      set(newValue) {
        this.$parent.user.name = newValue
      },
    },
    inputCompanyName: {
      get() {
        return this.user.company.company_name
      },
      set(newValue) {
        this.$parent.user.company.company_name = newValue
      },
    },
    selectedPosition: {
      get() {
        return this.user.company.position
      },
      set(newValue) {
        this.$parent.user.company.position = newValue
      },
    },
    selectedEmployee: {
      get() {
        return this.user.company.employees
      },
      set(newValue) {
        this.$parent.user.company.employees = newValue
      },
    },
    selectedStage: {
      get() {
        return this.user.company.stage
      },
      set(newValue) {
        this.$parent.user.company.stage = newValue
      },
    },
  },
  methods: {
    saveProfile() {
      this.$emit('save:profile')
      this.isEditing = false
    },
  },
}
</script>

<style lang="scss" scoped>
.panel {
  &__heading {
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: 2rem;
      line-height: 30px;
      margin-bottom: 20px;
    }
    button {
      box-sizing: border-box;
      color: $color-theme;
      font-weight: bold;
      font-size: 1.4rem;
      text-align: center;
      background: $color-white;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 4px;
      svg {
        width: 24px;
        height: 24px;
        rect {
          fill: $color-theme;
        }
      }
    }
  }
  &-table {
    width: 100%;
    border-collapse: collapse;
    border-color: inherit;
    text-indent: 0;
    tr {
      border-top: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      height: 60px;
      th {
        padding-left: 24px;
      }
      td {
        .itemField {
          width: 260px;
          @include isTablet {
            width: 100%;
          }
        }
        ::v-deep input,
        ::v-deep select {
          height: 40px;
          box-sizing: border-box;
          box-shadow: none;
          border: 1px solid #e5e5e5;
          font-size: 1.4rem;
          border-radius: 4px;
        }
        ::v-deep input {
          padding: 0 20px;
        }
      }
    }
  }
}
</style>
