<template>
  <div class="overLay" @click.self="close">
    <div class="signInModal">
      <div class="signInModal__header">
        <lp-logo />
      </div>
      <div class="signInModal__body">
        <div v-if="isForgetPassword">
          <p class="forgetPasswordMessage">
            {{ $t('auth.resetPasswordConfirm') }}
          </p>
          <p class="forgetPasswordEmail">{{ email }}</p>
          <button class="button cancelButton" @click="isForgetPassword = false">
            {{ $t('cancel') }}
          </button>
          <button class="button submitButton" @click="sendResetPassword">
            {{ $t('auth.submit') }}
          </button>
        </div>
        <div v-else>
          <div v-if="errorMessage" class="message">
            {{ errorMessage }}
          </div>
          <button class="googleButton" @click="signIn">
            <span>
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                alt=""
              />
            </span>
            <span class="signInModal__body-button">
              {{ $t('auth.signInWithGoogle') }}
            </span>
          </button>
          <div class="signInModal_email">
            <p>{{ $t('auth.loginTitle') }}</p>
            <input
              key="email"
              name="email"
              v-if="!verifiedEmailStatus"
              v-model="email"
              type="email"
              :placeholder="$t('auth.email')"
              v-validate="'required|email'"
              data-vv-as="email"
              required
            />
            <p class="errorMessage">{{ errors.first('email') }}</p>
            <div v-if="verifiedEmailStatus === 'signIn'">
              <p class="signInEmail">{{ this.email }}</p>
              <input
                key="password"
                name="password"
                type="password"
                v-model="password"
                :placeholder="$t('auth.password')"
                v-validate="'required'"
                data-vv-as="password"
                required
              />
              <p class="errorMessage">{{ errors.first('password') }}</p>
              <p class="forgetPasswordLink" @click="isForgetPassword = true">
                {{ $t('auth.forgetPassword') }}
              </p>
            </div>
            <div v-if="verifiedEmailStatus === 'signUp'">
              <input
                key="newPassword"
                name="newPassword"
                ref="newPassword"
                type="password"
                v-model="newPassword"
                :placeholder="$t('auth.password')"
                v-validate="
                  'required|regex:^[a-zA-Z0-9!-/:-@¥[-`{-~]*$|min:8|max:100'
                "
                data-vv-as="newPassword"
                required
              />
              <p class="errorMessage">{{ errors.first('newPassword') }}</p>
              <input
                key="confirmPassword"
                name="confirmPassword"
                type="password"
                v-model="confirmPassword"
                :placeholder="$t('auth.passwordConfirm')"
                v-validate="
                  'required|regex:^[a-zA-Z0-9!-/:-@¥[-`{-~]*$|min:8|max:100|confirmed:newPassword'
                "
                data-vv-as="confirmPassword"
                required
              />
              <p class="errorMessage">{{ errors.first('confirmPassword') }}</p>
            </div>
            <div class="buttonArea">
              <button
                v-if="!verifiedEmailStatus"
                class="button nextButton"
                @click="verifyEmail"
              >
                {{ $t('create.next') }}
              </button>
              <div v-if="verifiedEmailStatus">
                <button class="button cancelButton" @click="close">
                  {{ $t('cancel') }}
                </button>
                <button class="button submitButton" @click="emailSignIn">
                  {{
                    verifiedEmailStatus === 'signIn'
                      ? $t('lp.login')
                      : $t('auth.signUp')
                  }}
                </button>
              </div>
            </div>
          </div>
          <p v-html="$t('googleSignInMessage')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LpLogo from '@/assets/svg/LpLogo.svg'
import VeeValidate from 'vee-validate'
import i18n from '../../locales/i18n'

const dictionaries = {
  custom: {
    email: {
      required: i18n.t('auth.validateRequired', {
        field: i18n.t('auth.email'),
      }),
      email: i18n.t('auth.validateEmail'),
    },
    password: {
      required: i18n.t('auth.validateRequired', {
        field: i18n.t('auth.password'),
      }),
    },
    newPassword: {
      required: i18n.t('auth.validateRequired', {
        field: i18n.t('auth.password'),
      }),
      regex: i18n.t('auth.validateFormat'),
      min: i18n.t('auth.validateMin', {
        field: i18n.t('auth.password'),
        num: '8',
      }),
      max: i18n.t('auth.validateMax', {
        field: i18n.t('auth.password'),
        num: '100',
      }),
    },
    confirmPassword: {
      required: i18n.t('auth.validateRequired', {
        field: i18n.t('auth.passwordConfirm'),
      }),
      regex: i18n.t('auth.validateFormat'),
      min: i18n.t('auth.validateMin', {
        field: i18n.t('auth.passwordConfirm'),
        num: '8',
      }),
      max: i18n.t('auth.validateMax', {
        field: i18n.t('auth.passwordConfirm'),
        num: '100',
      }),
      confirmed: i18n.t('auth.validateConfirmed'),
    },
  },
}
VeeValidate.Validator.localize('ja', dictionaries)
VeeValidate.Validator.localize('en', dictionaries)

export default {
  name: 'LoginModal',
  components: {
    LpLogo,
  },
  props: {
    errorMessage: String,
    verifiedEmailStatus: String,
  },
  data() {
    return {
      email: '',
      password: '',
      newPassword: '',
      confirmPassword: '',
      isForgetPassword: false,
    }
  },
  methods: {
    signIn() {
      this.$emit('click:signIn')
    },
    close() {
      this.email = ''
      this.password = ''
      this.newPassword = ''
      this.confirmPassword = ''
      this.isForgetPassword = false
      this.$validator.reset()
      this.$emit('show:close')
    },
    async verifyEmail() {
      const valid = await this.$validator.validateAll({ email: this.email })
      if (!valid) return
      this.$emit('click:verifyEmail', this.email)
    },
    async emailSignIn() {
      const valid = await this.$validator.validateAll({
        password: this.password,
      })
      if (!valid) return
      const password =
        this.verifiedEmailStatus === 'signIn' ? this.password : this.newPassword
      await this.$emit('click:emailSignIn', {
        email: this.email,
        password: password,
      })
    },
    async sendResetPassword() {
      await this.$emit('send:resetPassword', this.email)
      this.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.overLay {
  position: fixed;
  inset: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
}
.signInModal {
  z-index: 2001;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 420px;
  padding-bottom: 40px;
  text-align: center;
  background: $color-white;
  border-radius: 10px 10px 8px 8px;
}
.signInModal__header {
  width: 100%;
  border-radius: 8px 8px 0 0;
  background-color: $color-theme;
  padding: 20px 0;
  path {
    fill: $color-white;
  }
}
.message {
  padding: 16px;
  margin: 32px -24px 0;
  font-size: 1.3rem;
  color: #eb5757;
  background-color: #fdeeee;
}
.signInModal__body {
  padding: 0 40px;
  .forgetPasswordMessage {
    margin-top: 24px;
  }
  .forgetPasswordEmail {
    margin: 16px auto 32px;
    background-color: $color-light-base;
    text-align: left;
    padding: 8px 16px;
  }
  .googleButton {
    margin: 32px auto 24px auto;
    background-color: $color-white;
    border: 0;
    outline: none;
    cursor: pointer;
    min-height: 40px;
    padding: 8px 16px;
    line-height: normal;
    text-align: left;
    border-radius: 2px;
    box-shadow: rgb(0 0 0 / 14%) 0 2px 2px 0, rgb(0 0 0 / 20%) 0 3px 1px -2px,
      rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  }
  img {
    display: initial;
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }
  &-button {
    padding-left: 16px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: bold;
    font-size: 1.4rem;
    font-family: Roboto, sans-serif;
    vertical-align: middle;
  }
}
.signInModal_email {
  border-top: 1px solid $color-light-gray;
  text-align: left;
  padding: 16px 8px;
  p {
    font-weight: bold;
    margin-bottom: 8px;
  }
  input {
    width: 100%;
    font-size: 1.6rem;
    box-sizing: border-box;
    height: 40px;
    padding: 8px;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    outline: none;
    margin-bottom: 4px;
  }
  .buttonArea {
    text-align: center;
    margin: 16px auto 8px;
  }
}
.button {
  box-sizing: border-box;
  width: 124px;
  height: 32px;
  padding: 8px 0;
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
  border-radius: 16px;
  cursor: pointer;
}
.cancelButton {
  color: $color-gray;
  background: #fff;
  border: 1px solid $color-gray;
  margin-right: 16px;
}
.nextButton {
  color: $color-theme;
  background: #fff;
  border: 1px solid $color-theme;
}
.submitButton {
  color: #fff;
  background: #503479;
  border: 1px solid #503479;
}
.errorMessage {
  color: $color-red;
  font-weight: normal !important;
}
.forgetPasswordLink {
  color: $color-theme;
  font-weight: normal !important;
  margin-top: -8px;
  cursor: pointer;
}
.signInEmail {
  margin-top: 16px;
  margin-bottom: 16px;
}
</style>
