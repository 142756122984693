import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import store from './store'
import router from './router'
import normalizeCss from 'normalize.css'
import mainCss from './assets/scss/main.scss'
import i18n from './locales/i18n'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VueApexCharts from 'vue-apexcharts'
import filter from './util/filter'
import VModal from 'vue-js-modal'
import FlashMessage from '@smartweb/vue-flash-message'
import VueGtm from 'vue-gtm'
import VueHead from 'vue-head'
import VeeValidate from 'vee-validate'

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VueAwesomeSwiper)
Vue.use(VueApexCharts)
Vue.use(VModal)
Vue.use(FlashMessage)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueGtm, { id: process.env.VUE_APP_GTM_CODE })
Vue.use(VueHead)
Vue.use(VeeValidate)

new Vue({
  router,
  normalizeCss,
  i18n,
  mainCss,
  filter,
  store,
  render: (h) => h(App),
}).$mount('#app')
