<template>
  <footer class="footer">
    <ul class="footer__links">
      <li>
        <router-link :to="{ name: 'Terms' }">
          {{ $t('lp.links.terms') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'PrivacyPolicy' }">
          {{ $t('lp.links.privacyPolicy') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'Law' }">
          {{ $t('lp.links.law') }}
        </router-link>
      </li>
    </ul>
    <small class="footer__copyright"> ©︎ projection-ai Inc. </small>
  </footer>
</template>

<script>
export default {
  name: 'PaFooter',
}
</script>

<style lang="scss" scoped>
.footer {
  margin-top: auto;
  padding: 20px;
}
.footer__links {
  display: flex;
  justify-content: center;
  li {
    margin: 0 20px;
  }
  a {
    color: rgb(102, 102, 102);
    text-decoration: none;
  }
}
.footer__copyright {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}
</style>
