<template>
  <section class="panel">
    <div class="panel__heading">
      <h2>{{ $t('setting.businessPlanList') }}</h2>
      <div class="businessPlans">
        <div
          v-for="(businessPlan, key) in businessPlans"
          :key="key"
          class="businessPlans__card"
        >
          <div>
            <p class="businessPlans__card-name">
              {{ businessPlan.business_plan_name }}
            </p>
            <span class="businessPlans__card-template">
              {{
                businessPlan.template === 'saas' ? 'SaaS' : 'SaaS(SelfServe)'
              }}
            </span>
          </div>
          <div v-if="businessPlan.is_owner" class="businessPlans__card-button">
            <button @click="clickSettingEdit(businessPlan.business_plan_id)">
              {{ $t('setting.edit') }}
            </button>
          </div>
        </div>
        <button
          v-if="canCreate"
          class="businessPlans__card-add"
          @click="clickAddBusinessPlan"
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1V4H1C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6H4V9C4 9.55229 4.44771 10 5 10C5.55228 10 6 9.55229 6 9V6H9C9.55229 6 10 5.55228 10 5C10 4.44772 9.55229 4 9 4H6V1Z"
              fill="#503479"
            ></path>
          </svg>
          {{ $t('setting.addBusinessPlan') }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import businessPlan from '../../util/businessPlan'

export default {
  name: 'BusinessPlanList',
  props: {
    businessPlans: Array,
    plan: Object,
  },
  computed: {
    canCreate() {
      return businessPlan.canCreateBusinessPlan(this.plan, this.businessPlans)
    },
  },
  methods: {
    async clickSettingEdit(businessPlanId) {
      await this.$store.dispatch('me/setBusinessPlan', businessPlanId)
      await this.$store.dispatch('currentView/hideSettingsModal')
      await this.$router.replace({
        name: 'Setting',
        params: { id: businessPlanId },
      })
      location.reload()
    },
    async clickAddBusinessPlan() {
      await this.$store.dispatch('currentView/hideSettingsModal')
      await this.$router.push({ name: 'Create' })
    },
  },
}
</script>

<style lang="scss" scoped>
.businessPlans {
  margin-top: 24px;
  margin-bottom: 16px;
}
.businessPlans__card {
  background-color: $color-light-base;
  border: 1px solid $color-light-gray;
  padding: 16px 24px;
  margin-bottom: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.businessPlans__card-name {
  font-weight: bold;
  font-size: 1.6rem;
}
.businessPlans__card-template {
  font-size: 1.2rem;
  color: $color-dark-gray;
}
.businessPlans__card-button {
  margin-left: auto;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 120px;
    height: 40px;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 14px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 16px;
    color: $color-theme;
    background: $color-white;
    border: 1px solid $color-theme;
    svg {
      margin-right: 5px;
    }
  }
}
.businessPlans__card-add {
  margin: 24px auto 0;
  background-color: $color-white;
  border: 1px solid $color-theme;
  color: $color-theme;
  width: 262px;
  height: 40px;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
  a:active {
    color: $color-theme;
  }
}
</style>
