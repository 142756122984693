<template>
  <section class="panel">
    <div class="panel__heading">
      <h2>{{ $t('setting.info') }}</h2>
    </div>
    <table class="panel-table">
      <colgroup>
        <col width="200px" />
      </colgroup>
      <tbody>
        <tr>
          <th>{{ $t('setting.email') }}</th>
          <td>{{ user.email }}</td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
export default {
  name: 'UserInfo',
  props: {
    user: Object,
  },
  methods: {
    signOut() {
      this.$emit('click:signOut')
    },
  },
}
</script>

<style lang="scss" scoped>
.panel {
  &__heading {
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: 2rem;
      line-height: 30px;
      margin-bottom: 20px;
    }
  }
  &-table {
    width: 100%;
    border-top: 1px solid #f2f2f2;
    border-collapse: collapse;
    text-indent: 0;
    margin-right: 16px;
    tr {
      height: 54px;
      th {
        padding-left: 24px;
      }
    }
  }
}
</style>
