<template>
  <div class="loader" />
</template>

<script>
export default {
  name: 'LoadingIcon',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.loader {
  margin: 6px;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}
.loader {
  position: relative;
  text-indent: -9999em;
  border-top: 4px solid $color-light-gray;
  border-right: 4px solid $color-light-gray;
  border-bottom: 4px solid $color-light-gray;
  border-left: 3px solid $color-theme;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
