<template>
  <div class="select__container">
    <template v-if="readOnly">
      <p>{{ options.find((x) => x.key === selectedItem).value }}</p>
    </template>
    <template v-else>
      <select
        v-model="selectedItem"
        :style="`height: ${height}px; font-size: ${fontSize}rem`"
        :disabled="disabled"
      >
        <option v-for="(option, key) in options" :key="key" :value="option.key">
          {{ option.value }}
        </option>
      </select>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 9L12 15L6 9"
          stroke="#503479"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PaSelect',
  props: {
    options: Array,
    value: String,
    height: Number,
    fontSize: Number,
    readOnly: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedItem: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.select__container {
  position: relative;
  display: flex;
  align-items: center;
  select {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    padding: 0 60px 0 20px;
    font-weight: bold;
    font-size: 1.8rem;
    background: $color-white;
    border: none;
    border-radius: 8px;
    outline: none;
    box-shadow: rgb(0 0 0 / 20%) 0 0 8px;
    appearance: none;
  }
  select:disabled {
    background-color: #f2f2f2;
  }
  svg {
    position: absolute;
    right: 10px;
    pointer-events: none;

    path {
      stroke: $color-theme;
    }
  }
}
</style>
