<template>
  <section class="kpi__summaryPanel">
    <div class="panel__summary">
      <div class="panel__summary-arr">
        <dt>{{ $t('kpi.target') }} = MRR×12</dt>
        <dd>
          {{ kpi.arr | numberFormat }}<span>{{ unit(currency) }}</span>
        </dd>
      </div>
      <div class="panel__summary-mrrs">
        <div class="summaryFields">
          <dt>MRR</dt>
          <dd>
            {{ kpi.mrr | numberFormat }}<span>{{ unit(currency) }}</span>
          </dd>
        </div>
        <div class="summaryFields">
          <dt>ARPA</dt>
          <dd>
            <pa-input
              class="panel__summary-mrrs-arpa"
              v-model="kpi.arpa"
              :read-only="isViewOnly"
              @change.native="changeSummary('arpa', kpi.arpa)"
            />
            <span>{{ unit(currency) }}</span>
          </dd>
        </div>
        <div class="summaryFields">
          <dt>{{ $t('kpi.increaseTiming') }}</dt>
          <dd>
            <pa-input
              class="panel__summary-mrrs-increaseMonth"
              v-model="kpi.upselling_month"
              :read-only="isViewOnly"
              @change.native="
                changeSummary('upselling_month', kpi.upselling_month)
              "
            />
            <span>{{ $t('kpi.month') }}</span>
          </dd>
        </div>
        <div class="summaryFields">
          <dt>{{ $t('kpi.increaseRate') }}</dt>
          <dd>
            <pa-input
              class="panel__summary-mrrs-increaseRate"
              v-model="kpi.upselling_rate"
              :read-only="isViewOnly"
              @change.native="
                changeSummary('upselling_rate', kpi.upselling_rate)
              "
            />
            <span>%</span>
          </dd>
        </div>
      </div>
      <div class="panel__summary-inputFields">
        <template v-if="template === 'saas'">
          <div class="panel__summary-inputField">
            <dt>
              {{ $t('kpi.leadRate') }}
              <pa-tooltip>
                {{ $t('kpi.leadRateTooltip') }}
              </pa-tooltip>
            </dt>
            <dd>
              <pa-input
                class="panel__summary-inputFields-input"
                v-model="kpi.lead_rate"
                :read-only="isViewOnly"
                @change.native="changeSummary('lead_rate', kpi.lead_rate)"
              />
              <span>%</span>
            </dd>
          </div>
          <div class="panel__summary-inputField">
            <dt>
              {{ $t('kpi.meetingRate') }}
              <pa-tooltip>
                {{ $t('kpi.meetingRateTooltip') }}
              </pa-tooltip>
            </dt>
            <dd>
              <pa-input
                class="panel__summary-inputFields-input"
                v-model="kpi.meeting_rate"
                :read-only="isViewOnly"
                @change.native="changeSummary('meeting_rate', kpi.meeting_rate)"
              />
              <span>%</span>
            </dd>
          </div>
          <div class="panel__summary-inputField">
            <dt>
              {{ $t('kpi.orderRate') }}
              <pa-tooltip>
                {{ $t('kpi.orderRateTooltip') }}
              </pa-tooltip>
            </dt>
            <dd>
              <pa-input
                class="panel__summary-inputFields-input"
                v-model="kpi.order_rate"
                :read-only="isViewOnly"
                @change.native="changeSummary('order_rate', kpi.order_rate)"
              />
              <span>%</span>
            </dd>
          </div>
        </template>
        <template v-else>
          <div class="panel__summary-inputField">
            <dt>
              {{ $t('kpi.registrationRate') }}
              <pa-tooltip>
                {{ $t('kpi.registrationRateTooltip') }}
              </pa-tooltip>
            </dt>
            <dd>
              <pa-input
                class="panel__summary-inputFields-input"
                v-model="kpi.registration_rate"
                :read-only="isViewOnly"
                @change.native="
                  changeSummary('registration_rate', kpi.registration_rate)
                "
              />
              <span>%</span>
            </dd>
          </div>
          <div class="panel__summary-inputField">
            <dt>
              {{ $t('kpi.paidRegistrationRate') }}
              <pa-tooltip>
                {{ $t('kpi.paidRegistrationRateTooltip') }}
              </pa-tooltip>
            </dt>
            <dd>
              <pa-input
                class="panel__summary-inputFields-input"
                v-model="kpi.billing_rate"
                :read-only="isViewOnly"
                @change.native="changeSummary('billing_rate', kpi.billing_rate)"
              />
              <span>%</span>
            </dd>
          </div>
        </template>
        <div class="panel__summary-inputField">
          <dt>
            {{ $t('kpi.churnRate') }}
            <pa-tooltip>{{ $t('kpi.churnRateTooltip') }}</pa-tooltip>
          </dt>
          <dd>
            <pa-input
              class="panel__summary-inputFields-input"
              v-model="kpi.churn_rate"
              :read-only="isViewOnly"
              is-decimal
              decimal-digit="2"
              @change.native="changeSummary('churn_rate', kpi.churn_rate)"
            />
            <span>%</span>
          </dd>
        </div>
      </div>
    </div>
    <div class="panel__summary-image">
      <div v-if="template === 'saas'" class="saas">
        <funnel-img />
        <div class="kpi-data-wrapper">
          <div>
            <p class="color-base">{{ $t('kpi.totalSession') }}</p>
            <p class="color-base">{{ kpi.yearly.session | numberFormat }}</p>
          </div>
          <div>
            <p>{{ $t('kpi.totalLead') }}</p>
            <p>{{ kpi.yearly.lead | numberFormat }}</p>
          </div>
          <div>
            <p>{{ $t('kpi.totalMeeting') }}</p>
            <p>{{ kpi.yearly.meeting | numberFormat }}</p>
          </div>
          <div>
            <p>{{ $t('kpi.totalOrder') }}</p>
            <p>{{ kpi.yearly.order | numberFormat }}</p>
          </div>
          <div>
            <p>{{ $t('kpi.totalChurn') }}</p>
            <p>{{ kpi.yearly.churn | numberFormat }}</p>
          </div>
          <div>
            <p>{{ $t('kpi.totalPayingCustomers') }}</p>
            <p>{{ kpi.yearly.paid | numberFormat }}</p>
          </div>
        </div>
      </div>
      <div v-else class="selfServe">
        <funnel-img-self-serve />
        <div class="kpi-data-wrapper">
          <div>
            <p>{{ $t('kpi.totalSession') }}</p>
            <p>{{ kpi.yearly.session | numberFormat }}</p>
          </div>
          <div>
            <p>{{ $t('kpi.yearRegistration') }}</p>
            <p>{{ kpi.yearly.registration | numberFormat }}</p>
          </div>
          <div>
            <p>{{ $t('kpi.yearPaidRegistration') }}</p>
            <p>{{ kpi.yearly.billing | numberFormat }}</p>
          </div>
          <div>
            <p>{{ $t('kpi.totalChurn') }}</p>
            <p>{{ kpi.yearly.churn | numberFormat }}</p>
          </div>
          <div>
            <p>{{ $t('kpi.totalPayingCustomers') }}</p>
            <p>{{ kpi.yearly.paid | numberFormat }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PaTooltip from '../PaTooltip'
import PaInput from '../PaInput'
import FunnelImg from '@/assets/svg/FunnelImg.svg'
import FunnelImgSelfServe from '@/assets/svg/FunnelImgSelfServe.svg'
import currencyUtil from '../../util/currency'

export default {
  name: 'KpiSummary',
  components: { PaInput, PaTooltip, FunnelImg, FunnelImgSelfServe },
  props: {
    kpi: Object,
    isViewOnly: Boolean,
    template: String,
    currency: String,
  },
  methods: {
    changeSummary(key, value) {
      this.$emit('change:summary', { key: key, value: value })
    },
    unit(currency) {
      return currencyUtil.unit(currency)
    },
  },
}
</script>

<style lang="scss" scoped>
.panel__summary {
  padding: 24px 0 24px 24px;
  background: rgb(246, 245, 248);
  border-radius: 8px 8px 0 0;
  text-align: left;
  span {
    margin-left: 5px;
    color: rgb(51, 51, 51);
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
  }
  &-arr,
  &-mrrs,
  &-inputFields {
    display: flex;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  &-arr {
    margin-bottom: 14px;
    dt {
      display: flex;
      align-items: center;
      color: $color-theme;
      font-weight: bold;
      font-size: 1.6rem;
    }
    dd {
      display: flex;
      margin-left: 16px;
      color: rgb(51, 51, 51);
      font-weight: bold;
      font-size: 2rem;
      line-height: 20px;
    }
  }
  &-mrrs {
    margin-bottom: 16px;
    flex-wrap: wrap;
    margin-right: 16px;
    @include isLarge {
      border-top: 1px solid rgba($color-theme, 0.2);
      border-bottom: 1px solid rgba($color-theme, 0.2);
      padding: 16px 0;
      gap: 5px;
    }
    dt {
      align-items: center;
      color: $color-theme;
      font-weight: bold;
      font-size: 1.6rem;
    }
    dd {
      display: flex;
      align-items: center;
      margin-left: 16px;
      color: rgb(51, 51, 51);
      font-weight: bold;
      font-size: 2rem;
      line-height: 20px;
    }
    &-arpa {
      width: 96px;
    }
    &-increaseMonth,
    &-increaseRate {
      width: 56px;
    }
  }
  &-inputFields {
    flex-wrap: wrap;
    &-input {
      width: 64px;
    }
    dt {
      display: flex;
      align-items: center;
      color: $color-dark-gray;
      font-size: 1.4rem;
      font-weight: bold;
    }
    dd {
      display: flex;
      align-items: center;
      margin-left: 16px;
    }
  }
  &-inputField {
    margin-right: 24px;
  }
}
.summaryFields {
  margin-right: 24px;
}
.panel__summary-image {
  position: relative;
  display: flex;
  padding: 40px 20px 40px 48px;
  color: #ffffff;
  font-weight: bold;
  font-size: 24px;
  font-family: Helvetica LT Pro, sans-serif;
  line-height: 24px;
  text-align: center;
  @include isLarge {
    padding: 10px;
  }
  .saas {
    width: 100%;
    position: relative;
    svg {
      width: 100%;
      height: auto;
    }
    .kpi-data-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-basis: 14.8%;
        font-size: 1.7vw;
        line-height: 2vw;
        p:nth-child(1) {
          font-size: 1.17vw;
        }
      }
    }
  }
  .selfServe {
    width: 100%;
    position: relative;
    svg {
      width: 100%;
      height: auto;
    }
    .kpi-data-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-basis: 18.5%;
        font-size: 1.7vw;
        line-height: 2vw;
        p:nth-child(1) {
          font-size: 1.17vw;
        }
      }
    }
  }
}
</style>
