import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/404'
import ServerError from '../views/500'
import userStore from '../store/user'
import Law from '../views/Law'
import PrivacyPolicy from '../views/PrivacyPolicy'
import Terms from '../views/Terms'
import BusinessPlans from '../views/BusinessPlans'
import Pl from '../views/BusinessPlans/Pl'
import Kpi from '../views/BusinessPlans/Kpi'
import Revenue from '../views/BusinessPlans/Revenue'
import Cost from '../views/BusinessPlans/Cost'
import Dashboard from '../views/BusinessPlans/Dashboard'
import Setting from '../views/BusinessPlans/Setting'
import Create from '../views/Create/Create'
import auth from '../client/auth'
import MonthlyPl from '../views/BusinessPlans/MonthlyPl'
import PasswordReset from '../views/PasswordReset'
import businessPlan from '../util/businessPlan'
import OAuthGoogleDrive from '../views/OAuthGoogleDrive'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/law',
    name: 'Law',
    component: Law,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset,
  },
  {
    path: '/create',
    name: 'Create',
    component: Create,
    meta: { requiresAuth: true },
  },
  {
    path: '/business-plans/:id',
    component: BusinessPlans,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: 'pl',
        name: 'PL',
        component: Pl,
      },
      {
        path: 'monthly_pl',
        name: 'MonthlyPl',
        component: MonthlyPl,
      },
      {
        path: 'expenses',
        name: 'Expenses',
        component: Cost,
      },
      {
        path: 'kpi',
        name: 'Kpi',
        component: Kpi,
      },
      {
        path: 'revenue',
        name: 'Revenue',
        component: Revenue,
      },
      {
        path: 'setting',
        name: 'Setting',
        component: Setting,
      },
    ],
  },
  {
    path: '/oauth/google-drive',
    name: 'OAuthGoogleDrive',
    meta: { requiresAuth: true },
    component: OAuthGoogleDrive,
  },
  {
    path: '/500',
    name: '500',
    component: ServerError,
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    if (to.name === 'PL' && from.name === 'Create') {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  await auth.init()
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !userStore.state().isAuthenticated
  ) {
    next({ name: 'Home' })
  } else {
    if (to.name === 'Create') {
      if (
        !businessPlan.canCreateBusinessPlan(
          userStore.state().user.plan,
          userStore.state().businessPlans
        )
      ) {
        next({
          name: 'PL',
          params: { id: userStore.state().businessPlans[0].business_plan_id },
        })
      }
    }
    next()
  }
})

export default router
