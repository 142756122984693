<template>
  <modal name="deleteCase" :clickToClose="false">
    <div class="deleteCaseContainer">
      <div class="deleteCaseContainer__contents">
        <h3>{{ $t('pl.deleteCaseConfirm') }}</h3>
      </div>
      <div class="deleteCaseContainer__buttons">
        <button class="cancel" @click="hideModal">{{ $t('cancel') }}</button>
        <button class="register" @click="register">{{ $t('submit') }}</button>
      </div>
    </div>
  </modal>
</template>

<script>
import cases from '@/client/cases'

export default {
  name: 'DeleteCase',
  computed: {
    currentBusinessPlanId() {
      return this.$store.getters['currentView/businessPlanId']
    },
    currentCaseId() {
      return this.$store.getters['currentView/caseId']
    },
    cases() {
      return this.$store.getters['me/businessPlan'].cases
    },
  },
  methods: {
    showModal() {
      this.$modal.show('deleteCase')
    },
    hideModal() {
      this.$modal.hide('deleteCase')
    },
    async register() {
      try {
        await cases.delete({
          business_plan_id: this.currentBusinessPlanId,
          case_id: this.currentCaseId,
        })
        await this.$store.dispatch('me/getMe')
        await this.$store.dispatch(
          'me/setBusinessPlan',
          this.currentBusinessPlanId
        )
        await this.$emit('fetchData')
        await this.$store.dispatch(
          'currentView/setCaseId',
          this.cases[0].case_id
        )
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      } finally {
        this.hideModal()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.deleteCaseContainer {
  padding: 30px;
  &__contents {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80px auto 64px;
    padding: 0 30px;
    h3 {
      text-align: left;
      margin-bottom: 5px;
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
    button {
      background-color: $color-white;
      width: 100px;
      padding: 10px 0;
      font-size: 1rem;
      font-weight: bold;
      border-radius: 16px;
      cursor: pointer;
    }
    .cancel {
      color: $color-theme;
      border: 1px solid $color-theme;
    }
    .register {
      color: $color-white;
      background-color: $color-theme;
      border: none;
    }
    .register:disabled {
      background-color: rgb(202, 202, 202);
      cursor: default;
      pointer-events: none;
    }
  }
}
</style>
