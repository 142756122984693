<template>
  <button
    class="subMenuButton"
    :class="{ '--active': isActive }"
    @mouseover="showTooltip = true"
    @mouseleave="showTooltip = false"
  >
    <slot name="label" />
    <span v-if="showTooltip" class="subMenuButton__hoverText">
      <slot name="tooltip" />
    </span>
  </button>
</template>

<script>
export default {
  name: 'SubMenuButton',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTooltip: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.subMenuButton {
  position: relative;
  min-width: 120px;
  font-size: 1.4rem;
  font-weight: bold;
  color: $color-theme;
  background-color: $color-theme-background;
  padding: 10px 0;
  border-radius: 8px;

  &:hover {
    background-color: $color-theme-background-hover;
  }

  &.--active {
    color: $color-white;
    background-color: $color-theme;
  }
}

.subMenuButton__hoverText {
  position: absolute;
  display: flex;
  align-items: flex-end;
  color: $color-theme;
  background-color: $color-white;
  transform: translateY(-100%);
  font-size: 1.2rem;
  text-align: left;
  vertical-align: bottom;
  width: 200px;
  top: -8px;
  left: 25%;
  padding: 8px;
  border-radius: 4px;
  filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.1));
}
</style>
