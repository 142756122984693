<template>
  <div class="settings">
    <business-plan-name
      :business-plan="currentBusinessPlan"
      @click:editBusinessPlan="editBusinessPlan"
      @click:deletePlan="deletePlan"
    />
    <member
      :roles="authority.role"
      :plan-name="user.plan.plan"
      :is-can-edit-addable="isCanEditAddable"
      @click:delete="deleteAuthority"
      @save:add="addAuthority"
      @save:edit="editAuthority"
    />
    <theme-color-panel />
  </div>
</template>

<script>
import auth from '@/client/auth'
import userStore from '@/store/user'
import BusinessPlanName from '../../components/Settings/BusinessPlanName'
import businessPlans from '@/client/businessPlans'
import Member from '../../components/Settings/Member'
import authority from '../../client/authority'
import ThemeColorPanel from '../../components/Settings/ThemeColorPanel'

export default {
  name: 'Setting',
  props: {
    businessPlans: Array,
    currentBusinessPlanId: String,
  },
  components: {
    ThemeColorPanel,
    Member,
    BusinessPlanName,
  },
  data() {
    return {
      authority: {},
    }
  },
  computed: {
    user() {
      return userStore.state().user
    },
    myBusinessPlan() {
      return this.businessPlans.find((item) => item.is_owner)
    },
    currentBusinessPlan() {
      return this.businessPlans.find(
        (item) => item.business_plan_id === this.currentBusinessPlanId
      )
    },
    isCanEditAddable() {
      const hasEditRole = this.authority.role?.filter((role) => {
        return Object.values(role).includes('can_edit')
      })
      return hasEditRole?.length <= 4
    },
  },
  async mounted() {
    if (!this.currentBusinessPlan.is_owner) {
      await this.$router.replace({
        name: 'PL',
        params: { id: this.currentBusinessPlanId },
      })
      return
    }
    await this.getAuthority()
    await this.$store.dispatch('currentView/disableLoading')
  },
  methods: {
    async getAuthority() {
      const result = await authority.retrieve({
        business_plan_id: this.currentBusinessPlanId,
      })
      this.authority = result.data
    },
    async addAuthority(args) {
      try {
        await authority.add({
          business_plan_id: this.currentBusinessPlanId,
          email: args.email,
          dashboard: args.dashboard,
          pl: args.pl,
          monthly_pl: args.monthly_pl,
          sales_detail: args.sales_detail,
          kpi: args.kpi,
          cogs: args.cogs,
          sm: args.sm,
          rd: args.rd,
          ga: args.ga,
          actuals: args.actuals,
        })
        await this.getAuthority()
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
    async editAuthority(args) {
      try {
        await authority.edit({
          business_plan_id: this.currentBusinessPlanId,
          email: args.email,
          dashboard: args.dashboard,
          pl: args.pl,
          monthly_pl: args.monthly_pl,
          sales_detail: args.sales_detail,
          kpi: args.kpi,
          cogs: args.cogs,
          sm: args.sm,
          rd: args.rd,
          ga: args.ga,
          actuals: args.actuals,
        })
        await this.getAuthority()
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
    async deleteAuthority(email) {
      try {
        await authority.delete({
          business_plan_id: this.currentBusinessPlanId,
          email: email,
        })
        await this.getAuthority()
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
    async editBusinessPlan(args) {
      try {
        const formData = new FormData()
        formData.append('business_plan_id', this.currentBusinessPlanId)
        formData.append('business_plan_name', args.business_plan_name)
        formData.append('business_plan_icon', args.business_plan_icon)
        formData.append('start_year', args.start_year)
        formData.append('start_month', args.start_month)

        await businessPlans.edit(formData)
        this.flashMessage.success({
          title: 'Success',
          message: this.$t('setting.editBusinessPlanComputed'),
        })
        await auth.getMe()
        await this.$store.dispatch(
          'me/setBusinessPlan',
          this.currentBusinessPlanId
        )
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
    async deletePlan() {
      try {
        await businessPlans.delete({
          business_plan_id: this.currentBusinessPlanId,
        })
        await auth.getMe()
        const ownerPlans = userStore
          .state()
          .businessPlans.filter((businessPlan) => businessPlan.is_owner)
        if (ownerPlans.length) {
          await this.$store.dispatch(
            'me/setBusinessPlan',
            ownerPlans[0].business_plan_id
          )
          await this.$router.replace({
            name: 'PL',
            params: { id: ownerPlans[0].business_plan_id },
          })
          location.reload()
          return
        }
        await this.$router.replace({ name: 'Create' })
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.settings {
  @include mainContentWidthPadding;
  color: $color-black;
  text-align: left;
  section {
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 10%) 0 0 4px;
    margin-bottom: 24px;
    padding: 40px;
  }
}
</style>
