import chartUtil from './chartUtil'

export default {
  options(term, forecastMonth) {
    return {
      chart: {
        id: 'monthlyPL',
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#325AB4', '#FF8A00'],
      plotOptions: {
        bar: {
          columnWidth: '50%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        strokeColors: ['#325AB4', '#FF8A00'],
      },
      markers: {
        size: 2,
        strokeColors: ['#325AB4', '#FF8A00'],
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      xaxis: {
        tickPlacement: 'between',
        categories: term,
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: [
        {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#666666',
            },
            formatter: (val) => {
              return chartUtil.formatAmount(val)
            },
          },
        },
      ],
      tooltip: {
        enabled: true,
        marker: {
          fillColors: ['#325AB4', '#FF8A00'],
        },
        x: {
          show: false,
        },
      },
      forecastDataPoints: {
        count: forecastMonth,
      },
      legend: {
        show: true,
        fontSize: '10px',
        position: 'bottom',
        horizontalAlign: 'right',
        markers: {
          width: 0,
          fillColors: ['#325AB4', '#FF8A00'],
          offsetY: 5,
          offsetX: -24,
          customHTML: function () {
            return '<span style="font-size: 24px; font-weight: bold;">ー</span>'
          },
        },
        labels: {
          colors: ['#666666'],
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    }
  },
}
