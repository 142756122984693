<template>
  <div class="pl__plan">
    <div class="pl__body-charts">
      <div class="pl__body-chart">
        <p class="pl__body-chart--title">PL</p>
        <apexchart
          type="line"
          width="99%"
          height="500"
          :options="options"
          :series="series"
        />
        <p class="pl__body-moneyLabel">（{{ unit(currency, 'million') }}）</p>
        <p class="pl__body-percentLabel">（%）</p>
      </div>
      <div class="pl__body-chart">
        <p class="pl__body-chart--title">{{ $t('pl.arrPerHeadcount') }}</p>
        <apexchart
          type="line"
          width="99%"
          height="500"
          :options="options"
          :series="arrSeries"
        />
        <p class="pl__body-moneyLabel">（{{ unit(currency, 'million') }}）</p>
        <p class="pl__body-percentLabel">（%）</p>
      </div>
    </div>
  </div>
</template>

<script>
import plChart from '../../charts/plChart'
import currencyUtil from '../../util/currency'

export default {
  name: 'PlPlan',
  props: {
    planCase: Object,
    caseIndex: Number,
    currency: String,
    annualDate: Array,
    colors: Array,
  },
  computed: {
    pls() {
      if (typeof this.planCase === 'undefined') return []
      return this.planCase.pl
    },
    options() {
      return plChart.options(this.caseIndex, this.annualDate, this.colors)
    },
    series() {
      return [
        {
          name: this.$t('pl.arr'),
          type: 'column',
          data: this.pls.map((pl) => pl.arr / 1000000),
        },
        {
          name: this.$t('pl.operatingIncome'),
          type: 'column',
          data: this.pls.map((pl) => pl.operating_pl / 1000000),
        },
        {
          name: this.$t('pl.grossProfitRate'),
          type: 'line',
          data: this.pls.map((pl) => pl.gross_profit_rate),
        },
      ]
    },
    arrSeries() {
      return [
        {
          name: this.$t('pl.employees'),
          type: 'column',
          data: this.pls.map((pl) => pl.employees),
        },
        {
          name: this.$t('pl.arrPerHeadcount'),
          type: 'line',
          data: this.pls.map((pl) => pl.arr_per_headcount / 1000000),
        },
      ]
    },
  },
  methods: {
    unit(currency, unit) {
      return currencyUtil.unit(currency, unit)
    },
  },
}
</script>

<style lang="scss" scoped>
.pl__plan {
  margin: 20px;
}

.pl__body-charts {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.pl__body-chart {
  background-color: $color-white;
  padding: 16px 0 0;
  width: 50%;
  box-shadow: rgb(0 0 0 / 10%) 0 0 4px;

  .pl__body-chart--title {
    text-align: left;
    padding-left: 24px;
    font-weight: bold;
    font-size: 1.6rem;
  }

  .pl__body-moneyLabel,
  .pl__body-percentLabel {
    color: #666666;
    font-size: 10px;
    position: absolute;
  }

  .pl__body-moneyLabel {
    bottom: 54px;
    left: 24px;
  }

  .pl__body-percentLabel {
    bottom: 54px;
    right: 60px;
  }
}
</style>
