<template>
  <business-plan-accessible-layout>
    <div class="dashboard__body">
      <pa-tab @fetch="getDashboard" />
      <div class="dashboard__body-cash">
        <div class="panel dashboard__body-cashBalance">
          <h2 class="title">
            {{
              $t('dashboard.cashBalance', {
                year: currentCase.cash_balance.year,
                month: currentCase.cash_balance.month,
              })
            }}
            <pa-tooltip>{{ $t('dashboard.cashBalanceTooltip') }}</pa-tooltip>
          </h2>
          <div class="cashBalance">
            {{ currentCase.cash_balance.cash_balance.toLocaleString() }}
            <span>{{ unit(currency) }}</span>
          </div>
        </div>
        <div class="panel dashboard__body-cashBalanceChart">
          <apexchart
            type="radialBar"
            height="160px"
            width="180px"
            :series="[70]"
            :options="cashBalanceOptions"
          />
          <span
            v-html="
              $t('dashboard.noCash', {
                monthLater: currentCase.cash_out,
                year: currentCase.cash_out_year,
                month: currentCase.cash_out_month,
              })
            "
            :style="`color: ${cashOutColor};`"
          />
        </div>
      </div>
      <button
        class="dashboard__body-switch"
        @click="showEstimateChart = !showEstimateChart"
        :disabled="isViewOnly"
      >
        <span>{{ $t('dashboard.hideEstimate') }}</span>
        <switch-on v-if="!showEstimateChart" class="--switch-on" />
        <switch-off v-else />
      </button>
      <dashboard-panel
        :title="$t('dashboard.cashBalanceTrend')"
        :tooltip-text="$t('dashboard.cashBalanceTrendTooltip')"
      >
        <template #switch>
          <span class="term">{{ currentTerm }}</span>
        </template>
        <template #contents>
          <span class="chartUnit">{{ `（${unit(currency, 'k')}）` }}</span>
          <apexchart
            :options="lineChartOptions"
            :series="cashBalanceSeries"
            type="line"
            width="100%"
            height="300"
          />
        </template>
      </dashboard-panel>
      <dashboard-mrr-chart
        :items="{ mrr: dashboard.mrr, arr: dashboard.arr }"
        :bar-chart-options="barChartOptions"
        :current-term="currentTerm"
        :currency="currency"
        :show-estimate-chart="showEstimateChart"
      />
      <div class="dashboard__body-arpaSection">
        <div class="panel mrr">
          <div class="mrr__contents">
            <p class="title">{{ $t('dashboard.mrrRate') }}</p>
            <p class="date">
              {{
                $t('dashboard.mrrRateDate', {
                  year: dashboard.mrr_progress.year,
                  month: dashboard.mrr_progress.month,
                })
              }}
            </p>
            <apexchart
              type="radialBar"
              height="260px"
              width="240px"
              :series="[dashboard.mrr_progress.progress_percent]"
              :options="mrrOptions"
              class="apexchart"
            />
            <p class="asis">
              {{
                `${$t(
                  'dashboard.mrrAsIs'
                )} ${dashboard.mrr_progress.as_is.toLocaleString()}`
              }}
              <span>{{ unit(currency, 'k') }}</span>
            </p>
            <p class="goal">
              {{
                `${$t(
                  'dashboard.mrrTarget'
                )} ${dashboard.mrr_progress.goal.toLocaleString()}`
              }}
              <span>{{ unit(currency, 'k') }}</span>
            </p>
            <p class="mrrTerm">{{ currentTerm }}</p>
          </div>
        </div>
        <dashboard-panel
          title="ARPA"
          :tooltip-text="$t('dashboard.arpaTooltip')"
          class="arpa"
        >
          <template #switch>
            <span class="term">{{ currentTerm }}</span>
          </template>
          <template #contents>
            <span class="chartUnit">{{ `（${unit(currency, 'k')}）` }}</span>
            <apexchart
              :options="barChartOptions"
              :series="arpaSeries"
              type="bar"
              width="100%"
              height="300"
            />
          </template>
        </dashboard-panel>
      </div>
      <div class="dashboard__body-chatsSection">
        <dashboard-other-chart
          :title="[$t('dashboard.grossMargin')]"
          :items="[dashboard.gross_margin]"
          :term="term"
          :forecast-month="dashboard.forecast_monthes"
          :unit="['%']"
          :tooltip-text="[$t('dashboard.grossMarginTooltip')]"
          :show-estimate-chart="showEstimateChart"
        />
        <dashboard-other-chart
          :title="[$t('dashboard.cumulativeCustomers')]"
          :items="[dashboard.customers]"
          :term="term"
          :forecast-month="dashboard.forecast_monthes"
          :unit="['']"
          :tooltip-text="[$t('dashboard.cumulativeCustomersTooltip')]"
          :show-estimate-chart="showEstimateChart"
        />
        <dashboard-other-chart
          :title="[$t('dashboard.ltvCac')]"
          :items="[dashboard.ltv_cac]"
          :term="term"
          :forecast-month="dashboard.forecast_monthes"
          :unit="['x']"
          :tooltip-text="[$t('dashboard.ltvCacTooltip')]"
          :show-estimate-chart="showEstimateChart"
        />
        <dashboard-other-chart
          :title="[$t('dashboard.ltv')]"
          :items="[dashboard.ltv]"
          :term="term"
          :forecast-month="dashboard.forecast_monthes"
          :unit="[unit(currency)]"
          :tooltip-text="[$t('dashboard.ltvTooltip')]"
          :show-estimate-chart="showEstimateChart"
        />
        <dashboard-other-chart
          :title="[$t('dashboard.cac')]"
          :items="[dashboard.cac]"
          :term="term"
          :forecast-month="dashboard.forecast_monthes"
          :unit="[unit(currency)]"
          :tooltip-text="[$t('dashboard.cacTooltip')]"
          :show-estimate-chart="showEstimateChart"
        />
        <dashboard-other-chart
          :title="[$t('dashboard.session')]"
          :items="[dashboard.session]"
          :term="term"
          :forecast-month="dashboard.forecast_monthes"
          :unit="['']"
          :tooltip-text="[$t('dashboard.sessionTooltip')]"
          :show-estimate-chart="showEstimateChart"
        />
        <dashboard-other-chart
          v-if="template === 'saas'"
          :title="[$t('dashboard.leadTrend'), $t('dashboard.leadRateTrend')]"
          :items="[dashboard.lead, dashboard.lead_cvr]"
          :term="term"
          :forecast-month="dashboard.forecast_monthes"
          :switch-text="{
            left: $t('dashboard.lead'),
            right: $t('dashboard.leadRate'),
          }"
          :unit="['', '%']"
          :tooltip-text="[
            $t('dashboard.leadTooltip'),
            $t('dashboard.leadRateTooltip'),
          ]"
          :show-estimate-chart="showEstimateChart"
        />
        <dashboard-other-chart
          v-if="template === 'saas'"
          :title="[
            $t('dashboard.opportunitiesTrend'),
            $t('dashboard.opportunitiesRateTrend'),
          ]"
          :items="[dashboard.meeting, dashboard.meeting_cvr]"
          :term="term"
          :forecast-month="dashboard.forecast_monthes"
          :switch-text="{
            left: $t('dashboard.opportunities'),
            right: $t('dashboard.opportunitiesRate'),
          }"
          :unit="['', '%']"
          :tooltip-text="[
            $t('dashboard.opportunitiesTrendTooltip'),
            $t('dashboard.opportunitiesRateTrendTooltip'),
          ]"
          :show-estimate-chart="showEstimateChart"
        />
        <dashboard-other-chart
          v-if="template === 'selfserve'"
          :title="[
            $t('dashboard.registrationTrend'),
            $t('dashboard.registrationRateTrend'),
          ]"
          :items="[dashboard.registration, dashboard.registration_cvr]"
          :term="term"
          :forecast-month="dashboard.forecast_monthes"
          :switch-text="{
            left: $t('dashboard.registration'),
            right: $t('dashboard.registrationRate'),
          }"
          :unit="['', '%']"
          :tooltip-text="[
            $t('dashboard.registrationTrendTooltip'),
            $t('dashboard.registrationRateTrendTooltip'),
          ]"
          :show-estimate-chart="showEstimateChart"
        />
        <dashboard-other-chart
          :title="[
            $t('dashboard.newCustomersTrend'),
            $t('dashboard.newCustomersRateTrend'),
          ]"
          :items="[dashboard.order, dashboard.order_cvr]"
          :term="term"
          :forecast-month="dashboard.forecast_monthes"
          :switch-text="{
            left: $t('dashboard.newCustomers'),
            right: $t('dashboard.newCustomersRate'),
          }"
          :unit="['', '%']"
          :tooltip-text="[
            $t('dashboard.newCustomersTrendTooltip'),
            $t('dashboard.newCustomersRateTrendTooltip'),
          ]"
          :show-estimate-chart="showEstimateChart"
        />
        <dashboard-other-chart
          :title="[$t('dashboard.churnTrend'), $t('dashboard.churnRateTrend')]"
          :items="[dashboard.churn, dashboard.churn_cvr]"
          :term="term"
          :forecast-month="dashboard.forecast_monthes"
          :switch-text="{
            left: $t('dashboard.churn'),
            right: $t('dashboard.churnRate'),
          }"
          :unit="['', '%']"
          :tooltip-text="[
            $t('dashboard.churnTrendTooltip'),
            $t('dashboard.churnRateTrendTooltip'),
          ]"
          :show-estimate-chart="showEstimateChart"
        />
        <dashboard-other-chart
          :title="[$t('dashboard.burnMultiple')]"
          :items="[dashboard.burn_multiple]"
          :term="term"
          :forecast-month="dashboard.forecast_monthes"
          :unit="['']"
          :tooltip-text="[$t('dashboard.burnMultipleTooltip')]"
          :show-estimate-chart="showEstimateChart"
        />
        <dashboard-other-chart
          :title="[$t('dashboard.arrPerEmployee')]"
          :items="[dashboard.arr_per_employees]"
          :term="term"
          :forecast-month="dashboard.forecast_monthes"
          :unit="['']"
          :tooltip-text="[]"
          :show-estimate-chart="showEstimateChart"
        />
        <dashboard-other-chart
          :title="[$t('dashboard.revenuePerEmployee')]"
          :items="[dashboard.revenue_per_employees]"
          :term="term"
          :forecast-month="dashboard.forecast_monthes"
          :unit="['']"
          :tooltip-text="[]"
          :show-estimate-chart="showEstimateChart"
        />
      </div>
    </div>
  </business-plan-accessible-layout>
</template>

<script>
import BusinessPlanAccessibleLayout from '../../components/Common/BusinessPlanAccessibleLayout'
import SwitchOn from '@/assets/svg/SwitchOn.svg'
import SwitchOff from '@/assets/svg/SwitchOff.svg'
import PaTab from '../../components/PaTab'
import currencyUtil from '../../util/currency'
import PaTooltip from '../../components/PaTooltip'
import dashboardChart from '../../charts/dashboardChart'
import DashboardPanel from '../../components/Dashboard/DashboardPanel'
import DashboardOtherChart from '../../components/Dashboard/DashboardOtherChart'
import DashboardMrrChart from '../../components/Dashboard/DashboardMrrChart'
import { format, addMonths, addYears } from 'date-fns'

export default {
  name: 'Dashboard',
  components: {
    BusinessPlanAccessibleLayout,
    SwitchOn,
    SwitchOff,
    DashboardMrrChart,
    DashboardOtherChart,
    DashboardPanel,
    PaTooltip,
    PaTab,
  },
  props: {
    roles: Object,
    term: Array,
    currency: String,
    template: String,
  },
  data() {
    return {
      showEstimateChart: true,
    }
  },
  async mounted() {
    if (!this.currentCaseId) {
      const businessPlan = this.$store.getters['me/businessPlan']
      await this.$store.dispatch(
        'currentView/setCaseId',
        businessPlan.cases[0].case_id
      )
      this.$store.subscribe(async (mutation) => {
        if (mutation.type === 'currentView/setCaseId') {
          await this.getDashboard()
        }
      })
    } else {
      await this.getDashboard()
    }
    await this.$store.dispatch('currentView/disableLoading')
  },
  computed: {
    isViewOnly() {
      return this.roles.dashboard === 'can_view'
    },
    currentCaseId() {
      return this.$store.getters['currentView/caseId']
    },
    currentYear() {
      return this.$store.getters['currentView/year']
    },
    currentCase() {
      return this.$store.getters['dashboard/caseData']
    },
    dashboard() {
      return this.currentCase.dashboard[this.currentYear - 1]
    },
    currentCaseIndex() {
      return this.$store.getters['dashboard/caseIndex']
    },
    currentTerm() {
      const businessPlan = this.$store.getters['me/businessPlan']
      const startYm = new Date(
        businessPlan.start_year,
        businessPlan.start_month - 1
      )
      const currentYm = addYears(startYm, this.currentYear - 1)
      const endYm = addMonths(currentYm, 11)
      return `Year${this.currentYear}：${format(
        currentYm,
        'yyyy/MM'
      )} - ${format(endYm, 'yyyy/MM')}`
    },
    cashBalanceOptions() {
      return dashboardChart.cashBalanceOptions(this.currentCase.cash_out)
    },
    cashOutColor() {
      const cashOut = this.currentCase.cash_out
      if (cashOut <= 12) {
        return '#FF3C50'
      }
      if (cashOut >= 19) {
        return '#FF8A00'
      }
      return '#32B4B4'
    },
    lineChartOptions() {
      return dashboardChart.lineChartOptions(
        this.term,
        this.dashboard.forecast_monthes,
        this.currentCaseIndex,
        true
      )
    },
    barChartOptions() {
      return dashboardChart.barChartOptions(
        this.term,
        this.dashboard.forecast_monthes,
        this.currentCaseIndex,
        this.showEstimateChart
      )
    },
    mrrOptions() {
      return dashboardChart.mrrOptions()
    },
    cashBalanceSeries() {
      const series = [
        {
          name: this.$t('dashboard.act'),
          data: this.dashboard.cash_balance.actual,
        },
      ]
      if (this.showEstimateChart) {
        series.push({
          name: this.$t('dashboard.est'),
          data: this.dashboard.cash_balance.estimate,
        })
      }
      return series
    },
    arpaSeries() {
      const series = [
        {
          name: this.$t('dashboard.act'),
          data: this.dashboard.arpa.actual,
        },
      ]
      if (this.showEstimateChart) {
        series.unshift({
          name: this.$t('dashboard.est'),
          data: this.dashboard.arpa.estimate,
        })
      }
      return series
    },
  },
  methods: {
    async getDashboard() {
      await this.$store.dispatch('dashboard/getDashboard')
    },
    unit(currency, digit = '') {
      return currencyUtil.unit(currency, digit)
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard__body {
  @include mainContentWidthPadding;
  text-align: left;
}
.panel {
  background: $color-white;
  box-shadow: rgb(0 0 0 / 10%) 0 0 4px;
  padding: 16px;
  margin-bottom: 24px;
}
.dashboard__body-cash {
  display: flex;
  gap: 24px;
  margin: 20px 20px 0;
  .panel {
    flex-basis: 50%;
  }
  @media screen and (max-width: 1000px) {
    gap: 0;
    flex-wrap: wrap;
    .panel {
      flex-basis: 100%;
    }
  }
}

.dashboard__body-cashBalance {
  h2 {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
  }
  .title {
    margin-bottom: 16px;
  }
  .cashBalance {
    color: $color-theme;
    background-color: $color-light-base;
    text-align: right;
    padding: 4px 34px;
    font-size: 4.2rem;
    font-weight: bold;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    span {
      font-size: 2.4rem;
    }
  }
}
.dashboard__body-cashBalanceChart {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
}
.dashboard__body-arpaSection {
  display: flex;
  gap: 24px;
  margin: 0 20px;
  width: calc(100% - 40px);
  overflow: hidden;
  .mrr {
    flex-basis: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .arpa {
    flex-basis: 75%;
    gap: 0;
  }
  @include isTablet {
    flex-wrap: wrap;
    gap: 0;
    .mrr {
      flex-basis: 100%;
    }
    .arpa {
      flex-basis: 100%;
    }
  }
}
.mrr__contents {
  text-align: center;
  font-weight: bold;
  .title {
    font-size: 1.8rem;
  }
  .date {
    font-size: 1.1rem;
  }
  .asis {
    font-size: 1.6rem;
    span {
      font-size: 1.4rem;
    }
  }
  .goal {
    font-size: 1.6rem;
    span {
      font-size: 1.4rem;
    }
  }
  .mrrTerm {
    margin-top: 4px;
    font-weight: normal;
    font-size: 1.4rem;
  }
  @include isTablet {
    display: grid;
    grid-template-areas:
      '... chart title ...'
      '... chart date ...'
      '... chart asis ...'
      '... chart goal ...'
      '... chart mrrTerm ...';
    grid-template-columns: 5% 45% 40% 10%;
    grid-template-rows: 35% 16% 12% 12% 25%;
    width: 100%;
    .apexchart {
      grid-area: chart;
      display: grid;
      justify-items: center;
    }
    .title {
      grid-area: title;
      display: grid;
      align-items: end;
    }
    .date {
      grid-area: date;
      display: grid;
      align-items: start;
    }
    .asis {
      grid-area: asis;
      display: flex;
      gap: 5px;
      justify-content: center;
      align-items: flex-end;
    }
    .goal {
      grid-area: goal;
      display: flex;
      gap: 5px;
      justify-content: center;
      align-items: flex-end;
    }
    .mrrTerm {
      grid-area: mrrTerm;
      display: grid;
      align-items: start;
    }
  }
}
.dashboard__body-chatsSection {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(485px, 1fr));
  grid-gap: 24px;
  padding: 0 20px;

  margin-bottom: 24px;
  ::v-deep.panel {
    width: 100%;
    margin-bottom: 0;
  }
  @include isLarge {
    ::v-deep.panel {
      width: 100%;
    }
  }
}
.term {
  font-size: 1.8rem;
  font-weight: bold;
}
.chartUnit {
  position: absolute;
  top: -4px;
  left: 0;
  font-size: 1rem;
}

.dashboard__body-switch {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  user-select: none;
  border: 0;
  outline: none;
  cursor: pointer;
  padding: 0 0 8px;

  span {
    margin: 0 6px;
    font-weight: bold;
    font-size: 1.4rem;
    font-family: $fontFamily-en;
  }

  .--switch-on {
    path:first-child {
      fill: $color-theme;
    }
  }
}
</style>
