<template>
  <div class="home-en-container">
    <section id="section-hero" class="home-en-section-hero section">
      <div class="home-en-main-contants">
        <div class="home-en-text-wrapper-1">
          <div class="home-en-container001">
            <span class="home-en-text003">The Fastest</span>
            <span class="home-en-text004">Financial Plan Builder</span>
          </div>
          <span class="home-en-text005">for SaaS Startups</span>
        </div>
        <div class="home-en-text-wrapper-2">
          <span class="home-en-text006">全てのSaaSスタートアップに</span>
          <span class="home-en-text007">ハイグレードな</span>
          <span class="home-en-text008">事業計画を</span>
        </div>
        <div class="home-en-iframe-container">
          <iframe
            src="https://app.quden.io/embed/player/623d3d72af13cd00272667ab"
            class="home-en-iframe"
          ></iframe>
        </div>
        <div class="home-en-image-container"></div>
        <div class="home-en-paragraph-container">
          <span class="home-en-text009">
            <span>
              “projection-ai” helps you create a top-down financial plan by
              selecting three simple items:
            </span>
            <span></span>
          </span>
          <div class="home-en-container002">
            <div class="home-en-container003">
              <div class="home-en-container004">
                <div class="home-en-container005">
                  <span class="home-en-text012">
                    <span>1</span>
                    <span></span>
                  </span>
                </div>
                <span class="home-en-text015">
                  <span>Business Model Template</span>
                  <span></span>
                </span>
              </div>
            </div>
            <div class="home-en-container006">
              <div class="home-en-container007">
                <div class="home-en-container008">
                  <span class="home-en-text018"><span>2</span></span>
                </div>
                <span class="home-en-text020">
                  <span>ARPA (Average Revenue Per Account)</span>
                </span>
              </div>
            </div>
            <div class="home-en-container009">
              <div class="home-en-container010">
                <div class="home-en-container011">
                  <span class="home-en-text022"><span>3</span></span>
                </div>
                <span class="home-en-text024">
                  <span>Growth Multiplier</span>
                </span>
              </div>
            </div>
          </div>
          <span class="home-en-text026">
            <span>
              We want to help startups create their financial plan in the
              easiest and quickest way possible.
            </span>
          </span>
        </div>
      </div>
    </section>
    <div class="home-en-section-logo section">
      <div class="home-en-container012">
        <img
          alt="image"
          src="/static/lp/images/logo_company01.svg"
          class="home-en-image06"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company02.svg"
          class="home-en-image07"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company03.svg"
          class="home-en-image08"
        />
        <img
          alt="image"
          src="/static/lp/images/cloudbrains.svg"
          class="home-en-image09"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company06.svg"
          class="home-en-image10"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company07.svg"
          class="home-en-image11"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company02.svg"
          class="home-en-image12"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company04.svg"
          class="home-en-image13"
        />
      </div>
      <div class="home-en-container013">
        <img
          alt="image"
          src="/static/lp/images/logo_company05.svg"
          class="home-en-image14"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company06.svg"
          class="home-en-image15"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company07.svg"
          class="home-en-image16"
        />
        <img
          alt="image"
          src="/static/lp/images/logo_company02.svg"
          class="home-en-image17"
        />
      </div>
    </div>
    <section id="section-steps" class="home-en-section-steps section-ai">
      <div class="home-en-en-title-wrapper">
        <span class="home-en-text029"><span>Features</span></span>
        <div class="home-en-titlebar-buttom"></div>
      </div>
      <div class="home-en-container014">
        <div class="home-en-step-title-item">
          <div class="home-en-container015">
            <span class="home-en-text031 en_Heading2">1</span>
          </div>
          <span class="home-en-text032">Sign Up</span>
        </div>
        <div class="home-en-step-title-item1">
          <div class="home-en-container016">
            <span class="home-en-text033 en_Heading2">2</span>
          </div>
          <span class="home-en-text034"> Build Multiple Financial Cases </span>
        </div>
        <div class="home-en-step-title-item2">
          <div class="home-en-container017">
            <span class="home-en-text035 en_Heading2">3</span>
          </div>
          <span class="home-en-text036">Calculate Monthly KPI</span>
        </div>
        <div class="home-en-step-title-item3">
          <div class="home-en-container018">
            <span class="home-en-text037 en_Heading2">4</span>
          </div>
          <span class="home-en-text038">Manage P&amp;L</span>
        </div>
        <div class="home-en-step-title-item4">
          <div class="home-en-container019">
            <span class="home-en-text039 en_Heading2">5</span>
          </div>
          <span class="home-en-text040">Use Dashboard</span>
        </div>
      </div>
      <div class="home-en-step-item-setup">
        <div class="home-en-container020">
          <div class="home-en-step-title-wrapper">
            <img
              alt="image"
              src="/static/lp/images/5-corner.svg"
              class="home-en-image18"
            />
            <span class="home-en-text041">Sign Up</span>
          </div>
          <div class="home-en-step-content">
            <span class="home-en-text042">
              <span>
                Sign Up using google account or your personal email.
              </span>
            </span>
          </div>
        </div>
        <img
          alt="image"
          src="/static/lp/images/en_01-contant-wrapper-1500w.png"
          class="home-en-image19"
        />
      </div>
      <div class="home-en-step-item-setup1">
        <div class="home-en-container021">
          <div class="home-en-step-title-wrapper1">
            <img
              alt="image"
              src="/static/lp/images/5-corner.svg"
              class="home-en-image20"
            />
            <span class="home-en-text045">Set Up</span>
          </div>
          <div class="home-en-step-content1">
            <span class="home-en-text046">
              <span>
                Once you have entered your company information, select your
                business model, enter your ARPA (Average Revenue Per Account),
                Growth multiple, and the Business start date. Press Create plan
                and you are ready to go.
              </span>
            </span>
          </div>
        </div>
        <img
          alt="image"
          src="/static/lp/images/en_02-contant-wrapper-1500w.png"
          class="home-en-image21"
        />
      </div>
      <div class="home-en-step-item-setup2">
        <div class="home-en-container022">
          <div class="home-en-step-title-wrapper2">
            <img
              alt="image"
              src="/static/lp/images/5-corner.svg"
              class="home-en-image22"
            />
            <span class="home-en-text048">
              Build Multiple Financial Cases
            </span>
          </div>
          <div class="home-en-step-content2">
            <span class="home-en-text049">
              <span>
                After the setup, the P&amp;L page will be created automatically
                but you will be able to edit the following items to tweak the
                model to your needs.
              </span>
            </span>
            <div class="home-en-container023">
              <div class="home-en-container024">
                <button class="home-en-button-like content3">ARR</button>
              </div>
              <div class="home-en-container025">
                <button class="home-en-button-like content3">
                  Cost of Service
                </button>
              </div>
              <div class="home-en-container026">
                <button class="home-en-button-like content3">
                  Operating Expenses
                </button>
              </div>
            </div>
            <span class="home-en-text051">
              <span>
                You can also easily create additional cases (base case, best
                case, worst case). It&apos;s always good to think through
                different scnenarios to be well prepared in advance.
              </span>
            </span>
          </div>
        </div>
        <img
          alt="image"
          src="/static/lp/images/en_02_2-contant-wrapper-1500w.png"
          class="home-en-image23"
        />
      </div>
      <div class="home-en-step-item-setup3">
        <div class="home-en-container027">
          <div class="home-en-step-title-wrapper3">
            <img
              alt="image"
              src="/static/lp/images/5-corner.svg"
              class="home-en-image24"
            />
            <span class="home-en-text053">Calculate Monthly KPIs</span>
          </div>
          <div class="home-en-step-content3">
            <span class="home-en-text054">
              <span>
                In order to achieve your target ARR, it&apos;s important to know
                your Key Performance Indicator(KPI), such as leads,
                opportunities, paying customers, and churn rate. projection-ai
                automatically calculates these KPIs for you on an annual and
                monthly basis.
              </span>
            </span>
          </div>
        </div>
        <img
          alt="image"
          src="/static/lp/images/en_03-contant-wrapper-1500w.png"
          class="home-en-image25"
        />
      </div>
      <div class="home-en-step-item-setup4">
        <div class="home-en-container028">
          <div class="home-en-step-title-wrapper4">
            <img
              alt="image"
              src="/static/lp/images/5-corner.svg"
              class="home-en-image26"
            />
            <span class="home-en-text056">Manage P&amp;L</span>
          </div>
          <div class="home-en-step-content4">
            <span class="home-en-text057">
              <span>
                After setting up your financial plan, you can compare your
                Actuals versus your Plan using the Monthly PL feature. You can
                also create an updated plan using the &quot;Revise the
                budge&quot; button.
              </span>
            </span>
            <div class="home-en-container029">
              <div
                class="
                  step-item-name-container step-item-name-root-class-name12
                "
              >
                <button class="step-item-name-button content3">
                  <span>Sales</span>
                </button>
              </div>
              <div
                class="
                  step-item-name-container step-item-name-root-class-name12
                "
              >
                <button class="step-item-name-button content3">
                  <span>COGS</span>
                </button>
              </div>
              <div
                class="
                  step-item-name-container step-item-name-root-class-name12
                "
              >
                <button class="step-item-name-button content3">
                  <span>S&M</span>
                </button>
              </div>
              <div
                class="
                  step-item-name-container step-item-name-root-class-name12
                "
              >
                <button class="step-item-name-button content3">
                  <span>R&D</span>
                </button>
              </div>
              <div
                class="
                  step-item-name-container step-item-name-root-class-name12
                "
              >
                <button class="step-item-name-button content3">
                  <span>G&A</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <img
          alt="image"
          src="/static/lp/images/en_04-contant-wrapper-1500w.png"
          class="home-en-image27"
        />
      </div>
      <div class="home-en-step-item-setup5">
        <div class="home-en-container030">
          <div class="home-en-step-title-wrapper5">
            <img
              alt="image"
              src="/static/lp/images/5-corner.svg"
              class="home-en-image28"
            />
            <span class="home-en-text059">Use Dashboard</span>
          </div>
          <div class="home-en-step-content5">
            <span class="home-en-text060">
              <span>
                Our dashboard gives you all the metrics you need to run your
                SaaS startup. You can also share it with existing investors or
                new investors to communicate clearly and seemlessly.
              </span>
            </span>
          </div>
          <div class="home-en-container031">
            <div
              class="step-item-name-container step-item-name-root-class-name12"
            >
              <button class="step-item-name-button content3">
                <span>ARR</span>
              </button>
            </div>
            <div
              class="step-item-name-container step-item-name-root-class-name12"
            >
              <button class="step-item-name-button content3">
                <span>LTV</span>
              </button>
            </div>
            <div
              class="step-item-name-container step-item-name-root-class-name12"
            >
              <button class="step-item-name-button content3">
                <span>CAC</span>
              </button>
            </div>
            <div
              class="step-item-name-container step-item-name-root-class-name12"
            >
              <button class="step-item-name-button content3">
                <span>Conversion%</span>
              </button>
            </div>
            <div
              class="step-item-name-container step-item-name-root-class-name12"
            >
              <button class="step-item-name-button content3">
                <span>ARPA</span>
              </button>
            </div>
          </div>
        </div>
        <img
          alt="image"
          src="/static/lp/images/en_05-contant-wrapper-1500w.png"
          class="home-en-image29"
        />
      </div>
    </section>
    <div class="red section-full home-en-cv-01">
      <div class="home-en-container032">
        <div class="home-en-step-title-wrapper6">
          <span class="home-en-text062">
            Build a Financial Plan for Your Startup
          </span>
        </div>
        <div class="home-en-step-content6">
          <div class="home-en-container033"></div>
        </div>
      </div>
    </div>
    <section id="section-feature" class="home-en-section-feature section-ai">
      <div class="home-en-step-container">
        <div class="home-en-en-title-wrapper1">
          <span class="home-en-text063"> Benefits of using projection-ai </span>
          <div class="home-en-titlebar-buttom1"></div>
        </div>
        <div class="home-en-container034">
          <div class="feature-item-container feature-item-root-class-name8">
            <div class="feature-item-container1">
              <img
                alt="image"
                src="/static/lp/images/feature01.svg"
                class="feature-item-image"
              />
              <span class="feature-item-text en_Heading2">
                <span>01</span>
              </span>
            </div>
            <span class="feature-item-text1">
              <span>Create Error Free Top-Down Financial Plan</span>
            </span>
            <span class="feature-item-text2">
              <span>
                With projection-ai, you can automatically calculate KPIs
                required to achieve your goal by breaking down the target ARR
                into key components and action plan. By visualizing the KPIs
                necessary to achieve your goal, you can build a financial plan
                that shows the path to success.
              </span>
            </span>
          </div>
          <div class="feature-item-container feature-item-root-class-name8">
            <div class="feature-item-container1">
              <img
                alt="image"
                src="/static/lp/images/feature01.svg"
                class="feature-item-image"
              />
              <span class="feature-item-text en_Heading2">
                <span>02</span>
              </span>
            </div>
            <span class="feature-item-text1">
              <span>Seamlessly Manage Your Profits and Losses</span>
            </span>
            <span class="feature-item-text2">
              <span>
                With projection-ai, you can not only create a financial plan,
                but also manage the actual results against the plan. By managing
                the forecast and actual results on a monthly basis, you can
                correct your course and get closer to achieving your goals in
                the most efficient manner.
              </span>
            </span>
          </div>
          <div class="feature-item-container feature-item-root-class-name8">
            <div class="feature-item-container1">
              <img
                alt="image"
                src="/static/lp/images/feature01.svg"
                class="feature-item-image"
              />
              <span class="feature-item-text en_Heading2">
                <span>03</span>
              </span>
            </div>
            <span class="feature-item-text1">
              <span>
                Metrics that are aligned with the SaaS Business Model
              </span>
            </span>
            <span class="feature-item-text2">
              <span>
                With projection-ai, it's easy to discuss and take actions from
                the perspective of the entire funnel, such as "How much of an
                impact would a 1% improvement in churn have on revenue?" or "How
                many annual sessions to an LP do we need to increase the number
                of paying users by X?
              </span>
            </span>
          </div>
          <div class="feature-item-container feature-item-root-class-name8">
            <div class="feature-item-container1">
              <img
                alt="image"
                src="/static/lp/images/feature01.svg"
                class="feature-item-image"
              />
              <span class="feature-item-text en_Heading2">
                <span>04</span>
              </span>
            </div>
            <span class="feature-item-text1">
              <span>Get Deep Insights on SaaS Industry</span>
            </span>
            <span class="feature-item-text2">
              <span>
                In projection-ai, we have incorporated data and knowledge on
                growth multiplier, cost structures, and workforce planning for
                SaaS companies in Japan and overseas. We have also incorporated
                the knowledge of One Capital CEO Shinji Asada, a VC specializing
                in SaaS, so you can easily understand what VCs are looking for.
              </span>
            </span>
          </div>
        </div>
      </div>
    </section>
    <section id="section-voices" class="home-en-section-steps1">
      <div class="en-title-wrapper-en-title-wrapper">
        <span class="en-title-wrapper-text"><span>Testimonials</span></span>
        <div class="en-title-wrapper-titlebar-buttom"></div>
      </div>
      <span class="home-en-text064">
        <span class="home-en-text065">Voice of Customers</span>
      </span>
      <customer-voices />
    </section>
    <div class="home-en-cv-02">
      <div class="home-en-container043">
        <div class="home-en-container044">
          <div class="home-en-step-title-wrapper7">
            <span class="home-en-text070">
              Build a Financial Plan for Your Startup
            </span>
          </div>
          <div class="home-en-step-content7">
            <div class="home-en-container045"></div>
          </div>
        </div>
        <img
          alt="image"
          src="/static/lp/images/kv02-1500w.png"
          class="home-en-image33"
        />
      </div>
    </div>
    <section id="section-media" class="home-en-section-media">
      <div class="home-en-en-title-wrapper2">
        <span class="home-en-text072"><span>Media</span></span>
        <div class="home-en-titlebar-buttom2"></div>
      </div>
      <span class="home-en-text074">
        <span class="home-en-text075">Media Coverage</span>
      </span>
      <div class="home-en-step-container2">
        <div class="home-en-container046">
          <a
            href="https://thebridge.jp/2021/06/projection-ai-beta-launch"
            target="_blank"
            rel="noreferrer noopener"
            class="home-en-link08"
          >
            <div class="home-en-container047">
              <div class="home-en-container048">
                <div class="home-en-container049">
                  <img
                    alt="image"
                    src="/static/lp/images/logo.svg"
                    class="home-en-image34"
                  />
                </div>
                <div class="home-en-container050">
                  <span class="home-en-text076">
                    Projection-ai, a business plan SaaS created by VCs.
                  </span>
                  <span class="home-en-text077">
                    It is common for investors to create and launch their own
                    platforms necessary for day-to-day investment activities.
                  </span>
                  <div class="home-en-container051">
                    <span class="home-en-text078 en_Heading2">
                      <span>See more</span>
                    </span>
                    <img
                      alt="image"
                      src="/static/lp/images/arrow-right.svg"
                      class="home-en-image35"
                    />
                  </div>
                </div>
              </div>
            </div>
          </a>
          <a
            href="https://jp.techcrunch.com/2021/06/07/2021%E2%88%9206-07%E2%88%92projection-ai-bata-release/"
            target="_blank"
            rel="noreferrer noopener"
            class="home-en-link09"
          >
            <div class="home-en-container052">
              <div class="home-en-container053">
                <div class="home-en-container054">
                  <img
                    alt="image"
                    src="/static/lp/images/techcrunch-min-800w.png"
                    class="home-en-image36"
                  />
                </div>
                <div class="home-en-container055">
                  <span class="home-en-text080">
                    Eliminating Unfulfilled Business Plans,
                    &quot;projection-ai&quot; Beta Version Released.
                  </span>
                  <span class="home-en-text081">
                    projection-ai, which helps people who want to start SaaS
                    startups create business plans, announced the public beta
                    release.
                  </span>
                  <div class="home-en-container056">
                    <span class="home-en-text082 en_Heading2">
                      <span>See more</span>
                    </span>
                    <img
                      alt="image"
                      src="/static/lp/images/arrow-right.svg"
                      class="home-en-image37"
                    />
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
    <section id="section-price" class="home-en-section-price">
      <div class="home-en-section-steps2 section-ai">
        <div class="home-en-en-title-wrapper3">
          <span class="home-en-text084"><span>Price</span></span>
          <div class="home-en-titlebar-buttom3"></div>
        </div>
        <span class="home-en-text086">
          <span class="home-en-text087">
            You can choose the best plan for your startup based on your company
            size.
          </span>
        </span>
        <div class="home-en-step-container3">
          <div class="home-en-container057">
            <div class="home-en-price-item">
              <div class="home-en-item-wrapper-top">
                <img
                  alt="image"
                  src="/static/lp/images/free.svg"
                  class="home-en-image38"
                />
                <span class="home-en-text088">
                  <span>
                    Best for entrepreneurs thinking of starting a startup and
                    just want to build P&amp;L quickly.
                  </span>
                </span>
                <div class="home-en-container058"></div>
                <div class="home-en-container059"></div>
                <div class="home-en-container060">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image39"
                  />
                  <span class="home-en-text090 content3">
                    <span>Creating P&amp;L / Scenario analysis</span>
                  </span>
                </div>
              </div>
              <div class="home-en-container061">
                <div class="home-en-container062"></div>
                <div class="home-en-container063">
                  <span class="home-en-text092">$</span>
                  <span class="home-en-text093"><span>0</span></span>
                </div>
              </div>
            </div>
            <div class="home-en-price-item1">
              <div class="home-en-item-wrapper-top1">
                <img
                  alt="image"
                  src="/static/lp/images/basic.svg"
                  class="home-en-image40"
                />
                <span class="home-en-text095"><span></span></span>
                <span class="home-en-text097">
                  <span>Best for seed and early-stage startups.</span>
                </span>
                <div class="home-en-container064"></div>
                <div class="home-en-container065"></div>
                <div class="home-en-container066">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image41"
                  />
                  <span class="home-en-text099 content3">
                    <span>Creating P&amp;L / Scenario analysis</span>
                  </span>
                </div>
                <div class="home-en-container067">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image42"
                  />
                  <span class="home-en-text101 content3">
                    <span>Creating sales detail plan</span>
                  </span>
                </div>
                <div class="home-en-container068">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image43"
                  />
                  <span class="home-en-text103 content3">
                    <span>Calculating monthly SaaS-specific KPIs</span>
                  </span>
                </div>
                <div class="home-en-container069">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image44"
                  />
                  <span class="home-en-text105 content3">
                    <span>Creating cost detail plan</span>
                  </span>
                </div>
                <div class="home-en-container070">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image45"
                  />
                  <span class="home-en-text107 content3">
                    <span>Forecast/Actual management</span>
                  </span>
                </div>
                <div class="home-en-container071">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image46"
                  />
                  <span class="home-en-text109 content3">
                    <span>Viewing dashboard</span>
                  </span>
                </div>
                <div class="home-en-container072">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image47"
                  />
                  <span class="home-en-text111 content3">
                    <span>Access to Knowledge Page</span>
                  </span>
                </div>
                <div class="home-en-container073">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image48"
                  />
                  <span class="home-en-text113 content3">
                    <span>Controlling access permission</span>
                  </span>
                </div>
                <div class="home-en-container074">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image49"
                  />
                  <span class="home-en-text115 content3">
                    <span>Adding additional viewers (no limit)</span>
                  </span>
                </div>
                <div class="home-en-container075">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image50"
                  />
                  <span class="home-en-text117 content3">
                    <span>
                      Support for building financial plan / meeting with VC team
                      at One Capital before financing (once a year, 60 min)
                    </span>
                  </span>
                </div>
                <div class="home-en-container076">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image51"
                  />
                  <span class="home-en-text119 content3">
                    <span>Create a business plan / up to 2</span>
                  </span>
                </div>
              </div>
              <div class="home-en-container077">
                <div class="home-en-container078"></div>
                <div class="home-en-container079">
                  <span class="home-en-text121">$</span>
                  <span class="home-en-text122"
                    ><span>
                      {{
                        isMonthly
                          ? $t('price.monthly.basic')
                          : $t('price.yearly.basic')
                      }}
                    </span></span
                  >
                  <span class="home-en-text124">/ month</span>
                </div>
              </div>
            </div>
            <div class="home-en-price-item2">
              <div class="home-en-item-wrapper-top2">
                <img
                  alt="image"
                  src="/static/lp/images/team.svg"
                  class="home-en-image52"
                />
                <span class="home-en-text125">
                  <span>Best for mid-stage and later-stage startups.</span>
                </span>
                <div class="home-en-container080"></div>
                <div class="home-en-container081"></div>
                <div class="home-en-container082">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image53"
                  />
                  <span class="home-en-text127 content3">
                    <span>Creating P&amp;L / Scenario analysis</span>
                  </span>
                </div>
                <div class="home-en-container083">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image54"
                  />
                  <span class="home-en-text129 content3">
                    <span>Creating sales detail plan</span>
                  </span>
                </div>
                <div class="home-en-container084">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image55"
                  />
                  <span class="home-en-text131 content3">
                    <span>Calculating monthly SaaS-specific KPIs</span>
                  </span>
                </div>
                <div class="home-en-container085">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image56"
                  />
                  <span class="home-en-text133 content3">
                    <span>Creating cost detail plan</span>
                    <span class="home-en-text135"></span>
                  </span>
                </div>
                <div class="home-en-container086">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image57"
                  />
                  <span class="home-en-text136 content3">
                    <span>Forecast/Actual management</span>
                  </span>
                </div>
                <div class="home-en-container087">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image58"
                  />
                  <span class="home-en-text138 content3">
                    <span>Viewing dashboard</span>
                  </span>
                </div>
                <div class="home-en-container088">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image59"
                  />
                  <span class="home-en-text140 content3">
                    <span>Access to Knowledge Page</span>
                  </span>
                </div>
                <div class="home-en-container089">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image60"
                  />
                  <span class="home-en-text142 content3">
                    <span>Controlling access permission</span>
                  </span>
                </div>
                <div class="home-en-container090">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image61"
                  />
                  <span class="home-en-text144 content3">
                    <span>Adding additional viewers (no limit)</span>
                  </span>
                </div>
                <div class="home-en-container091">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image62"
                  />
                  <span class="home-en-text146 content3">
                    <span>Adding additional editors (up to 4 person)</span>
                  </span>
                </div>
                <div class="home-en-container092">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image63"
                  />
                  <span class="home-en-text148 content3">
                    <span>
                      Support for building financial plan / meeting with VC team
                      at One Capital before financing (once a year, 60 min)
                    </span>
                  </span>
                </div>
                <div class="home-en-container093">
                  <img
                    alt="image"
                    src="/static/lp/images/notification-icon.svg"
                    class="home-en-image64"
                  />
                  <span class="home-en-text150 content3">
                    <span>Create a business plan / up to 3</span>
                  </span>
                </div>
              </div>
              <div class="home-en-container094">
                <div class="home-en-container095"></div>
                <div class="home-en-container096">
                  <span class="home-en-text152">¥</span>
                  <span class="home-en-text153">
                    <span>
                      {{
                        isMonthly
                          ? $t('price.monthly.team')
                          : $t('price.yearly.team')
                      }}
                    </span>
                  </span>
                  <span class="home-en-text155">/ month</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="plan__header">
          <button class="plan__header-switch" @click="isMonthly = !isMonthly">
            <span>Monthly Price</span>
            <switch-off v-if="isMonthly" />
            <switch-on v-else />
            <span>Annual Price（15% OFF）</span>
          </button>
        </div>
        <span class="home-en-text156"><span>* including tax</span></span>
      </div>
    </section>
    <div class="red section-full home-en-cv-03">
      <div class="home-en-container097">
        <div class="home-en-step-title-wrapper8">
          <span class="home-en-text158">
            Build a Financial Plan for Your Startup
          </span>
        </div>
        <div class="home-en-step-content8">
          <div class="home-en-container098"></div>
        </div>
      </div>
    </div>
    <section id="section-product" class="home-en-section-product section-ai">
      <div class="home-en-container099">
        <div class="home-en-en-title-wrapper4">
          <span class="home-en-text159"><span>SaaS Database</span></span>
          <div class="home-en-titlebar-buttom4"></div>
        </div>
        <span class="home-en-text161">
          <span class="home-en-text162">
            Supporting Entrepreneurs by developing products you need!
          </span>
        </span>
      </div>
      <div class="home-en-step-container4">
        <div class="home-en-container100">
          <div class="home-en-container101">
            <div class="home-en-container102">
              <span class="home-en-text163">projection-ai:db</span>
              <span class="home-en-text164">
                <span class="home-en-text165">
                  projection-ai:db is a database specializing in the SaaS
                  market, containing information such as the
                </span>
                <span class="home-en-text166">size of the</span>
                <span class="home-en-text167"></span>
                <span class="home-en-text168">
                  SaaS market, multiples of listed companies, SaaS metrics,
                </span>
                <span class="home-en-text169">etc.</span>
              </span>
              <div class="home-en-container103">
                <div class="home-en-container104">
                  <a
                    href="https://db.projection-ai.com/"
                    target="_blank"
                    rel="noreferrer noopener"
                    class="home-en-link10 en_Heading2"
                  >
                    <span>See more</span>
                  </a>
                  <img
                    alt="image"
                    src="/static/lp/images/arrow-right.svg"
                    class="home-en-image65"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="home-en-container101">
            <div class="home-en-container102">
              <span class="home-en-text163">projection-ai:blog</span>
              <span class="home-en-text164">
                <span class="home-en-text165">
                  projection-ai: blog is a medium that makes information about
                  SaaS in Japan and overseas easier to understand and more open.
                </span>
              </span>
              <div class="home-en-container103">
                <div class="home-en-container104">
                  <a
                    href="https://blog.projection-ai.com/"
                    target="_blank"
                    rel="noreferrer noopener"
                    class="home-en-link10 en_Heading2"
                  >
                    <span>See more</span>
                  </a>
                  <img
                    alt="image"
                    src="/static/lp/images/arrow-right.svg"
                    class="home-en-image65"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import '@/assets/lp/css/home-en.css'
import '@/assets/lp/css/reset.css'
import '@/assets/lp/css/style.css'
import SwitchOff from '@/assets/svg/SwitchOff.svg'
import SwitchOn from '@/assets/svg/SwitchOn.svg'
import CustomerVoices from './CustomerVoices'

export default {
  name: 'MainContainerEn',
  components: { CustomerVoices, SwitchOff, SwitchOn },
  data() {
    return {
      isMonthly: true,
    }
  },
  methods: {
    openDemoLink() {
      window.open(this.$t('meetingUrl'))
    },
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.google.com/noto/specimen/Noto+Sans+JP?subset=japanese');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.plan__header {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.plan__header-switch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  user-select: none;
  background-color: $color-white;
  border: 0;
  outline: none;
  cursor: pointer;
  span {
    margin: 0 12px;
    font-weight: bold;
    font-size: 1.6rem;
    font-family: $fontFamily-en;
  }
}
</style>
