<template>
  <modal name="deleteMember" :clickToClose="false">
    <div class="deleteMemberContainer">
      <div class="deleteMemberContainer__contents">
        <h3>
          {{ $t('setting.roles.deleteMemberMessage', { name: displayName }) }}
        </h3>
      </div>
      <div class="deleteMemberContainer__buttons">
        <button class="cancel" @click="hideModal">{{ $t('cancel') }}</button>
        <button class="register" @click="register">{{ $t('submit') }}</button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'deleteMember',
  data() {
    return {
      name: '',
      email: '',
    }
  },
  computed: {
    displayName() {
      return this.name || this.email
    },
  },
  methods: {
    showModal(name, email) {
      this.name = name
      this.email = email
      this.$modal.show('deleteMember')
    },
    hideModal() {
      this.name = ''
      this.email = ''
      this.$modal.hide('deleteMember')
    },
    register() {
      this.$emit('click:delete', this.email)
      this.hideModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.deleteMemberContainer {
  padding: 30px;
  &__contents {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80px auto 64px;
    padding: 0 30px;
    h3 {
      text-align: left;
      margin-bottom: 5px;
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
    button {
      background-color: $color-white;
      width: 100px;
      padding: 10px 0;
      font-size: 1rem;
      font-weight: bold;
      border-radius: 16px;
      cursor: pointer;
    }
    .cancel {
      color: $color-theme;
      border: 1px solid $color-theme;
    }
    .register {
      color: $color-white;
      background-color: $color-theme;
      border: none;
    }
    .register:disabled {
      background-color: rgb(202, 202, 202);
      cursor: default;
      pointer-events: none;
    }
  }
}
</style>
