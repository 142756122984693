import Vue from 'vue'

const state = Vue.observable({
  windowWidth: 1280,
})

export default {
  state() {
    return state
  },

  setWidth(value) {
    state.windowWidth = value
  },

  isPC() {
    return state.windowWidth > 1280
  },

  isLap() {
    return state.windowWidth <= 1280
  },

  isLarge() {
    return state.windowWidth <= 1024
  },

  isTablet() {
    return state.windowWidth <= 768
  },

  isSP() {
    return state.windowWidth <= 375
  },
}
