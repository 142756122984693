<template>
  <div class="panel__container">
    <div class="panel__inner">
      <article class="panel__title">
        <div class="panel__title-tool">
          <h2>{{ chartTitle[graphType] }}</h2>
          <pa-tooltip>{{ $t('monthlyPl.graph.tooltip') }}</pa-tooltip>
        </div>
        <pa-select
          :height="40"
          :fontSize="1.2"
          :options="selectOptions"
          v-model="selectedOptions"
        />
      </article>
      <div class="chart">
        <apexchart
          :options="options"
          :series="series"
          type="line"
          width="100%"
          height="100%"
        />
        <p class="chartUnit">{{ chartUnit ? `(${chartUnit})` : '' }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import PaTooltip from '../PaTooltip'
import PaSelect from '../Welcome/PaSelect'
import MonthlyPlChart from '../../charts/monthlyPlChart'
import currency from '../../util/currency'
export default {
  name: 'MonthlyPLChart',
  components: { PaSelect, PaTooltip },
  props: {
    graphType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chartTitle: {
        kpi: 'KPI',
        pl: 'PL & Cash Flow',
      },
      selectedOptions: 'session',
    }
  },
  created() {
    this.selectedOptions = this.graphType === 'kpi' ? 'session' : 'arpa'
  },
  computed: {
    selectOptionsList() {
      return {
        pl: [
          {
            key: 'arpa',
            value: this.$t('monthlyPl.graph.arpa'),
            unit: currency.unit(this.currency),
          },
          {
            key: 'mrr',
            value: this.$t('monthlyPl.graph.mrr'),
            unit: currency.unit(this.currency),
          },
          {
            key: 'revenue',
            value: this.$t('monthlyPl.graph.revenue'),
            unit: currency.unit(this.currency),
          },
          {
            key: 'cumulative_revenue',
            value: this.$t('monthlyPl.graph.cumulativeRevenue'),
            unit: currency.unit(this.currency),
          },
          {
            key: 'ending_cash_balance',
            value: this.$t('monthlyPl.graph.endingCashBalance'),
            unit: currency.unit(this.currency),
          },
        ],
        kpi: {
          saas: [
            {
              key: 'session',
              value: this.$t('monthlyPl.graph.session'),
              unit: '',
            },
            {
              key: 'lead',
              value: this.$t('monthlyPl.graph.lead'),
              unit: '',
            },
            {
              key: 'meeting',
              value: this.$t('monthlyPl.graph.meeting'),
              unit: '',
            },
            {
              key: 'order',
              value: this.$t('monthlyPl.graph.order'),
              unit: this.$t('companies'),
            },
            {
              key: 'churn',
              value: this.$t('monthlyPl.graph.churn'),
              unit: this.$t('companies'),
            },
            {
              key: 'cumulative_customers',
              value: this.$t('monthlyPl.graph.cumulativeCustomers'),
              unit: this.$t('companies'),
            },
          ],
          selfserve: [
            {
              key: 'session',
              value: this.$t('monthlyPl.graph.session'),
              unit: '',
            },
            {
              key: 'registration',
              value: this.$t('monthlyPl.graph.registration'),
              unit: '',
            },
            {
              key: 'order',
              value: this.$t('monthlyPl.graph.order'),
              unit: this.$t('companies'),
            },
            {
              key: 'churn',
              value: this.$t('monthlyPl.graph.churn'),
              unit: this.$t('companies'),
            },
            {
              key: 'cumulative_customers',
              value: this.$t('monthlyPl.graph.cumulativeCustomers'),
              unit: this.$t('companies'),
            },
          ],
        },
      }
    },
    businessPlan() {
      return this.$store.getters['me/businessPlan']
    },
    template() {
      return this.businessPlan.template
    },
    currency() {
      return this.businessPlan.currency
    },
    term() {
      return this.$store.getters['me/term']
    },
    graph() {
      return this.$store.getters['monthlyPl/graph']
    },
    selectOptions() {
      if (this.graphType === 'kpi') {
        return this.selectOptionsList.kpi[this.template]
      } else {
        return this.selectOptionsList.pl
      }
    },
    chartUnit() {
      if (!this.selectOptions) return ''
      return this.selectOptions.find(
        (option) => option.key === this.selectedOptions
      ).unit
    },
    options() {
      if (!this.graph) return
      return MonthlyPlChart.options(this.term, this.graph.forecast_monthes)
    },
    series() {
      return [
        {
          name: this.$t('monthlyPl.graph.plan'),
          data: this.graph[this.selectedOptions].estimate,
        },
        {
          name: this.$t('monthlyPl.graph.actual'),
          data: this.graph[this.selectedOptions].actual,
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.panel__container {
  width: 100%;
  min-height: 350px;
  margin-bottom: 24px;
  box-shadow: rgb(0 0 0 / 10%) 0 0 4px;

  @include isTablet {
    &:first-child {
      margin-bottom: 0;
    }
  }
  .panel__inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 24px;
    background: $color-white;

    display: flex;
    flex-direction: column;
  }
}
.panel__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  margin-bottom: 24px;
  h2 {
    font-size: 1.6rem;
  }
  &-tool {
    display: flex;
    align-items: center;
  }
  ::v-deep select {
    border: 1px solid $color-light-gray;
    border-radius: 4px;
    box-shadow: none;
    padding: 0 40px 0 16px;
  }
}
::v-deep .apexcharts-legend {
  gap: 24px;
}
.chart {
  position: relative;
  flex-grow: 1;
}
.chartUnit {
  position: absolute;
  top: 0;
  left: 0;
  color: $color-dark-gray;
  font-size: 1rem;
}
</style>
