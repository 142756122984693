import i18n from '../locales/i18n'
import lang from '../locales/lang'

export default {
  unit(currency, digit = '') {
    let unit = currency === 'JPY' ? i18n.t('yen') : i18n.t('usd')
    if (digit === 'k') {
      if (currency === 'JPY') {
        unit =
          lang.browserLanguage() === 'ja'
            ? i18n.t('k')
            : `${unit} ${i18n.t('k')}`
      } else {
        unit = `${unit} ${i18n.t('kUsd')}`
      }
    } else if (digit === 'million') {
      if (currency === 'JPY') {
        unit =
          lang.browserLanguage() === 'ja'
            ? i18n.t('million')
            : `${unit} ${i18n.t('million')}`
      } else {
        unit = `${unit} ${i18n.t('millionUsd')}`
      }
    }
    return unit
  },
}
