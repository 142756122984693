<template>
  <div class="plComparitionCharts">
    <div v-for="chart in plComparitionCharts" :key="chart.name">
      <h2
        class="plComparitionTitle"
        :class="chart.name === 'arr' ? 'arrChart' : 'plChart'"
      >
        <div
          class="before"
          :style="`background-color: ${
            chart.name === 'arr' ? colors[0][0] : colors[0][1]
          }`"
        />
        {{ chart.title }}
      </h2>
      <div class="plComparisonContainer">
        <apexchart
          :key="`${chart.name}${key}`"
          :options="chart.options"
          :series="chart.series"
          type="bar"
          width="100%"
          height="300"
        />
        <p>（{{ chart.unit }}）</p>
      </div>
    </div>
  </div>
</template>

<script>
import plChart from '../../charts/plChart'
import currencyUtil from '../../util/currency'
import localUtil from '../../util/local'

export default {
  name: 'PlComparisonCharts',
  props: {
    businessPlan: Array,
    currency: String,
    annualDate: Array,
    colors: Array,
  },
  data() {
    return {
      key: 0,
    }
  },
  computed: {
    arrColors() {
      return [this.colors[0][0], this.colors[1][0], this.colors[2][0]]
    },
    plColors() {
      return [this.colors[0][1], this.colors[1][1], this.colors[2][1]]
    },
    arrOptions() {
      return plChart.comparisonArrOptions(this.annualDate, this.arrColors)
    },
    plOptions() {
      return plChart.comparisonPlOptions(this.annualDate, this.plColors)
    },
    arrSeries() {
      return this.businessPlan.map((item) => ({
        name: localUtil.caseName(item.case_name),
        data: item.pl.map((pl) => pl.arr / 1000000),
      }))
    },
    plSeries() {
      return this.businessPlan.map((item) => ({
        name: localUtil.caseName(item.case_name),
        data: item.pl.map((pl) => pl.operating_pl / 1000000),
      }))
    },
    plComparitionCharts() {
      return [
        {
          name: 'arr',
          title: this.$t('pl.arr'),
          options: this.arrOptions,
          series: this.arrSeries,
          unit: this.unit(this.currency, 'million'),
        },
        {
          name: 'pl',
          title: this.$t('pl.operatingIncome'),
          options: this.plOptions,
          series: this.plSeries,
          unit: this.unit(this.currency, 'million'),
        },
      ]
    },
  },
  methods: {
    unit(currency, unit) {
      return currencyUtil.unit(currency, unit)
    },
  },
  watch: {
    colors: {
      handler() {
        this.key = this.key ? 0 : 1
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
h2.plComparitionTitle {
  text-align: left;
  margin-left: 30px;
  position: relative;
  .before {
    left: -54px;
    top: 0;
    bottom: 0;
    width: 36px;
    position: absolute;
  }
  &:first-child {
    margin-top: 10px;
  }
}
.plComparisonContainer {
  margin-bottom: 40px;
  p {
    color: $color-dark-gray;
    font-size: 1rem;
    position: absolute;
    bottom: 40px;
    left: 32px;
  }
}
</style>
