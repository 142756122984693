<template>
  <div id="top" class="home">
    <mobile-menu
      v-if="showMobileMenu"
      @show:modal="showModal"
      @click:menu="clickMenu"
    />
    <pa-header @show:modal="showModal" @click:menu="clickMenu" />
    <main-container-en v-if="isEn" />
    <main-container-ja v-else />
    <pa-footer />
    <login-modal
      v-show="showLoginModal"
      :error-message="errorMessage"
      :verified-email-status="verifiedEmailStatus"
      @click:signIn="signIn"
      @show:close="closeModal"
      @click:verifyEmail="verifyEmail"
      @click:emailSignIn="emailSignIn"
      @send:resetPassword="sendResetPassword"
    />
    <FlashMessage />
  </div>
</template>

<script>
import LoginModal from '../components/Lp/LoginModal'
import auth from '../client/auth'
import userStore from '../store/user'
import MainContainerJa from '../components/Lp/MainContainerJa'
import PaHeader from '../components/Lp/PaHeader'
import PaFooter from '../components/Lp/PaFooter'
import MobileMenu from '../components/Lp/MobileMenu'
import MainContainerEn from '../components/Lp/MainContainerEn'
import lang from '@/locales/lang'

export default {
  name: 'Home',
  components: {
    MainContainerEn,
    MobileMenu,
    PaFooter,
    PaHeader,
    MainContainerJa,
    LoginModal,
  },
  data() {
    return {
      showLoginModal: false,
      showMobileMenu: false,
      errorMessage: '',
      verifiedEmailStatus: '',
    }
  },
  computed: {
    isEn() {
      return lang.browserLanguage() === 'en'
    },
  },
  methods: {
    clickMenu() {
      this.showMobileMenu = !this.showMobileMenu
    },
    showModal() {
      this.errorMessage = ''
      this.showLoginModal = true
    },
    closeModal() {
      this.showLoginModal = false
      this.verifiedEmailStatus = ''
    },
    async emailSignIn(args) {
      try {
        const result = await auth.authForEmail(args.email, args.password)
        if (result.status === 209) {
          this.errorMessage = this.$t('auth.loginFailed')
          return
        }
        const businessPlan = userStore
          .state()
          .businessPlans.filter((businessPlan) => businessPlan.is_owner)
        if (businessPlan.length > 0) {
          this.$router.replace({
            name: 'PL',
            params: { id: businessPlan[0].business_plan_id },
          })
        } else {
          this.$router.replace({ name: 'Create' })
        }
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
    async signIn() {
      try {
        const result = await auth.auth()
        if (result.status === 209) {
          this.errorMessage = this.$t('auth.requiredDemo')
          return
        }
        if (result.status === 210) {
          this.errorMessage = this.$t('auth.emailUser')
          return
        }
        const businessPlan = userStore
          .state()
          .businessPlans.filter((businessPlan) => businessPlan.is_owner)
        if (businessPlan.length > 0) {
          this.$router.replace({
            name: 'PL',
            params: { id: businessPlan[0].business_plan_id },
          })
        } else {
          this.$router.replace({ name: 'Create' })
        }
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
    async verifyEmail(email) {
      this.errorMessage = ''
      try {
        const result = await auth.checkAuthenticationType(email)
        const statusCode = result.data.status_code
        if (statusCode === 'cant_signup') {
          this.errorMessage = this.$t('auth.requiredDemo')
          return
        }
        if (statusCode === 'google_signup_user') {
          this.errorMessage = this.$t('auth.googleUser')
          return
        }
        if (statusCode === 'can_signup') {
          this.verifiedEmailStatus = 'signUp'
        }
        if (statusCode === 'email_signup_user') {
          this.verifiedEmailStatus = 'signIn'
        }
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
    async sendResetPassword(email) {
      try {
        await auth.applyResetPassword(email)
        this.flashMessage.success({
          title: 'Success',
          message: this.$t('auth.passwordResetRequest'),
        })
      } catch (e) {
        this.flashMessage.error({
          title: e.response.title,
          message: e.response.message,
        })
      }
    },
    demo() {
      window.open(this.$t('meetingUrl'))
    },
  },
}
</script>
<style lang="scss" scoped>
.home {
  margin-top: -110px;
  padding-top: 110px;
}
.contents {
  margin-top: 105px;
}
.hero {
  display: flex;
}
.hero__left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $color-theme;
  h2 {
    color: $color-white;
    text-align: left;
    font-size: 2rem;
    letter-spacing: 0.1em;
    margin-left: 1em;
  }
  button {
    margin-top: 24px;
    color: $color-theme;
    background: $color-white;
    font-weight: bold;
    width: 280px;
    height: 40px;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 8px;
    outline: none;
    cursor: pointer;
  }
}
.hero__right {
  width: 50%;
  background-image: url(/static/img/hero.jpg);
  background-position: center center;
  background-size: cover;
}
.sectionImage {
  width: 100vw;
  margin-bottom: 108px;
  &-catch {
    padding: 30px 0;
    color: $color-white;
    font-weight: bold;
    text-align: center;
    background: linear-gradient(
      352.41deg,
      rgb(80, 52, 121) 21.72%,
      rgb(80, 52, 121) 79.67%
    );
    @include isPC() {
      font-size: 2.4rem;
    }
    @include isSP() {
      font-size: 2rem;
    }
  }
  &-section1,
  &-section2 {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  &-section1 {
    background-image: url(/static/img/img-concept.jpg);
  }
  &-section2 {
    background-image: url(/static/img/img-service-features.jpg);
  }
}
@include isPC() {
  .hero {
    height: 400px;
    padding-top: 60px;
  }
  .hero__left {
    width: 50%;
    h2 {
      font-size: 2rem;
    }
  }
  .sectionImage {
    &-section1,
    &-section2 {
      height: 400px;
    }
  }
}
@include isSP() {
  .hero {
    flex-flow: column-reverse wrap;
    height: auto;
  }
  .hero__left {
    width: 100%;
    height: 280px;

    h2 {
      font-size: 1.6rem;
    }
  }
  .hero__right {
    width: 100%;
    height: 300px;
  }
  .sectionImage {
    &-section1,
    &-section2 {
      height: 150px;
    }
  }
}
</style>
