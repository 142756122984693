<template>
  <div class="home-ja-container044">
    <div class="home-ja-container045">
      <div class="home-ja-container046">
        <div class="home-ja-arrow-container">
          <img
            alt="image"
            src="/static/lp/images/arrow-white.svg"
            class="home-ja-image34"
            @click="prev"
          />
        </div>
        <div class="home-ja-arrow-container1">
          <img
            alt="image"
            src="/static/lp/images/arrow-right.svg"
            class="home-ja-image35"
            @click="next"
          />
        </div>
      </div>
    </div>
    <div class="home-ja-container047">
      <img
        alt="image"
        :src="`/static/lp/images/${customers[index].image}`"
        class="home-ja-image36"
      />
      <div class="home-ja-container048">
        <span class="home-ja-text086" v-html="$t(customers[index].comment)" />
        <div class="home-ja-container049">
          <span class="home-ja-text087">{{ customers[index].name }}</span>
          <div class="home-ja-container050">
            <span class="home-ja-text088">{{ customers[index].company }}</span>
            <span class="home-ja-text089">{{ customers[index].position }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/lp/css/reset.css'
import '@/assets/lp/css/style.css'
import '@/assets/lp/css/home-ja.css'

export default {
  name: 'CustomerVoices',
  data() {
    return {
      index: 0,
      /* eslint-disable */
      customers: [
        {
          name: this.$t('lp.customer.baseMachina.name'),
          position: this.$t('lp.customer.baseMachina.position'),
          company: this.$t('lp.customer.baseMachina.company'),
          comment: this.$t('lp.customer.baseMachina.comment'),
          image: 'base-machina.jpeg',
        },
        {
          name: this.$t('lp.customer.cloudBrains.name'),
          position: this.$t('lp.customer.cloudBrains.position'),
          company: this.$t('lp.customer.cloudBrains.company'),
          comment: this.$t('lp.customer.cloudBrains.comment'),
          image: 'cloud-brains.png',
        },
        {
          name: this.$t('lp.customer.delight.name'),
          position: this.$t('lp.customer.delight.position'),
          company: this.$t('lp.customer.delight.company'),
          comment: this.$t('lp.customer.delight.comment'),
          image: 'delight.png',
        },
        {
          name: this.$t('lp.customer.porro.name'),
          position: this.$t('lp.customer.porro.position'),
          company: this.$t('lp.customer.porro.company'),
          comment: this.$t('lp.customer.porro.comment'),
          image: 'porro.jpeg',
        },
        {
          name: this.$t('lp.customer.nota.name'),
          position: this.$t('lp.customer.nota.position'),
          company: this.$t('lp.customer.nota.company'),
          comment: this.$t('lp.customer.nota.comment'),
          image: 'nota.png',
        },
        {
          name: this.$t('lp.customer.superDuper.name'),
          position: this.$t('lp.customer.superDuper.position'),
          company: this.$t('lp.customer.superDuper.company'),
          comment: this.$t('lp.customer.superDuper.comment'),
          image: 'super-duper.jpeg',
        },
        {
          name: this.$t('lp.customer.tsunaguAi.name'),
          position: this.$t('lp.customer.tsunaguAi.position'),
          company: this.$t('lp.customer.tsunaguAi.company'),
          comment: this.$t('lp.customer.tsunaguAi.comment'),
          image: 'tsunagu-ai.png',
        },
        {
          name: this.$t('lp.customer.zipunk.name'),
          position: this.$t('lp.customer.zipunk.position'),
          company: this.$t('lp.customer.zipunk.company'),
          comment: this.$t('lp.customer.zipunk.comment'),
          image: 'zipunk.png',
        },
        {
          name: this.$t('lp.customer.dsk.name'),
          position: this.$t('lp.customer.dsk.position'),
          company: this.$t('lp.customer.dsk.company'),
          comment: this.$t('lp.customer.dsk.comment'),
          image: 'dsk.png',
        },
        {
          name: this.$t('lp.customer.cosmeHunt.name'),
          position: this.$t('lp.customer.cosmeHunt.position'),
          company: this.$t('lp.customer.cosmeHunt.company'),
          comment: this.$t('lp.customer.cosmeHunt.comment'),
          image: 'cosme-hunt.png',
        },
      ],
      /* eslint-enable */
    }
  },
  methods: {
    prev() {
      if (this.index === 0) {
        this.index = this.customers.length - 1
        return
      }
      this.index--
    },
    next() {
      if (this.customers.length - 1 === this.index) {
        this.index = 0
        return
      }
      this.index++
    },
  },
}
</script>

<style lang="scss" scoped>
.home-ja-container046 {
  img {
    cursor: pointer;
  }
}
</style>
