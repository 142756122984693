<template>
  <div class="messageBox">
    <warning-icon />
    <slot />
  </div>
</template>

<script>
import WarningIcon from '@/assets/svg/WarningIcon.svg'
export default {
  name: 'WarningMessageBox',
  components: {
    WarningIcon,
  },
}
</script>

<style lang="scss" scoped>
.messageBox {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  background-color: #fff3cd;
  color: #856404;
  padding: 16px;
  text-align: left;
  border-radius: 8px;
  font-weight: bold;
}
</style>
