import businessPlans from '@/client/businessPlans'

const state = {
  caseIndex: 0,
  caseData: {
    case_id: '',
    case_name: '',
    cash_balance: {
      cash_balance: 0,
      month: 0,
      year: 0,
    },
    cash_out: 0,
    cash_out_month: 0,
    cash_out_year: 0,
    dashboard: [],
  },
}

const getters = {
  caseIndex: (state) => state.caseIndex,
  caseData: (state) => state.caseData,
}

const mutations = {
  getDashboard(state, { caseData, currentCaseIndex }) {
    state.caseIndex = currentCaseIndex
    state.caseData = { ...caseData }
  },
}

const actions = {
  async getDashboard({ commit, rootState }) {
    const businessPlanId = rootState.currentView.businessPlanId
    const currentCaseId = rootState.currentView.caseId
    const currentCaseIndex = rootState.me.businessPlan.cases.findIndex(
      (x) => x.case_id === currentCaseId
    )
    const result = await businessPlans.getDashboard({
      business_plan_id: businessPlanId,
      case_id: currentCaseId,
    })
    const caseData = result.data
    commit('getDashboard', { caseData, currentCaseIndex })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
