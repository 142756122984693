<template>
  <div>
    <template v-if="readOnly">
      <p>{{ value | numberFormat }}</p>
    </template>
    <template v-else>
      <input
        type="text"
        v-model="inputValue"
        :maxlength="maxLength"
        autocomplete="off"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'PaInput',
  props: {
    value: Number,
    maxLength: Number,
    readOnly: Boolean,
    isMinus: {
      type: Boolean,
      default: true,
    },
    isDecimal: {
      type: Boolean,
      default: false,
    },
    decimalDigit: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      inputValue: (this.value || 0).toLocaleString(),
    }
  },
  watch: {
    value() {
      this.inputValue = this.value.toLocaleString()
    },
    inputValue(newValue) {
      // 小数点以下を許可するかどうかで正規表現の条件を変える
      const regex = this.isDecimal ? /[^\d.]/g : /[^0-9-]+/g
      // まずは数字以外の文字列を削除し整数または小数にする
      let result = newValue.replace(regex, '')
      // マイナス値許可しない場合は符号消す
      result = this.isMinus ? result : result.replace('-', '')
      // 符号が不正な状態だった場合に削除する
      result = result.replace('--', '-')
      result.replace(/(.)-/g, () => {
        result = arguments[1].replace(/,/g, '')
      })
      // 小数入力中かどうかの状態を保持する
      const isDecimalEditing = result.endsWith('.')
      // 小数第2以下は切り捨て
      result =
        Math.floor(result * 10 ** this.decimalDigit) / 10 ** this.decimalDigit
      // 数値としての入力値を値としてはセット
      this.$emit('input', result)
      // 小数入力中だった場合、Math処理かましたことにより小数点が喪失するので表示上もとにもどしてあげる
      result = isDecimalEditing ? result + '.' : result
      // 3桁カンマ区切りにして表示する
      this.$nextTick(() => (this.inputValue = result.toLocaleString()))
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
  width: 100%;
}
input {
  font-size: 1.4rem;
  line-height: 14px;
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  padding: 6px;
  font-weight: bold;
  text-align: right;
  @include inputElement;
}
</style>
